import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import SignUpBanner from "../components/Banner";
import { useHistory, useLocation } from "react-router";
import InputPassword from "../../../components/Forms/Input/InputPassword";
import { authService } from "../../../service";
import toast from "../../../components/Toast";
import Loading from "../../../components/Loading";
import ExpiredLink from "../../StaticPages/ExpiredLink";

export default function ResetPassword(props) {
  const location = useLocation();
  const history = useHistory();

  const [formData, changeFormData] = useState({});
  const [errors, changeErrors] = useState({});
  const [loading, changeLoading] = useState(true);
  const [expiredLink, changeExpiredLink] = useState(false);

  const params = new URLSearchParams(location.search);

  const resetPwd = async () => {
    let email = params.get("email");
    let passCode = params.get("key");
    let password = formData.newPassword;
    let confirmPassword = formData.confirmPassword;

    if (email && passCode) {
      try {
        const response = await authService.setPassword({
          email,
          passCode,
          password,
          confirmPassword,
        });

        if (response?.data?.status?.code === 200) {
          toast({ type: "success", msg: `Your password has been successfully setup. Kindly login to continue` });
          history.push("/auth/login");
        } else {
          toast({ type: "error", msg: response?.data?.status?.message });
        }
      } catch (e) {
        let error = "";
        if (e?.response?.data?.status?.message) {
          error = e?.response?.data?.status?.message;
        } else {
          error = "Something went wrong!! try latter";
        }
        if (error) {
          toast({ type: "error", msg: error });
        }
      }
    }
  };

  const validateForm = () => {
    let _errors = {};
    if (!formData?.newPassword) {
      _errors = { ..._errors, newPassword: "This is a required field" };
    }
    if (!formData?.confirmPassword) {
      _errors = { ..._errors, confirmPassword: "This is a required field" };
    }

    if (formData?.confirmPassword !== formData?.newPassword) {
      _errors = { ..._errors, confirmPassword: "Password doesn’t match" };
    }

    changeErrors(_errors);

    return Object.keys(_errors).every((it) => _errors[it]?.length === 0);
  };

  useEffect(() => {
    if (!window.location.href.includes("/auth/set-password?email")) {
      history.push("/auth/login");
    } else {
      let email = params.get("email");
      let passCode = params.get("key");

      verifyExpiredLink({ email: email, verificationCode: passCode, type: 1 });
    }
  }, []);

  const verifyExpiredLink = async (obj) => {
    try {
      await authService.validateCode(obj);
      changeLoading(false);
    } catch (e) {
      changeLoading(false);

      // history.push("/expired-reset-link");
      changeExpiredLink(true);
      console.log(e);
    }
  };

  // const isDataValid = () => {
  //   return Object.keys(errors).every((it) => errors[it]?.length === 0);
  // };

  if (loading) return <Loading />;

  return (
    <>
      {!expiredLink ? (
        <section className="registration-form">
          <div className="container-fluid">
            <Row className="registration-form-container">
              <Col md={7} className="reg-form-wrapper">
                <div className="reg-form-inner">
                  <div className="form-logo">
                    <img src="/media/onboarding/logo.png" />
                    <span>UniSuite</span>
                  </div>
                </div>
                <div className="reg-form-inner">
                  <div className="form-steps-heading">
                    <div className="form-heading">
                      <div className="form-title">Set Password</div>
                      <div>In order to protect your account, please use passwords that meet the security conditions.</div>
                      <InputPassword
                        required
                        autoValidate
                        showCondition
                        name="newPassword"
                        label={"Enter new Password"}
                        placeholder={"Enter Password"}
                        value={formData?.newPassword}
                        onChange={(e) => {
                          changeFormData({
                            ...formData,
                            newPassword: e.value,
                          });
                          changeErrors({
                            ...errors,
                            newPassword: null,
                          });
                        }}
                        error={errors?.newPassword}
                      />

                      <InputPassword
                        required
                        name="confirmPassword"
                        label={"Confirm Password"}
                        placeholder={"Re-enter password"}
                        value={formData?.confirmPassword}
                        onChange={(e) => {
                          changeFormData({
                            ...formData,
                            confirmPassword: e.value,
                          });
                          changeErrors({
                            ...errors,
                            confirmPassword: null,
                          });
                        }}
                        error={errors?.confirmPassword}
                      />

                      <Button
                        className="top-28"
                        onClick={() => {
                          console.log(validateForm());
                          if (validateForm()) {
                            resetPwd();
                          }
                        }}
                        // disabled={!isDataValid()}
                      >
                        Save Changes
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={5} className="reg-form-banner">
                <SignUpBanner signInResetPassword={true} />
              </Col>
            </Row>
          </div>
        </section>
      ) : (
        <ExpiredLink {...props} type="verification" />
      )}
    </>
  );
}
