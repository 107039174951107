import { AxiosResponse } from "axios";
import { HTTPClient } from "./http.service";
var qs = require("qs");

export const rolesService = {
    async getRoles(params:any) {
        try {
          const result:AxiosResponse = await HTTPClient.get(`/company/settings/role/`, params);
          return result;
        } catch (e) {
          return Promise.reject(e);
        }
    },
    async getAllRoles() {
          try {
            const result:AxiosResponse = await HTTPClient.get(`/company/settings/role/`);
            return result;
          } catch (e) {
            return Promise.reject(e);
          }
      },
    async getCompanyRoles() {
        try {
          const result:AxiosResponse = await HTTPClient.get(`/company/companyManagement/getRoles/`);
          return result;
        } catch (e) {
          return Promise.reject(e);
        }
    },
    async getMenu() {
        try {
          const result:AxiosResponse = await HTTPClient.get(`/company/settings/menu/`);
          return result;
        } catch (e) {
          return Promise.reject(e);
        }
    },
  async addRole(data: any) {
    try {
      const response = await HTTPClient.post("/company/settings/role", data);
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async editRole(data: any, id: number) {
    try {
      const response = await HTTPClient.put(`/company/settings/role/${id}`, data);
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async deleteRole(id:any) {
    try {
      const result:AxiosResponse = await HTTPClient.delete(`/company/settings/role/${id}`);
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getSingleRole(id:any) {
      try {
        const result:AxiosResponse = await HTTPClient.get(`/company/settings/role/${id}`);
        return result;
      } catch (e) {
        return Promise.reject(e);
      }
  },
}