const customStyles = {
	table: {
		style: {
			borderRadius: '0px !important'
		},
	},
	tableWrapper: {
		style: {
			display: 'table',
			borderRadius: '0px !important'
		},
	},
	headRow: {
		style: {
			// borderBottomWidth: '1px',
			// borderBottomColor: 'lightgray',
			minHeight:'40px',
			borderBottom: '0px',
			backgroundColor: '#F6F8FD'
		},
	},
	headCells: {
		style: {
			textTransform: 'uppercase',
			// borderRight: '1px solid #ebedf3',
			// borderLeft: '1px solid #ebedf3',
			color: 'black !important',
			fontWeight: '600',
			marginLeft: '32px',
			padding: '0px 4px',
			'&:last-child': {
				maxWidth: '150px'
			},
			'&:first-child': {
				marginLeft: '4px',
				paddingLeft: '0px',
				width: '25%'
			}
		},
		activeSortStyle: {
			'div.table-sort-icon-contaier': {
				background: '#8E90B0',
				'div': {
					backgroundColor: '#E9EBF2 !important'
				}
			}
		}
	},
	rows: {
		style: {
			margin: '8px 0px',
			borderBottom: '0px !important',
			'&:not(:last-of-type)': {
				// borderBottomColor: '#ebedf3'
			},
			'&:hover': {
				backgroundColor: 'rgba(142, 144, 176, 0.1)',
			},
			// '&:hover': {
			// 	'& .trigger-button' : {
			// 		display:'block'
			// 	}
			// },

		}
	},
	cells: {
		style: {
			paddingLeft: '16px', // override the cell padding for data cells
			paddingTop: '10px',
			paddingBottom: '10px',
			marginLeft: '32px',
			padding: '0px 4px',
			// fontWeight: '400 !important',
			fontSize: '14px !important',
			lineHeight: '16px !important',
			color: '#40425A',
			// borderBottom: '0px !important',
			// borderRight: '1px solid #ebedf3',
			// borderBottom: '1px solid #EEEEEE',
			'&:last-child': {
				maxWidth: '150px'
			},
			'&:first-child' : {
				marginLeft: '4px',
				paddingLeft: '0px',
				color: '#40425A',
				// color:'#175B71',
				// fontWeight:'600',
			}
		},
	},
	noData: {
		style: {
			padding:'24px',
		},
	},
	pagination: {
		style: {
			fontSize: '13px',
			minHeight: '56px',
			borderTop: '0px',
			background: 'transparent'
		},
	}
};

export const CouponCustomStyles = {
	headRow: {
		style: {
			minHeight:'40px',
			borderBottom: '0px',
			backgroundColor: '#F6F8FD',
			display: 'none'
		},
	},
	headCells: {
		style: {
			textTransform: 'uppercase',
			// borderRight: '1px solid #ebedf3',
			// borderLeft: '1px solid #ebedf3',
			color: 'black !important',
			fontWeight: '600',
			marginLeft: '32px',
			padding: '0px 4px',
			'&:last-child': {
				maxWidth: '150px'
			},
			'&:first-child': {
				marginLeft: '4px',
				paddingLeft: '0px',
				width: '25%'
			}
		}
	},
	rows: {
		style: {
			margin: '8px 0px',
			borderBottom: '0px !important',
			'&:not(:last-of-type)': {
				// borderBottomColor: '#ebedf3'
			},
			// '&:hover': {
			// 	'& .trigger-button' : {
			// 		display:'block'
			// 	}
			// },

		}
	},
	cells: {
		style: {
			paddingLeft: '16px', // override the cell padding for data cells
			paddingTop: '10px',
			paddingBottom: '10px',
			marginLeft: '32px',
			padding: '0px 4px',
			// fontWeight: '400 !important',
			fontSize: '14px !important',
			lineHeight: '16px !important',
			color: '#40425A',
			// borderBottom: '0px !important',
			// borderRight: '1px solid #ebedf3',
			// borderBottom: '1px solid #EEEEEE',
			'&:last-child': {
				maxWidth: '150px'
			},
			'&:first-child' : {
				marginLeft: '4px',
				paddingLeft: '0px',
				color: '#40425A',
				// color:'#175B71',
				// fontWeight:'600',
			}
		},
	},
	noData: {
		style: {
			padding:'24px',
		},
	},
	pagination: {
		style: {
			fontSize: '13px',
			minHeight: '56px',
			borderTop: '0px',
			background: 'transparent'
		},
	}
};


export default customStyles;
