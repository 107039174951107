import React from "react";
import { Row, Form, Col } from "react-bootstrap";
import { OnChangeEventProps, InputDataProps } from "./SelectInterface";
import Tooltip from "../../Tooltip";
import ReactSelect from "react-select";
import { customStyles } from "./customStyle";

const InputSelect: React.FC<InputDataProps> = ({
  id,
  label,
  name,
  value,
  hint,
  onChange,
  error,
  disabled,
  options,
  required,
  onBlur = () => {},
  className,
  isSearchable,
  placeholder = "Select a value",
}) => {
  return (
    <Form.Group className={`top-28 ${className}`}>
      {label && (
        <Form.Label className="d-flex align-items-center">
          {label}
          {required && <span className="required-input-field">*</span>}
          {hint && (
            <Tooltip
              label={<div className="bg-icon bg-help-circle bg-icon16 icon-grey icon-left12" />}
              variant="primary"
              description={<span>{hint}</span>}
              placement="top"
            />
          )}
        </Form.Label>
      )}
      <ReactSelect
        className="search-input-edit"
        placeholder={placeholder}
        isDisabled={disabled}
        options={options}
        value={value}
        onChange={onChange}
        styles={customStyles(error)}
        components={{
          DropdownIndicator: () => <i className={`bg-icon bg-icon16 bg-chevron-down icon-right10 ${value ? "icon-primary2" : "icon-grey"}`}></i>,
        }}
        isSearchable={isSearchable}
      />
      {error && <p className="invalid-feedback d-block">{error}</p>}
    </Form.Group>
  );
};

export default InputSelect;
