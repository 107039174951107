import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import ArrowRightOutlinedIcon from "@material-ui/icons/ArrowRightOutlined";
import EmptyPieChart from "./assets/EmptyPieChart";
import "./styles/styles.scss";
import addFunnel from "highcharts/modules/funnel";
import { highchartsColors } from "../../constants/constants";

interface Props {
  data: object[];
  stack: Boolean;
}

Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: 'Inter, Helvetica, "sans-serif"',
    },
  },
});

export const OperatingSystemReport: React.FC<Props> = (props) => {
  const { data, stack, colors, isEmpty, emptyData } = props;
  const [chartLoaded, setChartLoaded] = useState(false);
  const cleanData = isEmpty
    ? emptyData.map((item) => [item.name, Number(item.value)])
    : data.map((item) => [item.name, Number(item.value)]);
  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false,
      height: 240,
    },
    title: {
      text: "",
      align: "left",
    },
    tooltip: {
      enabled: true,
      // pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false,
          distance: -50,
          style: {
            fontWeight: "bold",
            color: "white",
          },
        },
        startAngle: 0,
        endAngle: 360,
        center: ["50%", "50%"],
        size: "120%",
        // showInLegend: true
      },
    },
    series: [
      {
        type: "pie",
        name: "Clicks",
        innerSize: "70%",
        data: cleanData,
      },
    ],
    colors: isEmpty? ['#E0E0E0']: colors ? colors : highchartsColors,
    legend: {
      accessibility: {
        enabled: true,
      },
    },
  };

  useEffect(() => {
    window.Highcharts = Highcharts;
    addFunnel(Highcharts);
    setChartLoaded(true);
  }, []);

  const renderProgress = (data) => {
    return (
      <>
        {data.map((item, index) => (
          <div className="country-report-item">
            <Row className="my-2">
              <Col className="my-3" md={4}>
                <div className="country-detail">
                  <div
                    className="flag-icon-wrapper-icon"
                    style={{
                      background: isEmpty? '#E0E0E0': colors
                        ? colors[index]
                        : highchartsColors[index],
                    }}
                    title={item.name}
                  ></div>
                  <span className="country-name text-left">{item.name}</span>
                </div>
              </Col>
              <Col className="my-3" md={8}>
                <div className="country-stats-container">
                  <div className="country-stats-graph">
                    <div
                      className="stats-actual-value"
                      style={{ width: `${item.value}%` }}
                    ></div>
                  </div>
                  <div className="country-stats-number">
                    {item.value === null ? "-" : item.value} %
                  </div>
                  {
                    // <ArrowRightOutlinedIcon />
                  }
                </div>
              </Col>
            </Row>
          </div>
        ))}
      </>
    );
  };

  return (
    <Row>
      <Col className="my-3" md={!stack ? 6 : 12} lg={!stack ? 5 : 12}>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          constructorType={"chart"}
        />
      </Col>
      <Col className="my-3" md={!stack ? 7 : 12} lg={!stack ? 7 : 12}>
        <div className="country-report-items">
          {renderProgress(isEmpty ? emptyData : data)}
        </div>
      </Col>
      {isEmpty && (
        <div className="graph-empty-state">
          <EmptyPieChart />
        </div>
      )}
    </Row>
  );
};

export default OperatingSystemReport;
