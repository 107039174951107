import actionTypes from './breadcrumb.actionType'
const initialState: any = {
    data:'hello worlds'
};

const BreadcrumbReducer = (
  state = initialState,
  action: { type: String; payload: any }
) => {
  switch (action.type) {

    case actionTypes.SET_ACTIVE_PAGE: {
        return Object.assign({},state, {
            ...action.payload
        })
    }
    default:
      return state;
  }
};

export default BreadcrumbReducer;
