import React, { useState, useEffect } from "react";
// import { TableWrapper, TableHeader } from "../../../component/tableWrapper";
import DataTable from "react-data-table-component";
import ActionCompContainer from "../components/ActionCompContainer";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import SurveyWrapper from "../components/SurveyWrapper";
import { surveyService } from "../../../service/api/survey.service";
import Select from "react-select";
import { Spinner } from "react-bootstrap";
import { COMPLETED_SURVEY_USERS, INCOMPLETE_SURVEY_USERS } from "../utils";

const Users = (props) => {

  const [surveys, setSurveys] = useState([
  ]);
  const [totalEntries, setTotalEntries] = useState(0);
  const [params, setParams] = useState({ ...initParams });
  const { location, push, ...history } = useHistory();
  const [loading, setLoading] = useState(true);
  const [isCompleted, setIsCompleted] = useState(COMPLETED_SURVEY_USERS);

  const { id: surveyId } = useParams();
  const initParams = React.useMemo(() => ({ pattern: "" }), []);
  const columns = React.useMemo(() => {
    let obj = [
      {
        name: "First Name",
        selector: "firstName",
        left: true,
      },
      {
        name: "Last Name",
        selector: "lastName",
        left: true,
      },
      {
        name: "Mobile Number",
        selector: "mobileNumber",
        left: true,
      },
      {
        name: "Submitted At",
        selector: "submittedAt",
        left: true,
      },
    ];

    if (isCompleted.value === COMPLETED_SURVEY_USERS.value) {
      obj.push({
        name: "ACTIONS",
        right: true,
        cell: ({ userId }) => (
          <ActionCompContainer
            options={[
              {
                label: (
                  <div className="d-flex align-items-center cursor-pointer table-action-color">
                    &nbsp; <span>View response</span>
                  </div>
                ),
                onClick: () => push(`/loyalty/games/survey/${surveyId}/users/${userId}`),
              },
            ]}
            trigger={<Button variant="light">Actions</Button>}
            id={userId}
          />
        ),
      });
    }

    return obj;
  }, [isCompleted]);


  const usersList = (id) => {
    return surveyService.getSurveyUsers(id, isCompleted.value);
  };

  const list = () => {
    setLoading(true);
    usersList(surveyId)
      .then((res) => {
        if (res.status === 200) {
          let temp = res.data.data.docs;
          setSurveys(temp);
          setTotalEntries(res.data.data.totalEntries);
        }
        if (res.errors) {
          setSurveys([]);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    list();
  }, [isCompleted]);
  return (
    <SurveyWrapper {...props}>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
        <div className='card border-0'>
          <div
            className='card border-0'
          >
            <h2>
              Users
            </h2>
            <Select
              className="w-25 ml-auto"
              options={[COMPLETED_SURVEY_USERS, INCOMPLETE_SURVEY_USERS]}
              value={isCompleted}
              onChange={(e) => setIsCompleted(e)}
            />
          </div>
          <div className="card-body">
            <DataTable
              columns={columns}
              data={surveys}
              paginationTotalRows={totalEntries}
              loading={false}
              noHeader
              paginationServer
              pagination
            />
          </div>
        </div>
        </>
      )}
    </SurveyWrapper>
  );
};

export default Users;
