import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { surveyService } from "../../../service/api/survey.service";
import { SurveyContext } from "../store/SurveyStateManager";
// import CreateSurvey from "./CreateSurvey";
import Preview from "../components/Preview";
import * as moment from "moment";
export default function EditSurvey() {
  const params = useParams();
  const history = useHistory();
  const {
    ANSWER_TYPES,
    PRIZE_SELECT_OPTIONS,
    surveyInfoFormik,
    questionnaireFormik,
    setTargettingData,
    ANSWER_TYPE_OPTIONS,
    setIsActive,
    setSurveyDates,
    DefaultTypeOptions,
    setSurveyData
  } = React.useContext(SurveyContext);
  const [survey, setSurvey] = React.useState({});

  React.useEffect(() => {
    if (!params?.id) {
      history.goBack();
    } else {
      getSurvey(params.id);
    }
  }, []);

  const formatSurveyData = (data) => {
    const formatAnswer = (optionType, options, isOther) => {
      if (
        optionType === ANSWER_TYPES.SHORT ||
        optionType === ANSWER_TYPES.LONG
      ) {
        return "";
      } else if (optionType === ANSWER_TYPES.SLIDER) {
        return {
          // {id: 1, lablel: "Horrible", min: "0"}
          minValue: {
            id: options[0].id,
            label: options[0].lablel,
            value: options[0].min,
          },
          maxValue: {
            id: options[1].id,
            label: options[1].lablel,
            value: options[1].max,
          },
        };
      } else if (
        optionType === ANSWER_TYPES.CHECKBOX ||
        optionType === ANSWER_TYPES.DROPDOWN ||
        optionType === ANSWER_TYPES.RADIO
      ) {
        let answers = options.map((item) => ({ ...item, type: "static" }));
        if (isOther) answers.push({ type: "other", value: "" });
        return answers;
      }
    };
    //Prepopulating Survey Info
    let surveyInfo = {
      surveyTitle: data.name,
      successMessage: data.benefitMessage || '',
      isPrize: data.isPrize === 1,
      benefitId: data.benefitId,
      // retailer: { value: data.retailer.id, label: data.retailer.retailerName },
      prize:
        PRIZE_SELECT_OPTIONS.find((item) => item.value === data.benefitType) ||
        {},
      surveyDescription: data.description,
      image: data.imageUrl,
      prizeAmount: data.benefitValue,
      surveyId: data.id,
    };
    if (data.benefitDetails) {
      if(data.benefitType === 1)
      surveyInfo.benefitId = {
        label: data.benefitDetails.data.couponName,
        value: data.benefitId,
      };
    }

    //Prepopulating Questionnaire
    let questions = data.questions.map((item) => {
      return {
        questionTitle: item.question,
        questionDescription: item.description,
        id: item.id,
        required: !!+item.isRequired, //converts 0 and 1 to true or false
        qOrder: item.qOrder,
        isOther: !!+item.isOther,
        answerType:
          ANSWER_TYPE_OPTIONS.find((type) => type.value === item.optionType) ||
          null,
        answer: formatAnswer(item.optionType, item.options, !!+item.isOther),
      };
    });
    surveyInfoFormik.setValues({ ...surveyInfo });
    questionnaireFormik.setValues({ questions });
    setSurveyData({...surveyInfo, questions})
    // setTargettingData(targetting);
    // setSurveyDates(dates, "dates");
    setIsActive(data.isActive);
  };

  const getSurvey = async (id) => {
    let data = await surveyService.getSurveyById(id);
    formatSurveyData(data);
  };

  return <div className='inner-pages-form-container'><Preview /></div>;
}
