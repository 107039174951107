import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { authActionTypes } from "./auth.actiontype";

const INIT_STATE = {
  loggedIn: false,
  user: null,
  authToken: null,
  isSubmitting: false,
  error: null,
};

const authReducer = persistReducer(
  { storage, key: "crm", whitelist: ["user", "loggedIn", "authToken", "loggedIn", "isSubmitting"] },
  (state = INIT_STATE, action) => {
    switch (action.type) {
      case authActionTypes.LOGIN_SUCCESS:
        console.log("success");
        return Object.assign({}, state, {
          otpVerifyed: false,
          loggedIn: true,
          user: action.payload,
          authToken: action.payload.authToken,
          isSubmitting: false,
          error: "",
        });

      case authActionTypes.LOGIN_FAIL:
        return Object.assign({}, state, {
          isSubmitting: false,
          error: action.payload,
        });

      case authActionTypes.LOGIN_INIT:
        console.log("login init");
        return Object.assign({}, state, {
          isSubmitting: true,
          error: "",
        });

      case authActionTypes.LOGIN_INIT_AUTO:
        console.log("login init Auto");
        return Object.assign({}, state, {
          isSubmitting: true,
          error: "",
        });

      case authActionTypes.OTP_VERIFYED:
        return Object.assign({}, state, {
          otpVerifyed: true,
        });

      case authActionTypes.CLEAR_USER_DATA:
        return Object.assign({}, state, {
          ...INIT_STATE,
        });

      case authActionTypes.SET_USER: {
        return { ...state, user: action.payload };
      }
      default:
        return state;
    }
  }
);

export default authReducer;
