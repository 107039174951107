import React from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { campaignStatusTypes, landingStatusTypes } from "../../../../app/utils/Constants";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory } from "react-router";
import { headerActions } from "../../../../app/store/header/header.action";

const WithCount = ({ heading }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const count = useSelector((state) => state.header.count);
  React.useEffect(() => {
    dispatch(headerActions.updateHeaderData(null));
  }, [location.pathname]);
  return (
    <div className="page-heading">
      {heading} &nbsp;
      {!isNaN(count) && count !== null && <span className="list-count">{count}</span>}
    </div>
  );
};

const WithDynamicHeading = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const count = useSelector((state) => state.header.count);
  React.useEffect(() => {
    dispatch(headerActions.updateHeaderData(null));
  }, [location.pathname]);
  return <div className="page-heading">{count}</div>;
};

const PageHeading = () => {
  const { push, goBack } = useHistory();
  return (
    <div className="crm-page-heading">
      <Switch>
        <Route exact={true} path="/contacts/all-contacts" render={() => <WithCount heading="" />} />
        <Route exact={true} path="/overview" render={() => <div>Dashboard</div>} />
        <Route
          exact={true}
          path="/contacts/all-contacts/import"
          render={() => (
            <div>
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => push("/contacts/all-contacts")} />
              Imports
            </div>
          )}
        />
        <Route
          path="/contacts/import/options"
          render={() => (
            <div>
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => push("/contacts/all-contacts/")} />
              Import Contacts Option
            </div>
          )}
        />
        <Route
          path="/contacts/import/options?:step"
          render={() => (
            <div>
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={goBack} />
              Import Contacts
            </div>
          )}
        />

        <Route exact path="/campaigns/all-campaigns" render={() => <WithCount heading="Campaigns" />} />
        <Route path={`/campaigns/all-campaigns/${campaignStatusTypes.ALL}`} render={() => <WithCount heading="Campaigns" />} />
        <Route path={`/campaigns/all-campaigns/${campaignStatusTypes.DRAFT}`} render={() => <WithCount heading="Draft campaigns" />} />
        <Route path={`/campaigns/all-campaigns/${campaignStatusTypes.SCHEDULE}`} render={() => <WithCount heading="Scheduled campaigns" />} />
        <Route path={`/campaigns/all-campaigns/${campaignStatusTypes.SENT}`} render={() => <WithCount heading="Sent campaigns" />} />
        <Route
          path={`/campaigns/sms-report/:id`}
          render={() => (
            <div>
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => push("/campaigns/all-campaigns")} />
              All campaigns
            </div>
          )}
        />
        <Route
          path={`/campaigns/whats-app-report/:id`}
          render={() => (
            <div>
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => push("/campaigns/all-campaigns")} />
              All campaigns
            </div>
          )}
        />
        <Route
          path="/campaigns/create-campaign"
          render={() => (
            <div>
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={goBack} />
              Create Campaign
            </div>
          )}
        />
        <Route
          path="/campaigns/edit/:id"
          render={() => (
            <div>
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={goBack} />
              Edit Campaign
            </div>
          )}
        />

        <Route exact path="/loyalty/games" render={() => <div>Loyalty games</div>} />
        <Route
          exact
          path="/loyalty/games/quiz/create"
          render={() => (
            <div>
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={goBack} />
              Create Quiz
            </div>
          )}
        />

        <Route
          exact
          path="/loyalty/games/quiz/:id/edit"
          render={() => (
            <div>
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={goBack} />
              Edit Quiz
            </div>
          )}
        />
        <Route
          exact
          path="/loyalty/games/quiz/:id/view"
          render={() => (
            <div>
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={goBack} />
              View Quiz
            </div>
          )}
        />
        <Route
          path="/loyalty/games/quiz"
          render={() => (
            <div className="d-flex align-items-center">
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={goBack} />
              <WithCount heading="Quiz" />
            </div>
          )}
        />
        <Route
          path="/users/user-details/:poolId/:id"
          render={() => (
            <div className="d-flex align-items-center">
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={goBack} />
              Contacts
            </div>
          )}
        />

        <Route
          exact
          path="/loyalty/games/spin-the-wheel/create"
          render={() => (
            <div>
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={goBack} />
              Create spin the wheel
            </div>
          )}
        />

        <Route
          exact
          path="/loyalty/games/spin-the-wheel/:id/edit"
          render={() => (
            <div>
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={goBack} />
              Edit spin the wheel
            </div>
          )}
        />
        <Route
          exact
          path="/loyalty/games/spin-the-wheel/:id/view"
          render={() => (
            <div>
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={goBack} />
              View spin the wheel
            </div>
          )}
        />

        <Route
          path="/loyalty/games/spin-the-wheel"
          render={() => (
            <div className="d-flex align-items-center">
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={goBack} />
              <WithCount heading="Spin the wheel" />
            </div>
          )}
        />

        <Route
          exact
          path="/loyalty/games/scratch-card/create"
          render={() => (
            <div>
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={goBack} />
              Create scratch card
            </div>
          )}
        />

        <Route
          exact
          path="/loyalty/games/scratch-card/:id/edit"
          render={() => (
            <div>
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={goBack} />
              Edit scratch card
            </div>
          )}
        />
        <Route
          exact
          path="/loyalty/games/scratch-card/:id/view"
          render={() => (
            <div>
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={goBack} />
              View scratch card
            </div>
          )}
        />

        <Route
          path="/loyalty/games/scratch-card"
          render={() => (
            <div className="d-flex align-items-center">
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={goBack} />
              <WithCount heading="Scratch Card" />
            </div>
          )}
        />
        {
          // <Route
          //   path="/loyalty/landing-page/create"
          //   render={() => (
          //     <div>
          //       <ArrowBackIosIcon
          //         style={{ cursor: "pointer" }}
          //         onClick={goBack}
          //       />
          //       Create landing page
          //     </div>
          //   )}
          // />
          // <Route
          //   path="/loyalty/landing-page/:id/edit"
          //   render={() => (
          //     <div>
          //       <ArrowBackIosIcon
          //         style={{ cursor: "pointer" }}
          //         onClick={goBack}
          //       />
          //       Edit landing page
          //     </div>
          //   )}
          // />
          // <Route
          //   path={`/loyalty/landing-page/${landingStatusTypes.ALL}`}
          //   render={() => <div className='d-flex align-items-center'>
          //     <ArrowBackIosIcon
          //       style={{ cursor: "pointer" }}
          //       onClick={goBack}
          //     /><WithCount heading="Landing pages" />
          //   </div>}
          // />
          // <Route
          //   path={`/loyalty/landing-page/${landingStatusTypes.DRAFT}`}
          //   render={() => <div className='d-flex align-items-center'>
          //     <ArrowBackIosIcon
          //       style={{ cursor: "pointer" }}
          //       onClick={goBack}
          //     /><WithCount heading="Landing pages" />
          //   </div>}
          // />
          // <Route
          //   path={`/loyalty/landing-page/${landingStatusTypes.ACTIVE}`}
          //   render={() => <div className='d-flex align-items-center'>
          //     <ArrowBackIosIcon
          //       style={{ cursor: "pointer" }}
          //       onClick={goBack}
          //     /><WithCount heading="Landing pages" />
          //   </div>}
          // />
          // <Route
          //   path={`/loyalty/landing-page/${landingStatusTypes.EXPIRED}`}
          //   render={() => <div className='d-flex align-items-center'>
          //     <ArrowBackIosIcon
          //       style={{ cursor: "pointer" }}
          //       onClick={goBack}
          //     /><WithCount heading="Landing pages" />
          //   </div>}
          // />
          // <Route
          //   path={`/loyalty/landing-page/:id/analytics`}
          //   render={() => (
          //     <div>
          //       <ArrowBackIosIcon
          //         style={{ cursor: "pointer" }}
          //         onClick={goBack}
          //       />
          //       Analytics
          //     </div>
          //   )}
          // />
          // <Route
          //   exact
          //   path={`/loyalty/landing-page/:id/summary`}
          //   render={() => (
          //     <div>
          //       <ArrowBackIosIcon
          //         style={{ cursor: "pointer" }}
          //         onClick={goBack}
          //       />
          //       Landing page stats
          //     </div>
          //   )}
          // />
          // <Route
          //   exact
          //   path={`/loyalty/landing-page/:id/users`}
          //   render={() => (
          //     <div>
          //       <ArrowBackIosIcon
          //         style={{ cursor: "pointer" }}
          //         onClick={goBack}
          //       />
          //       Landing page stats
          //     </div>
          //   )}
          // />
          // <Route
          //   exact
          //   path={`/loyalty/landing-page/:id/users`}
          //   render={() => (
          //     <div>
          //       <ArrowBackIosIcon
          //         style={{ cursor: "pointer" }}
          //         onClick={goBack}
          //       />
          //       Landing page stats
          //     </div>
          //   )}
          // />
          // <Route
          //   exact
          //   path={`/loyalty/landing-page/:id/questionwise`}
          //   render={() => (
          //     <div>
          //       <ArrowBackIosIcon
          //         style={{ cursor: "pointer" }}
          //         onClick={goBack}
          //       />
          //       Landing page stats
          //     </div>
          //   )}
          // />
        }
        <Route
          path="/contacts/segmentations/segment/edit/:id"
          render={() => (
            <div>
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={goBack} />
              Edit Segment
            </div>
          )}
        />
        <Route
          path="/contacts/segmentations/segment/view/:id"
          render={() => (
            <div>
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => push("/contacts/segmentations/all-segments")} />
              View Segment
            </div>
          )}
        />


        {
          // <Route
          //   path="/contacts/segmentations/create"
          //   render={() => (
          //     <div>
          //       <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => push("/contacts/segmentations/all-segments")} />
          //       Create Segment
          //     </div>
          //   )}
          // />
          // <Route path="/contacts/segmentations/all-segments" exact={true} render={() => <WithCount heading="Segmentations" />} />
        }
        <Route
          path="/contacts/segmentations/segmentUserList/:id"
          render={() => (
            <div className="d-flex">
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={goBack} />
              <WithDynamicHeading />
            </div>
          )}
        />
        <Route
          path="/team-and-roles/team"
          render={() => (
            <div>
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={goBack} />
              Team and Roles Management
            </div>
          )}
        />
        <Route
          path="/team-and-roles/roles"
          render={() => (
            <div>
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={goBack} />
              Team and Roles Management
            </div>
          )}
        />
        {/* <Route
          path="/settings"
          render={() => (
            <div>

              Settings
            </div>
          )}
        /> */}
        <Route
          exact
          path="/loyalty/games/survey/create-survey"
          render={() => (
            <div>
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={goBack} />
              Create Survey
            </div>
          )}
        />
        <Route
          path="/loyalty/games/survey/edit/:id"
          render={() => (
            <div>
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={goBack} />
              Edit Survey
            </div>
          )}
        />
        <Route
          path="/loyalty/games/survey/view/:id"
          render={() => (
            <div>
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={goBack} />
              View Survey
            </div>
          )}
        />
        <Route
          exact
          path="/loyalty/games/survey/:type"
          render={() => (
            <div className="d-flex align-items-center">
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={goBack} />
              <WithCount heading="Surveys" />
            </div>
          )}
        />
        {
          // <Route
          //   path="/loyalty/coupons/:id/edit"
          //   render={() => (
          //     <div>
          //     <ArrowBackIosIcon
          //       style={{ cursor: "pointer" }}
          //       onClick={goBack}
          //     /> Edit coupon
          //     </div>
          //   )}
          // />
          // <Route
          //   path="/loyalty/coupons/:id/view"
          //   render={() => (
          //     <div>
          //     <ArrowBackIosIcon
          //       style={{ cursor: "pointer" }}
          //       onClick={goBack}
          //     /> View Coupon
          //     </div>
          //   )}
          // />
          // <Route
          //   path="/loyalty/coupons/:id/add-coupon-code"
          //   render={() => (
          //     <div>
          //     <ArrowBackIosIcon
          //       style={{ cursor: "pointer" }}
          //       onClick={() => push('/loyalty/coupons/all')}
          //     /> Edit coupon codes
          //     </div>
          //   )}
          // />
          // <Route
          //   path="/loyalty/coupons/create"
          //   render={() => (
          //     <div>
          //     <ArrowBackIosIcon
          //       style={{ cursor: "pointer" }}
          //       onClick={goBack}
          //     /> Create coupon
          //     </div>
          //   )}
          // />
          // <Route
          //   path="/loyalty/coupons"
          //   render={() => <div className='d-flex align-items-center'>
          //     <ArrowBackIosIcon
          //       style={{ cursor: "pointer" }}
          //       onClick={goBack}
          //     />
          //     <WithCount heading="Coupons" />
          //   </div>}
          // />
        }
        <Route
          path="/analytics/audience"
          render={() => (
            <div>
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={goBack} /> Audience Dashboard
            </div>
          )}
        />
        <Route
          path="/analytics/acquisition"
          render={() => (
            <div>
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={goBack} /> Acquisition Dashboard
            </div>
          )}
        />
        <Route
          path="/analytics/behaviour"
          render={() => (
            <div>
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={goBack} /> Behaviour Dashboard
            </div>
          )}
        />
        <Route
          path="/loyalty/landing-page/:id/edit"
          render={() => (
            <div>
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={goBack} />
              Edit landing page
            </div>
          )}
        />
        <Route
          path="/campaigns/email-report/:id"
          render={() => (
            <div>
              <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={goBack} />
              All campaigns
            </div>
          )}
        />
      </Switch>
    </div>
  );
};

export default PageHeading;
