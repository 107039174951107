import React from "react";
import { SurveyContext } from "../store/SurveyStateManager";
import { Button } from "react-bootstrap";

export default function Preview() {
  const [tierList, setTierList] = React.useState([]);
  const {
    surveyData,
    ANSWER_TYPES,
    ANSWER_TYPE_OPTIONS
  } = React.useContext( SurveyContext );

  const extraSegLabels = React.useMemo(() => {
    return {
      gender: "Gender",
      dateOfBirth: "Date of Birth",
      ageGroup: "Age group",
      birthday: "Birthday",
      anniversary: "Anniversary",
      tier: "Tier(s)"
    }
  }, [])

  const getTierList = async () => {
    try {
      // let data = await couponService.getTierList();
      // setTierList(data.docs);
    } catch (err) {
      // setTierList([]);
    }
  };



  const TierMapping = React.useMemo(() => {
    return tierList.reduce((acc, item) => {
      acc[item.id] = item.tierName;
      return acc;
    }, {});
  }, [tierList]);

  const tiersExist = React.useMemo(() => {
    return (
      (surveyData?.tier &&
        typeof surveyData.tier === "object" &&
        Object.entries(surveyData.tier).length !== 0) ||
      false
    );
  }, [surveyData]);

  const segmentationsExist = React.useMemo(() => {
    return (
      (surveyData?.couponExtraSegmentation &&
        typeof surveyData.couponExtraSegmentation === "object" &&
        Object.entries(surveyData.couponExtraSegmentation).length !== 0) ||
      false
    );
  }, [surveyData]);

  const segmentations = { ...surveyData.couponExtraSegmentation }
  if (segmentations.upperAgeLimit || segmentations.lowerAgeLimit) {
    segmentations.ageGroup = `${segmentations.lowerAgeLimit} - ${segmentations.upperAgeLimit}`
    delete segmentations.lowerAgeLimit;
    delete segmentations.upperAgeLimit
  }
  if(segmentations.tier){
    segmentations.tier = segmentations.tier.map(item=>TierMapping[String(item)]).join(', ')
  }

  React.useEffect(() => {
    getTierList();
  }, []);



  return (
    <div class="preview-page-wrapper">
      <h2 className="overview-heading">Overview</h2>
      {
        // <Button
        //   className="ufyndBtn ufyndBtn_primary d-flex gray-button preview-btn"
        //   color="primary"
        // >
        //   <div className='bg-icons bg-icon16 bg-eye icon-right4'></div>Preview survey
        // </Button>
      }

      <br />
      <div className="info-box">
        <p className="overview-title">Survey name</p>
        <p className="overview-desc">{surveyData?.surveyTitle}</p>
      </div>

      <div className="info-box">
        <p className="overview-title">Survey description</p>
        <p className="overview-desc">
          {/* {surveyData?.surveyDescription} */}
          <div dangerouslySetInnerHTML={{__html: surveyData?.surveyDescription}}></div>
        </p>
      </div>

      <div className="info-box">
        <p className="overview-title">No. of questions</p>
        <p className="overview-desc">{surveyData?.questions?.length}</p>
      </div>
      {
        surveyData?.isPrize ===1 && surveyData?.prize?.label &&
        <div className="info-box border-bottom">
          <p className="overview-title">Prize</p>
          <p className="overview-desc">{surveyData?.benefitId?.label} ({surveyData?.prize?.label})</p>
        </div>
      }

      <h2 className="overview-heading pt-6">Questionnaire</h2>
      {surveyData?.questions?.map((item, index) => {
        console.log('item', item);
        return (
          <div className="question-list-item row py-5 px-2">
            <div className="col-2">
              <p className="overview-title">Question {index + 1}:</p>
            </div>
            <div className="col-10">
              <p className="overview-desc">{item?.questionTitle}</p>
            </div>
            <div className="col-2">
              <p className="overview-title">Answer type:</p>
            </div>
            <div className="col-10">
              <p className="overview-desc">{item?.answerType?.label}</p>
            </div>
            {[
              ANSWER_TYPES.CHECKBOX,
              ANSWER_TYPES.DROPDOWN,
              ANSWER_TYPES.RADIO,
            ].includes(item?.answerType?.value) && (
                <>
                  <div className="col-2">
                    <p className="overview-title">Options</p>
                  </div>

                  <div className="col-10">
                    {JSON.stringify()}
                    <p className="overview-desc">{item.answer.map((item) => item.value || (item.type==="other"? 'other': '')).join(", ")}</p>
                  </div>
                </>
              )}

            {item?.answerType?.value === ANSWER_TYPES.SLIDER && (
              <>
                <div className="col-2">
                  <p>
                    <span className="overview-title">Minimum value:</span>&nbsp;
                    <span>{item.answer?.minValue?.value}&nbsp;</span>
                    {item.answer?.minValue?.label && (
                      <span>({item.answer?.minValue?.label})</span>
                    )}
                  </p>
                </div>

                <div className="col-2">
                  <p>
                    <span className="overview-title">Maximum value:</span>&nbsp;
                    <span>{item.answer?.maxValue?.value}&nbsp;</span>
                    {item.answer?.minValue?.label && (
                      <span>({item.answer?.maxValue?.label})</span>
                    )}
                  </p>
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
}
