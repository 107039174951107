import { HTTPClient } from "./http.service";
var qs = require("qs");

export const campaignService = {
  async getCompanyChannels() {
    try {
      const {
        data: { data: companyChannels },
      } = await HTTPClient.get("/company/campaign/getChannels");
      return companyChannels;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getSavedSmsTemplateSingle(params: { id: number }) {
    try {
      const { data } = await HTTPClient.get(
        "/company/campaign/channel/getSavedSmsTemplates/" + params.id
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getSavedWhatsAppTemplateSingle(params: { id: number }) {
    try {
      const { data } = await HTTPClient.get(
        "/company/campaign/channel/getSavedWhatsAppTemplates/" + params.id
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getSavedSmsTemplate(params: object) {
    try {
      const { data } = await HTTPClient.get(
        "/company/campaign/channel/getSavedSmsTemplates/", params
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getSavedWhatsAppTemplate(params: object) {
    try {
      const { data } = await HTTPClient.get(
        "/company/campaign/channel/getSavedWhatsAppTemplates/", params
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  },
	async addSmsChannel(data: any) {
		try {
			return await HTTPClient.customRequest({
				url: `/company/campaign/channel/addSmsChannel`,
				method: 'post',
				headers: {'Content-Type': 'application/json'},
				data: data
			});
		} catch(e) {
			return Promise.reject(e);
		}
	},
	async updateSmsChannel(id: any, data: any) {
		try {
			return await HTTPClient.customRequest({
				url: `/company/campaign/channel/updateSmsChannel/${id}`,
				method: 'put',
				headers: {'Content-Type': 'application/json'},
				data: data
			});
		} catch(e) {
			return Promise.reject(e);
		}
	},
  async addWhatsAppChannel(data: any) {
		try {
			return await HTTPClient.customRequest({
				url: `/company/campaign/channel/addWhatsappChannel`,
				method: 'post',
				headers: {'Content-Type': 'application/json'},
				data: data
			});
		} catch(e) {
			return Promise.reject(e);
		}
	},
  async updateWhatsAppChannel(id: any, data: any) {
    try {
      return await HTTPClient.customRequest({
        url: `/company/campaign/channel/updateWhatsAppChannel/${id}`,
        method: 'put',
        headers: {'Content-Type': 'application/json'},
        data: data
      });
    } catch(e) {
      return Promise.reject(e);
    }
  },
	async sendTestMessage(data: any) {
		try {
			return await HTTPClient.customRequest({
				url: 'company/campaign/channel/sendTestSms',
				method: 'post',
				headers: {'Content-Type': 'application/json'},
				data: data
				// data: qs.stringify(data)
			});
		} catch(e) {
			return Promise.reject(e);
		}
	},
	async sendWhatsAppTestMessage(data: any) {
		try {
			return await HTTPClient.customRequest({
				url: '/company/campaign/channel/sendTestWhatsapp',
				method: 'post',
				headers: {'Content-Type': 'application/json'},
				data: data
			});
		} catch(e) {
			return Promise.reject(e);
		}
	},
	async getSmsLibrary() {
		try {
			const {data} = await HTTPClient.get('/constants/getSmsLibrary')
			return data;
		} catch(e) {
			return Promise.reject(e);
		}
	},
	async getWhatsappLibrary() {
		try {
			const {data} = await HTTPClient.get('/constants/getWhatsappLibrary')
			return data;
		} catch(e) {
			return Promise.reject(e);
		}
	},
	async getSmsLibrarySingle(params: { id: number }) {
    try {
      const { data } = await HTTPClient.get(
        "/constants/getSmsLibrary/" + params.id
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  },
	async addSmsTemplate(data: any) {
		try {
			return await HTTPClient.customRequest({
				url: '/company/campaign/channel/addSmsTemplate',
				method: "post",
				headers: { "Content-Type": "application/x-www-form-urlencoded" },
				data: qs.stringify(data)
			})
		} catch(e) {
			return Promise.reject(e)
		}
	},
	async updateSmsTemplate(id: number, data: any) {
		try {
			return await HTTPClient.customRequest({
				url: `/company/campaign/channel/updateSmsTemplate/${id}`,
				method: "put",
				headers: { "Content-Type": "application/x-www-form-urlencoded" },
				data: qs.stringify(data)
			})
		} catch(e) {
			return Promise.reject(e)
		}
	},
	async updateWhatsAppTemplate(id: number, data: any) {
		try {
			return await HTTPClient.customRequest({
				url: `/company/campaign/channel/updateWhatsAppTemplate/${id}`,
				method: "put",
				headers: { "Content-Type": "application/x-www-form-urlencoded" },
				data: data
			})
		} catch(e) {
			return Promise.reject(e)
		}
	},
  async addWhatsAppTemplate(data: any) {
    try {
      return await HTTPClient.customRequest({
        url: '/company/campaign/channel/addWhatsAppTemplate',
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: data
      })
    } catch(e) {
      return Promise.reject(e)
    }
  },
  // async updateWhatsAppTemplate(id: number, data: any) {
  //   try {
  //     return await HTTPClient.customRequest({
  //       url: `/company/campaign/channel/updateWhatsAppTemplate/${id}`,
  //       method: "put",
  //       headers: { "Content-Type": "application/x-www-form-urlencoded" },
  //       data: qs.stringify(data)
  //     })
  //   } catch(e) {
  //     return Promise.reject(e)
  //   }
  // },
	async addCampaign(data: any) {
		try {
			return await HTTPClient.customRequest({
				url: '/company/campaign',
				method: "post",
				headers: { "Content-Type": "application/x-www-form-urlencoded" },
				data: data
			})
		} catch(e) {
			return Promise.reject(e)
		}
	},
	async updateCampaign(id: number, data: any) {
		try {
			return await HTTPClient.customRequest({
				url: `/company/campaign/${id}`,
				method: "put",
				headers: { "Content-Type": "application/x-www-form-urlencoded" },
				data: data
			})
		} catch(e) {
			return Promise.reject(e)
		}
	},
	async deleteCampaign(id: number) {
		try {
			return await HTTPClient.customRequest({
				url: `/company/campaign/${id}`,
				method: "delete",
				headers: { "Content-Type": "application/x-www-form-urlencoded" },
			})
		} catch(e) {
			return Promise.reject(e)
		}
	},
  async getCampaignList(params: any) {
    try {
      const result = await HTTPClient.get(`/company/campaign/`, params);
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getCampaign(id: any) {
    try {
      const result = await HTTPClient.get(`/company/campaign/${id}`);
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
	async getCompanySmsChannels(params: any) {
		try {
      return  await HTTPClient.get(`/company/campaign/channel/getCompanySmsChannels/`, params);
    } catch (e) {
      return Promise.reject(e);
    }
	},
	async getCompanyWhatsAppChannels(params: any) {
		try {
      return  await HTTPClient.get(`/company/campaign/channel/getCompanyWhatsappChannels/`, params);
    } catch (e) {
      return Promise.reject(e);
    }
	},
	async getCompanyEmailChannels(params: any) {
		try {
      return  await HTTPClient.get(`/company/campaign/channel/getCompanyEmailChannels/`, params);
    } catch (e) {
      return Promise.reject(e);
    }
	},
	async getCampaignSmsReports(id: number) {
		try {
      return await HTTPClient.get(`/company/campaign/campaignSmsReports/${id}`);
    } catch (e) {
      return Promise.reject(e);
    }
	},
	async getCampaignWhatsAppReports(id: number) {
		try {
      return await HTTPClient.get(`/company/campaign/campaignWhatsappReports/${id}`);
    } catch (e) {
      return Promise.reject(e);
    }
	},
	async getCampaignEmailReports(id: number) {
		try {
      return await HTTPClient.get(`/company/campaign/campaignEmailReports/${id}`);
    } catch (e) {
      return Promise.reject(e);
    }
	},
	async getPartnerConfiguration() {
		try {
      return await HTTPClient.get(`/constants/getPartnerConfiguration`);
    } catch (e) {
      return Promise.reject(e);
    }
	},
  async deleteSmsTemplate(id) {
    try {
      return await HTTPClient.delete(`/company/campaign/channel/deleteSmsTemplate/${id}`);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async deleteWhatsAppTemplate(id) {
    try {
      return await HTTPClient.delete(`/company/campaign/channel/deleteWhatsAppTemplate/${id}`);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async updateEmailChannel(id: any, data: any) {
    try {
      return await HTTPClient.customRequest({
        url: `/company/campaign/channel/updateEmailChannel/${id}`,
        method: 'put',
        headers: {'Content-Type': 'application/json'},
        data: data
      });
    } catch(e) {
      return Promise.reject(e);
    }
  },
  async addEmailChannel(data: any) {
		try {
			return await HTTPClient.customRequest({
				url: `/company/campaign/channel/addEmailChannel`,
				method: 'post',
				headers: {'Content-Type': 'application/json'},
				data: data
			});
		} catch(e) {
			return Promise.reject(e);
		}
	},
  async getEmailTemplateImages(params: object) {
    try {
      const { data } = await HTTPClient.get(
        "/company/campaign/channel/getEmailTemplateImages/", params
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getSavedEmailTemplates(params: object) {
    try {
      const { data } = await HTTPClient.get(
        "/company/campaign/channel/getSavedEmailTemplates/", params
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getLibraryEmailTemplates(params: object) {
    try {
      const { data } = await HTTPClient.get(
        "/company/campaign/email/library", params
      );
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async addEmailTemplate(data: any) {
		try {
			return await HTTPClient.customRequest({
				url: '/company/campaign/channel/addEmailTemplate',
				method: "post",
				headers: { "Content-Type": "application/x-www-form-urlencoded" },
				data: qs.stringify(data)
			})
		} catch(e) {
			return Promise.reject(e)
		}
	}
}
