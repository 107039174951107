import React from "react";
import { surveyService } from "../../../service/api/v2/survey.service";
import { SURVEY_STEPS_KEY, generateOptions } from "../utils";
import useSurveyInfoForm from "./useSurveyInfoForm";
import useSurveyQuestionnaireForm from "./useSurveyQuestionnaireForm";
import useSurveyTargetingForm from "./useSurveyTargetingForm";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import RichTextEditor from "react-rte";
import { useHistory } from "react-router";
import * as moment from "moment";

export const SurveyContext = React.createContext();

export const SurveyContextProvider = ({ children, editMode }) => {
  const [surveyData, setSurveyData] = React.useState({
    startDate: "",
    endDate: null,
    startTime: null,
    endtime: null,
  });
  const { surveyInfoFormik, surveyInfoInitValues } = useSurveyInfoForm();
  const {
    questionnaireFormik,
    validateEntries,
    intitialData,
    ...questionnaireFormOptions
  } = useSurveyQuestionnaireForm();

  const [isActive, setIsActive] = React.useState();

  //  {
  // BENEFIT_TYPES,
  // DEFAULT_TYPE_BENEFITS,
  // QUESTION_OPTIONS,
  // SURVEY_STATUS,
  // TARGET_AUDIENCE,
  // }
  const CONSTANTS = useSelector(
    (store) => store.appData.globalConstants.survey
  );
  const { ANSWER_TYPES, SURVEY_STEOS_KEY, ...CONSTANT_OPTIONS } = React.useMemo(
    () => generateOptions(CONSTANTS),
    []
  );

  const { setErrors, targettingData, targettingErrors, setTargettingData } =
    useSurveyTargetingForm();

  const history = useHistory();
  const [allQuestionsTouched, setQuestionsTouched] = React.useState(false);
  //SET FORM STEP HERE
  const [stepper, setStepper] = React.useState(SURVEY_STEPS_KEY.SURVEY_INFO);

  const handleSurveySubmit = async (dates = null, status) => {
    function formatQuestions(questionData) {
      let questionsArr = [];

      questionData.forEach((item, index) => {
        let newObj = {};
        newObj.question = item.questionTitle;
        newObj.description = item.questionDescription;
        newObj.optionType = item.answerType.value;
        newObj.isRequired = item.required;
        newObj.qOrder = index + 1;
        newObj.isOther = item.isOther;
        if (
          item.answerType.value === ANSWER_TYPES.SHORT ||
          item.answerType.value === ANSWER_TYPES.LONG
        ) {
          newObj.options = null;
        }
        if (item.answerType.value == ANSWER_TYPES.SLIDER) {
          newObj.options = [
            {
              id: 1,
              lablel: item.answer.minValue.label,
              min: item.answer.minValue.value,
            },
            {
              id: 2,
              lablel: item.answer.maxValue.label,
              max: item.answer.maxValue.value,
            },
          ];
        }

        if (
          item.answerType.value == ANSWER_TYPES.CHECKBOX ||
          item.answerType.value == ANSWER_TYPES.DROPDOWN ||
          item.answerType.value == ANSWER_TYPES.RADIO
        ) {
          newObj.options = item.answer
            .filter((item) => item.type === "static")
            .map((choice) => {
              return {
                id: choice.id,
                value: choice.value,
                oOrder: choice.oOrder,
              };
            });
        }
        questionsArr.push(newObj);
      });
      return questionsArr;
    }

    let formData = new FormData();
    let obj = {};
    //SURVEY INFO - STEP 1
    // formData.append("name", surveyData.surveyTitle);
    obj.name = surveyData.surveyTitle;
    obj.status = status;
    if (surveyData.surveyDescription) {
      // formData.append("description", surveyData.surveyDescription);
      obj.description = surveyData.surveyDescription;
    }
    if (surveyData.retailer) {
      // formData.append("retailerId", surveyData.retailer.value);
      obj.retailerId = surveyData.retailer.value;
    }

    // formData.append("benefitType", surveyData.prize.value);
    obj.benefitType = surveyData.prize.value;
    obj.isPrize = surveyData.isPrize ? 1 : 0;
    obj.benefitMessage = surveyData.successMessage;

    // formData.append("benefitValue", surveyData.prizeAmount);
    // obj.benefitValue = surveyData.prizeAmount;

    // formData.append("benefitId", surveyData?.benefitId?.value || "");
    obj.benefitId = surveyData?.benefitId?.value;

    // formData.append("defaultType", surveyData?.defaultType?.value || "");
    // obj.defaultType = surveyData?.defaultType?.value || "";

    // formData.append("surveyCoverImage", surveyData.image);
    // obj.surveyCoverImage = surveyData?.image;
    //QUESTIONNAIRE - STEP 2
    let questionData = JSON.stringify(formatQuestions(surveyData.questions));
    // formData.append("questions", JSON.stringify(questionData));
    obj.questions = questionData;

    //TARGETTING - STEP 3

    // formData.append("targetAudience", surveyData.targetAudienceSetting);
    obj.targetAudience = surveyData.targetAudienceSetting;

    if (String(surveyData.targetAudienceSetting) === "2") {
      // formData.append(
      //   "tiers",
      //   JSON.stringify(Object.entries(surveyData.tier).map((item) => item[1]))
      // );

      obj.tiers = JSON.stringify(
        Object.keys(surveyData.tier).map((item) => item)
      );
    }
    if (String(surveyData.targetAudienceSetting) === "1") {
      // formData.append("cohortId", surveyData.cohertId);
      obj.cohortId = surveyData.cohertId;
    }
    // obj.extraSegmentation =
    //   surveyData?.couponExtraSegmentation &&
    //   Object.keys(surveyData?.couponExtraSegmentation).length !== 0
    //     ? surveyData.couponExtraSegmentation
    //     : "";

    // if (dates) {
    //   const startDate = dates.startDate
    //     ? moment(dates.startDate).format("yyyy-MM-DD")
    //     : "";
    //   const endDate = dates?.endDate
    //     ? moment(dates.endDate).format("yyyy-MM-DD")
    //     : "";

    //   const startDateTime = moment(
    //     startDate + " " + dates.startTime,
    //     "yyyy-MM-DD HH:mm"
    //   );
    //   const endDateTime = moment(
    //     endDate + " " + dates.endTime,
    //     "yyyy-MM-DD HH:mm"
    //   );
    //
    //   if (startDateTime.isBefore(moment())) {
    //     return Swal.fire(
    //       "",
    //       "Cannot set survey with past date and time",
    //       "info"
    //     );
    //   }
    //   if (endDateTime.isBefore(startDateTime)) {
    //     return Swal.fire(
    //       "",
    //       "Survey cannot end before start time",
    //       "info"
    //     );
    //   }
    //   obj = {
    //     ...obj,
    //     startDate,
    //     endDate,
    //     startTime: dates?.startTime || "",
    //     endTime: dates?.endTime || "",
    //   };
    // }

    try {
      if (!editMode) await surveyService.createSurvey(obj);
      if (editMode) await surveyService.editSurvey(obj, surveyData.surveyId);
      Swal.fire("Success", "Survey created successfully", "success").then(() =>
        history.push("/loyalty/games/survey/all")
      );
    } catch (err) {
      Swal.fire("", err.response?.data?.status?.message, "info");
    }
  };

  const handleNextStep = (step) => {
    switch (step) {
      case SURVEY_STEPS_KEY.SURVEY_INFO: {
        surveyInfoFormik.setTouched({
          surveyTitle: true,
          surveyDescription: true,
          successMessage: true,
          prize: true,
          benefitId: true,
        });
        const description = RichTextEditor.createValueFromString(
          surveyInfoFormik.values.surveyDescription,
          "html"
        );
        const pDescription = description._editorState
          .getCurrentContent()
          .getPlainText();
        if (
          !surveyInfoFormik.dirty ||
          !surveyInfoFormik.isValid ||
          pDescription.length === 0 ||
          pDescription.length > 500
        )
          return false;
        else {
          setSurveyData((data) => ({ ...data, ...surveyInfoFormik.values }));
          setStepper(step + 1);
        }
        break;
      }

      case SURVEY_STEPS_KEY.QUESTIONNAIRE: {
        validateEntries();
        if (
          questionnaireFormik.errors?.questions &&
          Object.keys(questionnaireFormik.errors?.questions).length !== 0
        ) {
          setQuestionsTouched(true);
          return false;
        } else {
          setSurveyData((data) => ({ ...data, ...questionnaireFormik.values }));
          setStepper(step + 1);
        }
        break;
      }
      case SURVEY_STEPS_KEY.TARGETING: {
        if (
          targettingErrors?.errorObj &&
          Object.keys(targettingErrors.errorObj).length !== 0
        ) {
          return false;
        } else {
          setSurveyData((data) => ({ ...data, ...targettingData }));
          setStepper(step + 1);
        }
        break;
      }

      case SURVEY_STEPS_KEY.PREVIEW: {
        handleSurveySubmit(null, 0);
        break;
      }
      default:
        return setStepper(step + 1);
    }
  };

  const publishSurvey = (dates) => {
    handleSurveySubmit(dates, 1);
  };

  const saveAsDraft = () => {
    handleSurveySubmit(null, 0);
  };

  const setSurveyDates = (dates) => {
    setSurveyData((data) => ({ ...data, ...dates }));
  };

  const surveyDates = {
    startTime: surveyData.startTime,
    startDate: surveyData.startDate,
    endTime: surveyData.endTime,
    endDate: surveyData.endDate,
  };

  const clearData = () => {
    // setSurveyData({ ...CouponDetailsInitialData });
    setStepper(SURVEY_STEPS_KEY.SURVEY_INFO);
    // setToInitalValues();
    surveyInfoFormik.setValues({ ...surveyInfoInitValues });
    questionnaireFormik.setValues({ questions: [{ ...intitialData }] });
    surveyInfoFormik.setErrors({});
    questionnaireFormik.setErrors({});
  };

  //Create Permissions Object
  // in -> isActive ,  accessibleKeys, redux Survey types
  // out => { [formikKey : boolean]: boolean }

  const generatePermissionsObject = (surveyStatus) => {
    return {};
    let PERMISSIONS = {};
    if (CONSTANTS.SURVEY_STATUS) {
      let obj = Object.entries(CONSTANTS.SURVEY_STATUS).find((item) => {
        return item[1].status === surveyStatus;
      });
      if (obj) {
        if (Array.isArray(obj)[1] && !obj[1]?.editable) {
        } else {
          if (obj[1].editable) {
            PERMISSIONS = obj[1].editable.reduce((acc, item) => {
              acc[item] = true;
              return acc;
            }, {});
          }
        }
      }
    }
    return PERMISSIONS;
  };

  // React.useEffect(() => {
  let PERMISSIONS = {};
  if (editMode === true) {
    PERMISSIONS = generatePermissionsObject(isActive);
  } else {
    PERMISSIONS = { "*": true };
  }

  const isDisabled = (fieldname) => {
    return false;
    // console.log('PERMISSIONS', PERMISSIONS);
    // if (PERMISSIONS["*"]) return false;
    // else if (PERMISSIONS[fieldname]) return false;
    // return true;
  };
  // }, [isActive, surveyData, CONSTANTS]);
  return (
    <SurveyContext.Provider
      value={{
        stepper,
        setStepper,
        surveyData,
        surveyInfoFormik,
        questionnaireFormik,
        ...questionnaireFormOptions,
        handleNextStep,
        setErrors,
        targettingData,
        setTargettingData,
        publishSurvey,
        saveAsDraft,
        setSurveyDates,
        surveyDates,
        editMode,
        ANSWER_TYPES,
        setIsActive,
        PERMISSIONS,
        isDisabled,
        allQuestionsTouched,
        setQuestionsTouched,
        ...CONSTANT_OPTIONS,
        clearData,
        setSurveyData,
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
};
