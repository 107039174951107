export interface formDataProps {
  email: string;
  password: string;
}

export interface errorFormDataProps {
  [key: string]: string;
}

export const INITIAL_STATE_REGISTRATION = {
  email: '',
  password: ''
}

export interface ValidateFormData {
  [key: string]: (value: string) => undefined | string;
}

export const validationFormData: ValidateFormData = {
  email (value: string) {
    let string = value.trim();
    if (!string) {
      return 'This field is required'
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(string)) {
      return 'Email is not valid'
    }
    return;
  },
  password (value: string) {
    let string = value.trim();
    if (!string) {
      return 'Password is required'
    }
    // if (string.length < 8 || string.length > 20) {
    //   return 'Password should be between 8 and 20 characters in length'
    // }
    return;
  },
}
