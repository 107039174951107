import React from "react";

interface Props {
  signInViaPassword?: boolean;
  signInViaOTP?: boolean;
  signInOTPVerification?: boolean;
  signInResetPassword?: boolean;
}

const SignUpBanner: React.FC<Props> = ({
  signInViaPassword,
  signInViaOTP,
  signInOTPVerification,
  signInResetPassword
}: Props) => {
  return (
    <div className="banner-container">
      {signInViaPassword ? (
        <img src="/media/onboarding/banner/password-banner.svg" className="image" />
      ) : signInViaOTP ? (
        <img src="/media/onboarding/banner/otp-banner.svg" className="image" />
      ) : signInOTPVerification ? (
        <img src="/media/onboarding/banner/verify-banner.svg" className="image" />
      ) : signInResetPassword ? (
        <img src="/media/onboarding/banner/reset-password-banner.svg" className="image" />
      ) : (
        <img src="/media/onboarding/banner/welcome-banner.svg" className="image" />
      )}
      {/* <div className="logo-wrapper">
        <span className="logo">
          <img src="/media/unifynd/unisuite.png"/>
        </span>
        <h3 className="heading-1">UniSuite</h3>
      </div> */}
      {/* <img src={bannerImage?bannerImage:"/media/onboarding/banner/welcome-banner.svg"} className="image" /> */}
      {/* <div className="banner-content-wrapper">
        {bannerImage ? '' : <h2 className="heading-2">Welcome to UniSuite!</h2>}
        {bannerImage ? 
          <div className="tip-message-container">
            <div className="tip-message-header">
              <img src="/media/info.svg"/> Tips
            </div>
            <div className="tip-message-text">
              Make sure your password includes symbols and numbers to keep things extra safe.
            </div>
          </div>
          : 
          <span className="text-1 light-yellow">
            Find your people. Engage your customers. Build your brand. Do it all
            with UniSuite.
          </span>
        }
      </div> */}
    </div>
  );
};

export default SignUpBanner;