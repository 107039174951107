import React from 'react'
import {useFormik} from 'formik'

export default function useSurveyTargetingForm() {



    const targettingFormik = useFormik({
        initialValues: null
    })

    const setErrors = (errobj) => {
        // console.log(errors, )
        targettingFormik.setErrors(errobj)
        console.log(targettingFormik.errors)
    }
    
    const setTargettingData = (values) =>{
        targettingFormik.setValues(values)
    }

    return {
        targettingFormik,
        setErrors,
        setTargettingData,
        targettingData:targettingFormik.values
    }
}
