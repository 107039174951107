import {authActionTypes} from './auth.actiontype';

export const loginStart = (emailAndPassoword: {email:string, password:string}) => ({
  type: authActionTypes.LOGIN_INIT,
  payload: emailAndPassoword
});

export const otpVerifyed = () => ({
  type: authActionTypes.OTP_VERIFYED
});

export const loginSuccess = (userData:any) => ({
  type: authActionTypes.LOGIN_SUCCESS,
  payload: userData
});

export const loginFail = (error:any) => ({
  type: authActionTypes.LOGIN_FAIL,
  payload: error
});

export const logoutStart = () => ({
  type: authActionTypes.LOGOUT_INIT
});

export const clearUserData = () => ({
  type: authActionTypes.CLEAR_USER_DATA

})
export const frtPass_SendEmail = (email:string) => ({
  type: authActionTypes.FRGTPASS_EMAILINIT,
  payload: email
});

export const frtPass_EmailSuccess = () => ({
  type: authActionTypes.FRGTPASS_EMAILSUCCESS
});

export const frtPass_EmailError = () => ({
  type: authActionTypes.FRGTPASS_EMAILFAIL
});

export const register_start = (payload:any) => ({
  type: authActionTypes.REGISTER_INIT,
  payload
});
export const register_success = () => ({
  type: authActionTypes.REGISTER_SUCCESS,
});
export const register_fail = () => ({
  type: authActionTypes.REGISTER_FAIL,
});
export const setUser = (data:any)=>({
  type:authActionTypes.SET_USER, payload:data
})

export const loginInitAuto = (data:string)=>({
  type:authActionTypes.LOGIN_INIT_AUTO, payload:data
})
// export const actions = {
//   login: authToken => ({ type: authActionTypes.Login, payload: { authToken } }),
//   register: authToken => ({
//     type: authActionTypes.Register,
//     payload: { authToken }
//   }),
//   logout: () => ({ type: authActionTypes.Logout }),
//   requestUser: user => ({ type: authActionTypes.UserRequested, payload: { user } }),
//   fulfillUser: user => ({ type: authActionTypes.UserLoaded, payload: { user } })
// };
