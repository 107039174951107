import React from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { Switch } from "@material-ui/core";
import InputRadio from "../../../../components/Forms/Radio/InputRadio";
import Tooltip from "../../../../components/Tooltip";

const Step4 = (props) => {
  const { formData, changeFormData, setShowModal } = props;
  const [fileMapping, changeFileMapping] = React.useState([...formData.fileMapping]);
  const [attributeListType, changeAttributeListType] = React.useState();

  const allCols = fileMapping.filter(item => (item.displayName)).length;
  const mappedCols = fileMapping.filter(item => (item.operation&&item.displayName)).length;
  const isAllColMapped = mappedCols===fileMapping.filter(item => (item.displayName && item.attributeType==1)).length;

  const onHide = () => {
    // changeSetupCustomConfigModalStatus(false);
    changeFormData({...formData, setupCustomConfigModalStatus: false});
  }

  const { step4 } = formData;

  const { multipleValues = "", emptyValues = "", newDataRecord = "", customAttributeMapping = false } = step4 || {};

  const disableBtn = () => {
    if (!multipleValues && !customAttributeMapping) {
      return true;
    }

    if (multipleValues === "Merge with new data" && !newDataRecord) {
      return true;
    }

    if (!emptyValues) {
      return true;
    }

    return false;
  };

  const setPropertyValue = (itemMapping, updatedData, index) => {
    const item = {
      ...itemMapping,
      ...updatedData
    }
    const _newMapping = fileMapping.slice();
    _newMapping.splice(index, 1, item);
    changeFileMapping(_newMapping)
  };

  const renderTableData = () => {
    const columns: any = [];
    fileMapping.filter((_item) => {
      if(!_item.displayName) {
        return false;
      }
      if(attributeListType === 'Mapped') {
        return _item.operation;
      } else if(attributeListType === 'Unmapped') {
        return !_item.operation;
      }
      if(_item.attributeType == 2) {
        return false
      }
      return true;
    }).forEach((row, index) => {
      columns.push(
        <tr
          key={row.name}
          className={`show ${row.isPrimary==1? 'map-attribute-primary-row': ''}`}
        >
          <td className="text-small-2 navy">
            <div className="d-flex align-items-center">{row.displayName}
            {row.isPrimary==1 && (<div className='inner-type'>Primary Identifier</div>)}
            </div>
          </td>
          <td className="text-small-2 navy">
            {<InputRadio
              formGroupClassName='top-0'
              label={""}
              options={[
                { label: "", value: "selected" }
              ]}
              id="multipleValues"
              name="multipleValues"
              value={row.operation==3 || ['firstName', 'lastName', 'dob'].indexOf(row.name) !==-1? 'selected': ''}
              handleRadioChange={({ value }: any) => {
                setPropertyValue(row, {operation: 3}, row.index)
              }}
              disabled={['firstName', 'lastName', 'dob'].indexOf(row.name) !==-1 || row.isPrimary==1}
            />}
          </td>
          <td className="text-small-2 navy">
            {
              ['firstName', 'lastName', 'dob'].indexOf(row.name) ===-1 && row.isPrimary!=1?
              <InputRadio
                formGroupClassName='top-0'
                label={""}
                options={[
                  { label: "", value: "selected" }
                ]}
                id="multipleValues"
                name="multipleValues"
                value={row.operation==1||row.operation==2?'selected': ''}
                handleRadioChange={({ value }: any) => {
                  setPropertyValue(row, {operation: 2}, row.index)
                }}
                disabled={row.isPrimary==1}
              />: null
            }

          </td>
          <td className="text-small-2 navy">
            {
              ['firstName', 'lastName', 'dob'].indexOf(row.name) ===-1 && row.isPrimary!=1?
              <div className="switch-container">
                <Switch
                  checked={row.operation==1}
                  onChange={(e) => {
                    setPropertyValue(row, {operation: row.operation==1? 2: 1}, row.index)
                  }}
                  color="primary"
                  disabled={row.operation==3 || !row.operation || row.isPrimary==1}
                />
              </div>: null
            }
          </td>
        </tr>
      )
    });
    return columns;
  }

  return (
    <div className="import-contact-page-wrapper">
      <div className="import-title">Map attributes</div>
      <div className="import-description">
        You can customise matching records for each attribute. Select between merging new records to existing ones or replace old records with new
        values. Each attribute has to be mapped.
      </div>

      <div className={`select-type-wrapper step4 mt-18`}>
        <InputRadio
          label={"What should we do with multiple entries?"}
          hint={"What do you want to do when the crm is importing some information against a contact attribute for which some value already exists."}
          className={`step4-second-radio ${formData.attributesMapped ? 'select-type-wrapper-disabled' : ''} ${multipleValues === "Merge with new data" && !formData.attributesMapped ? "subradio-box" : ""}`}
          options={[
            { label: "Merge with new data", value: "Merge with new data" },
            { label: "Replace with new data", value: "Replace with new data" },
          ]}
          id="multipleValues"
          name="multipleValues"
          value={formData.attributesMapped? "": multipleValues || ""}
          handleRadioChange={({ value }: any) => {
            changeFormData({ ...formData, step4: { ...formData.step4, multipleValues: value, newDataRecord: null } });
          }}
          disabled={formData.attributesMapped}
        />

        {(multipleValues === "Merge with new data" && !formData.attributesMapped) && (
          <div className="select-type-wrapper-radio">
            <InputRadio
              label={"Do you want to use this new data as the main record?"}
              sublabel={"These new entries will be used to send campaigns to your customers."}
              hint={"When there are multiple values stored against an attribute, the value which is specified as the main value will be used at the time of campaign personalisation"}
              options={[
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
              ]}
              id="newDataRecord"
              name="newDataRecord"
              value={newDataRecord || ""}
              handleRadioChange={({ value }: any) => {
                changeFormData({ ...formData, step4: { ...formData.step4, newDataRecord: value } });
              }}
            />
          </div>
        )}

        <div
          className={`d-flex customise-attribute-mapping ${formData.attributesMapped ? "checked" : ""}`}
          onClick={() => {
            // changeFormData({ ...formData, step4: { ...formData.step4, customAttributeMapping: true, multipleValues: null } });
          }}
        >
          <div
            onClick={() => {
              if(!formData.attributesMapped) {
                // changeSetupCustomConfigModalStatus(true);
                changeFormData({...formData, setupCustomConfigModalStatus: true});
              }
            }}
            className="cam-text"
          >
            {formData.attributesMapped && (
              <div className="green-tick icon-right8">
                <i className="bg-icon bg-icon8 icon-white bg-tick-filled"></i>
              </div>
            )}
            {formData.attributesMapped ? 'Customise mapping added' : 'Customise attribute mapping'}
          </div>
          {formData.attributesMapped && (
            <div className="d-flex align-items-center icon-left20">
              <div
                className="d-flex align-items-center justify-content-center cam-btn cam-btn-add cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  // changeSetupCustomConfigModalStatus(true)
                  changeFormData({...formData, setupCustomConfigModalStatus: true});
                  // changeFormData({ ...formData, step4: { ...formData.step4, customAttributeMapping: null, multipleValues: null } });
                }}
              >
                <i className="bg-icon bg-edit icon-primary bg-icon12"></i>
              </div>
              <div
                className="d-flex align-items-center icon-left8 justify-content-center cam-btn cam-btn-remove cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowModal({
                    title: "Delete Customisation",
                    description: "Are you sure you want to discard the attribute customisation?",
                    btn1: {
                      title: "Confirm",
                      variant: "danger",
                      handler: () => {
                        setShowModal(null);
                        changeFormData({...formData, attributesMapped: false});
                      },
                    },
                    btn2: {
                      title: "Cancel",
                      variant: "secondary",
                      handler: () => {
                        setShowModal(null);
                      },
                    }
                  });
                  // changeFormData({ ...formData, step4: { ...formData.step4, customAttributeMapping: null, multipleValues: null } });
                }}
              >
                <i className="bg-icon bg-trash-2 icon-red bg-icon12"></i>
              </div>
            </div>
          )}
        </div>

        <InputRadio
          label={"What should we do with empty values?"}
          className={`step4-second-radio`}
          options={[
            { label: "Ignore them", value: "Ignore them" },
            { label: "Delete existing attribute values", value: "Delete existing attribute values" },
          ]}
          id="emptyValues"
          name="emptyValues"
          value={emptyValues || ""}
          handleRadioChange={({ value }: any) => {
            changeFormData({ ...formData, step4: { ...formData.step4, emptyValues: value } });
          }}
        />
      </div>
      {formData.setupCustomConfigModalStatus?
        <div className='unifynd-full-screen'>
          <div className='full-screen-header'>
              <div className='full-screen-header-title'>
                Map Attributes
              </div>
          </div>
          <div className='full-screen-body'>
            <div className='full-screen-body-inner'>
              <div className="import-title">Map attributes</div>
              <div className="import-description">
                You can customise matching records for each attribute. Select between merging new records to existing ones or replace old records with new
                values. Each attribute has to be mapped.
              </div>

              <div className='mapping-filters-header d-flex align-items-center justify-content-between'>
                <div className='mapping-filters-list d-flex'>
                  <div
                    className={`p-2 cursor-pointer ${attributeListType==='All'? 'activeListType': ''}`}
                    onClick={() => changeAttributeListType('All')}
                  >
                    All ({allCols})
                  </div>
                  <div
                    className={`p-2 cursor-pointer ${attributeListType==='Mapped'? 'activeListType': ''}`}
                    onClick={() => changeAttributeListType('Mapped')}
                  >
                    Mapped ({mappedCols})
                  </div>
                  <div
                    className={`p-2 cursor-pointer ${attributeListType==='Unmapped'? 'activeListType': ''}`}
                    onClick={() => changeAttributeListType('Unmapped')}
                  >
                    Unmapped ({allCols-mappedCols})
                  </div>
                </div>
              </div>
              <Table className="bg-white rounded mapping-filters-table table">
                <thead>
                  <tr>
                    <th className="column-header">Attributes</th>
                    <th className="column-header">Replace with new data</th>
                    <th className="column-header">Append new data</th>
                    <th className="column-header">
                      <div className="d-flex justify-content-center">
                        USE as Main Record
                        <Tooltip
                          label={<i className='bg-icon bg-help-circle bg-icon16 icon-grey icon-left12 cursor-pointer'/>}
                          variant="primary"
                          description={'When there are multiple values stored against an attribute, the main record will be used at the time of message personalisations.'}
                          placement="top"
                        />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>{renderTableData()}</tbody>
              </Table>

            </div>
          </div>
          <div className='full-screen-footer d-flex justify-content-end'>
            <Button
              onClick={() => onHide()}
              variant="secondary"
            > Cancel
            </Button>
            <Button
              onClick={() => {
                if(isAllColMapped) {
                  changeFormData({...formData, fileMapping: fileMapping, attributesMapped: true, setupCustomConfigModalStatus: false})
                  // changeSetupCustomConfigModalStatus(false);
                }
              }}
              variant="primary"
              className='icon-left12'
              disabled={!isAllColMapped}
            > Save Customisation
            </Button>
          </div>
        </div>: null
      }
    </div>
  );
};


export default Step4;
