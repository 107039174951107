import React, { Suspense, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../../_metronic/layout";
import NoAccessComponent from "../components/AccessDenied";
import { setActiivePage } from "../store/breadcrumbManager/breadcrumb.action";
import PagesList from "../utils/PagesList";
import { DashboardPage } from "./dashboard";
import Survey from "./Survey/index";

const Contacts = lazy(() => import("./ContactsV2/pages"));

const UserProfilepage = lazy(() => import("./UserProfile/UserProfilePage"));

const Settings = lazy(() => import("./SettingsV2/pages/index"));

const Templates = lazy(() => import("./TemplatesV2/pages/index"));

const Campaigns = lazy(() => import("./CampaignsV2/pages/index"));

const TeamAndRoles = lazy(() => import("./TeamAndRoles/pages/index"));

const Users = lazy(() => import("./UserManagement/pages"));

const Loyalty = lazy(() => import("./Loyalty/pages"));

const MicroGames = lazy(() => import("./MicroGames/pages"));

const Analytics = lazy(() => import("./Analytics/pages"));
const AssetsRef = lazy(() => import("./assetsRef"));

// const ALLOWED_FOR_ALL_USERS = ["settings"];

export default function DefaultPage() {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state?.appData?.globalConstants?.menu);

  const updatePage = (pageDetails) => {
    dispatch(setActiivePage(pageDetails));
  };

  const renderWithBreadcrumb = (Component, pageObject) => {
    if (menu) {
      let found = Object.keys(menu).some((it) => pageObject.key === it);

      if (found) {
        return (props) => {
          updatePage(pageObject);
          return <Component {...props} />;
        };
      }
    }

    return (props) => {
      updatePage(pageObject);
      return <NoAccessComponent />;
    };

    // Object.keys(menu).forEach((it, index) => {
    //   if (found) return;

    //   if (pageObject.key === it) {
    //     let _innerPages = pageObject?.innerPages;

    //     found = _innerPages;

    //     // if (Object.keys(_innerPages)?.length === 0) return;

    //     // found = Object.keys(_innerPages).find((item) => menu[it]?.subMenu?.[_innerPages[item]?.key]);

    //     return;
    //   } else if (ALLOWED_FOR_ALL_USERS.includes(pageObject.key)) {
    //     found = pageObject.key;
    //   }
    // });
  };

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to={PagesList.CONTACTS.url} />
        <Redirect exact from="/auth" to={PagesList.CONTACTS.url} />
        <Redirect exact from="/auth/login" to={PagesList.CONTACTS.url} />
        <Redirect exact from="/overview" to={PagesList.CONTACTS.url} />

        {/* {<Redirect from="/auth" to="/overview" />} */}
        <ContentRoute path="/overview" component={DashboardPage} />
        <Route path={PagesList.CONTACTS.url} render={(props) => renderWithBreadcrumb(Contacts, PagesList.CONTACTS)(props)} />
        <Route path="/users" component={Users} />
        <Route path="/loyalty/games/survey" component={Survey} />
        <Route path={PagesList.CAMPAIGN.url} render={(props) => renderWithBreadcrumb(Campaigns, PagesList.CAMPAIGN)(props)} />
        <Route path="/team-and-roles" component={TeamAndRoles} />
        <Route path="/settings" render={(props) => renderWithBreadcrumb(Settings, PagesList.SETTINGS)(props)} />
        <Route path={PagesList.TEMPLATES.url} render={(props) => renderWithBreadcrumb(Templates, PagesList.TEMPLATES)(props)} />
        <Route path={PagesList.LOYALTY.url} render={(props) => renderWithBreadcrumb(Loyalty, PagesList.LOYALTY)(props)} />
        <Route path={PagesList.MICRO_GAMES.url} render={(props) => renderWithBreadcrumb(MicroGames, PagesList.MICRO_GAMES)(props)} />
        <Route path={PagesList.ANALYTICS.url} render={(props) => renderWithBreadcrumb(Analytics, PagesList.ANALYTICS)(props)} />
        <Route path={PagesList.ASSETS_REF.url} render={(props) => renderWithBreadcrumb(AssetsRef, PagesList.AssetsRef)(props)} />
        <Route path="/user-profile" component={UserProfilepage} />

        {/* <Redirect to="/error/error-v1" /> */}
      </Switch>
    </Suspense>
  );
}
