import { campaignActionTypes } from "./campaign.actiontype";

const changeCreateCampaignStep = (step: string) => ({
	type: campaignActionTypes.CAMPAIGN_CHANGE_CREATE_CAMPAIGN_STEP,
	payload: {step}
});

const goToNextStep = () => ({
	type: campaignActionTypes.CAMPAIGN_GO_TO_NEXT_STEP,
});

const goToPrevStep = () => ({
	type: campaignActionTypes.CAMPAIGN_GO_TO_PREV_STEP,
});

const changeCampaignSubStep = (step: string) => ({
	type: campaignActionTypes.CAMPAIGN_CHANGE_CAMPAIGN_SUB_STEP,
	payload: {step}
});

const goToNextSubStep = () => ({
	type: campaignActionTypes.CAMPAIGN_GO_TO_NEXT_SUB_STEP
});

const goToPrevSubStep = () => ({
	type: campaignActionTypes.CAMPAIGN_GO_TO_PREV_SUB_STEP
});

const fetchCampaignChannels = () => ({
	type: campaignActionTypes.FETCH_CAMPAIGN_CHANNELS
});

const setCreateEditCampaignForm = (data: any) => ({
	type: campaignActionTypes.SET_CREATE_EDIT_CAMPAIGN_FORM,
	payload: data
});

const changeCampaignChannelId = (data: any) => ({
	type: campaignActionTypes.CHANGE_CAMPAIGN_CHANNEL_ID,
	payload: data
});

const changeCompanyChannelId = (data: any) => ({
	type: campaignActionTypes.CHANGE_COMPANY_CHANNEL_ID,
	payload: data
});

const changeCampaignNameModal = (data: any) => ({
	type: campaignActionTypes.CHANGE_CAMPAIGN_NAME_MODAL,
	payload: data
})

const setCampignName = (data: any) => ({
	type: campaignActionTypes.SET_CAMPAIGN_NAME,
	payload: data
});

const resetCampaignFormData = () => ({
	type: campaignActionTypes.RESET_CAPAIGN_FORM_DATA
});

const campaignCreatedSuccessfull = () => ({
	type: campaignActionTypes.CAMPAIGN_CREATED_SUCCESSFULL
});

const openAddCampaignNameModal = () =>
	changeCampaignNameModal({show: true, isEdit: false, isCreate: true});

const openEditCampaignNameModal = () =>
	changeCampaignNameModal({show: true, isEdit: true, isCreate: false});

const closeCampaignNameModal = () =>
	changeCampaignNameModal({show: false, isEdit: false, isCreate: false});

const changeCampaignSegment = (data: any) => ({
	type: campaignActionTypes.CHANGE_CAMPAIGN_SEGMENT,
	payload: data
})

// template actions
const setDltApprovedStatus = (data: any) => ({
	type: campaignActionTypes.SET_IS_DLT_APPROVED_STATUS,
	payload: data
});
const changeSelectedTemplateMessage = (data: any) => ({
	type: campaignActionTypes.UPDATE_SELECTED_TEMPLATE_DATA,
	payload: data
});
const setDynamicMapping = (data: any) => ({
	type: campaignActionTypes.SET_DYNAMIC_MAPPING,
	payload: data
});
const setCompanySmsChannel = (data: any) => ({
	type: campaignActionTypes.SET_COMPANY_SMS_CHANNEL,
	payload: data
});

const updateCampaignFormErrors = (data: any) => ({
	type: campaignActionTypes.UPDATE_CAMPAIGN_FORM_ERRORS,
	payload: data
});

const changeScheduleData = (data: any) => ({
	type: campaignActionTypes.CHANGE_SCHEDULE_DATA,
	payload: data
});

// any should be array of channel type here
const saveCampaignChannels = (data: any) => ({
	type: campaignActionTypes.SAVE_CAMPAIGN_CHANNELS,
	payload: data
});

const saveCampaignAsDraft = (data: any) => ({
	type: campaignActionTypes.SAVE_CAMPAIGN_AS_DRAFT
});

const changeEmailSettings = (data: any) => ({
	type: campaignActionTypes.CHANGE_EMAIL_SETTINGS,
	payload: data
});

const changeEmailPreviewModalStatus = (data: any) => ({
	type: campaignActionTypes.CHANGE_EMAIL_PREVIEW_MODAL_STATUS,
	payload: data
});

const showEmailPreviewModal = () =>
	changeEmailPreviewModalStatus({show: true});

const hideEmailPreviewModal = () =>
	changeEmailPreviewModalStatus({show: false});

const campaignStepsActions = {
	changeCreateCampaignStep,
	goToNextStep,
	goToPrevStep,
	fetchCampaignChannels,
	saveCampaignChannels,
	setCreateEditCampaignForm,
	changeCampaignChannelId,
	changeCompanyChannelId,
	openAddCampaignNameModal,
	openEditCampaignNameModal,
	closeCampaignNameModal,
	setCampignName,
	changeCampaignSegment,
	setDltApprovedStatus,
	changeSelectedTemplateMessage,
	setDynamicMapping,
	setCompanySmsChannel,
	updateCampaignFormErrors,
	changeScheduleData,
	resetCampaignFormData,
	campaignCreatedSuccessfull,
	saveCampaignAsDraft,
	changeCampaignSubStep,
	changeEmailSettings,
	changeEmailPreviewModalStatus,
	showEmailPreviewModal,
	hideEmailPreviewModal,
	goToNextSubStep,
	goToPrevSubStep
};

export default campaignStepsActions;
