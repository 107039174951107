import React, { ReactElement } from "react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import './_style.scss'
type StepObject = {
  label: string;
  value: string | number;
};
interface Props {
  steps: StepObject[];
  active: number | string;
}

export default function StepTracker({ steps, active }: Props): ReactElement {
  return (
      <div className="stepper-items-container">
       <div className="stepper-items">
       {steps.map((item, _index) => {
          const { label, value } = item;
          return (
            <span 
              key={`create-coupon-steps-${value}`}
              className={`create-campaign-steps  ${
                value === active ? "current  " : ""
              }`}
            >
              {label}{" "}
              {_index === steps.length - 1 ? null : <ChevronRightIcon />}
            </span>
          );
        })}
       </div>
      </div>
  );
}
