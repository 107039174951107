import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore } from "redux-persist";
import { HTTPClient, HTTPClientV2, HTTPClientV3 } from "../service";

import RootReducer from "./root.reducer";
import RootSaga from "./root.saga";

import { appConst_init } from "./appStore/appStore.action";
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

if (process.env.NODE_ENV === "development") {
  // middleware.push(logger)
}

const store = createStore(
  RootReducer,
  composeEnhancers(applyMiddleware(...middleware))
);

export const persistor = persistStore(store, {}, () => {
  const { auth } = store.getState();
  if(auth.authToken) {
    HTTPClient.saveHeader({key: 'Authorization', value: `Bearer ${auth.authToken}`});
    HTTPClientV2.saveHeader({key: 'Authorization', value: `Bearer ${auth.authToken}`});
    HTTPClientV3.saveHeader({key: 'Authorization', value: `Bearer ${auth.authToken}`});
  }
  if(auth.user) {
    HTTPClient.saveHeader({key: 'companyId', value: `company_${auth.user.companyId}`})
    HTTPClientV2.saveHeader({key: 'companyId', value: `company_${auth.user.companyId}`})
    HTTPClientV3.saveHeader({key: 'companyId', value: `company_${auth.user.companyId}`})
  }
  store.dispatch(appConst_init());
});

sagaMiddleware.run(RootSaga);

export default store;
