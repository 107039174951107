import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import LoginViaOTP from "./LoginViaOTP";
import LoginViaPassword from "./LoginViaPassword";
import InputText from "../../../components/Forms/Input/Input";
import MyButton from "../../../components/Forms/Button/Button";
import AlertBox from "../../../components/Forms/Alert/Alert";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { formDataProps, INITIAL_STATE_REGISTRATION, errorFormDataProps, validationFormData } from "../_constants/index";
import SignUpBanner from "../components/Banner";
import { authActionTypes } from "../../../store/auth/auth.actiontype";
import "../_styles/index.scss";
import { Link, useLocation } from "react-router-dom";
import { authService } from "../../../service";
import Swal from "sweetalert2";
import { getQueryParams } from "../_utils";
interface eventProps {
  name: string;
  value: string;
}
const AuthPage: React.FC = () => {
  const dispatch = useDispatch();
  const [loginType, changeLoginType] = React.useState(0);
  const [isRequesting, changeRequesting] = React.useState(false);
  const [emailError, changeEmailError] = React.useState("");
  const error: string | undefined = useSelector((state: any) => state.auth.error, shallowEqual);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [formData, setFormData] = useState<formDataProps>({
    ...INITIAL_STATE_REGISTRATION,
  });
  const [errors, setErrors] = useState<errorFormDataProps>({});
  const location = useLocation();
  const FieldValidation = ({ name, value }: eventProps) => {
    let error: undefined | string = validationFormData[name](value);
    if (error) {
      setErrors({ ...errors, [name]: error });
    } else {
      delete errors[name];
      setErrors(errors);
    }
  };
  const handleChange = ({ name, value }: eventProps) => {
    setFormData({ ...formData, [name]: value });
    // FieldValidation({ name, value });
  };
  const handleBlur = ({ name, value }: eventProps) => {
    FieldValidation({ name, value });
  };
  const validateForm = (formDataValues: formDataProps): boolean => {
    let submitErrors: errorFormDataProps = {};
    for (const [key, value] of Object.entries(formDataValues)) {
      let error: undefined | string = validationFormData[key](value);
      if (error) {
        submitErrors[key] = error;
      }
    }
    setErrors(submitErrors);
    return Object.keys(submitErrors).length === 0;
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    let validationErrors: boolean = validateForm(formData);
    if (validationErrors) {
      try {
        dispatch({ type: authActionTypes.LOGIN_INIT, payload: formData });
      } catch (error) {}
    }
  };

  let queryParams = getQueryParams(location);

  useEffect(() => {
    if (queryParams && queryParams.enc) {
      let encodedToken = queryParams.enc;
      let token = atob(encodedToken);
      dispatch({ type: authActionTypes.LOGIN_INIT_AUTO, payload: token });
    }
  }, []);

  const handleLoginViaOTP = async () => {
    changeRequesting(true);
    try {
      const error = validationFormData.email(formData.email);
      if (!error) {
        // const response = await authService.verifyEmail({
        //   email:formData.email
        // });
        changeLoginType(1);
      } else {
        changeEmailError(error);
      }
    } catch (e) {
      const message = e?.response?.data?.status?.message || "Something went wrong !! Try again latter.";
      changeEmailError(message);
    }
    changeRequesting(false);
  };

  const handleLoginViaPassword = async () => {
    changeRequesting(true);
    console.log("login via pwd");
    try {
      const error = validationFormData.email(formData.email);
      if (!error) {
        // const response = await authService.verifyEmail({
        //   email:formData.email
        // });
        changeLoginType(2);
      } else {
        changeEmailError(error);
      }
    } catch (e) {
      const message = e?.response?.data?.status?.message || "Something went wrong !! Try again latter.";
      changeEmailError(message);
    }
    changeRequesting(false);
  };

  if (loginType == 1) {
    return <LoginViaOTP email={formData.email} changeLoginType={changeLoginType} />;
  }
  if (loginType == 2) {
    return <LoginViaPassword email={formData.email} changeLoginType={changeLoginType} />;
  }

  return (
    <section className="registration-form">
      <div className="container-fluid">
        <Row className="registration-form-container">
          <Col md={7} className="reg-form-wrapper">
            <div className="reg-form-inner">
              <div className="form-logo">
                <img src="/media/onboarding/logo.png" />
                <span>UniSuite</span>
              </div>
              <div className="welcome-box">
                <div className="wb-heading">
                  <span>Welcome</span>
                  <span>back.</span>
                </div>
              </div>
              <form id="kt_login_signin_form" className="form-box-container" onSubmit={handleSubmit}>
                <InputText
                  label="Email"
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Enter email address"
                  value={formData.email}
                  required={true}
                  onChange={(data) => {
                    if (errors.email || emailError) {
                      changeEmailError("");
                      setErrors({ ...formData, email: "" });
                    }
                    handleChange(data);
                  }}
                  onBlur={() => {}}
                  error={errors.email || emailError}
                  autoComplete="false"
                  className="top-0"
                />
                {/* <InputText
                  label="Password"
                  type="password"
                  name="password"
                  id="password"
                  placeholder=""
                  value={formData.password}
                  required={true}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.password}
                /> */}
                {/* <p className="">
                  <Link
                    className="text-primary text-decoration-underline"
                    to="#"
                    onClick={() => setOpen(true)}
                  >
                    Forgot Password?
                  </Link>
                </p> */}
                <div className="two-btns-container">
                  <MyButton
                    variant="primary"
                    name="Login via OTP"
                    onClick={handleLoginViaOTP}
                    block={true}
                    disabled={errors.email || emailError || isRequesting}
                    className="btn-new"
                  />
                  <MyButton
                    variant="primary"
                    name="Login via Password"
                    block={true}
                    onClick={handleLoginViaPassword}
                    disabled={errors.email || emailError || isRequesting}
                    className="btn-new"
                  />
                  {/*
                    <Link to="/auth/login-otp" className={`btn-new ${(formData.email.length === 0  || errors.email) ? 'disabled' : ''}`}>Login via OTP</Link>
                    <Link to={{pathname:"/auth/login-password", state: {signInEmail: formData.email }}} className={`btn-new ${(formData.email.length === 0  || errors.email) ? 'disabled' : ''}`}>Login via Password</Link>
                     <MyButton
                    variant="primary"
                    type="submit"
                    name="Login via OTP"
                    block={true}
                    disabled={formData.email.length === 0 || formData.password.length === 0 || errors.email||errors.password}
                    className="btn-new"
                  />
                  <MyButton
                    variant="primary"
                    type="submit"
                    name="Login via Password"
                    block={true}
                    className="btn-new"
                  /> */}
                </div>
                {/* <div className="wb-signin">
                  Don't have an account? &nbsp;{" "}
                  <a className="wb-signin-link" href={process.env.REACT_APP_SIGN_UP_URL} target="_blank">
                    Create New
                  </a>
                </div> */}
              </form>
            </div>
            {/* <Row className="vh-100 d-flex align-items-center py-5 bg-white">
              <Col md={{ span: 8, offset: 2 }}>
                <div className="text-center mb-4 mb-lg-7">
                  <h3 className="font-size-h1">Sign in to UniSuite</h3>
                  <p className="text-muted font-weight-bold">
                    Don't have an account?{" "}
                    <a
                      href={process.env.REACT_APP_SIGN_UP_URL}
                      target="_blank"
                      className="text-primary text-decoration-underline"
                    >
                      Sign Up
                    </a>
                  </p>
                </div>
                {error && <AlertBox variant="danger" message={error} />}
                <form
                  id="kt_login_signin_form"
                  className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                  onSubmit={handleSubmit}
                >
                  <InputText
                    label="Email"
                    type="text"
                    name="email"
                    id="email"
                    placeholder=""
                    value={formData.email}
                    required={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email}
                    autoComplete="false"
                  />
                  <InputText
                    label="Password"
                    type="password"
                    name="password"
                    id="password"
                    placeholder=""
                    value={formData.password}
                    required={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.password}
                  />
                  <p className="">
                    <Link
                      className="text-primary text-decoration-underline"
                      to="#"
                      onClick={() => setOpen(true)}
                    >
                      Forgot Password?
                    </Link>
                  </p>
                  <MyButton
                    variant="primary"
                    type="submit"
                    name={
                      <span>
                        <span>Login </span>
                        <NavigateNextIcon />
                      </span>
                    }
                    block={true}
                    disabled={formData.email.length === 0 || formData.password.length === 0 || errors.email||errors.password}
                  />
                </form>
                {
                  <p className="text-center mt-4">
                    Forgot Password? Click{" "}
                    <Link
                      className="text-primary"
                      to="#"
                      onClick={() => setOpen(true)}
                    >
                      here
                    </Link>
                    .
                  </p>
                }
              </Col>
            </Row> */}
          </Col>
          <Col md={5} className="reg-form-banner">
            <SignUpBanner />
          </Col>
        </Row>
      </div>
      <EmailModal isOpen={isOpen} setOpen={setOpen} />
    </section>
  );
};

const EmailModal = ({ isOpen, setOpen }: { isOpen: boolean; setOpen: Function }) => {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const EmailRegex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

  const handleSubmit = async () => {
    try {
      await authService.forgotPass_sendEmail(email);
      return Swal.fire("Success", "Recovery mail sent to your Email", "success").then(() => setEmail(""), setOpen(false));
    } catch (err) {
      setError(err.response?.data?.status?.message);
    }
  };

  const onHide = () => {
    setEmail("");
    setOpen(false);
  };
  return (
    <Modal show={isOpen} onHide={onHide} keyboard={true}>
      <Modal.Header closeButton>
        <Modal.Title>Enter Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input className="form-control" onChange={(e) => setEmail(e.target.value)} placeholder={"Email"} value={email} type="email" />
        <p className="text-danger">{error}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={!EmailRegex.test(email)}>
          Submit
        </Button>
        {/* <Button variant="primary">Understood</Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default AuthPage;
