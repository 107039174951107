import React, { useState } from "react";
import { OrgChartComponent } from "./OrgChartComponent";
import "./styles.scss";
import { prepareObj } from "./helper/index";
import { InputSearch } from "../Forms/Search";

const stageNodeClass = {
  1: "first",
  2: "second",
  3: "third",
  4: "fourth",
};

const D3OrgChart = (props) => {

  const { tableData } = props;
  //const tableData = rawData;
  const [searchText, changeSearchText] = useState("");
  const [stageValue, setStageValue] = useState(0);
  let data = {};
  const getRootOfTable = (tableData) => {
    let root = "";
    Object.keys(tableData)?.forEach((val) => {
      if(!tableData[val]?.parent) {
        root = val;
      }
    })
    return root;
  };

  const getChildOfRoot = (tableName, data) => {
    let childTables = Object.keys(data)?.filter(item => {
      if (data[item]?.parent === tableName) {
        return item;
      }
    })
    return getChildOfTable(childTables);
  }

  const getChildOfTable = (childTables) => {
    let child = [];
    childTables?.forEach(item => {
      let obj = {tableName: item};
      child.push(obj);
    });
    return child;
  }
  
  const getChildTablesData = (data) => {
    let childTables = {};
    Object.keys(data)?.forEach(item => {
      if(data[item]?.parent) {
        childTables[item] = { tableName: item, child: getChildOfTable(data[item].child)};
      }
    })
    return childTables;
  }

  const rootOfTable = getRootOfTable(tableData);
  
  let childTableData = getChildTablesData(tableData);
  
  let tableStructure={};

  tableStructure[rootOfTable] = {
    tableName: rootOfTable,
    child: getChildOfRoot(rootOfTable, tableData),
    ...childTableData,
  }
  let tablesData = {
    root: rootOfTable,
    tableStructure: {
      ...tableStructure,
    }
  }

  data = prepareObj(tablesData);
  return (
    <>
      {data?.length ? (
        <div>
          <div className="org-chart-header d-flex justify-content-between">
            <div>
              <InputSearch
                delay={500}
                onSearch={(e) => {
                  changeSearchText(e);
                  console.log(searchText);
                }}
                placeholder="Look for a table"
              />
            </div>
            <div className="d-flex">
              <DataItemComponent name="Primary Table" stage={1} action={false} onClick={() => setStageValue(1)}/>
              <DataItemComponent name="Secondary Tables" stage={2} action={false} onClick={() => setStageValue(2)}/>
              <DataItemComponent name="Tertiary Tables" stage={3} action={false} onClick={() => setStageValue(3)}/>
              <DataItemComponent name="Quaternary Tables" stage={4} action={false} onClick={() => setStageValue(4)}/>
            </div>
          </div>
          <OrgChartComponent
            {...props}
            data={data || []}
            searchText={searchText}
            stageValue={stageValue}
            setStageValue={setStageValue}
            rootOfTable={rootOfTable}
          />
        </div>
      ) : null}
    </>
  );
};

const DataItemComponent = (props) => {
  const { name, stage, action, onClick } = props;
  return (
    <div class={`tree-node-container showConnection hover ${stageNodeClass[stage]}`} onClick={onClick}>
      <div class="tnc-left">
        <div class="tnc-level">{stage}</div>
      </div>
      <div class="tnc-middle">{name}</div>
      {action ? <div class="tnc-right"></div> : null}

      <div className="arrow"></div>
    </div>
  );
};

export default D3OrgChart;
