import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";

export default function ({ src, createPath, buttonLabel, description, clickHandler, style = {} }) {
  const { push } = useHistory();
  return (
    <div className="crm-empty-screen" style={{ ...style }}>
      <img src={src} />
      <div className="empty-state-msg">{description}</div>
      {buttonLabel && createPath && (
        <Button
          variant="primary"
          onClick={() => {
            console.log("createPath", createPath);
            if (clickHandler instanceof Function) {
              clickHandler();
            } else {
              push(createPath);
            }
          }}>
          <div className="bg-icon bg-math-plus bg-icon20 icon-white icon-right8 " />
          {buttonLabel}
        </Button>
      )}
    </div>
  );
}
