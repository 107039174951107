import {HTTPClient, HTTPClientV3} from './http.service';

export const appService = {
  async getAppConstant() {
    try {
      const {data:{data: appConstant}} = await HTTPClient.get('/constants/getConstants/');
      return appConstant;
    }catch(e) {
      console.log(e);
    }
  },

  async getLanguageList() {
    try {
      const {data:{data: appConstant}} = await HTTPClient.get('/constants/getLanguageConfiguration/');
      return appConstant;
    }catch(e) {
      console.log(e);
    }
  },

  async getMenuObj() {
    try {
     return await HTTPClientV3.get('/menu/getMenuObject');
    }catch(e) {
      console.log(e);
    }
  },

  async getPartnerConfig() {
    try {
      return await HTTPClient.get('/constants/getPartnerConfiguration');
    }catch(e) {
      console.log(e);
    }
  },

  async getMappingList() {
    try {
     return await HTTPClientV3.get('/contacts/getMapping');
    }catch(e) {
      console.log(e);
    }
  }
  
}
