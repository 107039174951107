import React from "react";
import { Menu, MenuItem } from "@material-ui/core";

const ActionCompContainer = (props) => {
  const { id, trigger, noDropdown = false } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: React.FormEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div className="trigger-button">
        {
          props?.options?.length === 1 && noDropdown === true?
          <div onClick = {() => props.options[0].onClick(props.id)}>{props.options[0].label}</div> :  trigger ? (
          <a onClick={handleClick}>{trigger}</a>
        ) : (
          'Actions'
        )}
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {props?.options?.map(
          ( item, index ) => (
            <MenuItem
              key={`MuiButtonIconContainer-${index}-${id}`}
              className="MuiButtonIconContainer view"
              onClick={() => {
                item.onClick(String(id));
                handleClose();
              }}
              disabled={item.disabled || false}
            >
              {item.label}
            </MenuItem>
          )
        )}
      </Menu>
    </div>
  );
};

export default ActionCompContainer;
