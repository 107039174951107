import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router";
import {Tabs, Tab} from 'react-bootstrap';

interface Props {
	selected: string,
  options: Array<{label: string, value: string}>,
  onChange: Function,
	variant: string
}

export const TabsWrapper : React.FC<Props> = (props) => {
	const {selected, options, onChange, variant} = props;
	const handleTabs = (e: string) => {
		if(typeof onChange === 'function') {
			onChange(e);
		}
	}

	return (
		<Tabs
			id="tabs"
			variant="pills"
			className={`tabs-main-outer ${variant? variant: 'secondary'}`}
			activeKey={selected}
			onSelect={(e: string) => handleTabs(e)}
		>
      {
        options.map((item, index) => {
          return <Tab
            key = {`tab-item-${item.value}-index`}
            eventKey={item.value} title={item.label}
          ></Tab>
        })
      }
		</Tabs>
	)
}

export default TabsWrapper;
