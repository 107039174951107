import React, { useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DataTable from "react-data-table-component";
import TableCustomStyles from "../../utils/tableCustomStyles";
import { TableFilterParamTyps } from "../../constants/types";
import CalenderRangeFilter from "../Forms/DateTime/Range";
import { InputGroup, Form, Spinner } from "react-bootstrap";
import InputSelect from "../Forms/Select/InputSelect";
import { create, debounce } from "lodash";
import EmptyState from "../EmptyState";
import "../../styles/tables.scss";

export interface Props {
  isLoading: boolean;
  columns: any;
  label?: string;
  showSearchFilter?: boolean;
  showRangeFilter?: boolean;
  data: {
    data: Array<any>;
    totalEntries: number;
  };
  onChangeParams: Function;
  params: TableFilterParamTyps;
  searchPlaceHolder?: string;

  imgUrl: any;
  redirectTo: any;
  titleText: string;
  createButton: boolean;
  footerText: string;
}

export const initParams: TableFilterParamTyps = {
  sort: "desc",
  filters: "active",
  pageNo: 1,
  pageSize: 10,
  pattern: "",
  startDate: null,
  endDate: null,
};

const BootyPagination = ({
  variant,
  totalEntries,
  rowsPerPage,
  rowCount,
  onChangePage,
  onChangeRowsPerPage, // available but not used here
  currentPage,
}) => {
  const handleBackButtonClick = () => {
    onChangePage(currentPage - 1);
  };

  const handleNextButtonClick = () => {
    onChangePage(currentPage + 1);
  };

  const handlePageNumber = (e) => {
    onChangePage(Number(e.target.value));
  };

  function getNumberOfPages(rowCount, rowsPerPage) {
    return Math.ceil(rowCount / rowsPerPage);
  }
  function toPages(pages: number) {
    const results = [];

    for (let i = 1; i <= pages; i++) {
      results.push(i);
    }

    return results;
  }
  const pages = getNumberOfPages(totalEntries, rowsPerPage);
  const pageItems = toPages(pages);
  const nextDisabled = currentPage === pageItems.length;
  const previosDisabled = currentPage === 1;

  return (
    <div className={`crm-pagintaion-wrapper ${variant ? variant : "primary"}`}>
      <div className="crm-pagintaion">
        <div className="change-page">
          <div className="change-page-label">Rows per page:</div>
          <div>
            <InputSelect
              name="pagePerPahe"
              id="pagePerPahe"
              value={rowsPerPage}
              handleInputSelectChange={(e) => onChangeRowsPerPage(e.value)}
              options={[
                { label: 10, value: 10 },
                { label: 25, value: 25 },
                { label: 50, value: 50 },
                { label: 100, value: 100 },
              ]}
            />
          </div>
        </div>

        <div className="jump-to-page">
          <div className="jump-to-page-label">Page</div>
          <div className={`page-item ${previosDisabled ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={handleBackButtonClick}
              disabled={previosDisabled}
              aria-disabled={previosDisabled}
              aria-label="previous page"
            >
              &lt;
            </button>
          </div>
          {pageItems.length < 6 ? (
            pageItems.map((page) => {
              const className = page === currentPage ? "page-item active" : "page-item";

              return (
                <div key={page} className={className}>
                  <button className="page-link" onClick={handlePageNumber} value={page}>
                    {page}
                  </button>
                </div>
              );
            })
          ) : (
            <>
              {pageItems.splice(0, 5).map((page) => {
                const className = page === currentPage ? "page-item active" : "page-item";

                return (
                  <div key={page} className={className}>
                    <button className="page-link" onClick={handlePageNumber} value={page}>
                      {page}
                    </button>
                  </div>
                );
              })}
              <div className="page-item dashes">...</div>
              <div key={"last-page"} className={currentPage === pages ? "page-item active" : "page-item"}>
                <button className="page-link" onClick={handlePageNumber} value={pages}>
                  {pages}
                </button>
              </div>
            </>
          )}
          <div className="page-item" className={`page-item ${nextDisabled ? "disabled" : ""}`}>
            <button className="page-link" onClick={handleNextButtonClick} disabled={nextDisabled} aria-disabled={nextDisabled} aria-label="next page">
              &gt;
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DataTableComponent: React.FC<Props> = (props) => {
  const {
    data: { data, totalEntries },
    columns,
    label,
    showRangeFilter,
    showSearchFilter,
    onChangeParams,
    params,
    searchPlaceHolder,
    isLoading,
    imgUrl,
    redirectTo,
    titleText,
    createButton,
    footerText,
    showLabel,
    onSort,
    variant,
    showPagination = true,
  } = props;
  const [showCalendar, setShowCalender] = useState(false);
  const [searchString, changeSearchString] = useState<string>("");

  const handleStartDateParent = (data: any) => {
    onChangeParams({ ...params, pageNo: 1, startDate: data });
  };

  const handleEndDateParent = (data: any) => {
    onChangeParams({ ...params, pageNo: 1, endDate: data });
  };

  // const prevRequestDataParams = useRef();
  // useEffect(() => {
  // 	const data: any = { };
  // 	data.sort = params.sort;
  // 	data.filters = params.filters;
  // 	data.pageNo = params.pageNo;
  // 	data.pageSize = params.pageSize;
  // 	if(params.pattern.length > 0) {
  // 		data.pattern = params.pattern;
  // 	}
  // 	if(params.dateFrom && params.dateTo) {
  // 		data.dateFrom = params.dateFrom;
  // 		data.dateTo = params.dateTo;
  // 	}
  // 	if(!isEqual(prevRequestDataParams.current, data) || props.isUpdated) {
  // 		getTableDataList(data);
  // 	}
  // 	prevRequestDataParams.current = data;
  // }, [params, props.isUpdated]);

  const handlePagesizeChange = (val: number) => {
    onChangeParams({ ...params, pageSize: val, pageNo: 1 });
  };

  const handlePageChange = (val: number) => {
    onChangeParams({ ...params, pageNo: val });
  };

  const handlePatternChange = (pattern: string) => {
    onChangeParams({ ...params, pattern });
  };

  const debounceSearch = React.useCallback(
    debounce((pattern) => handlePatternChange(pattern), 500),
    []
  );

  const resetCalendar = () => {
    onChangeParams({ ...params, startDate: null, endDate: null });
    setShowCalender(false);
  };
  const searchRef = React.useRef("");

  React.useEffect(() => {
    if (searchString !== searchRef.current) {
      debounceSearch(searchString);
    }
  }, [searchString]);

  let noDataComponentProps = {
    ...(params.pattern === "" &&
      (params?.startDate === undefined || params?.startDate === null) &&
      (params?.endDate === undefined || params?.endDate === null) && {
        noDataComponent: (
          <EmptyState imgUrl={imgUrl} redirectTo={redirectTo} titleText={titleText} createButton={createButton} footerText={footerText} />
        ),
      }),
  };

  return (
    <div className="crm-table-wrapper">
      {(showRangeFilter !== false || showSearchFilter !== false) && (
        <div className="d-flex justify-content-start">
          <div className="unifynd-crm-tabel-container">
            {!showCalendar && (
              <InputGroup className="ufyndTextBox_search">
                {typeof showSearchFilter === "undefined" || showSearchFilter ? (
                  <>
                    <SearchIcon className="searchIcon" />
                    <Form.Control
                      id="search"
                      name="search"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        searchRef.current = searchString;
                        changeSearchString(e.target.value);
                      }}
                      type="text"
                      value={searchString}
                      placeholder={searchPlaceHolder ? searchPlaceHolder : `Search by name`}
                    />
                  </>
                ) : null}
                {!params.pattern && !showCalendar && (typeof showRangeFilter === "undefined" || showRangeFilter) && (
                  <InputGroup.Append className="ufyndSearchAppend" onClick={() => setShowCalender(true)}>
                    <InputGroup.Text className="ufyndSearchIcon">
                      <DateRangeIcon />
                    </InputGroup.Text>
                  </InputGroup.Append>
                )}
                {params.pattern && !showCalendar && (typeof showSearchFilter === "undefined" || showSearchFilter) && (
                  <InputGroup.Append className="ufyndSearchAppend" onClick={() => changeSearchString("")}>
                    <InputGroup.Text className="ufyndSearchIcon">
                      <HighlightOffIcon />
                    </InputGroup.Text>
                  </InputGroup.Append>
                )}
              </InputGroup>
            )}
            {showCalendar && (typeof showRangeFilter === "undefined" || showRangeFilter) && (
              <CalenderRangeFilter
                currentState={{
                  dateStart: params.dateFrom,
                  dateEnd: params.dateTo,
                }}
                handleFromDate={handleStartDateParent}
                handleToDate={handleEndDateParent}
                resetCalendar={resetCalendar}
              />
            )}
          </div>
        </div>
      )}
      {(showLabel) && (
      <div className="h3 lh-24 py-3">
            <p>Total Contacts : {totalEntries}</p>
      </div>) 
      } 
      <DataTable
        className="crm-data-table"
        data={data}
        columns={columns}
        fixedHeader={true}
        customStyles={TableCustomStyles}
        paginationTotalRows={totalEntries}
        onChangeRowsPerPage={handlePagesizeChange}
        onChangePage={handlePageChange}
        noHeader
        persistTableHead
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        pagination={showPagination}
        paginationServer
        paginationComponent={(props) => <BootyPagination variant={variant} totalEntries={totalEntries} {...props} />}
        onSort={onSort ? onSort : () => {}}
        sortServer
        progressPending={isLoading}
        progressComponent={<Spinner animation="border" role="status"></Spinner>}
        {...noDataComponentProps}
      />
    </div>
  );
};

export default DataTableComponent;
