import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import SignUpBanner from "../components/Banner";
import "../_styles/index.scss";
import { authService, HTTPClientV3 } from "../../../service";
import InputText from "../../../components/Forms/Input/Input";
import InputSelect from "../../../components/Forms/Select/InputSelect2";
import Toast from "../../../components/Toast";
import { useHistory } from "react-router-dom";
import { setUser } from "../../../store/auth/auth.action";

const RESEND_COUNTER = 30;
const AuthPage: React.FC = () => {
  const dispatch = useDispatch();

  const data = useSelector(({ auth }) => auth.user, shallowEqual);
  const [submittingCompanyInfo, changeSubmittingCompanyInfoStatus] = useState(false);
  const [formData, changeFormData] = useState({});
  const [errors, changeErrors] = useState({});
  const { push } = useHistory();
  const industryOptions = useSelector((state: any) => state?.appData?.globalConstants?.industry);

  const validationFunc = {
    companyName: (value: String) => (!value ? "This field is required" : ""),
    companyWebsite: (value: String) => {
      if (!value) {
        return "This field is required";
      }
      const regex = RegExp("^(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$");
      if (!regex.test(value)) {
        return "Invalid Website";
      }
    },
    industry: (value: String) => (!value ? "This field is required" : ""),
  };

  const handleCompanyDetailsSubmit = async (e) => {
    e.preventDefault();
    let validationErrors = {
      companyName: validationFunc.companyName(formData.companyName),
      companyWebsite: validationFunc.companyWebsite(formData.companyWebsite),
      industry: validationFunc.industry(formData.industry),
    };
    changeErrors(validationErrors);

    if (Object.keys(validationErrors).filter((item) => validationErrors[item]).length == 0) {
      try {
        changeSubmittingCompanyInfoStatus(true);
      
        let formDataObj: any = {
          companyEmail: data.email,
          companyWebsite: formData.companyWebsite,
          companyName: formData.companyName,
          industry: formData.industry.value,
        };

        // HTTPClientV3.saveHeader({ key: "Authorization", value: `Bearer ${data.authToken}` });

        await authService.updateCompanyDetails(formDataObj, data.authToken);

        dispatch(setUser({ ...data, status: 3 }));

        changeSubmittingCompanyInfoStatus(false);
      } catch (error) {
        const message = error?.response?.data?.status?.message;
        Toast({ type: "error", msg: message });
        changeSubmittingCompanyInfoStatus(false);
      }
    }
  };

  return (
    <section className="registration-form">
      <div className="container-fluid">
        <Row className="registration-form-container">
          <Col md={7} className="reg-form-wrapper">
            <div className="reg-form-inner">
              <div className="form-logo">
                <img src="/media/onboarding/logo.png" />
                <span>UniSuite</span>
              </div>
            </div>
            <div className="reg-form-inner">
              <div className="form-steps-heading">
                <div className="form-heading">
                  <div className="form-title">Tell us about your company</div>
                  <div className="form-subtext">Just one last thing. Before we dive in, help us create your company dashboard.</div>
                </div>
              </div>
              <form className="form-box-container" onSubmit={handleCompanyDetailsSubmit}>
                <div className="form-group">
                  <InputText
                    className="top-0"
                    label="Company Name"
                    type="text"
                    name="companyName"
                    id="companyName"
                    placeholder="What company do you work in?"
                    value={formData.companyName}
                    required={true}
                    onChange={({ value }) => {
                      changeFormData({ ...formData, companyName: value });
                      if (errors.companyName) {
                        changeErrors({ ...errors, companyName: null });
                      }
                    }}
                    onBlur={() => {}}
                    error={errors.companyName}
                  />
                  <InputText
                    label="Company URL"
                    type="text"
                    name="companyWebsite"
                    id="companyWebsite"
                    placeholder="Enter company URL"
                    value={formData.companyWebsite}
                    required={true}
                    onChange={({ value }) => {
                      changeFormData({ ...formData, companyWebsite: value });
                      if (errors.companyWebsite) {
                        changeErrors({ ...errors, companyWebsite: null });
                      }
                    }}
                    onBlur={() => {}}
                    error={errors.companyWebsite}
                  />
                  <InputSelect
                    required={true}
                    label="Industry"
                    name="industry"
                    id="industry"
                    placeholder="Mention your industry"
                    value={formData.industry}
                    options={[...industryOptions.map(({ id, name }) => ({ label: name, value: id }))]}
                    onChange={(event) => {
                      changeFormData({ ...formData, industry: event });
                      if (errors.industry) {
                        changeErrors({ ...errors, industry: null });
                      }
                    }}
                    onBlur={() => {}}
                    error={errors.industry}
                    className="select-input"
                  />
                </div>
                <Button variant="primary" type="submit" disabled={submittingCompanyInfo} className="btn-new">
                  Submit
                </Button>
              </form>
            </div>
          </Col>
          <Col md={5} className="reg-form-banner">
            <SignUpBanner />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default AuthPage;
