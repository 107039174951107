import React, { useMemo } from "react";
import objectPath from "object-path";
import {useHistory} from 'react-router';
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { QuickUserToggler } from "../extras/QuiclUserToggler";

export function Topbar() {
  const uiService = useHtmlClassService();
  const {push} = useHistory();
  const layoutProps = useMemo(() => {
    return {
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  return (
    <div className="topbar align-items-center">
      <div className='bg-icons bg-notification mr-2' onClick={()=> alert('Comming soon')}/>
      <div className='bg-icons bg-gear cursor-pointer mr-2' onClick={()=> push('/settings')}/>
       {layoutProps.viewUserDisplay && <QuickUserToggler />}
    </div>
  );
}
