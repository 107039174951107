import React from "react";
import EmptyScreens from "../EmptyScreens";
import EmptyState from "./assets/emptyState.svg";

const NoAccessComponent = () => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
      }}>
      <EmptyScreens
        style={{
          borderRadius: 0,
          border: "none",
        }}
        src={EmptyState}
        description={"You don't have access to this route."}
      />
    </div>
  );
};

export default NoAccessComponent;
