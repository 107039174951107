export const FormValidationMessages = {
  MANDATORY_FIELD: "This field is mandatory",
  INCORRECT_FORMAT: "Please correct the format of this entry",
};

export const ActivityTabsMap = {
  Activity: 1,
  Emails: 2,
  Notifications: 3,
  Sms: 4,
  Notes: 5,
};

export const MOBILE_VALIDATION = /^[0-9]*$/;

export const campaignStatusTypes = {
  ALL: "all",
  DRAFT: "draft",
  SCHEDULE: "scheduled",
  SENT: "sent",
  COMPLETED: "completed",
};

export const landingStatusTypes = {
  ALL: "all",
  DRAFT: "draft",
  ACTIVE: "active",
  EXPIRED: "expired",
  EXPIRING: "expiring",
};

export const recentActivityMappings = {
  1: {
    1: "User Added",
    2: "User Updated",
  },
  2: {
    1: "Event Added",
  },
  3: {
    1: "Email Sent",
    2: "Opened Email",
    3: "Email Clicked",
    4: "Email Failed",
    5: "Unsubscribed Email",
  },
  4: {
    1: "Whatsapp Sent",
    2: "Whatsapp Read",
    3: "Whatsapp Delivered",
    4: "Whatsapp Failed",
  },
  5: {
    1: "Sms Sent",
    2: "Sms Failed",
    3: "Sms Delivered",
  },
};
