import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import addFunnel from "highcharts/modules/funnel";
import EmptyBarChart from './assets/EmptyBarChart';
import './styles/styles.scss';

interface Props {
  funnelData: any;
}

Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: 'Inter, Helvetica, "sans-serif"',
    },
  },
});
export const FunnelHighChart: React.FC<Props> = ({funnelData, title, isEmpty, emptyData}: Props) => {
  const [chartLoaded, setChartLoaded] = useState(false);

  const options = {
    chart: {
      height: 300,
      type: "funnel",
      backgroundColor: 'white'
    },
    title: {
      text: "",
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          format: isEmpty? "<b>{point.name}</b>" :"<b>{point.name}</b> ({point.y:,.0f})",
          softConnector: true,
        },
        center: ["40%", "50%"],
        neckWidth: "30%",
        neckHeight: "25%",
        width: "80%",
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: "Unique users",
        data: isEmpty? emptyData: funnelData,
      },
    ],

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            plotOptions: {
              series: {
                dataLabels: {
                  inside: true,
                },
                center: ["50%", "50%"],
                width: "100%",
              },
            },
          },
        },
      ],
    },
  };

  if (isEmpty) {
    options.colors = ['#E0E0E0', '#E0E0E0', '#E0E0E0']
  } else {
    options.colors = ['#FFC130', '#41D4A8', '#EC758B']
  }

  useEffect(() => {
    window.Highcharts = Highcharts;
    addFunnel(Highcharts);
    setChartLoaded(true);
    // console.log(addFunnel);
  }, []);

  return(
      <>
          <div className="row">
              <div className="col-xl-12">
                  <div className="card card-custom card-stretch gutter-b shadow-none">
                      {
                        title
                        &&
                        <div className="card-header h-auto border-0">
                            <div className="card-title py-5">
                                <h6 className="card-label">
                                <span className="d-block text-dark font-weight-bolder">{title? title: 'Cumulative Campaign Funnel'}</span>
                                </h6>
                            </div>
                        </div>
                      }
                      <div className="card-body">
                          {funnelData[0][1] > 0 && <><div className="row">
                            <div className="col-12">
                              <div className="text-center"><span style={{fontSize: '13px'}}>Total Users: </span> {funnelData[0][1]}</div>
                            </div>
                          </div>
                          </>}
                          <div className="row">
                              <div className="col-12">
                              {chartLoaded ? (
                                <HighchartsReact
                                  highcharts={Highcharts}
                                  options={options}
                                  constructorType={"chart"}
                                />
                              ) : (
                                "Funnel Loading..."
                              )}
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          {
            isEmpty?
            <div className='graph-empty-state'>
              <EmptyBarChart />
            </div>:
            null
          }
      </>
  );
};

export default FunnelHighChart;
