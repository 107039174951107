import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import MyButton from "../../../components/Forms/Button/Button";
import {
  formDataProps,
  INITIAL_STATE_REGISTRATION,
  errorFormDataProps,
  validationFormData,
} from "../_constants/index";
import SignUpBanner from "../components/Banner";
import { authActionTypes } from "../../../store/auth/auth.actiontype";
import "../_styles/index.scss";
import { useLocation } from "react-router-dom";
import { authService } from "../../../service";
import { getQueryParams } from "../_utils";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import OtpInput from "react-otp-input";

interface eventProps {
  name: string;
  value: string;
}
const RESEND_COUNTER = 30;
const AuthPage: React.FC = (props) => {
  const dispatch = useDispatch();
  const [serverError, changeServerError] = React.useState('');
  const error: string | undefined = useSelector(
    (state: any) => state.auth.error,
    shallowEqual
  );
  const otpVerifyed: string | undefined = useSelector(
    (state: any) => state.auth.otpVerifyed,
    shallowEqual
  );

  const [isOpen, setOpen] = useState<boolean>(false);
  const [isRequesting, changeRequesting] = useState<boolean>(false);
  const [clearError, setClearError] = useState<boolean>(false);
  const [resendTimerCount, changeResendCount] = useState<number>(RESEND_COUNTER);
  const [otp, changeOtp] = useState<string>('');
  const [formData, setFormData] = useState<formDataProps>({
    ...INITIAL_STATE_REGISTRATION,
  });
  React.useEffect(() => {
    if(otp.length>0) {
      changeServerError(error);
    }
  }, [error]);
  const [errors, setErrors] = useState<errorFormDataProps>({});
  const location = useLocation();
  const FieldValidation = ({ name, value }: eventProps) => {
    let error: undefined | string = validationFormData[name](value);
    if (error) {
      setErrors({ ...errors, [name]: error });
    } else {
      delete errors[name];
      setErrors(errors);
    }
  };
  const handleChange = ({ name, value }: eventProps) => {
    setFormData({ ...formData, [name]: value });
    FieldValidation({ name, value });
  };
  const handleBlur = ({ name, value }: eventProps) => {
    FieldValidation({ name, value });
  };
  const validateForm = (formDataValues: formDataProps): boolean => {
    let submitErrors: errorFormDataProps = {};
    for (const [key, value] of Object.entries(formDataValues)) {
      let error: undefined | string = validationFormData[key](value);
      if (error) {
        submitErrors[key] = error;
      }
    }
    setErrors(submitErrors);
    return Object.keys(submitErrors).length === 0;
  };

  const resendOtp = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    await authService.sendVerificationLink({
      email: props.email,
      status: 1
    });
    changeResendCount(RESEND_COUNTER);
    startResendTimer();
  }

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    changeRequesting(true);
    setTimeout(() => {
      if (otp.length === 6) {
        try {
          const response = dispatch({ type: authActionTypes.LOGIN_INIT, payload: {otp, email: props.email} });
        } catch (error) {
          console.log('error', error);
        }
      }
      changeRequesting(false);
    }, 500);
  };

  let queryParams = getQueryParams(location);

  const startResendTimer = () => {
    const intervalInstance = setInterval(() => {
      changeResendCount((nextNumber) => {
        if(nextNumber === 0) {
          clearInterval(intervalInstance);
          return 0;
        }
        return nextNumber-1
      });
    }, 1000)
  }

  React.useEffect(() => {
    startResendTimer();
  }, []);

  React.useEffect(() => {
    setClearError(true);
  }, [error]);

  const sendOtp = async () => {
    try {
      const response = await authService.sendVerificationLink({
        email:props.email,
        status: 1
      });
    } catch(e) {
      console.log('e', e);
    }
  };

  useEffect(() => {
    sendOtp();
  }, []);

  return (
    <section className="registration-form">
      <div className="container-fluid">
        <Row className="registration-form-container">
          <Col md={7} className="reg-form-wrapper">
            <div className="reg-form-inner">
              <div className="form-logo">
                <img src="/media/onboarding/logo.png" />
                <span>UniSuite</span>
              </div>
            </div>
            <div className="reg-form-inner">
              <div className="form-steps-heading">
                <div className="form-heading">
                  <div className="form-title">Welcome back <img src="/media/onboarding/welcome-back.png" alt=""/></div>
                </div>
              </div>
              <form
                id="kt_login_signin_form"
                className="form-box-container"
                onSubmit={handleSubmit}
                >
                <div className="form-group">
                  <label className="form-label">Enter 6-digit OTP <span className="required-input-field">*</span></label>
                  <div className="otp-container">
                    <OtpInput
                      value={otp}
                      className="otp-input-list"
                      numInputs={6}
                      placeholder=""
                      inputStyle={`otp-input ${otpVerifyed? 'verify-otp': ''}`}
                      focusStyle="otp-input-focused"
                      isInputNum={true}
                      errorStyle="invalid-otp"
                      hasErrored={serverError && clearError}
                      onChange={(value) => {
                        setClearError(false);
                        changeOtp(value);

                        changeServerError('');
                      }}

                    />
                    {otpVerifyed && <img className="otp-verified" src="/media/onboarding/otp-verified.svg"/>}
                  </div>
                  {serverError&&<div className="text-danger">{serverError}</div>}
                  <div className="resend-otp-container">
                    {(resendTimerCount>0 && !otpVerifyed) && <span className="resend-wait">Resend in {resendTimerCount}s</span>}
                    {(resendTimerCount<=0 && !otpVerifyed) &&<span className="resend-active" onClick={resendOtp}>Resend OTP</span>}
                  </div>
                </div>
                <MyButton
                  variant="primary"
                  type="submit"
                  name="Log In"
                  block={true}
                  disabled={otp.length!=6||otpVerifyed||isRequesting||serverError}
                  className="btn-new"
                />
                <MyButton
                  variant="secondary"
                  onClick={() => props.changeLoginType(2)}
                  name="Log in via Password"
                  block={true}
                  disabled={otpVerifyed||isRequesting}
                  className="btn-back"
                />
                <div className="wb-go-back-container">
                  <div className="wb-go-back" onClick={() => props.changeLoginType(0)}><NavigateBeforeIcon /> Go Back</div>
                </div>
              </form>
            </div>
          </Col>
          <Col md={5} className="reg-form-banner">
            <SignUpBanner signInViaOTP={true} />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default AuthPage;
