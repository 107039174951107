import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import InputText from "../../../components/Forms/Input/Input";
import MyButton from "../../../components/Forms/Button/Button";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { formDataProps, INITIAL_STATE_REGISTRATION, errorFormDataProps, validationFormData } from "../_constants/index";
import SignUpBanner from "../components/Banner";
import { authActionTypes } from "../../../store/auth/auth.actiontype";
import "../_styles/index.scss";
import { Link, useLocation } from "react-router-dom";
import { authService } from "../../../service";
import Swal from "sweetalert2";
import { getQueryParams } from "../_utils";
interface eventProps {
  name: string;
  value: string;
}
const AuthPage: React.FC = (props) => {
  const dispatch = useDispatch();
  const [serverError, changeServerError] = React.useState("");
  const [isRequesting, changeRequesting] = useState<boolean>(false);
  const [viewPassword, changePasswordPreview] = useState<boolean>(false);
  const error: string | undefined = useSelector((state: any) => state.auth.error, shallowEqual);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [formData, setFormData] = useState<formDataProps>({
    ...INITIAL_STATE_REGISTRATION,
  });
  const [errors, setErrors] = useState<errorFormDataProps>({});
  const location: any = useLocation();
  const FieldValidation = ({ name, value }: eventProps) => {
    let error: undefined | string = validationFormData[name](value);
    if (error) {
      setErrors({ ...errors, [name]: error });
    } else {
      delete errors[name];
      setErrors(errors);
    }
  };
  React.useEffect(() => {
    if (formData.password.length > 0) {
      changeServerError(error);
    }
  }, [error]);
  const handleChange = ({ name, value }: eventProps) => {
    setFormData({ ...formData, [name]: value });
    // FieldValidation({ name, value });
  };
  const handleBlur = ({ name, value }: eventProps) => {
    FieldValidation({ name, value });
  };
  const validateForm = (formDataValues: formDataProps): boolean => {
    let submitErrors: errorFormDataProps = {};
    for (const [key, value] of Object.entries(formDataValues)) {
      let error: undefined | string = validationFormData[key](value);
      if (error) {
        submitErrors[key] = error;
      }
    }
    setErrors(submitErrors);
    return Object.keys(submitErrors).length === 0;
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    // formData.email = location?.state.signInEmail
    changeRequesting(true);
    setTimeout(() => {
      const data = { email: props.email, password: formData.password };
      let validationErrors: boolean = validateForm(data);
      if (validationErrors) {
        try {
          dispatch({ type: authActionTypes.LOGIN_INIT, payload: data });
        } catch (error) { }
      }
      changeRequesting(false);
    }, 500);
  };

  let queryParams = getQueryParams(location);

  useEffect(() => {
    if (queryParams && queryParams.enc) {
      let encodedToken = queryParams.enc;
      let token = atob(encodedToken);
      dispatch({ type: authActionTypes.LOGIN_INIT_AUTO, payload: token });
    }
    return () => {
      changeServerError("");
    };
  }, []);

  return (
    <section className="registration-form">
      <div className="container-fluid">
        <Row className="registration-form-container">
          <Col md={7} className="reg-form-wrapper">
            <div className="reg-form-inner">
              <div className="form-logo">
                <img src="/media/onboarding/logo.png" />
                <span>UniSuite</span>
              </div>
            </div>
            <div className="reg-form-inner">
              <div className="form-steps-heading">
                <div className="form-heading">
                  <div className="form-title">
                    Welcome back <img src="/media/onboarding/welcome-back.png" alt="" />
                  </div>
                </div>
              </div>
              <form id="kt_login_signin_form" className="form-box-container" onSubmit={handleSubmit}>
                <div className="position-relative">
                  <InputText
                    label="Password"
                    type={viewPassword ? "text" : "password"}
                    name="password"
                    id="password"
                    placeholder="Enter password"
                    value={formData.password}
                    required={true}
                    onChange={(e) => {
                      changeServerError("");
                      handleChange(e);
                    }}
                    onBlur={() => { }}
                    error={errors.password || (serverError != "Invalid OTP Entered" ? serverError : "")}
                    className="top-0"
                  />
                  <div className="password-visible">
                    <div
                      className={`cursor-pointer bg-icon bg-icon bg-icon16 icon-grey ${viewPassword ? "bg-eye-off" : "bg-eye"} ${serverError ? "icon-red" : ""
                        }`}
                      onClick={() => changePasswordPreview(!viewPassword)}
                    />
                  </div>
                </div>
                <Link className={`input-link ${errors.password || error ? "input-link-space" : ""}`} to="/auth/forgot-password">
                  Forgot Password?
                </Link>
                <MyButton
                  variant="primary"
                  type="submit"
                  name="Log In"
                  block={true}
                  disabled={isRequesting || formData.password.length === 0 || errors.password || serverError}
                  className="btn-new"
                />
                <MyButton
                  variant="secondary"
                  onClick={() => props.changeLoginType(1)}
                  name="Login via OTP"
                  block={true}
                  disabled={isRequesting}
                  className="btn-back"
                />
                <div className="wb-go-back-container">
                  <div to="/" className="wb-go-back" onClick={() => props.changeLoginType(0)}>
                    <NavigateBeforeIcon /> Go Back
                  </div>
                </div>
              </form>
            </div>
          </Col>
          <Col md={5} className="reg-form-banner">
            <SignUpBanner signInViaPassword={true} />
          </Col>
        </Row>
      </div>
      <EmailModal isOpen={isOpen} setOpen={setOpen} />
    </section>
  );
};

const EmailModal = ({ isOpen, setOpen }: { isOpen: boolean; setOpen: Function }) => {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const EmailRegex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

  const handleSubmit = async () => {
    try {
      await authService.forgotPass_sendEmail(email);
      return Swal.fire("Success", "Recovery mail sent to your Email", "success").then(() => setEmail(""), setOpen(false));
    } catch (err) {
      setError(err.response?.data?.status?.message);
    }
  };

  const onHide = () => {
    setEmail("");
    setOpen(false);
  };
  return (
    <Modal show={isOpen} onHide={onHide} keyboard={true}>
      <Modal.Header closeButton>
        <Modal.Title>Enter Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input className="form-control" onChange={(e) => setEmail(e.target.value)} placeholder={"Email"} value={email} type="email" />
        <p className="text-danger">{error}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={!EmailRegex.test(email)}>
          Submit
        </Button>
        {/* <Button variant="primary">Understood</Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default AuthPage;