export const Modules = {
  COUPON: "COUPON",
  LANDING_PAGE: "LANDING_PAGE",
  SPIN_WHEEL: "SPIN_WHEEL",
  SCRATCH_CARD: "SCRATCH_CARD",
  QUIZ: "QUIZ",
  SURVEY: "SURVEY",
  CONTACT: "CONTACT",
  CAMPAIGN: "CAMPAIGN",
  TEMPLATES: "TEMPLATES",
  TEMPLATES_PREVIEW: "TEMPLATES_PREVIEW",
  SMS_CAMPAIGN: "SMS_CAMPAIGN",
  WHATSAPP: "WHATSAPP",
  SEGMENT: "SEGMENT",
  SINGULAR_PROFILE: "SINGULAR_PROFILE",
};

export const confirmationPopUpInfo = {
  [Modules.COUPON]: {
    heading: "Are you sure you want to quit?",
    message: "We’ll save your progress in the Drafts, but you’re just a few steps away from creating an amazing reward for your audience",
  },
  [Modules.LANDING_PAGE]: {
    heading: "Are you sure you want to quit?",
    message: "We’ll save your progress in the Drafts, but you’re just a few steps away from creating an amazing landing page for your audience.",
  },
  [Modules.SPIN_WHEEL]: {
    heading: "Are you sure you want to quit?",
    message: "We’ll save your progress in the Drafts, but you’re just a few steps away from creating an amazing game for your audience.",
  },
  [Modules.SCRATCH_CARD]: {
    heading: "Are you sure you want to quit?",
    message: "We’ll save your progress in the Drafts, but you’re just a few steps away from creating an amazing game for your audience.",
  },
  [Modules.SURVEY]: {
    heading: "Are you sure you want to quit?",
    message: "We’ll save your progress in the Drafts, but you’re just a few steps away from creating an amazing game for your audience.",
  },
  [Modules.QUIZ]: {
    heading: "Are you sure you want to quit?",
    message: "We’ll save your progress in the Drafts, but you’re just a few steps away from creating an amazing quiz for your audience.",
  },
  [Modules.CONTACT]: {
    heading: "Are you sure you want to quit?",
    message: "Are you sure you want to quit the import? Your progress will be discarded.",
  },
  [Modules.CAMPAIGN]: {
    heading: "Are you sure you want to quit?",
    message: "We’ll save your progress in the Drafts, but you’re just a few steps away from creating an amazing campaign for your audience.",
  },
  [Modules.WHATSAPP]: {
    heading: "Are you sure you want to quit?",
    message: "We’ll save your progress in the Drafts, but you’re just a few steps away from creating an amazing whatsapp campaign for your audience.",
  },

  [Modules.TEMPLATES]: {
    heading: "Are you sure you want to quit?",
    message: "We’ll save your progress in the Drafts, but you’re just a few steps away from creating an amazing template for your audience.",
  },
  [Modules.TEMPLATES_PREVIEW]: {
    heading: " Are you sure you want to exit",
    message: "We'll redirect you to the selected page. Are you sure you want to leave this page?",
  },
  [Modules.SMS_CAMPAIGN]: {
    heading: "Are you sure you want to quit?",
    message: "We’ll save your progress in the Drafts, but you’re just a few steps away from creating an amazing sms campaign for your audience.",
  },
  [Modules.SEGMENT]: {
    heading: "Are you sure you want to quit?",
    message: "We’ll save your progress in the Drafts, but you’re just a few steps away from creating an amazing segment for your audience.",
  },
  [Modules.SINGULAR_PROFILE]: {
    heading: " Are you sure you want to exit",
    message: "We'll redirect you to the selected page. Are you sure you want to continue?",
  },
};
