import React from 'react';
import Lottie from 'react-lottie';
import animationData from './loader.json';

export default function () {
  return (
    <div className='crm-loader'>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }}
        }
        width={576}
        height={284}
      />
    </div>
  );
}
