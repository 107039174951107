import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  ANSWER_TYPES as AT,
  ReactSelectValidation,
  SURVEY_STEPS_KEY,
} from "../utils";
import { SurveyContext } from "./SurveyStateManager";

export default function useSurveyQuestionnaireForm() {


  // const  { ANSWER_TYPES  } = React.useContext(SurveyContext);
  // console.log(ANSWER_TYPES, 'ANSWER TYPES')

  const intitialData = React.useMemo(
    () => ({
      questionTitle: "",
      questionDescription: "",
      answerType: "",
      required: false,
      answer: "",
      isOther: false,
      qOrder: 0,
    }),
    []
  );

  const validationSchema = React.useMemo(() => {
    return Yup.object().shape({
      questionTitle: Yup.string().trim().required("A question title is required"),
      questionDescription: Yup.string(),
      answerType: ReactSelectValidation,
      qOrder: Yup.number().required(),
      isOther: Yup.boolean().required(),
      answer: Yup.mixed().when("answerType", (answerType) => {
        if (
          answerType?.value === AT?.LONG ||
          answerType?.value === AT?.SHORT
        ) {
          return Yup.string().nullable();
        }
        if (answerType?.value === AT?.SLIDER) {
          return Yup.object()
            .shape({
              minValue: Yup.object().shape({
                label: Yup.string().max(24,'Label should be 24 characters long'),
                value: Yup.number().typeError('Must be a number').min(1, 'Min value 1').max(10, 'Max value 10'),
              }),
              maxValue: Yup.object().shape({
                label: Yup.string().max(24, 'Label should be 24 characters long'),
                value: Yup.number().typeError('Must be a number').min(1, 'Min value 1').max(10, 'Max value 10'),
              }),
            })
            .required("");
        }
        if (
          answerType?.value === AT?.DROPDOWN ||
          answerType?.value === AT?.RADIO ||
          answerType?.value === AT?.CHECKBOX
        ) {
          return Yup.array().of(
            Yup.object()
              .shape({
                value: Yup.string().when('type', {
                  is: (type) => type === 'other',
                  then: Yup.string(),
                  otherwise: Yup.string().trim().required('Field is required')
                }),
                type: Yup.string().oneOf(["static", "other"])
              })
              .required()
          );
        }
      }),
    });
  }, [AT]);

  const questionnaireFormik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      questions: [{ ...intitialData }],
    },
  });

  const addQuestionBox = () => {
    let data = [...questionnaireFormik.values.questions];
    data.push({ ...intitialData, qOrder: data.length + 1 });
    questionnaireFormik.setFieldValue("questions", data);
  };
  const deleteQuestionBox = (order) => {
    let data = [...questionnaireFormik.values.questions];
    data = data.filter((_, index) => index !== order);
    questionnaireFormik.setFieldValue("questions", data);
  };
  const copyQuestionBox = (order, data) => {
    let questions = [...questionnaireFormik.values.questions];
    questions.splice(order, 0, { ...data, qOrder: questions.length + 1 });
    questionnaireFormik.setFieldValue("questions", questions);
  };

  const updateQuestionData = (order, field, value) => {
    let data = [...questionnaireFormik.values.questions];
    data.forEach((item, index) => {
      if (index === order) {
        item[field] = value;
        if (
          value.value === AT.RADIO ||
          value.value === AT.CHECKBOX ||
          value.value === AT.DROPDOWN
        )
          item["answer"] = [{ value: "", type: "static" }];
        if (value.value === AT.SLIDER) {
          item["answer"]["minValue"] = { label: "", value: "" };
          item["answer"]["maxValue"] = { label: "", value: "" };
        }
      }
    });
    questionnaireFormik.setValues("questions", data);
  };

  const validateEntries = async () => {
    let errObj = {};
    let questions = questionnaireFormik.values.questions;
    let length = questions.length;
    for (let i = 0; i < length; i++) {
      try {
        let error = await validationSchema.validate(questions[i], {
          abortEarly: false,
        });
        if(questions[i].answerType.value === Number(AT.SLIDER)) {
          if(Number(questions[i].answer.maxValue.value) <= Number(questions[i].answer.minValue.value) ) {
            let errorText = 'Min value should be less than max value';
            if(errObj[i]) {
              errObj[i]['answer'] = errorText;
            } else {
              errObj[i] = {
                answer: errorText
              }
            }
          } else {
            if(errObj[i]) {
              delete errObj[i]['answer'];
            }
          }
        }
      } catch (err) {
        err.inner.forEach((item) => {
          if (!errObj[i]) errObj[i] = {};
          errObj[i][item.path] = item.errors[0];
        });
      }
    }

    questionnaireFormik.setErrors({ questions: errObj });
  };

  React.useEffect(() => {
    validateEntries();
  }, [JSON.stringify(questionnaireFormik.values.questions)]);

  return {
    questionnaireFormik,
    copyQuestionBox,
    deleteQuestionBox,
    updateQuestionData,
    addQuestionBox,
    validateEntries,
    intitialData
  };
}
