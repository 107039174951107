import { takeLatest, all, call, put, take } from "redux-saga/effects";
import actionTypes from "./breadcrumb.actionType";
import * as actions from "./breadcrumb.action";

export function* logger() {
  while (true) {
    yield take("*");
    // console.log(`Auth Saga: ${JSON.stringify(action, null, 2)}`);
  }
}

export function* breadcrumbSaga() {
  yield all([call(logger)]);
}
