import { campaignActionTypes } from "./campaign.actiontype";
import { authActionTypes } from "../auth/auth.actiontype";
import { ICampaignReducer } from "./types";

const initialState: ICampaignReducer = {
  selectedTemplate: {
		name: "",
		template: "",
		dltId: ""
	},
  savedRes: null,
  savedTemplates: [],
  templateLibrary: [],
  campaignName: "",
	selectTemplateType: '',
	campaignNameModal: {show: false, isEdit: false, isCreate: false},
	dynamicMapping: [],
	campaignSelectedSegment: {},
	companySmsChannel: {},
	isDLTApproved: false,
	campaignProviderFormData: {
		selectedService: {value: 'otherGeneric', label: 'Other Generic', settingType: 1},
		smsProviderNickname: "",
		requestType: "",
		httpEndpoint: "",
		headers: [{ key: "", value: "" }],
		authenticationType: "noAuthentication",
		basicAuthenticationUserName: "",
		basicAuthenticationPassword: "",
		markThisProviderDefault: "",
		selectedAuthenticationTab: "authentication",

		apiKey: '',
		companyName: '',
		PEID: '',
		uniHeader: '',
	},
  whatsAppCampaignProviderFormData: {

  },
  emailCampaignProviderFormData: {
    settingType: '',
    partnerId: '',
    nickName: '',
    isDefault: '1',
    settings: '',
  },
	showSaveTemplateModal: {show: false, isSaveAndContinue: false},
  saveTemplateSettingTemplate: {
    templateName: '',
    header: '',
    template: '',
    whatsAppTemplateId: '',
    dltId: ''
  },
  emailPreviewModalStatus: {show: false}
};

const CampaignReducer = (
  state = initialState,
  action: { type: String; payload: any, templateType?: any }
) => {
  switch (action.type) {
		case campaignActionTypes.CHANGE_SHOW_SAVE_TEMPLATE_MODAL:
			return Object.assign({}, state, {showSaveTemplateModal: action.payload});
		case campaignActionTypes.SET_IS_DLT_APPROVED_STATUS:
			return Object.assign({}, state, {isDLTApproved: action.payload});
		case campaignActionTypes.SET_DYNAMIC_MAPPING:
			return Object.assign({}, state, {dynamicMapping: action.payload});
		case campaignActionTypes.SET_CAMPAIGN_PROVIDER_FORM_DATA:
			return Object.assign({}, state, {campaignProviderFormData: action.payload});
		case campaignActionTypes.SET_WHATS_APP_CAMPAIGN_PROVIDER_FORM_DATA:
			return Object.assign({}, state, {whatsAppCampaignProviderFormData: action.payload});
		case campaignActionTypes.SET_EMAIL_CAMPAIGN_PROVIDER_FORM_DATA:
			return Object.assign({}, state, {emailCampaignProviderFormData: action.payload});
		case campaignActionTypes.SET_COMPANY_SMS_CHANNEL:
			return Object.assign({}, state, {companySmsChannel: action.payload});
		case campaignActionTypes.SET_SELECTED_SEGMENT_DETAIL:
			return Object.assign({}, state, {campaignSelectedSegment: action.payload});
		case campaignActionTypes.CHANGE_CAMPAIGN_NAME_MODAL:
			return Object.assign({}, state, {campaignNameModal: action.payload});
		case campaignActionTypes.UPDATE_SELECTED_TEMPLATE_DATA:
			return Object.assign({}, state, {selectedTemplate: action.payload});
    case campaignActionTypes.SET_TEMPLATE: {
      return { ...state, selectedTemplate: action.payload, selectTemplateType: action.templateType };
    }
    case campaignActionTypes.SAVING_TEMPPLATE: {
      return { ...state, savedRes: "loading" };
    }

    case campaignActionTypes.SUCCESS_SAVE_TEMPLATE: {
      return { ...state, savedRes: true };
    }

    case campaignActionTypes.FAILURE_SAVE_TEMPLATE: {
      return { ...state, savedRes: false };
    }
    case campaignActionTypes.RESET_SAVE_TEMPLATE: {
      return { ...state, savedRes: null };
    }
    case campaignActionTypes.SET_SAVED_SMS_TEMPLATES: {
      return { ...state, savedTemplates: action.payload };
    }
    case campaignActionTypes.SET_SMS_LIBRARY_TEMPLATES: {
      return { ...state, templateLibrary: action.payload };
    }
    case campaignActionTypes.SET_CAMPAIGN_NAME: {
      return { ...state, campaignName: action.payload };
    }
    case campaignActionTypes.CLEAR_CAMPAIGN_DATA:
    case authActionTypes.CLEAR_USER_DATA:
      return Object.assign({}, initialState);

		case campaignActionTypes.SET_CREATE_EDIT_CAMPAIGN_FORM:
			return Object.assign({}, state, {
				...action.payload.templateInfo,
				selectTemplateType: 'savedTemplates',
        companySmsChannel: action.payload.channelSettingsInfo
			});
		case campaignActionTypes.RESET_CAPAIGN_FORM_DATA:
			return Object.assign({}, state, {
				isDLTApproved: true,
				selectedTemplate: {
					name: '',
					template: '',
					dltId: ''
				},
				dynamicMapping: [],
				companySmsChannel: {},
				selectTemplateType: '',
        selectEmailTemplate: {},
        emailAttachments: null
			});
    case campaignActionTypes.SAVE_TEMPLATE_SETTING_TEMPLATE:
      return Object.assign({}, state, {
        saveTemplateSettingTemplate: action.payload
      });
    case campaignActionTypes.SELECT_EMAIL_TEMPLATE:
      return Object.assign({}, state, {
        selectEmailTemplate: action.payload
      });
    case campaignActionTypes.UPDATE_EMAIL_ATTACHMENT:
      return Object.assign({}, state, {
        emailAttachments: action.payload
      });
    case campaignActionTypes.CHANGE_EMAIL_PREVIEW_MODAL_STATUS:
      return Object.assign({}, state, {
        emailPreviewModalStatus: action.payload
      });
    case campaignActionTypes.CAMPAIGN_CREATED_SUCCESSFULL:
			return Object.assign({}, state, {
				...initialState
			});
    default:
      return state;
  }
};

export default CampaignReducer;
