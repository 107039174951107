import { AxiosResponse } from "axios";
import {
  ICouponPostRequest,
  IGetCouponParams,
} from "../../pages/Loyalty/Coupons/_constants/types";
import { HTTPClient, HTTPClientV2 } from "./http.service";

var qs = require("qs");

export const loyaltyService = {
  async getCoupons(params: IGetCouponParams) {
    try {
      const result: AxiosResponse = await HTTPClientV2.get(
        `/company/coupon/`,
        params
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async addCoupon(data: any) {
    try {
      const result: AxiosResponse = await HTTPClientV2.post(
        `/company/coupon/`,
        data
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async editCoupon(id: number | string, data: any) {
    try {
      const result: AxiosResponse = await HTTPClientV2.put(
        `/company/coupon/${id}`,
        data
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async addCouponCodes(data: any) {
    try {
      const result: AxiosResponse = await HTTPClientV2.post(
        `/company/coupon/codes`,
        data
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getSingleCoupon(id: number | string) {
    try {
      const result: AxiosResponse = await HTTPClientV2.get(
        `/company/coupon/${id}`
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async updateCouponCodes(id: string | number, data: any) {
    try {
      const result: AxiosResponse = await HTTPClientV2.put(
        `/company/coupon/codes/${id}`,
        data
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getQuizList(params: any) {
    // ["all", 1, 2, 3]; // 1: Draft, 2: Active, 3: Completed

    try {
      const result: AxiosResponse = await HTTPClientV2.get(
        `/company/quiz/`,
        params
      );
      return result;
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async getSpinTheWheelList(params: any) {
    // ["all", 1, 2, 3]; // 1: Draft, 2: Active, 3: Completed

    try {
      const result: AxiosResponse = await HTTPClientV2.get(
        `/company/spinWheel/`,
        params
      );
      return result;
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async getScratchCardList(params: any) {
    // ["all", 1, 2, 3]; // 1: Draft, 2: Active, 3: Completed

    try {
      const result: AxiosResponse = await HTTPClientV2.get(
        `/company/scratchCard/`,
        params
      );
      return result;
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async getScratchCardById(id: string | number) {
    try {
      const result: AxiosResponse = await HTTPClientV2.get(`/company/scratchCard/${id}`);
      return result;
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async addSpinWheel(data: any) {
    try {
      const result: AxiosResponse = await HTTPClientV2.post(
        `/company/spinWheel/`,
        data
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getSpinWheelById(id: string | number) {
    try {
      const result: AxiosResponse = await HTTPClientV2.get(`/company/spinWheel/${id}`);
      return result;
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async updateSpinWheelStatus(data:any, id:number) {
    try {
      const result: AxiosResponse = await HTTPClientV2.customRequest({
        url: `/company/spinWheel/${id}`,
        method: "put",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data),
      });
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async updateSpinWheel(data:any, id:number) {
    try {
      const result: AxiosResponse = await HTTPClientV2.customRequest({
        url: `/company/spinWheel/updateSpinWheel/${id}`,
        method: "put",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data),
      });
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getQuizById(id: string | number) {
    try {
      const result: AxiosResponse = await HTTPClientV2.get(`/company/quiz/${id}`);
      return result;
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async createQuiz(data: any) {
    try {
      const result: AxiosResponse = await HTTPClientV2.customRequest({
        url: "/company/quiz",
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data),
      });
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async updateQuiz(data:any, id:number) {
    try {
      const result: AxiosResponse = await HTTPClientV2.customRequest({
        url: `/company/quiz/${id}`,
        method: "put",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data),
      });
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getLandingPageDetail(id: any) {
    try {
      const result: AxiosResponse = await HTTPClient.get(
        `/company/landingPage/analytics/${id}`
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getLandingPageDetailAnalytics(id: any, params: any) {
    try {
      const result: AxiosResponse = await HTTPClientV2.get(
        `/company/landingPage/analytics/landingPageDetail/${id}`,
        params
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getLandingPageCustomerResponse(id: any, params: any) {
    try {
      const result: AxiosResponse = await HTTPClient.get(
        `/company/landingPage/analytics/getUserResponse`,
        params
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getQuestionWiseAnswers(data: any, params: any) {
    try {
      const result: AxiosResponse = await HTTPClient.customRequest({
        url: `company/landingPage/getAnswersByQuestions?${qs.stringify(params)}`,
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data),
      });
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async addScratchCard(data: any) {
    try {
      const result: AxiosResponse = await HTTPClientV2.post(
        `/company/scratchCard`,
        data
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async updateScratchCardStatus(data:any, id:number) {
    try {
      const result: AxiosResponse = await HTTPClientV2.customRequest({
        url: `/company/scratchCard/${id}`,
        method: "put",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data),
      });
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async updateQuizStatus(data:any, id:number) {
    try {
      const result: AxiosResponse = await HTTPClientV2.customRequest({
        url: `/company/quiz/updateQuiz/${id}`,
        method: "put",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data),
      });
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async updateScratchCard(data:any, id:number) {
    try {
      const result: AxiosResponse = await HTTPClientV2.customRequest({
        url: `/company/scratchCard/updateScratchCard/${id}`,
        method: "put",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data),
      });
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
};
