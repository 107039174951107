import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
// import { ANSWER_TYPES } from "../utils";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { SurveyContext } from "../store/SurveyStateManager";

export default function AnswerComponent({
  type,
  addOption,
  addOtherOption,
  data,
  updateData,
  removeAnswer,
  removeOtherAnswer,
  updateAnswer,
  updateOtherAnswer,
  updateLinearScaleData,
  errors,
  order,
  isOther = false,
  disabledInputs = false,
  touched,
  allQuestionsTouched,
  setQuestionsTouched,
  setFieldTouched
}) {
  const [showMinLabel, setMinLabel] = React.useState(false);
  const [showMaxLabel, setMaxLabel] = React.useState(false);
  const { ANSWER_TYPES } = React.useContext(SurveyContext);
  React.useEffect(() => {
    if (
      type === ANSWER_TYPES.CHECKBOX ||
      type === ANSWER_TYPES.DROPDOWN ||
      type === ANSWER_TYPES.RADIO
    ) {
      removeOtherAnswer();
    }
  }, [type]);

  if (!type || type === ANSWER_TYPES.SHORT || type === ANSWER_TYPES.LONG)
    return "";

  if (
    type === ANSWER_TYPES.CHECKBOX ||
    type === ANSWER_TYPES.DROPDOWN ||
    type === ANSWER_TYPES.RADIO
  ) {
    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      result.forEach((item, index) => {
        item.oOrder = index;
      });
      return result;
    };

    function onDragEnd(result) {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      const items = reorder(
        data,
        result.source.index,
        result.destination.index
      );
      updateData(items);
    }

    let otherOption = null;
    let staticOptions = [];
    if (data)
      data.forEach((item) => {
        if (item.type === "static") staticOptions.push(item);
        if (item.type === "other") otherOption = item;
      });

    return (
      <>
        <DragDropContext
          onDragEnd={onDragEnd}
          className={disabledInputs && "pointer-action-none"}
        >
          <Droppable droppableId="droppableMultipleChoice">
            {(provided, snapshot) => (
              <div
                className="questionnaire-form"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <FormGroup className="mt-5">
                  <Label>Options</Label>

                  {staticOptions &&
                    staticOptions.length !== 0 &&
                    staticOptions.map((item, index) => (
                      <Draggable
                        key={`${item.id}`}
                        draggableId={`question-option-${item.id}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            // isDragDisabled={item?.type === "other"}
                            {...provided.dragHandleProps}
                            disableInteractiveElementBlocking={false}
                          >
                            <div
                              className="my-2 d-flex align-items-center"
                              key={index}
                            >
                              <div className="option-dragable-icon" />
                              <Input
                                value={item?.value}
                                onChange={(e) =>
                                  updateAnswer(e.target.value, item?.id)
                                }
                                onBlur={() => setFieldTouched(`questions[${order}].answer[${item?.id}]`, true)}
                                disabled={disabledInputs}
                              ></Input>{" "}
                              <i
                                className="fa fa-times ml-5 cursor-pointer"
                                onClick={() =>
                                  disabledInputs ? null : removeAnswer(item.id)
                                }
                              />
                            </div>
                            {
                              (
                                (
                                  touched?.questions && touched?.questions[order] &&
                                  touched?.questions[order]?.answer &&
                                  touched?.questions[order]?.answer[item?.id]
                                ) || allQuestionsTouched
                             ) &&
                              errors?.questions &&
                              errors?.questions[order] &&
                              errors.questions[order][`answer[${index}].value`] && (
                                <div className="customCssInputFieldError">
                                  {errors.questions[order][`answer[${index}].value`]}
                                </div>
                              )
                            }
                            {
                              errors?.questions &&
                              errors?.questions[order] &&
                              errors.questions[order][`answer[${index}].type`] && (
                                <div className="customCssInputFieldError">
                                  {errors.questions[order][`answer[${index}].type`]}
                                </div>
                              )
                            }
                          </div>
                        )}
                      </Draggable>
                    ))}
                </FormGroup>

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {otherOption && (
          <div className="my-2 d-flex align-items-center">
            <div className="" />
            <Input
              value={otherOption?.value}
              disabled
              onChange={(e) => updateOtherAnswer(e.target.value)}
              className="other-option"
              placeholder="Other"
              // disabled={disabledInputs}
            ></Input>{" "}
            <i
              className="fa fa-times ml-5 cursor-pointer"
              onClick={() => disabledInputs ? null : removeOtherAnswer()}
            />
          </div>
        )}
        <span
          className={`text-primary cursor-pointer mr-2 ${
            disabledInputs ? "pointer-action-none" : ""
          }`}
          onClick={addOption}
        >
          <i className="fa fa-plus  text-primary" />
          &nbsp;Add Option
        </span>
        {!isOther && (
          <>
            or
            <span
              className={`text-primary cursor-pointer ml-2 ${
                disabledInputs ? "pointer-action-none" : ""
              }`}
              onClick={addOtherOption}
            >
              <i className="fa fa-plus  text-primary" />
              &nbsp;Add Other
            </span>
          </>
        )}
      </>
    );
  }
  if (type === ANSWER_TYPES.SLIDER) {
    return (
      <>
        <FormGroup className="mt-5  d-inline-block">
          <Label>Minimum value</Label>

          <div className="my-2 d-flex align-items-center">
            <Input
              onBlur={() => setFieldTouched(`questions[${order}].answer.minValue`, true)}
              className="w-75"
              value={data?.minValue?.value}
              onChange={(e) =>
                updateLinearScaleData({ value: e.target.value }, "minValue")
              }
              disabled={disabledInputs}
            ></Input>
            {!showMinLabel && (
              <span
                onClick={() => setMinLabel(true)}
                style={{ minWidth: "100px" }}
              >
                <i className="fa fa-plus ml-5 cursor-pointer" />
                &nbsp;Add Label
              </span>
            )}

          </div>
          {
            (
              (
                touched?.questions && touched?.questions[order] &&
                touched?.questions[order]?.answer?.minValue
              ) || allQuestionsTouched
           ) &&

            errors?.questions &&
            errors?.questions[order] &&
            errors.questions[order]['answer.minValue.value'] && (
              <div className="customCssInputFieldError">
                {errors.questions[order]['answer.minValue.value']}
              </div>
            )
          }
        </FormGroup>

        {showMinLabel && (
          <FormGroup className="mt-5 d-inline-block">
            <Label className="ufyndLabel">Minimum label</Label>
            <Input
              onBlur={() => setFieldTouched(`questions[${order}].answer.minValue.label`, true)}
              name="gems_value"
              value={data?.minValue?.label}
              onChange={(e) =>
                updateLinearScaleData({ label: e.target.value }, "minValue")
              }
              placeholder="Enter Min label here"
              disabled={disabledInputs}
            />
            {
              (
                (
                  touched?.questions && touched?.questions[order] &&
                  touched?.questions[order]?.answer?.minValue?.label
                ) || allQuestionsTouched
             ) &&

              errors?.questions &&
              errors?.questions[order] &&
              errors.questions[order]['answer.minValue.label'] && (
                <div className="customCssInputFieldError">
                  {errors.questions[order]['answer.minValue.label']}
                </div>
              )
            }
          </FormGroup>
        )}

        <br />
        <FormGroup className="mt-5 d-inline-block">
          <Label>Maximum value</Label>
          <div className="my-2 d-flex align-items-center">
            <Input
              className="w-75"
              value={data?.maxValue?.value}
              onChange={(e) =>
                updateLinearScaleData({ value: e.target.value }, "maxValue")
              }
              onBlur={() => setFieldTouched(`questions[${order}].answer.maxValue`, true)}
              disabled={disabledInputs}
            ></Input>{" "}
            {!showMaxLabel && (
              <span
                onClick={() => setMaxLabel(true)}
                style={{ minWidth: "100px" }}
              >
                <i className="fa fa-plus ml-5 cursor-pointer" />
                &nbsp;Add Label
              </span>
            )}
          </div>
          {
            (
              (
                touched?.questions && touched?.questions[order] &&
                  touched?.questions[order]?.answer?.maxValue
                ) || allQuestionsTouched
           ) &&
            errors?.questions &&
            errors?.questions[order] &&
            errors.questions[order]['answer.maxValue.value'] && (
              <div className="customCssInputFieldError">
                {errors.questions[order]['answer.maxValue.value']}
              </div>
            )
          }
        </FormGroup>

        {showMaxLabel && (
          <FormGroup className="mt-5 d-inline-block">
            <Label className="ufyndLabel">Maximum label</Label>
            <Input
              onBlur={() => setFieldTouched(`questions[${order}].answer.maxValue.label`, true)}
              name="gems_value"
              value={data?.maxValue?.label}
              onChange={(e) =>
                updateLinearScaleData({ label: e.target.value }, "maxValue")
              }
              disabled={disabledInputs}
              placeholder="Enter Max label here"
            />
            {
              (
                (
                  touched?.questions && touched?.questions[order] &&
                  touched?.questions[order]?.answer?.maxValue?.label
                ) || allQuestionsTouched
             ) &&

              errors?.questions &&
              errors?.questions[order] &&
              errors.questions[order]['answer.maxValue.label'] && (
                <div className="customCssInputFieldError">
                  {errors.questions[order]['answer.maxValue.label']}
                </div>
              )
            }
          </FormGroup>
        )}
        {
          errors?.questions &&
          errors?.questions[order] &&
          errors.questions[order]['answer'] && (
            <div className="customCssInputFieldError">
              {errors.questions[order]['answer']}
            </div>
          )
        }
      </>
    );
  }

  return "";
}
