import { takeLatest, all, call, put } from "redux-saga/effects";
import { appStoreActiontype } from "./appStore.actiontype";
import { appConstSuccess } from "./appStore.action";
import { appService } from "../../service";

export function* handleAppConstInit() {
  try {
    const [appConstant, menuObj, partnerConfig] = yield Promise.all([appService.getAppConstant(), appService.getMenuObj(), appService.getPartnerConfig()]);
    yield all([
      put(
        appConstSuccess({
          ...appConstant,
          menu: {
            ...menuObj?.data?.data,

            // campaign: { subMenu: { email: 2, whatsapp: 2, sms: 2 } },
            // loyalty: { subMenu: { landingPage: 2, coupons: 2 } },
            // microGames: { subMenu: { scratchCard: 2, spinWheel: 2, quiz: 2, survey: 2 } },
            // settings: { subMenu: { channelManagement: 2, companyManagement: 2, profile: 2, teamManagement: 2 } },
            // contacts: { subMenu: { allContacts: 2, segmentation: 2, imports: 2 } },
          },
          partnerConfig: {
            ...partnerConfig?.data?.data,
          }
        })
      ),
    ]);
  } catch (e) {
    console.log(e);
  }
}

export function* appConstantSaga() {
  yield takeLatest(appStoreActiontype.APPCONSTANT_INIT, handleAppConstInit);
}

export function* appStoreSaga() {
  yield all([call(appConstantSaga)]);
}
