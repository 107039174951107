import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {appStoreActiontype} from './appStore.actiontype';

const INIT_STATE = {
  globalConstants:undefined,
  apiErrors: undefined,
  appApiProcess: false,
  selectedCreationModule: ''
};

interface INIT_STATE_TYPE {
  globalConstants: any;
  apiErrors: any;
  appApiProcess: boolean;
  selectedCreationModule: string;
}

const appDataReducer =persistReducer(
  {storage, key: 'userModule', whitelist: ['globalConstants']},
  (state: INIT_STATE_TYPE = INIT_STATE, {type, payload}: {type: string, payload: any}) => {
  switch(type) {
    case appStoreActiontype.APPCONSTANT_SUCCESS:
      return Object.assign({}, state, {
        globalConstants : payload
      });

      case appStoreActiontype.APP_MENU_SUCCESS:
        let menu = payload?.data?.data
        return Object.assign({}, state, {
          globalConstants : {...state?.globalConstants,menu}
        });
    case appStoreActiontype.APPSTORE_ERROR:
      return {
        ...state,
        apiErrors: payload
      }
    case appStoreActiontype.APPSTORE_CLEAR_ERROR:
      return {
        ...state,
        apiErrors: undefined
      }
    case appStoreActiontype.SET_CREATION_PAGE:
      return {
        ...state,
        selectedCreationModule: payload.moduleName
      };
    case appStoreActiontype.APPCONSTANT_ONLOGOUT:
      return INIT_STATE

    default:
      return state
  }
}
)

export default appDataReducer;
