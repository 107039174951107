import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ReactSelectValidation, ReactSelectValidationOptional } from "../utils";

export default function useSurveyInfoForm() {
  /**SUrvey INFO */
  const surveyInfoInitValues = React.useMemo(
    () => ({
      surveyTitle: "",
      surveyDescription: "",
      retailer: "",
      image: "",
      prize: "",
      isPrize: false,
      prizeAmount: "",
      benefitId: "",
      defaultType:""
    }),
    []
  );



  const surveyInfoValidationSchema = Yup.object().shape({
    surveyTitle: Yup.string().trim()
      .required("Survey title is required")
      .max(140, `Survey title cannot be more than 140 characters`),
    surveyDescription: Yup.string().trim()
      .required("Required"),
    // retailer: ReactSelectValidation,
    prize: Yup.object().when('isPrize', {
      is: (isPrize) => isPrize,
      then: Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      }),
      otherwise: Yup.object()
    }),
    successMessage: Yup.string().when('isPrize', {
      is: 1,
      then: Yup.string().nullable(),
      otherwise: Yup.string().trim().required("Success message is required").typeError("Success message is required").max(156).typeError("Success message could have 156 characters."),
    }),
    // benefitId: ReactSelectValidationOptional,
    benefitId: Yup.object().when(['isPrize', 'prize'], {
      is: (isPrize, prize) => isPrize && prize && prize.value === '1',
      then: Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      }),
      otherwise: Yup.object().nullable()
    }),
    // defaultType:Yup.string().,
    // image: Yup.string().required("An image is required"),
    // image: Yup.string(),
    // prizeAmount: Yup.number().required("An amount is required"),
  });

  const surveyInfoFormik = useFormik({
    initialValues: { ...surveyInfoInitValues },
    validationSchema: surveyInfoValidationSchema,
  });
  /**SUrvey INFO */
  return { surveyInfoFormik, surveyInfoInitValues };
}
