import React, { useState, useRef } from "react";
import { Popover, OverlayTrigger, Form } from "react-bootstrap";
import InputModal from "./InputModal";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { contactService } from "../../../service/api/v2/contacts.service";
import toast from "../../../components/Toast";
import InputText from "../../../components/Forms/Input/Input";
import InputSelect from "../../../components/Forms/Select/InputSelect2";
import InputRadio from "../../../components/Forms/Radio/InputRadio";

export default function PropertiesDropdown(props) {
  const { entities, rIndex, itemMapping, changeMapping, showAddPropertyModal, dataType, tableList, isChildTable, heightOfTable, formData, optOut } =
    props;
  const [propertyTypes, changePropertyTypes] = React.useState("people");
  const [showModal, changeShowModal] = React.useState(false);
  const [showPropertyModal, changeShowAddPropertyModal] = React.useState(false);
  const [addingNewProperty, changeAddingNewProperty] = React.useState(false);
  const [createPropertyData, changeCreatePropertyData] = React.useState({});
  const [createPropertyErrors, changeCreatePropertyErrors] = React.useState({});

  let _entities = optOut ? entities?.optOut : dataType == "events" && propertyTypes == "events" ? entities.event : entities.people;

  if (isChildTable || propertyTypes == "events") {
    let mandatoryField = _entities.find((item) => item.isMandatory === 1);
    _entities = mandatoryField ? (_entities = [mandatoryField, ..._entities.filter((item) => item.isMandatory !== 1)]) : [..._entities];
  }

  const [searchText, setSearchText] = useState("");
  const setSearchValue = (e) => setSearchText(e?.target?.value);
  const buttonRef = useRef(null);
  const closePopover = () => buttonRef?.current?.click();
  const getAttributeType = (item) => {
    if (dataType === "events" && item.displayName == "Import as Table") return 2;
    return propertyTypes === "people" ? 1 : 2;
  };
  const setPropertyValue = (item) => {
    const _mappingItem = {
      ...itemMapping,
      name: item.name,
      displayName: item.displayName,
      dataType: item.dataType,
      isPrimary: item.isMandatory,
      operation: ["firstName", "lastName"].indexOf(item.name) !== -1 || item.isMandatory == 1 ? 3 : undefined,
      index: rIndex,
      attributeType: getAttributeType(item),
      dontImport: undefined,
      tableName: item.displayName == "Import as Table" ? item?.selectedTable?.value : undefined,
      isEventType: propertyTypes === "events" ? 1 : undefined,
      isEventPrimary: propertyTypes === "events" && item?.isMandatory ? 1 : 0,
    };
    if (isChildTable && item.isMandatory) {
      _mappingItem.displayName = itemMapping.columnName;
      _mappingItem.name = itemMapping.columnName;
      // _mappingItem.displayName =  itemMapping.columnName
    }
    if (propertyTypes === "events" && item.isMandatory) {
      _mappingItem.displayName = itemMapping.columnName;
      _mappingItem.name = formData?.currentTable + "_" + itemMapping.columnName;
      _mappingItem.isPrimary = 1;
      _mappingItem.dataType = "integer";
    }

    console.log(_mappingItem, itemMapping, "----------");

    changeMapping(_mappingItem);
    closePopover();
  };

  const dontAddThisColumn = () => {
    changeMapping({
      ...itemMapping,
      name: undefined,
      displayName: undefined,
      dataType: undefined,
      isPrimary: undefined,
      // operation : undefined,
      // index: undefined,
      attributeType: undefined,
      dontImport: true,
    });
    closePopover();
  };

  const handleAddModal = () => {
    closePopover();
    props?.isDbsync ? changeShowAddPropertyModal(itemMapping) : showAddPropertyModal(itemMapping);
    // //showAddPropertyModal(itemMapping);
    // changeShowAddPropertyModal(itemMapping);
  };

  const handleImportAsTable = () => {
    changeShowModal(true);
    closePopover();
  };

  const onHide = () => {
    changeShowAddPropertyModal(false);
    setPropertyValue({
      name: createPropertyData?.propertyName,
      displayName: createPropertyData?.propertyName,
      dataType: createPropertyData?.objectType?.value,
    });
    closePopover();
    changeCreatePropertyErrors({});
  };

  const camelize = (str) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  };

  const validateCreatePropertyDataObj = {
    propertyName: (value) => (value ? "" : "This field is required"),
    objectType: (value) => (value ? "" : "This field is required"),
    dataType: (value) => {
      if (formData.step2.dataType == "events" && !value) {
        return "This field is required";
      }
      return "";
    },
  };

  const isDataValid = () => {
    const _errors = {
      propertyName: validateCreatePropertyDataObj.propertyName(createPropertyData.propertyName),
      objectType: validateCreatePropertyDataObj.objectType(createPropertyData.objectType),
      dataType: validateCreatePropertyDataObj.dataType(createPropertyData.dataType),
    };
    changeCreatePropertyErrors({ ..._errors });
    if (Object.keys(_errors).filter((key) => _errors[key].length !== 0).length === 0) {
      return true;
    }
    return false;
  };

  const elasticDataTypesObject = useSelector((state) => {
    return state.appData.globalConstants.elasticDataTypesObject.map((item) => ({
      label: item.displayName,
      value: item.value,
    }));
  });

  const addCustomEntity = async () => {
    changeAddingNewProperty(true);
    try {
      if (isDataValid()) {
        const requestData = {
          name: camelize(createPropertyData.propertyName),
          dataType: createPropertyData.objectType.value,
          attributeType: formData.step2.dataType == "events" ? createPropertyData.dataType : 1, //1 for people data 2 for event data
          displayName: createPropertyData.propertyName,
        };

        if (createPropertyData.dataType == 2) {
          requestData.eventId = formData.step2.eventType.value; //only at the time of event property
        }
        const result = await contactService.addCustomEntity(requestData);
        toast({ msg: `Attribute "${createPropertyData.propertyName}" is created successfully` });
        onHide();
        props.getDefaultProperties();
      }
    } catch (e) {
      const msg = e?.response?.data?.status?.message || "Something went wrong";
      toast({ type: "error", msg: msg });
    }
    changeAddingNewProperty(false);
  };

  return (
    <>
      <OverlayTrigger
        trigger="click"
        key={"right"}
        placement={"bottom"}
        rootClose
        overlay={
          <Popover id={`popover-positioned-bottom`} className="properties-popover">
            <Popover.Title>
              <span>Properties</span>
              <div className="ufyndTextBox_search input-group">
                <div className="searchIcon">
                  <i className="bg-icon bg-icon16 bg-search icon-grey"></i>
                </div>
                <Form.Control
                  type="text"
                  className="input-field  border-radius-4 form-control"
                  placeholder="Search property"
                  autoFocus
                  value={searchText}
                  onChange={setSearchValue}
                />
              </div>
            </Popover.Title>
            <Popover.Content>
              {dataType === "events" ? (
                <div className="popover-tabs">
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      console.log("stopPorp");
                      changePropertyTypes("people");
                    }}
                    className={propertyTypes == "people" ? "active" : ""}>
                    Personal
                  </div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      console.log("stopPorp");

                      changePropertyTypes("events");
                    }}
                    className={propertyTypes == "events" ? "active" : ""}>
                    Event
                  </div>
                </div>
              ) : null}
              <ul className="properties-list">
                {_entities.map((item, index) => {
                  const { displayName, name } = item;
                  const _searchText = searchText.toLocaleLowerCase();
                  if (displayName.toLocaleLowerCase().includes(_searchText) || name.toLocaleLowerCase().includes(_searchText)) {
                    return (
                      <React.Fragment key={index}>
                        <li
                          className="display-7 my-2 cursor-pointer property-sub-type d-flex align-items-center"
                          key={item.value}
                          onClick={() => setPropertyValue(item)}>
                          {(isChildTable || propertyTypes == "events") && item.isMandatory == 1 ? (
                            <div
                              style={{
                                color: "#fff",
                                padding: "2px 8px",
                                backgroundColor: "#63BDB2",
                              }}>
                              Primary Key
                            </div>
                          ) : (
                            <>
                              {item.displayName} {item.isMandatory === 1 ? <div className="inner-type">Primary Identifier</div> : null}
                            </>
                          )}
                          {item.name === "createdAt" ? (
                            <div className="created-at-attribute d-flex">
                              (Epoch Time Stamp){" "}
                              <i
                                className="bg-icon bg-icon16 icon-grey bg-help-circle icon-left8"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  window.open("https://www.epochconverter.com/");
                                }}
                              />
                            </div>
                          ) : null}
                        </li>
                      </React.Fragment>
                    );
                  }
                })}
              </ul>
              <ul className="list-unstyled">
                {itemMapping?.isForeignKey == 1 && heightOfTable < 3 ? (
                  <li className="list-link list-link-table" onClick={handleImportAsTable}>
                    <i className="bg-icon bg-icon16 icon-right8 icon-secondary bg-layout"></i> Import table linked to key
                  </li>
                ) : null}

                {!optOut ? (
                  <li className="list-link list-link-add" onClick={handleAddModal}>
                    <i className="bg-icon bg-icon16 icon-right8 icon-primary bg-math-plus"></i> Create new property
                  </li>
                ) : null}
                <li className="list-link list-link-remove" onClick={dontAddThisColumn}>
                  <i className="bg-icon bg-icon16 icon-right8 icon-red bg-close"></i> Don't import this column
                </li>
              </ul>
            </Popover.Content>
          </Popover>
        }>
        <div className={`fakeSelect ${itemMapping?.displayName ? "selected" : ""}`} ref={buttonRef}>
          {itemMapping?.displayName ? itemMapping?.displayName : itemMapping.dontImport ? "Do not import" : "Select Mapping"}{" "}
          <span className="float-right text-primary">
            <i className="fa fa-angle-down" />
          </span>
        </div>
      </OverlayTrigger>

      {tableList ? (
        <InputModal
          tableList={tableList}
          showModal={showModal}
          onHide={() => {
            changeShowModal(false);
          }}
          onSave={(data) => {
            setPropertyValue({
              name: data?.value,
              displayName: "Import as Table",
              selectedTable: data,
            });
            changeShowModal(false);
            closePopover();
          }}
        />
      ) : null}

      {showPropertyModal && (
        <Modal show={showPropertyModal} onHide={onHide} centered className="standard-modal">
          <Modal.Header>
            <Modal.Title>Create New Property</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputText
              className="top-0 pb-0"
              label="Property Name"
              required={true}
              type="text"
              value={createPropertyData.propertyName}
              onChange={({ value }) => {
                changeCreatePropertyData({ ...createPropertyData, propertyName: value });
                changeCreatePropertyErrors({
                  ...createPropertyErrors,
                  propertyName: "",
                });
              }}
              name="propertyName"
              id="propertyName"
              placeholder="Enter property name"
              disabled={false}
              error={createPropertyErrors.propertyName}
              onBlur={() => {}}
            />

            {formData.step2.dataType === "events" ? (
              <InputRadio
                label={"Property Type"}
                required={true}
                className="d-flex align-items-center no-padding-label"
                options={[
                  { label: "People", value: 1 },
                  { label: "Events", value: 2 },
                ]}
                id="dataType"
                name="dataType"
                value={createPropertyData.dataType || ""}
                handleRadioChange={({ value }) => {
                  changeCreatePropertyData({ ...createPropertyData, dataType: value });
                  changeCreatePropertyErrors({
                    ...createPropertyErrors,
                    dataType: "",
                  });
                }}
                error={createPropertyErrors.dataType}
              />
            ) : null}
            <InputSelect
              className="select-input"
              required={true}
              label="Object Type"
              name="objectType"
              id="objectType"
              placeholder="Select object type"
              value={createPropertyData.objectType || ""}
              options={elasticDataTypesObject}
              onChange={(event) => {
                changeCreatePropertyData({ ...createPropertyData, objectType: event });
                changeCreatePropertyErrors({
                  ...createPropertyErrors,
                  objectType: "",
                });
              }}
              onBlur={() => {}}
              error={createPropertyErrors.objectType}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={addCustomEntity} disabled={addingNewProperty}>
              Add
            </Button>
            <Button variant="secondary" onClick={onHide}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
