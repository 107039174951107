import { IScratchCardInfo } from "./types";
import * as Yup from "yup";
const REQUIRED_MESSAGE = "This is a required field";

export enum SCRATCH_CARD_STATUS {
  ALL = "all",
  DRAFT = "1",
  ACTIVE = "2",
  COMPLETED = "3",
}

export enum SCRATCH_CARD_TYPE {
  TRIVIA = 1,
  DYNAMIC = 2,
}

export enum SCRATCH_CARD_FORM_STEP_KEYS {
  DETAILS = "1",
  PRIZES = "2",
  OVERVIEW = "3"
}

export const ScratchCardFormSteps = [
  { label: "Scratch card details", value: SCRATCH_CARD_FORM_STEP_KEYS.DETAILS },
  { label: "Distribution and prizes", value: SCRATCH_CARD_FORM_STEP_KEYS.PRIZES },
  { label: "Overview", value: SCRATCH_CARD_FORM_STEP_KEYS.OVERVIEW },
];

export const ScratchCardInfoInitialValues: IScratchCardInfo = {
  name: "",
  description: "",
  termsAndConditions: ""
};

export const ScratchCardDetailValidationSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED_MESSAGE).min(4, 'Name must have at least 4 charater.').max(48, 'Name must have at most 48 characters.'),
  termsAndConditions: Yup.string().required(REQUIRED_MESSAGE),
});

export const SLOTS_OPTIONS = [
  { label: "6 slots", value: 6 },
  { label: "5 slots", value: 5 },
  { label: "4 slots", value: 4 },
  { label: "3 slots", value: 3 },
];

export const ScratchCardPrizesInitialValues = {
  benefits: [
    {
      benefitType: null,
      value: null
    }
  ],
  redemptionPerUser: null,
  scratchCardPerDay: null,
  backgroundImage: null,
  defaultMessage: '',
};

export const ScratchCardPrizesValidationSchema = Yup.object().shape({
  benefits: Yup.array()
    .of(Yup.object().shape({
        benefitType: Yup.number().typeError(REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
        value: Yup.mixed().typeError(REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
        count: Yup.mixed().typeError(REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
      })),
  // redemptionPerUser: Yup.number().typeError(REQUIRED_MESSAGE).required(REQUIRED_MESSAGE).min(1, 'Must be greater than or equal to 1'),
  // scratchCardPerDay: Yup.number().typeError(REQUIRED_MESSAGE).required(REQUIRED_MESSAGE).min(1, 'Must be greater than or equal to 1'),
  backgroundImage: Yup.number().typeError(REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
  defaultMessage: Yup.string().typeError(REQUIRED_MESSAGE).required(REQUIRED_MESSAGE)
});
