/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, useLocation } from "react-router-dom";

export function BreadCrumbs({ items, breadcrumb }) {
  const [breadcrumbItems, setItems] = React.useState([]);
  const { pathname } = useLocation();

  const parseItems = (crumbs) => {
    if (crumbs) {
      let items = [];
      let crumb = crumbs;
      let i = 0;
      while (crumb !== null) {
        let item = {
          pageHeading: crumb?.pageHeading,
          breadCrumb: crumb?.breadcrumbTitle,
          url: crumb?.url,
        };

        let nextItem = null;
        items.push(item);

        if (crumb?.innerPages) {
          for (let section in crumb.innerPages) {
            if (pathname === crumb.innerPages[section].url) {
              items.push({
                pageHeading: crumb.innerPages[section]?.pageHeading,
                breadCrumb: crumb.innerPages[section]?.breadcrumbTitle,
                url: crumb.innerPages[section]?.url,
              });
              break;
            } else if (pathname.includes(crumb.innerPages[section].url)) {
              nextItem = crumb.innerPages[section];
              break;
            } else {
            }
          }
        } else {
          // nextItem = null;
        }
        // i++;

        crumb = nextItem;
      }
      setItems(items);
    } else {
      setItems([{breadCrumb:'asasdsd', url:'asdasd', }])
    }
  };

  React.useEffect(() => {
    parseItems(breadcrumb);
  }, [breadcrumb, pathname]);


  // if (!items || !items.length) {
  //   return "";
  // }

  return (
    <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2">
      <li className="breadcrumb-item">
        <Link to="/overview">
          <i className="flaticon2-shelter text-muted icon-1x" />
        </Link>
      </li>
      {breadcrumbItems.map((item, index) => (
        <li key={`bc${index}`} className="breadcrumb-item">
          <Link className="text-muted" to={{ pathname: item.url }}>
            {item.breadCrumb}
          </Link>
        </li>
      ))}
    </ul>
  );
}
