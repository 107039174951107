import React, { useEffect, useState } from "react";
import {Col, Row} from 'react-bootstrap';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import addFunnel from "highcharts/modules/funnel";
import ArrowRightOutlinedIcon from '@material-ui/icons/ArrowRightOutlined';
import EmptyPieChart from './assets/EmptyPieChart';
import './styles/styles.scss';
import {highchartsColors} from '../../constants/constants';

interface Props {
	data: object[]
}
Highcharts.setOptions({
	chart: {
	  style: {
		fontFamily: 'Inter, Helvetica, "sans-serif"',
	  },
	},
  });

export const BrowserReport: React.FC<Props> = (props) => {
	const {data, isEmpty, emptyData} = props;
  const [chartLoaded, setChartLoaded] = useState(false);
	const listData = isEmpty? emptyData: data;
	const cleanData = isEmpty? emptyData.map(item => ([item.name, Number(item.value)])): data.map(item => ([item.name, Number(item.value)]))
  const options = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
				height: 240
    },
    title: {
        text: '',
        align: 'left',
    },
    tooltip: {
				enabled: true,
        // pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
        pie: {
            dataLabels: {
                enabled: false,
                distance: -50,
                style: {
                    fontWeight: 'bold',
                    color: 'white'
                }
            },
            startAngle: 0,
            endAngle: 360,
            center: ['50%', '50%'],
            size: '120%',
						// showInLegend: true
        }
    },
    series: [{
        type: 'pie',
        name: 'Clicks',
        innerSize: '70%',
        data: [
					['Firefox', 64],
					['Chrome', 12],
					['Safari', 2]
        ],
				data: cleanData
    }],
		colors: highchartsColors
};

  useEffect(() => {
    window.Highcharts = Highcharts;
    addFunnel(Highcharts);
    setChartLoaded(true);
  }, []);

  return (
	<div>
		<Row>
			<Col className='my-3' md={5}>
				<HighchartsReact
					highcharts={Highcharts}
					options={options}
					constructorType={"chart"}
				/>
			</Col>
			<Col className='my-3' md={7} lg={7}>
				<div className = 'country-report-items'>
				{
					listData.map((item, index) =>
						<div className='country-report-item'>
							<Row className='my-2'>
									<Col className='my-3' md={4}>
										<div className = 'country-detail'>
											<div className = 'flag-icon-wrapper-icon' style={{
												background: isEmpty? '#E0E0E0': highchartsColors[index]
											}}>

											</div>
											<span title={item.name}  className = 'country-name'>{item.name}</span>
										</div>
									</Col>
									<Col className='my-3' md={8}>
										<div className = 'country-stats-container'>
											<div className = 'country-stats-graph'>
												<div className='stats-actual-value' style={{width: `${item.value}%`}}></div>

											</div>
											<div className = 'country-stats-number'>
												{item.value === null? '-': item.value} %
											</div>
											{
												// <ArrowRightOutlinedIcon />
											}
										</div>
									</Col>
							</Row>
						</div>
					)
				}
				</div>
			</Col>
			{
				isEmpty?
				<div className='graph-empty-state'>
					<EmptyPieChart />
				</div>:
				null
			}
		</Row>
	</div>
  );
};

export default BrowserReport;
