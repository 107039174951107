import React, { useState, useEffect } from "react";
// import { TableWrapper, TableHeader } from "../../../component/tableWrapper";
// import DebounceSearchInput from "../../../component/debounceSearch";
// import DataTable from "react-data-table-component";
// import ActionCompContainer from "../components/ActionCompContainer";
import { useHistory, useParams } from "react-router-dom";
// import { Button } from "react-bootstrap";
import SurveyWrapper from "../components/SurveyWrapper";
import { surveyService } from "../../../service/api/survey.service";
import { Spinner } from "react-bootstrap";

const UserResponse = ({ answerTypes, ...props }) => {
  const { id, userId } = useParams();
  const { push } = useHistory();
  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [totalEntries, setTotalEntries] = useState([]);

  const getUserResponse = () => {
    return surveyService.getUserResponsesForSurvey(id, userId);
  };

  const getResponses = () => {
    setLoading(true);
    getUserResponse()
      .then((res) => {
        if (res.status === 200) {
          let temp = res.data.data.docs;
          setResponses(temp);
          // setTotalEntries(res.data.data.totalEntries);
        }
        if (res.errors) {
          setResponses([]);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderAnswer = (optionType, answer, other) => {
    let otherAnswer = other == null ? "" : other;
    let mainAnswer = answer == null ? "" : answer;
    switch (optionType) {
      case answerTypes.LONG:
        return mainAnswer + " " + otherAnswer;
      case answerTypes.SHORT:
        return mainAnswer + " " + otherAnswer;
      case answerTypes.SLIDER:
        return mainAnswer + " " + otherAnswer;
      case answerTypes.CHECKBOX:
        return mainAnswer
          ? answer.map((item) => item.value).join(", ") + " " + otherAnswer
          : otherAnswer;
      case answerTypes.DROPDOWN:
        return mainAnswer
          ? answer.map((item) => item.value).join(", ") + " " + otherAnswer
          : otherAnswer;
      case answerTypes.RADIO:
        return mainAnswer
          ? answer.map((item) => item.value).join(", ") + " " + otherAnswer
          : otherAnswer;
    }
  };

  useEffect(() => {
    getResponses({});
  }, []);

  return (
    <SurveyWrapper {...props}>
      <div className="d-flex align-items-center container">
        <i
          className="fas fa-arrow-left font-24 text-black cursor-pointer"
          onClick={() => push(`/loyalty/games/survey/${id}/users`)}
        />
        &nbsp;&nbsp;<span className="font-20 font-weight-bold">Back</span>
      </div>
      <div className="card-body">
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <div className="d-flex flex-column questions">
            {responses.map((item, index) => {
              const {
                surveyQuestion: { question, optionType },
                answer,
                other,
              } = item;
              return (
                <div className="question-item gray-bottom-border py-5">
                  <div className="question font-weight-bolder">
                    {index + 1}. {question}
                  </div>
                  <div className="answer py-2">
                    {renderAnswer(optionType, answer, other)}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </SurveyWrapper>
  );
};

export default UserResponse;
