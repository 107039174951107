import React from "react";
import {Spinner} from 'react-bootstrap';
import Highcharts from 'highcharts'
import HighchartsTreeChart from "highcharts/modules/treemap";
import HighchartsReact from 'highcharts-react-official'
import EmptyBarChart from './assets/EmptyBarChart';
import './styles/styles.scss';
Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: 'Inter, Helvetica, "sans-serif"',
    },
  },
});
const LineChart = ({data, title, isEmpty, emptyData}) => {

  window.Highcharts = Highcharts;
  HighchartsTreeChart(Highcharts);

  const options = {
    chart: {
      height: 300
    },
    series: [{
        type: 'treemap',
        layoutAlgorithm: 'squarified',
        showInLegend: true,
        legendType: 'point',
        
        data: isEmpty? emptyData: data,
        
    }],
    title: {
        text: title,
        align: 'left',
        style: {
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '16px',
          // fontFamily: 'Poppins'
        }
    },
    plotOptions: {
      treemap: {
        dataLabels: {
          enabled: isEmpty ? false : true,
          format: '<b>{point.value} %',
          // padding: '10px',

        },
      }
    }
  };

  return(
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
      {
        isEmpty?
        <div className='graph-empty-state'>
          <EmptyBarChart />
        </div>:
        null
      }
    </>
  )
}
export default LineChart;
