import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import EmptyBarChart from './assets/EmptyBarChart';
import './styles/styles.scss';
import addFunnel from "highcharts/modules/funnel";

interface Props {
	data: object[],
	title: string,
	xAxis: string,
	yAxis: string
}

Highcharts.setOptions({
    chart: {
      style: {
        fontFamily: 'Inter, Helvetica, "sans-serif"',
      },
    },
  });

export const SmsConvertionRatio: React.FC<Props> = (props) => {
  const [chartLoaded, setChartLoaded] = useState(false);
	const {data, title, xAxis, yAxis, categories, isEmpty, emptyData} = props;
  const options = {
            chart: {
								type: 'spline',
								height: 300
            },
            title: {
                text: title,
                align:'left',
								style: {
									fontFamily: 'Inter',
									fontStyle: 'normal',
									fontWeight: '600',
									fontSize: '16px',
									lineHeight: '20px',
								}
            },
            subtitle: {
                // text: document.ontouchstart === undefined ?
                //     'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in'
            },
            xAxis: {
               categories: isEmpty? emptyData.categories :categories,
								title: {
                    text: xAxis
                }
            },
            yAxis: {
                title: {
                    text: yAxis
                }
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                area: {
                    // fillColor: {
                    //     linearGradient: {
                    //         x1: 0,
                    //         y1: 0,
                    //         x2: 0,
                    //         y2: 1
                    //     },
                    //     stops: [
                    //         [0, Highcharts.getOptions().colors[0]],
                    //         [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                    //     ]
                    // },
                    marker: {
                        radius: 2
                    },
                    lineWidth: 1,
                    states: {
                        hover: {
                            lineWidth: 1
                        }
                    },
                    threshold: null
                }
            },
            series: [{
                type: 'area',
                name: 'Users',
                data: isEmpty? emptyData.data :data
            }],
						colors: isEmpty? ['#E0E0E0']: ['linear-gradient(180deg, rgba(128, 133, 233, 0.14) 0.18%, rgba(255, 255, 255, 0) 100%)']
        };

  return (
		<>
	    <HighchartsReact
	      highcharts={Highcharts}
	      options={options}
	      constructorType={"chart"}
	    />
			{
				isEmpty?
				<div className='graph-empty-state'>
					<EmptyBarChart />
				</div>:
				null
			}
		</>
  );
};

export default SmsConvertionRatio;
