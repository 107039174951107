import React from "react";
import { useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
import InputRadio from "../../../../components/Forms/Radio/InputRadio";
import { contactService } from "../../../../service/api/v2/contacts.service";
import PropertiesDropdown from "../PropertiesDropdown";
import InputText from "../../../../components/Forms/Input/Input";
import InputSelect from "../../../../components/Forms/Select/InputSelect2";
import InputCheckbox from "../../../../components/Forms/Checkbox/InputCheckbox";
import toast from "../../../../components/Toast";

function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}

const Step3 = (props) => {
  const { formData, changeFormData, changeCurrentStep, optOut } = props;
  const { dataType } = formData.step2;
  const _fileMapping = formData.fileMapping.slice();
  const [attributeListType, changeAttributeListType] = React.useState();
  const [addingNewProperty, changeAddingNewProperty] = React.useState(false);
  const [properties, changeProperties] = React.useState({ people: [], event: [] });
  const [createPropertyData, changeCreatePropertyData] = React.useState({});
  const [createPropertyErrors, changeCreatePropertyErrors] = React.useState({});

  const elasticDataTypesObject = useSelector((state) => {
    return state.appData.globalConstants.elasticDataTypesObject.map((item) => ({
      label: item.displayName,
      value: item.value,
    }));
  });

  const validateCreatePropertyDataObj = {
    propertyName: (value) => (value ? "" : "This field is required"),
    objectType: (value) => (value ? "" : "This field is required"),
    dataType: (value) => {
      if (formData.step2.dataType == "events" && !value) {
        return "This field is required";
      }
      return "";
    },
  };

  const isDataValid = () => {
    const _errors = {
      propertyName: validateCreatePropertyDataObj.propertyName(createPropertyData.propertyName),
      objectType: validateCreatePropertyDataObj.objectType(createPropertyData.objectType),
      dataType: validateCreatePropertyDataObj.dataType(createPropertyData.dataType),
    };
    changeCreatePropertyErrors({ ..._errors });
    if (Object.keys(_errors).filter((key) => _errors[key].length !== 0).length === 0) {
      return true;
    }
    return false;
  };

  const [addPropertyModalStatus, showAddPropertyModal] = React.useState(false);
  const onHide = () => {
    showAddPropertyModal(false);
    changeCreatePropertyData({});
    changeCreatePropertyErrors({});
  };
  const { fileData } = formData.step2;
  // fileMapping
  const getDefaultProperties = async () => {
    try {
      const requestData = {};
      if (formData.step2.dataType == "events") {
        requestData.type = 2;
        requestData.eventId = formData.step2.eventType.value;
      } else {
        requestData.type = 1;
      }
      const data = await contactService.getDefaultProperties(requestData);
      const { people, event } = data.data.data;
      changeProperties({ people, event: event || [] });
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    getDefaultProperties();
  }, []);

  const renderTableData = () => {
    let columns: any = [];
    _fileMapping
      .filter((_item) => {
        if (attributeListType === "Mapped") {
          return _item.name;
        } else if (attributeListType === "Unmapped") {
          return !_item.name;
        }
        return true;
      })
      .forEach((row) => {
        const fileMappingItem = _fileMapping[row.index];
        const isMappingDone = fileMappingItem.displayName;
        columns.push(
          <tr key={row.label} className={"show"}>
            <td className="text-small-2 navy">
              <InputCheckbox
                label=""
                size="sm"
                options={[{ label: "", value: "selected" }]}
                id={`propertyMapped-${row.index}`}
                name={`propertyMapped-${row.index}`}
                value={[isMappingDone ? "selected" : ""]}
                handleCheckboxChange={(e) => {}}
              />
            </td>
            <td className="text-small-2 navy">{row.label}</td>
            <td className="preview-info">
              {row.data.map((item: any, index: number) => {
                let value = String(item);
                if (item instanceof Date) {
                  value = `${String("0" + item.getUTCDay()).slice(-2)} / ${String("0" + (item.getUTCMonth() + 1)).slice(
                    -2
                  )} / ${item.getUTCFullYear()}`;
                }
                if (value && value.length > 30) {
                  value = value.slice(0, 27) + "...";
                }
                return (
                  <React.Fragment key={index}>
                    <span>{value ? value : "N/A"}</span>
                    <br />
                  </React.Fragment>
                );
              })}
            </td>
            <td>
              {
                <PropertiesDropdown
                  optOut={optOut}
                  dataType={dataType}
                  entities={{
                    optOut: properties.people.filter((it) => it?.isMandatory),
                    people: properties.people.filter((item) => {
                      return !_fileMapping.find((_item) => _item.name == item.name);
                    }),
                    event: properties.event.filter((item) => {
                      return !_fileMapping.find((_item) => _item.name == item.name);
                    }),
                  }}
                  rIndex={row.index}
                  itemMapping={_fileMapping[row.index]}
                  showAddPropertyModal={showAddPropertyModal}
                  changeMapping={(mappingItem) => {
                    let _newMapping = _fileMapping.slice();

                    if (optOut) {
                      _newMapping = _newMapping.map((it, index) => {
                        if (index === row?.index) {
                          return mappingItem;
                        } else {
                          return { ...it, attributeType: "", dataType: "", displayName: "", isPrimary: false, dontImport: true };
                        }
                      });
                    } else {
                      _newMapping.splice(row.index, 1, mappingItem);
                    }
                    console.log(_newMapping);
                    changeFormData({ ...formData, fileMapping: _newMapping });
                  }}
                />
              }
            </td>
          </tr>
        );
      });
    return columns;
  };

  const addCustomEntity = async () => {
    changeAddingNewProperty(true);
    try {
      if (isDataValid()) {
        const requestData = {
          name: camelize(createPropertyData.propertyName),
          dataType: createPropertyData.objectType.value,
          attributeType: formData.step2.dataType == "events" ? createPropertyData.dataType : 1, //1 for people data 2 for event data
          displayName: createPropertyData.propertyName,
        };

        if (createPropertyData.dataType == 2) {
          requestData.eventId = formData.step2.eventType.value; //only at the time of event property
        }
        const result = await contactService.addCustomEntity(requestData);

        const _newMapping = _fileMapping.slice();
        _newMapping.splice(addPropertyModalStatus.index, 1, {
          ...addPropertyModalStatus,
          name: requestData.name,
          displayName: requestData.displayName,
          dataType: requestData.dataType,
          isPrimary: 0,
          operation: undefined,
          index: addPropertyModalStatus.index,
          attributeType: requestData.attributeType,
          dontImport: undefined,
        });
        changeFormData({ ...formData, fileMapping: _newMapping });

        toast({ msg: `Attribute "${createPropertyData.propertyName}" is created successfully` });
        onHide();
        getDefaultProperties();
      }
    } catch (e) {
      const msg = e?.response?.data?.status?.message || "Something went wrong";
      toast({ type: "error", msg: msg });
    }
    changeAddingNewProperty(false);
  };

  const mappedCols = _fileMapping.filter((item) => item.name).length;
  const isMappingDone = mappedCols === fileData.length;
  return (
    <div className="import-contact-page-wrapper">
      <div className="import-title">Map columns in your file to contact properties</div>
      {!optOut ? (
        <div className="import-description">
          Each column header must be mapped to a contact property in CRM using the dropdown menu. <br /> You can always create a new property or
          choose “Don’t import” for columns you wish to skip. You can even uncheck them.
        </div>
      ) : null}
      <div className="mapping-filters-header d-flex align-items-center justify-content-between">
        {!optOut ? (
          <div className="mapping-filters-list d-flex">
            <div
              className={`cursor-pointer ${
                attributeListType === "All" || (attributeListType !== "Mapped" && attributeListType !== "Unmapped") ? "activeListType" : ""
              }`}
              onClick={() => changeAttributeListType("All")}>
              All ({_fileMapping.length})
            </div>
            <div
              className={`cursor-pointer ${attributeListType === "Mapped" ? "activeListType" : ""}`}
              onClick={() => changeAttributeListType("Mapped")}>
              Mapped ({mappedCols})
            </div>
            <div
              className={`cursor-pointer ${attributeListType === "Unmapped" ? "activeListType" : ""}`}
              onClick={() => changeAttributeListType("Unmapped")}>
              Unmapped ({_fileMapping.length - mappedCols})
            </div>
          </div>
        ) : null}
      </div>
      <Table className="bg-white rounded mapping-filters-table">
        <thead>
          <tr>
            <th className="column-header">
              <InputCheckbox
                label=""
                size="sm"
                options={[{ label: "", value: "selected" }]}
                id={`propertyMapped-header`}
                name={`propertyMapped-header`}
                value={[isMappingDone ? "selected" : ""]}
                handleCheckboxChange={(e) => {}}
              />
            </th>
            <th className="column-header">Column Header</th>
            <th className="column-header">Information Preview</th>
            <th className="column-header">Crm Properties</th>
          </tr>
        </thead>
        <tbody>{renderTableData()}</tbody>
      </Table>
      <Modal show={addPropertyModalStatus} onHide={onHide} centered className="standard-modal">
        <Modal.Header>
          <Modal.Title>Create New Property</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputText
            className="top-0 pb-0"
            label="Property Name"
            required={true}
            type="text"
            value={createPropertyData.propertyName}
            onChange={({ value }) => {
              changeCreatePropertyData({ ...createPropertyData, propertyName: value });
              changeCreatePropertyErrors({
                ...createPropertyErrors,
                propertyName: "",
              });
            }}
            name="propertyName"
            id="propertyName"
            placeholder="Enter property name"
            disabled={false}
            error={createPropertyErrors.propertyName}
            onBlur={() => {}}
          />

          {formData.step2.dataType === "events" ? (
            <InputRadio
              label={"Property Type"}
              required={true}
              className="d-flex align-items-center no-padding-label"
              options={[
                { label: "People", value: 1 },
                { label: "Events", value: 2 },
              ]}
              id="dataType"
              name="dataType"
              value={createPropertyData.dataType || ""}
              handleRadioChange={({ value }: any) => {
                changeCreatePropertyData({ ...createPropertyData, dataType: value });
                changeCreatePropertyErrors({
                  ...createPropertyErrors,
                  dataType: "",
                });
              }}
              error={createPropertyErrors.dataType}
            />
          ) : null}

          <InputSelect
            className="select-input"
            required={true}
            label="Object Type"
            name="objectType"
            id="objectType"
            placeholder="Select object type"
            value={createPropertyData.objectType || ""}
            options={elasticDataTypesObject}
            onChange={(event) => {
              changeCreatePropertyData({ ...createPropertyData, objectType: event });
              changeCreatePropertyErrors({
                ...createPropertyErrors,
                objectType: "",
              });
            }}
            onBlur={() => {}}
            error={createPropertyErrors.objectType}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={addCustomEntity} disabled={addingNewProperty}>
            Add
          </Button>
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
          {/* {`{${camelize(createPropertyData.propertyName||'')}}`} */}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Step3;
