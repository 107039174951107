import { IQuizInfo } from "./types";
import * as Yup from "yup";
export enum QUESTION_TIMED {
  YES = 1,
  NO = 0,
}
const REQUIRED_MESSAGE = "This is a required field";
export enum QUIZ_STATUS {
  ALL = "all",
  DRAFT = "1",
  ACTIVE = "2",
  COMPLETED = "3",
}

export enum QUIZ_TYPE {
  TRIVIA = 1,
  DYNAMIC = 2,
}

export enum QUIZ_FORM_STEP_KEYS {
  QUIZ_INFO = "1",
  QUESTIONNAIRE = "2",
  COHORTS = "3",
  OVERVIEW = "4",
}

export enum QUIZ_FORM_STEP_KEYS_COUNT {
  QUIZ_INFO = "1",
  COHORTS = "2",
  QUESTIONNAIRE = "3",
  OVERVIEW = "4",
}

export const QuizFormSteps = [
  { label: "Quiz Info", value: QUIZ_FORM_STEP_KEYS.QUIZ_INFO },
  { label: "Questionnaire", value: QUIZ_FORM_STEP_KEYS.QUESTIONNAIRE },
  { label: "Cohorts & rewards", value: QUIZ_FORM_STEP_KEYS.COHORTS },
  { label: "Overview", value: QUIZ_FORM_STEP_KEYS.OVERVIEW },
];

export const QuizFormSteps1 = [
  { label: "Quiz Info", value: QUIZ_FORM_STEP_KEYS.QUIZ_INFO },
  { label: "Cohorts & rewards", value: QUIZ_FORM_STEP_KEYS.COHORTS },
  { label: "Questionnaire", value: QUIZ_FORM_STEP_KEYS.QUESTIONNAIRE },
  { label: "Overview", value: QUIZ_FORM_STEP_KEYS.OVERVIEW },
];

export const PointOptions = [
  { label: "1 pt", value: 1 },
  { label: "2 pts", value: 2 },
  { label: "3 pts", value: 3 },
  { label: "4 pts", value: 4 },
  { label: "5 pts", value: 5 },
];

export const QuizInfoInitialValues: IQuizInfo = {
  name: "",
  description: "",
  timed: QUESTION_TIMED.NO,
  type: 1,
};

export const QuizInfoValidationSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED_MESSAGE),
  description:  Yup.string().required('Required'),//.required(REQUIRED_MESSAGE),
  timed: Yup.number().required(),
  type: Yup.number()
    .required("Please select a quiz type")
    .typeError("Please select a quiz type"),
});

export enum TEST_TYPES {
  TRIVIA = 0,
  DYNAMIC_SUM = 1,
  DYNAMIC_COUNT = 2,
}
export const DYNAMIC_TEST_OPTIONS = [
  { label: "Sum Based", value: TEST_TYPES.DYNAMIC_SUM },
  { label: "Count Based", value: TEST_TYPES.DYNAMIC_COUNT },
];

export const TriviaAnswerOptionInitialValues = {
  text: "",
  isCorrect: false,
  oorder: 0,
};
export const TriviaQuestionnaireInitialValues = {
  question: "",
  description: "",
  qOrder: 1,
  optionType: 1,
  timer: 0,
  options: [{ ...TriviaAnswerOptionInitialValues }],
  isRandom: false
};

export const TriviaQuestionValidationSchema = Yup.object().shape({
  question: Yup.string().required(REQUIRED_MESSAGE),
  // description: Yup.string().required(REQUIRED_MESSAGE),
  qOrder: Yup.number().required(),
  optionType: Yup.number().nullable(),
  options: Yup.array()
    .of(
      Yup.object().shape({
        text: Yup.string().required(REQUIRED_MESSAGE),
        oorder: Yup.number().required(),
        isCorrect: Yup.boolean().required(),
      })
    )
    .required()
    .min(2),
  timer: Yup.number().when('isTimer', {
    is: (isTimer) => {
      return isTimer;
    },
    then: Yup.number().min(30).required('Required')
  }),
  isRandom: Yup.boolean()
});

export const TriviaCohortsInitialValues = {
  id: 0,
  name: "",
  message: "",
  prize: "",
};

export const TriviaCohortsValidationSchema = Yup.object().shape({
  id: Yup.number().required(),
  name: Yup.string().required(REQUIRED_MESSAGE),
  message: Yup.string().required(REQUIRED_MESSAGE),
  prize: Yup.string().required(REQUIRED_MESSAGE),
});

export const CohortPointsInitialValues = {
  id: 1,
  couponId: { label: "", value: "" },
  minPoints: 0,
  maxPoints: 0,
  successMessage: "",
};

export const CohortPointsValidationSchema = Yup.object().shape({
  id: Yup.number().required(),
  couponId: Yup.object()
    .shape({ label: Yup.string().required(), value: Yup.string().required() })
    .required(REQUIRED_MESSAGE),
  minPoints: Yup.number().required(REQUIRED_MESSAGE),
  maxPoints: Yup.number().required(REQUIRED_MESSAGE),
  successMessage: Yup.string().required(REQUIRED_MESSAGE),
});

export const CohortInitialValues = {
  id: 0,
  couponId: { label: "", value: "" },
  cohortName: "",
  successMessage: "",
};

export const CohortValidationSchema = Yup.object().shape({
  id: Yup.number().required(),
  couponId: Yup.object()
    .shape({ label: Yup.string().required(), value: Yup.string().required() })
    .required(REQUIRED_MESSAGE),
  cohortName: Yup.string().required(REQUIRED_MESSAGE),
  successMessage: Yup.string().required(REQUIRED_MESSAGE),
});

export const DynamicSumAnswerInitialValues = {
  text: "",
  isCorrect: false,
  oorder: 0,
  points: 0,
};

export const DynamicCountAnswerInitialValues = {
  text: "",
  isCorrect: false,
  oorder: 0,
  cohort: null,
};

export const DynamicSumQuestionInitialValues = {
  question: "",
  description: "",
  qOrder: 1,
  optionType: 1,
  options: [{ ...DynamicSumAnswerInitialValues }],
  isRandom: false
};

export const DynamicCountQuestionInitialValues = {
  question: "",
  description: "",
  qOrder: 1,
  optionType: 1,
  options: [{ ...DynamicCountAnswerInitialValues }],
  isRandom: false
};

export const DynamicSumValidationSchema = Yup.object().shape({
  question: Yup.string().required(REQUIRED_MESSAGE),
  qOrder: Yup.number().required(),
  optionType: Yup.number().nullable(),
  options: Yup.array()
    .of(
      Yup.object().shape({
        text: Yup.string().required(REQUIRED_MESSAGE),
        oorder: Yup.number().required(),
        isCorrect: Yup.boolean().nullable(),
        points: Yup.number().required().min(1),
      })
    )
    .required()
    .min(2),
});

export const DynamicCountValidationSchema = Yup.object().shape({
  question: Yup.string().required(REQUIRED_MESSAGE),
  qOrder: Yup.number().required(),
  optionType: Yup.number().nullable(),
  options: Yup.array()
    .of(
      Yup.object().shape({
        text: Yup.string().required(REQUIRED_MESSAGE),
        oorder: Yup.number().required(),
        isCorrect: Yup.boolean().nullable(),
        cohort: Yup.number().required(),
      })
    )
    .required()
    .min(2),
});
