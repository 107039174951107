import React, { useState, useEffect, useRef } from "react"
import { geoEqualEarth, geoPath } from "d3-geo"
import { feature, mesh, merge } from "topojson-client"
import * as d3 from 'd3';
import countryCodeNameMapping from '../../constants/countryCodeNameMapping';
const topojson = require('../../constants/topo.json');

let colorsToFill = ['#3551DD', '#3551DD', '#3551DD', '#3551DD', '#3551DD', '#3551DD', '#3551DD', '#3551DD', '#3551DD', '#3551DD', '#3551DD']

export default class WorldMap extends React.Component {
  constructor(props) {
    super(props)

    const {countries} = this.props;
    this.countriesObj = countries ? countries.reduce((obj, country) => {
      return {
        ...obj,
        [country.name]: country.percent
      }
    }, {}) : [];
    // this.state = {expandedCountry: props.expandedCountry};
  }

  renderMap(g) {
    const width = 500, height = 300;
    const center = [width / 2, height / 2];

    const projection = d3.geoMercator()
    .scale(70)
    .translate(center)

    const path = d3.geoPath()
        .projection(projection);
    const expandedCountry = this.props.expandedCountry;
    const onCountryClick = this.props.onCountryClick;
    // d3.json("https://cdn.jsdelivr.net/npm/world-atlas@2/countries-50m.json").then((response) => {
      const data = feature(topojson, topojson.objects.countries).features;
      data.map((d, i) => {
        let fill = 'white';
        if(expandedCountry.indexOf(d.properties.name) !== -1) {
          fill = colorsToFill[this.countriesObj[d.properties.name] / 10] || '#89CFF0';
        }
        // const fill = colorsToFill[this.countriesObj[d.properties.name] / 10] || '#f5f5f5';
        // console.log(fill, this.countriesObj);
        g.append("path")
        .attr("d", path(d))
        .attr("fill", fill)
        .attr("stroke", '#89CFF0')
        .attr("stroke-width", '0.5')
        .on("click", () => onCountryClick({name: d.properties.name, d}))

      });
    // });
  }

  componentDidUpdate() {
    const g = d3.select(this.g);
    this.renderMap(g);
  }

  componentDidMount() {
    const svg = d3.select(this.svg);
    const g = d3.select(this.g);
    // const g = svg.append("g");

    function zoomed(event) {
      const transform = event.transform;
      g.style("stroke-width", 1.5 / transform.k + "px");
      g.attr("transform", transform);
    }

    const zoom = d3.zoom()
      .scaleExtent([1, 8])
      .on('zoom', zoomed);
    const expandedCountry = this.props.expandedCountry;
    svg.call(zoom);
    this.renderMap(g);
    d3.select('#zoom-in').on('click', function() {
    	// zoom.scaleBy(svg.transition().duration(750), 1.3);
    	zoom.scaleBy(svg, 1.3);
    });

    d3.select('#zoom-out').on('click', function() {
      zoom.scaleBy(svg, 1 / 1.3);
    });
  }
  render() {
    return (
      <div className='world-map-controls d-flex'>
        <div className='zoom d-flex flex-column'>
          <span className='zoom-button mb-2 d-flex' id='zoom-in'><span class="material-icons">add</span></span>
          <span className='zoom-button d-flex' id='zoom-out'><span class="material-icons"><span class="material-symbols-outlined">
horizontal_rule
</span></span></span>
        </div>
      <svg viewBox="0 0 500 300" ref={svg=> this.svg=svg}>
        <g ref={g=>this.g=g}></g>
      </svg>
      </div>
    )
  }
}
