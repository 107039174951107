import React, {useMemo} from "react";
import {Link, useHistory} from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import {toAbsoluteUrl} from "../../../_helpers";
import FormatUnderlined from '@material-ui/icons/FormatUnderlined';
import KTLayoutAsideToggle from "../../../_assets/js/layout/base/aside-toggle";
import BrandLogo from './BrandLogo';
import { IRootState } from "../../../../app/store/root.reducer";
import { confirmationPopUpInfo } from "../../../../app/utils/CreateEditPageUtils";
import { Modal, Button } from "react-bootstrap";

export function Brand() {
  const { push } = useHistory();
  const user = useSelector((state: IRootState) => state.auth.user, shallowEqual);
  const asideMenuUtils = KTLayoutAsideToggle.init("kt_aside_toggle");
  const selectedCreationModule = useSelector((state) => state.appData.selectedCreationModule);
  const confirmationPopUpConfig = confirmationPopUpInfo[selectedCreationModule];
  const [showConfirmationPopUp, changeShowConfirmationPopUpStatus] = React.useState(false);
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      asideSelfMinimizeToggle: objectPath.get(
          uiService.config,
          "aside.self.minimize.toggle"
      ),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo()
    };
  }, [uiService]);
  return (
    <>
      {/* begin::Brand */}
      <div
          className={`brand flex-column-auto bg-darkgreen ${layoutProps.brandClasses}`}
          id="kt_brand"
      >
        {/* begin::Logo */}
        <div
          className="brand-logo cursor-pointer"
          onClick={() => {
            if (selectedCreationModule.length === 0) {
              push("/overview");
            } else {
              changeShowConfirmationPopUpStatus(true);
            }
          }}
        >
          <div className='company-user-info-container'>
            <div className='companu-user-info-content d-flex align-items-center'>
              <BrandLogo className='unifyndIcon'/>
              <div className='company-user-info'>
                <div className='company-name'>UniSuite</div>
              </div>
            </div>
          </div>
        </div>
        {/* end::Logo */}
      </div>
      {/* end::Brand */}
      <Modal show={showConfirmationPopUp} onHide={() => changeShowConfirmationPopUpStatus(false)} centered className="standard-modal">
        <Modal.Header>
          <Modal.Title>{confirmationPopUpConfig?.heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body-text">{confirmationPopUpConfig?.message}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              changeShowConfirmationPopUpStatus(false);
              asideMenuUtils.toggleOff();
              push("/overview");
            }}
          >
            Yes, quit
          </Button>
          <Button variant="secondary" onClick={() => changeShowConfirmationPopUpStatus(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      </>
  );
}
