import React from "react";
import { Form, InputGroup, FormControl } from "react-bootstrap";
import RichTextEditor from "react-rte";

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

import "froala-editor/js/plugins/code_view.min.js";
import "froala-editor/js/plugins/align.min.js";

import "froala-editor/js/plugins/char_counter.min.js";
import "froala-editor/css/plugins/char_counter.min.css";

import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/css/plugins/colors.min.css";

import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/link.min.js";

import "froala-editor/js/plugins/quick_insert.min.js";
import "froala-editor/css/plugins/quick_insert.min.css";

import "froala-editor/js/plugins/draggable.min.js";

import "froala-editor/js/plugins/url.min.js";

import "froala-editor/js/third_party/embedly.min.js";
import "froala-editor/css/third_party/embedly.min.css";

import "froala-editor/js/plugins/table.min.js";
import "froala-editor/css/plugins/table.min.css";

import "froala-editor/js/plugins/image.min.js";
import "froala-editor/css/plugins/image.min.css";

import "froala-editor/js/plugins/video.min.js";
import "froala-editor/css/plugins/video.min.css";

import "froala-editor/js/plugins/inline_style.min.js";
import "froala-editor/js/plugins/inline_class.min.js";

import "froala-editor/js/plugins/fullscreen.min.js";
import "froala-editor/css/plugins/fullscreen.min.css";

import "froala-editor/js/plugins/help.min.js";
import "froala-editor/css/plugins/help.min.css";

import "froala-editor/js/plugins/print.min.js";
import "froala-editor/js/plugins/font_family.min.js";
import "froala-editor/js/plugins/paragraph_format.min.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/css/themes/dark.min.css";

// import 'froala-editor/js/plugins/font_awesome.min.js';
// import 'froala-editor/js/plugins/emoticons.min.js';
// import 'froala-editor/css/plugins/emoticons.min.css';

// import 'froala-editor/js/plugins/special_characters.min.js	';
// import 'froala-editor/css/plugins/special_characters.min.css';

import "froala-editor/css/plugins/code_view.min.css";

import "froala-editor/js/plugins/image_manager.min.js";
import "froala-editor/css/plugins/image_manager.min.css";

// Require Font Awesome.
// import 'font-awesome/css/font-awesome.css';

import FroalaEditor from "react-froala-wysiwyg";

interface Props {
  content: string;
  onChange: Function;
  config?: any;
  readOnly?: boolean;
  id?: "";
  required?: boolean;
  label?: string;
  name?: string;
  onBlur?: Function;
  error?: string;
}

export const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "LINK_BUTTONS", "BLOCK_TYPE_DROPDOWN"],

  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

export const Editor: React.FC<Props> = ({
  content,
  onChange,
  config,
  readOnly,
  required,
  label,
  id,
  name,
  onBlur = () => {},
  error = "",
  placeholder,
}) => {
  const [value, changeValue] = React.useState(RichTextEditor.createValueFromString(content, "html"));
  return (
    <Form.Group controlId={id} className={`mt-5 ${required ? "" : ""}`}>
      <Form.Label>
        {label}
        {required && <span className="text-danger">*</span>}
      </Form.Label>
      <RichTextEditor
        placeholder={placeholder}
        value={value}
        toolbarConfig={toolbarConfig}
        onChange={(val) => {
          onChange(val.toString("html"));
          changeValue(val);
        }}
        onBlur={() => {
          onBlur();
        }}
      />
      {error && <div className="text-danger">{error}</div>}
    </Form.Group>
  );

  return (
    <>
      <Form.Group controlId={id} className={`mt-5 ${required ? "required" : ""}`}>
        <Form.Label>
          {label}
          {required && <span className="text-danger">*</span>}
        </Form.Label>

        <FroalaEditor
          config={Object.assign(
            {},
            {
              name: name,
              // events: {
              //   initialized: function (e: any, editor: any) {
              //     if (readOnly) {
              //       // @ts-ignore
              //       this.edit.off();
              //     }
              //   },
              //   // blur: function () {
              //   //   onBlur();
              //   //   // @ts-ignore
              //   //   // if (this.codeView.isActive()) {
              //   //   //   // @ts-ignore

              //   //   //   this.codeView.toggle();
              //   //   // }
              //   // },
              // },
              width: 600,
              toolbarButtons: ["bold", "italic", "underline", "strikeThrough", "insertLink"],
              // htmlAllowedTags: ['html', 'div', 'h1', 'a', 'img'],
              // quickInsertButtons: ["image", "table", "ol", "ul", "myButton"],
              placeholderText: "Please enter some text",
              charCounterMax: "280",
              charCounterCount: false,
              heightMax: 200,
              height: 200,
              dragInline: true,
              fontFamilySelection: true,
              fontSizeSelection: true,
              attribution: false,
              toolbarSticky: true,
              htmlUntouched: false,
              pluginsEnabled: ["link"],
              tableColors: [
                "#61BD6D",
                "#1ABC9C",
                "#54ACD2",
                "#2C82C9",
                "#9365B8",
                "#475577",
                "#CCCCCC",
                "#41A85F",
                "#00A885",
                "#3D8EB9",
                "#2969B0",
                "#553982",
                "#28324E",
                "#000000",
                "#F7DA64",
                "#FBA026",
                "#EB6B56",
                "#E25041",
                "#A38F84",
                "#EFEFEF",
                "#FFFFFF",
                "#FAC51C",
                "#F37934",
                "#D14841",
                "#B8312F",
                "#7C706B",
                "#D1D5D8",
                "REMOVE",
              ],
              // inlineClasses: {
              //   'fr-class-code': 'Code',
              //   'fr-class-highlighted': 'Highlighted',
              //   'fr-class-transparency': 'Transparent',
              // },
              // inlineStyles: {
              //   "Big Red": "font-size: 20px; color: red;",
              //   "Small Blue": "font-size: 14px; color: blue;",
              //   Code: "padding: 5px; background: gray",
              // },
              // theme: "dark",
              // embedlyKey: 'XXXXXXXXXXXXXXXXXXXXXX'
              colorsBackground: ["#41A85F", "#00A885", "#3D8EB9", "#2969B0", "#553982", "#28324E", "#000000"],
              codeMirror: true,
              codeMirrorOptions: {
                indentWithTabs: true,
                lineNumbers: true,
                lineWrapping: true,
                mode: "text/html",
                tabMode: "indent",
                tabSize: 2,
              },
            },
            config ? config : {}
          )}
          model={content}
          onModelChange={onChange}
        />

        {content?.length <= 10 && <div className="text-danger">{error}</div>}
      </Form.Group>
    </>
  );
};

export default Editor;
