import {combineReducers} from 'redux';
import authReducer from './auth/auth.reducer';
import Pool from './pool/pool.reducer';
import FileData from './fileUpload/fileUpload.reducer';
import appDataReducer from './appStore/appStore.reducer';
import Campaign from './campaign/campaign.reducer'
import CampaignStepsReducer from './campaign/campaign.steps.reducer'
import BreadcrumbReducer from './breadcrumbManager/breadcrumb.reducer'
import HeaderData from './header/header.reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  Pool,
  FileData,
  appData: appDataReducer,
  Campaign,
	CampaignStepsReducer,
  breadcrumb:BreadcrumbReducer,
  header:HeaderData
});

// export type IRootState = ReturnType<typeof rootReducer>;
export type IRootState = any


export default rootReducer;
