import React from "react";
import { Menu, MenuItem } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { actionCompContainerProps } from "../../constants/types";

const ActionCompContainer: React.FC<actionCompContainerProps> = (props) => {
  const { id, trigger, onClick, onClose, show = true } = props;
  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  const handleClick = (event: React.FormEvent) => {
    if (onClick) {
      onClick();
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setAnchorEl(null);
  };

  return (
    <>
      {show ? (
        <div>
          <div className="trigger-button">{trigger ? <a onClick={handleClick}>{trigger}</a> : <MoreVertIcon onClick={handleClick} />}</div>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            className="table-dropdown-menu-wrapper">
            {props?.options
              ?.filter((item) => item)
              .map((item: { label: string; onClick: Function; disabled?: boolean }, index: number) => (
                <MenuItem
                  key={`MuiButtonIconContainer-${index}-${id}`}
                  className="MuiButtonIconContainer table-dropdown-item-wrapper"
                  onClick={() => {
                    item.onClick(String(id));
                    handleClose();
                  }}
                  disabled={item.disabled || false}>
                  {item.label}
                </MenuItem>
              ))}
          </Menu>
        </div>
      ) : null}
    </>
  );
};

export default ActionCompContainer;
