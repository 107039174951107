import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import OtpInput from "react-otp-input";
import SignUpBanner from "../components/Banner";
import "../_styles/index.scss";
import { authService } from "../../../service";
import { setUser } from "../../../store/auth/auth.action";

const RESEND_COUNTER = 30;
const AuthPage: React.FC = () => {
  const data = useSelector(({ auth }) => auth.user, shallowEqual);
  const [status, setStatus] = useState({});
  const [otp, changeOTP] = React.useState("");
  const [otpError, changeOtpError] = useState("");
  const [otpVerified, changeOTPVerified] = useState<boolean>(false);
  const [resendTimerCount, changeResendCount] = useState<number>(RESEND_COUNTER);

  const dispatch = useDispatch();

  const verifyCode = async (e) => {
    e.preventDefault();
    try {
      if (otp.length === 6) {
        setStatus(
          Object.assign({}, status, {
            isRequesting: true,
            error: undefined,
          })
        );
        let formDataObj: any = {
          verificationCode: otp,
          email: data.email,
          deviceId: String(Date.now()),
          device: "Dashboard",
          build: "1.0.0",
        };
        const result = await authService.verifyCode(formDataObj);
        changeOTPVerified(true);
        setTimeout(() => {
          // changeStep(4);
          dispatch(setUser({ status: 2 }));
        }, 3000);

        const {
          data: {
            data: { authToken, hasCompany },
          },
        } = result;

        // setToken(authToken);
        // HTTPClientV1.saveHeader({
        //   key: "Authorization",
        //   value: `Bearer ${authToken}`,
        // });
        // HTTPClientV3.saveHeader({
        //   key: "Authorization",
        //   value: `Bearer ${authToken}`,
        // });
        setStatus(
          Object.assign({}, status, {
            isRequesting: false,
            success: true,
            error: undefined,
          })
        );
      }
    } catch (error) {
      const message = error?.data?.status?.message;
      changeOtpError(message || "Invalid OTP");
      setStatus(
        Object.assign({}, status, {
          isRequesting: false,
          error: message || "Something went wrong !! Try again latter.",
        })
      );
    }
  };

  return (
    <section className="registration-form">
      <div className="container-fluid">
        <Row className="registration-form-container">
          <Col md={7} className="reg-form-wrapper">
            <div className="reg-form-inner">
              <div className="form-logo">
                <img src="/media/onboarding/logo.png" />
                <span>UniSuite</span>
              </div>
            </div>
            <div className="reg-form-inner">
              <div className="form-steps-heading">
                <div className="form-heading">
                  <div className="form-title">Verify Email Address</div>
                  <div className="form-subtext">
                    Confirm your email address to continue your onboarding. Please check your spam folder if you do not recieve it right away.
                  </div>
                </div>
              </div>
              <form className="form-box-container" onSubmit={verifyCode}>
                <div className="form-group">
                  <label className="form-label">
                    Enter 6-digit OTP <span className="required-input-field">*</span>
                  </label>
                  <div className="otp-container">
                    <OtpInput
                      value={otp}
                      className="otp-input-list"
                      numInputs={6}
                      placeholder=""
                      inputStyle={`otp-input ${otpError ? "invalid-otp" : ""} ${otpVerified ? "verify-otp" : ""}`}
                      focusStyle="otp-input-focused"
                      isInputNum={true}
                      onChange={(value) => {
                        changeOtpError("");
                        changeOTPVerified(false);
                        changeOTP(value);
                      }}
                    />
                    {otpVerified && <img className="otp-verified" src="/media/onboarding/otp-verified.svg" />}
                  </div>
                  {otpError && <div className="text-danger">{otpError}</div>}
                  <div className="invalid-feedback">Incorrect OTP</div>
                  <div className="resend-otp-container">
                    {resendTimerCount > 0 && !otpVerified && <span className="resend-wait">Resend in {resendTimerCount}s</span>}
                    {resendTimerCount <= 0 && !otpVerified && (
                      <span className="resend-active" onClick={resendOtp}>
                        Resend OTP
                      </span>
                    )}
                  </div>
                </div>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={status.isRequesting || otp.length < 6 || otpVerified || otpError.length > 0}
                  className="btn-new">
                  Next
                </Button>
              </form>
            </div>
          </Col>
          <Col md={5} className="reg-form-banner">
            <SignUpBanner />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default AuthPage;
