import React from "react";
import Select from "react-select";
import { FormGroup, Label, Input } from "reactstrap";
import { surveyService } from "../../../service/api/survey.service";
import SurveyWrapper from "../components/SurveyWrapper";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";

// import { ANSWER_TYPES } from "../utils";

const QuestionWise = ({
    // answerTypes,
    ...props
  }) => {
    const answerTypes = {
      // SHORT_ANSWER: "Short Answer",
      // PARAGRAPH_ANSWER: "Paragraph Answer",
      // MULTIPLE_CHOICE: "Multiple Choice",
      // LINEAR_SCALE: "Linear Scale",


      SHORT: '1',
      LONG: '2',
      SLIDER: '3',
      CHECKBOX: '4',
      DROPDOWN: '5',
      RADIO: '6'
    };
  const [selectedQuestion, changeQuestion] = React.useState(null);
  const [questions, setQuestions] = React.useState([
    {id: 358, question: "enter your name", description: "", qOrder: 0},
    {id: 359, question: "describe about yourself", description: "", qOrder: 1},
    {id: 360, question: "Favourite chocolate", description: "", qOrder: 2},
    {id: 361, question: "Gender", description: "", qOrder: 4},
    {id: 362, question: "hobbies", description: "", qOrder: 5},
    {id: 363, question: "Rate us", description: "", qOrder: 6}
  ]);
  const [answers, setAnswers] = React.useState({
    answers: [
      {
        answer: "Kitkat, Dairy Milk",
        date: "Oct 8, 08:21 PM"
      },
      {
        answer: "Kitkat, Dairy Milk",
        date: "Oct 8, 08:21 PM"
      }
    ],
    optionType: 4,
    totalResponses: 1,
    otherAnswers: []
  });
  const { id: surveyId } = useParams();
  const [loading, setLoading] = React.useState(true);

  const getQuestionsList = async () => {
    setLoading(true);
    try {
      let result = await surveyService.getSurveyQuestions(surveyId);
      setQuestions(result.data.data.docs);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const fetchAnswersOfQuestion = async (questionId) => {
    setLoading(true);

    try {
      let result = await surveyService.getQuestionAnswers(
        surveyId,
        questionId,
        1
      );
      setAnswers({
        answers: result.data.data.answers?.docs,
        optionType: result.data.data.optionType,
        totalResponses: result.data.data.totalResponse,
        otherAnswers: result?.data?.data?.otherAnswers?.docs || [],
      });
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const renderAnswer = () => {
    if (!answers) {
      return (
        <p className="text-center text-muted font-italic">
          There are no answers to show
        </p>
      );
    } else if (answers?.answers && answers?.answers?.length === 0) {
      return <></>;
    } else {
      if (
        answers.optionType === answerTypes.SHORT ||
        answers.optionType === answerTypes.LONG
      ) {
        return (
          <>
            <p>
              <strong>{answers.totalResponses} responses</strong>{" "}
            </p>
            {answers.answers.map((item, index) => {
              return (
                <div className="question-responses-item" key={index}>
                  <div className="response-text">{item.answer}</div>
                  <div className="response-timestamp">{item.date}</div>
                </div>
              );
            })}
          </>
        );
      }

      if (
        answers.optionType === answerTypes.CHECKBOX ||
        answers.optionType === answerTypes.RADIO ||
        answers.optionType === answerTypes.DROPDOWN
      ) {
        let mainAnswers = answers?.answers;
        let otherAnswers =
          answers?.otherAnswers?.map((item) => ({
            ...item,
            answer: item.other,
          })) || [];
        let allAnswers = [...mainAnswers, ...otherAnswers];
        return (
          <>
            <p>
              <strong>{answers.totalResponses} responses</strong>{" "}
            </p>

            {allAnswers.map((item, index) => {
              return (
                <div className="question-responses-item" key={index}>
                  <div className="response-text">{item.answer || "-"}</div>
                  <div className="response-timestamp">{item.date}</div>
                </div>
              );
            })}
          </>
        );
      }

      if (answers.optionType === answerTypes.SLIDER) {
        return (
          <>
            <p>
              <strong>{answers.totalResponses} responses</strong>{" "}
            </p>

            {answers?.answers?.map((item, index) => {
              return (
                <div className="question-responses-item" key={index}>
                  <div className="response-text">{item.value || "-"}</div>
                  <div className="response-timestamp">{item.date}</div>
                </div>
              );
            })}
          </>
        );
      }
    }
  };

  React.useEffect(() => {
    if (selectedQuestion) {
      fetchAnswersOfQuestion(selectedQuestion.id);
    }
  }, [selectedQuestion]);

  React.useEffect(() => {
    getQuestionsList();
  }, []);

  return (
    <SurveyWrapper {...props}>
      <div className="questionwiese-container">
        <div className="questionwise-header">
          <div className="header-row-1 d-flex flex-column justify-space-between">
            <div className="font-20 font-weight-bold">Questionwise</div>
          </div>
          <div className="select-question-section py-5">
            <FormGroup>
              <Label className="ufyndLabel">Select question</Label>
              <Select
                options={questions}
                className={"w-50"}
                getOptionLabel={(option) => option.question}
                getOptionValue={(option) => option.id}
                aria
                name="questionNumber"
                value={selectedQuestion}
                placeholder="Select a question"
                onChange={(e) => changeQuestion(e)}
              />
            </FormGroup>
          </div>
        </div>
        <div className="responses">
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div className="question-responses">{renderAnswer()}</div>
          )}
        </div>
      </div>
    </SurveyWrapper>
  );
};

export default QuestionWise;
