import React from "react";
import { Form, Col, Row } from "react-bootstrap";
import { InputDataProps } from "./interface";
import "../styles/ButtonRadioBox.module.scss";
import { Divider } from "@material-ui/core";
import Tooltip from "../../Tooltip";

const InputRadio: React.FC<InputDataProps> = ({
  id,
  name,
  size,
  label,
  sublabel,
  hint,
  required,
  error,
  value,
  handleRadioChange,
  disabled,
  options,
  className,
  formGroupClassName,
  top,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let data = { name, value };
    handleRadioChange(data);
  };

  return (
    <Form.Group controlId={id} className={`${top?.length > 0 ? "top-" + `${top}` : "top-28"} ${required ? "required" : ""} ${formGroupClassName}`}>
      {label ? (
        <Form.Label className="d-flex align-items-center">
          {label}
          {required && <span className="required-input-field">*</span>}

          {hint && (
            <Tooltip
              label={<div className="bg-icon bg-help-circle bg-icon16 icon-grey icon-left12" />}
              variant="primary"
              description={<span>{hint}</span>}
              placement="top"
            />
          )}
        </Form.Label>
      ) : null}
      {sublabel ? <div className="sub-label">{sublabel}</div> : null}

      <div className={`radio-height ${className}`}>
        {options.map((option, index) => {
          return (
            <div className={`d-flex radio-edit ${size} right-20`} key={`${name}-${option.label}-${index}`}>
              <span className={`input-checkbox ${value == option.value ? "input-checked" : ""}`}></span>
              <Form.Check
                className="position-relative check-box-label"
                key={index}
                name={name}
                type={"radio"}
                inline
                label={option.label}
                id={`inline-${id}-${index}-1`}
                onChange={handleChange}
                disabled={disabled ? true : false}
                value={option.value}
                checked={value == option.value}
                top={top}
              />
            </div>
          );
        })}
      </div>
      {error && <div className="error-text">{error}</div>}
    </Form.Group>
  );
};

export default InputRadio;
