/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router";
// import Home from "@material-ui/icons/Home";
import { NavLink, useHistory } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
// import {appMenu} from '../../store/modules_mallsStore/mm.select';
// import menuConfig from "../../router/MenuConfig";
// import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
// import TuneIcon from "@material-ui/icons/Tune";
// import RecentActorsIcon from "@material-ui/icons/RecentActors";
// import PeopleIcon from "@material-ui/icons/People";
// import SegmentIcon from '@mui/icons-material/Segment';
// import CampaignIcon from "@mui/icons-material/Campaign";
// import WebIcon from "@mui/icons-material/Web";
// import SettingsIcon from "@material-ui/icons/Settings";
import KTLayoutAsideToggle from "../../../_metronic/_assets/js/layout/base/aside-toggle";
// import AnalyticsIcon from "./analytics-icon";
// import LoyaltyIcon from "./loyalty-icon";
import { useSelector } from "react-redux";

// import { addMenuConfig } from '../../store/menuSearch/menu.action';
import { confirmationPopUpInfo } from "../../utils/CreateEditPageUtils";

// const filterMenuConfigList = (defautList, roleBasedList) => {
//   let updatedMenuConfigState = [];

//   let menu = roleBasedList;
//   let menuConfigState = defautList;

//   Object.keys(roleBasedList).forEach((it) => {
//     menuConfigState.items.forEach((defaultMenuItem, index) => {
//       if (defaultMenuItem?.key === it) {
//         let newSubMenu = menu[it]?.subMenu;
//         let newSubMenuList = [];

//         Object.keys(newSubMenu).forEach((newSubMenuItem) => {
//           let found = defaultMenuItem?.submenu?.find((item) => item.key == newSubMenuItem);

//           let canRead = newSubMenu[newSubMenuItem] == 1 || newSubMenu[newSubMenuItem] == 2;
//           let canWrite = newSubMenu[newSubMenuItem] == 2;

//           if (found) {
//             newSubMenuList.push({ ...found, canRead, canWrite });
//           }
//         });
//         updatedMenuConfigState.push({ ...defaultMenuItem, submenu: newSubMenuList });
//       }
//     });
//   });

//   console.log(updatedMenuConfigState);

//   return updatedMenuConfigState;
// };

const MenuList = (props) => {
  const asideMenuUtils = KTLayoutAsideToggle.init("kt_aside_toggle");
  const { layoutProps, appMenu, getMenuItemActive } = props;
  const [showConfirmationPopUp, changeShowConfirmationPopUpStatus] = React.useState(false);
  const [redirectionUrl, changeRedirectionUrl] = React.useState(false);
  const [collapseStatus, changeCollapseStatus] = React.useState(false);
  const location = useLocation();
  const { push } = useHistory();
  const selectedCreationModule = useSelector((state) => state.appData.selectedCreationModule);
  const confirmationPopUpConfig = confirmationPopUpInfo[selectedCreationModule];

  let menuConfigState = {
    self: {},
    items: [
      {
        bullet: "dot",
        icon: "bg-icon bg-icon16 bg-user icon-grey",
        activeIcon: "bg-icon bg-icon16 bg-user-filled icon-grey",
        key: "contacts",
        root: true,
        page: "contacts/all-contacts",
        submenu: [
          {
            title: "All Contacts",
            key: "allContacts",
            page: "contacts/all-contacts",
            bullet: "dot",
          },
          {
            title: "Sourcepools",
            key: "sourcepools",
            page: "contacts/sourcepools",
            bullet: "dot",
          },
          {
            title: "Segmentation",
            key: "segmentation",
            page: "contacts/segmentations",
            bullet: "dot",
          },
          {
            title: "Imports",
            key: "imports",
            page: "contacts/imports",
            bullet: "dot",
          },
        ],
        title: "Contacts",
      },
      {
        bullet: "dot",
        icon: "bg-icon bg-icon16 bg-message-circle icon-grey",
        activeIcon: "bg-icon bg-icon16 bg-message-circle-filled icon-grey",
        key: "campaign",
        root: true,
        page: "campaigns",
        submenu: [
          {
            title: "Email ",
            key: "email",
            page: "campaigns/email",
            bullet: "dot",
          },
          {
            title: "SMS",
            key: "sms",
            page: "campaigns/sms",
            bullet: "dot",
          },
          {
            title: "WhatsApp",
            key: "whatsapp", // <--- Change is made on this line whatsApp to whatsapp
            page: "campaigns/whatsapp",
            bullet: "dot",
          },
        ],
        title: "Campaign",
      },
      {
        bullet: "dot",
        icon: "bg-icon bg-icon16 bg-star icon-grey",
        activeIcon: "bg-icon bg-icon16 bg-star-filled icon-grey",
        key: "loyalty",
        root: true,
        page: "loyalty/coupons",
        submenu: [
          {
            title: "Coupons",
            key: "coupons",
            page: "loyalty/coupons/",
            bullet: "dot",
          },
          {
            title: "Landing Page",
            key: "landingPage",
            page: "loyalty/landing-page/",
            bullet: "dot",
          },
        ],
        title: "Loyalty",
      },
      {
        bullet: "dot",
        icon: "bg-icon bg-icon16 bg-game-controller icon-grey",
        activeIcon: "bg-icon bg-icon16 bg-game-controller-filled icon-grey",
        key: "microGames",
        root: true,
        page: "micro-games",
        submenu: [
          {
            title: "Survey",
            key: "survey",
            page: "micro-games/survey",
            bullet: "dot",
          },
          {
            title: "Quiz",
            key: "quiz",
            page: "micro-games/quiz",
            bullet: "dot",
          },
          {
            title: "Spin The Wheel",
            key: "spinWheel",
            page: "micro-games/spin-the-wheel",
            bullet: "dot",
          },
          {
            title: "Scratch Card",
            key: "scratchCard",
            page: "micro-games/scratch-card",
            bullet: "dot",
          },
        ],
        title: "Micro Games",
      },
      {
        bullet: "dot",
        icon: "bg-icon bg-icon16 bg-bar-chart-2 icon-grey",
        activeIcon: "bg-icon bg-icon16 bg-bar-chart-filled icon-grey",
        key: "analytics",
        root: true,
        page: "analytics/audience",
        title: "Analytics",
        submenu: [
          {
            title: "Audience",
            key: "audience",
            page: "analytics/audience",
            bullet: "dot",
          },
          {
            title: "Acquisition",
            key: "acquisition",
            page: "analytics/acquisition/",
            bullet: "dot",
          },
          {
            title: "Behaviour",
            key: "behaviour",
            page: "analytics/behaviour/",
            bullet: "dot",
          },
        ],
      },
    ],
  };

  let settingsConfig = {
    bullet: "dot",
    icon: "bg-icon bg-icon16 bg-setting icon-grey",
    activeIcon: "bg-icon bg-icon16 bg-setting-filled icon-grey",
    key: "settings",
    root: true,
    page: "settings",
    submenu: [
      {
        title: "Profile",
        key: "profile",
        page: "settings/profile-setting",
        bullet: "dot",
      },
      {
        title: "Company",
        key: "companyManagement",
        page: "settings/company-information",
        bullet: "dot",
      },
      {
        title: "Channel",
        key: "channelManagement",
        page: "settings/channel-setting",
        bullet: "dot",
      },
      {
        title: "Team Management",
        key: "teamManagement",
        page: "settings/team-and-roles",
        bullet: "dot",
      },
    ],
    title: "Settings",
  };

  // menuConfigState = {
  //   ...menuConfigState,
  //   items: filterMenuConfigList(menuConfigState, menu),
  // };

  // console.log(appMenu);
  const openDropdown = (key) => {
    let urlKey = location.pathname.split("/");
    let locationKey = urlKey[1];
    let openModule = [];
    let indexKey = null;
    menuConfigState.items.map((child, index) => {
      if (child.submenu) {
        openModule = child.submenu.filter((ele) => ele.page === locationKey);
        if (openModule.length > 0) {
          indexKey = index;
          let nameKey = menuConfigState.items[indexKey].key;
          let parentClass = document.getElementsByTagName(`li`);
          if (parentClass !== null && parentClass.length > 0) {
            setTimeout(() => {
              for (let i = 0; i < 50; i++) {
                if (parentClass.item(i) !== null) {
                  if (String(parentClass.item(i).id) === String(nameKey)) {
                    // console.log(parentClass.item(i))
                    // parentClass.item(i).classList.add(`menu-item-active`);
                    // parentClass.item(i).classList.add(`menu-item-open`);
                    parentClass.item(i).className += `${true && "menu-item-active"} menu-item-open`;
                  }
                }
              }
            }, 1000);
          }
        }
      }
      return null;
    });
  };

  useEffect(() => {
    // if (selectedCreationModule == "SEGMENT" || selectedCreationModule == "CONTACT") {
    //   // if(selectedCreationModule=='SEGMENT') {
    //   hideAsideMenuList();
    //   return;
    // }
    if (selectedCreationModule.length) {
      asideMenuUtils.toggleOn();
    }
    if (selectedCreationModule.length === 0) {
      if (asideMenuUtils) {
        asideMenuUtils.toggleOff();
      }
    }
  }, [selectedCreationModule]);

  useEffect(() => {
    if (Object.keys(menuConfigState).length > 0) {
      openDropdown();
    }
  }, [menuConfigState]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Modal show={showConfirmationPopUp} onHide={() => changeShowConfirmationPopUpStatus(false)} centered className="standard-modal">
        <Modal.Header>
          <Modal.Title>{confirmationPopUpConfig?.heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body-text">{confirmationPopUpConfig?.message}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              changeShowConfirmationPopUpStatus(false);
              asideMenuUtils.toggleOff();
              push(redirectionUrl || "/overview");
            }}>
            Yes, quit
          </Button>
          <Button variant="secondary" onClick={() => changeShowConfirmationPopUpStatus(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {appMenu ? (
        <>
          <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/* <li className={`menu-item ${getMenuItemActive(`dashboard`, false)}`} aria-haspopup="true">
              <div
                className="menu-link"
                onClick={() => {
                  if (selectedCreationModule.length === 0) {
                    // check if menu us collapsed
                    if (collapseStatus) {
                      asideMenuUtils.toggleOff();
                      // return;
                    }
                    push("/overview");
                  } else {
                    changeShowConfirmationPopUpStatus(true);
                    changeRedirectionUrl("/overview");
                  }
                }}
              >
                <span className="svg-icon menu-icon">
                  <div className="default-icon bg-icon bg-icon16 bg-home icon-grey" />
                  <div className="active-icon bg-icon bg-icon16 bg-home-filled icon-grey" />
                </span>
                <span className="menu-text">Dashboard</span>
              </div>
            </li>
            <hr /> */}
            {
              // <li
              //   key={"Dashboard"}
              //   className={`menu-item ${getMenuItemActive(`/overview`, false)}`}
              //   aria-haspopup="true"
              // >
              // <NavLink className="menu-link" to="/overview">
              //   <span className="svg-icon menu-icon">
              //     <Home />
              //   </span>
              //   <span className="menu-text">Dashboard</span>
              // </NavLink>
              // </li>
            }

            {menuConfigState.items.map((child, index) => {
              return (
                <React.Fragment key={`menu-item-${index}`}>
                  {child.title && appMenu[child.key] && (
                    <>
                      {
                        child.submenu.length > 0 && selectedCreationModule.length === 0 && (
                          // {/* Bootstrap */}
                          // {/*begin::1 Level*/}
                          <li id={child.key} className={`menu-item menu-item-submenu`} aria-haspopup="true" data-menu-toggle="hover">
                            <NavLink className="menu-link menu-toggle" to="/dashboard/">
                              <span
                                className="svg-icon menu-icon"
                                onClick={() => {
                                  if (collapseStatus) {
                                    asideMenuUtils.toggleOff();
                                    return;
                                  }
                                }}>
                                <div className={`default-icon ${child.icon}`} />
                                <div className={`active-icon ${child.activeIcon}`} />
                              </span>
                              <span className="menu-text">{child.title}</span>
                              <i className="menu-arrow" />
                            </NavLink>
                            <div className="menu-submenu ">
                              <ul className="menu-subnav">
                                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                                  <span className="menu-link">
                                    <span className="menu-text">{child.title}</span>
                                  </span>
                                </li>
                                {/*begin::2 Level*/}
                                {child.submenu.map((item, index) => {
                                  return (
                                    <>
                                      {item.title && appMenu[child.key].subMenu[item.key] ? (
                                        <li className={`menu-item ${getMenuItemActive(`/${item.page}`)}`} aria-haspopup="true">
                                          <NavLink className="menu-link" to={`/${item.page}`}>
                                            <i className="menu-bullet menu-bullet-dot">
                                              <span />
                                            </i>

                                            <span className="menu-text">{item.title}</span>
                                          </NavLink>
                                        </li>
                                      ) : null}
                                    </>
                                  );
                                })}
                                {/*end::2 Level*/}
                              </ul>
                            </div>
                          </li>
                        )
                        // {/*end::1 Level*/}
                      }
                      {
                        (child.submenu.length === 0 || selectedCreationModule.length !== 0) && (
                          // {/*begin::2 Level*/}
                          <li className={`menu-item ${getMenuItemActive(`${child.page}`, false)}`} aria-haspopup="true">
                            <div
                              className="menu-link"
                              to={`/${child.page}`}
                              onClick={() => {
                                if (selectedCreationModule.length === 0) {
                                  if (collapseStatus) {
                                    asideMenuUtils.toggleOff();
                                    return;
                                  }
                                  push(`/${child.page}`);
                                } else {
                                  changeRedirectionUrl(`/${child.page}`);
                                  changeShowConfirmationPopUpStatus(true);
                                }
                              }}>
                              <span className="svg-icon menu-icon">
                                {/* <span className="svg-icon menu-icon"> */}
                                <div className={`default-icon ${child.icon}`} />
                                <div className={`active-icon ${child.activeIcon}`} />
                                {/* </span> */}
                              </span>
                              <span className="menu-text">{child.title}</span>
                            </div>
                          </li>
                        )
                        // {/*end::2 Level*/}
                      }
                    </>
                  )}
                </React.Fragment>
              );
            })}
            <hr />
            {appMenu?.template ? (
              <li className={`menu-item ${getMenuItemActive(`templates`, false)}`} aria-haspopup="true">
                <div
                  className="menu-link"
                  to={`/templates`}
                  onClick={() => {
                    if (selectedCreationModule.length === 0) {
                      if (collapseStatus) {
                        asideMenuUtils.toggleOff();
                        // return;
                      }
                      push(`/templates`);
                    } else {
                      changeRedirectionUrl(`/templates`);
                      changeShowConfirmationPopUpStatus(true);
                    }
                  }}>
                  <span className="svg-icon menu-icon">
                    {/* <span className="svg-icon menu-icon"> */}
                    <div className="default-icon bg-icon bg-icon16 bg-layout icon-grey" />
                    <div className="active-icon bg-icon bg-icon16 bg-layout-filled icon-grey" />
                    {/* </span> */}
                  </span>
                  <span className="menu-text">Templates</span>
                </div>
              </li>
            ) : null}
            {/* <li className={`menu-item ${getMenuItemActive(`settings`, false)}`} aria-haspopup="true">
              <div
                className="menu-link"
                to={`/settings`}
                onClick={() => {
                  if (selectedCreationModule.length === 0) {
                    if (collapseStatus) {
                      asideMenuUtils.toggleOff();
                      // return;
                    }
                    push(`/settings`);
                  } else {
                    changeRedirectionUrl(`/settings`);
                    changeShowConfirmationPopUpStatus(true);
                  }
                }}
              >
                <span className="svg-icon menu-icon">
                  <div className="default-icon bg-icon bg-icon16 bg-setting icon-grey" />
                  <div className="active-icon bg-icon bg-icon16 bg-setting-filled icon-grey" />
                </span>
                <span className="menu-text">Settings</span>
              </div>
            </li> */}

            <React.Fragment key={`menu-item`}>
              {settingsConfig.title && appMenu[settingsConfig.key] && (
                <>
                  {
                    // settingsConfig.submenu.length > 0 && selectedCreationModule.length === 0 && (
                    settingsConfig.submenu.length > 0 && selectedCreationModule.length === 0 && (
                      <li id={settingsConfig.key} className={`menu-item menu-item-submenu`} aria-haspopup="true" data-menu-toggle="hover">
                        <NavLink className="menu-link menu-toggle" to="/dashboard/">
                          <span
                            className="svg-icon menu-icon"
                            onClick={() => {
                              if (collapseStatus) {
                                asideMenuUtils.toggleOff();
                                return;
                              }
                            }}>
                            <div className={`default-icon ${settingsConfig.icon}`} />
                            <div className={`active-icon ${settingsConfig.activeIcon}`} />
                          </span>
                          <span className="menu-text">{settingsConfig.title}</span>
                          <i className="menu-arrow" />
                        </NavLink>
                        <div className="menu-submenu ">
                          <ul className="menu-subnav">
                            <li className="menu-item  menu-item-parent" aria-haspopup="true">
                              <span className="menu-link">
                                <span className="menu-text">{settingsConfig.title}</span>
                              </span>
                            </li>
                            {/*begin::2 Level*/}
                            {settingsConfig.submenu.map((item, index) => {
                              return (
                                <>
                                  {item.title && appMenu[settingsConfig.key].subMenu[item.key] ? (
                                    <li className={`menu-item ${getMenuItemActive(`/${item.page}`)}`} aria-haspopup="true">
                                      <NavLink className="menu-link" to={`/${item.page}`}>
                                        <i className="menu-bullet menu-bullet-dot">
                                          <span />
                                        </i>

                                        <span className="menu-text">{item.title}</span>
                                      </NavLink>
                                    </li>
                                  ) : null}
                                </>
                              );
                            })}
                            {/*end::2 Level*/}
                          </ul>
                        </div>
                      </li>
                    )
                    // {/*end::1 Level*/}
                  }
                  {
                    (settingsConfig.submenu.length === 0 || selectedCreationModule.length !== 0) && (
                      // {/*begin::2 Level*/}
                      <li className={`menu-item ${getMenuItemActive(`${settingsConfig.page}`, false)}`} aria-haspopup="true">
                        <div
                          className="menu-link"
                          to={`/${settingsConfig.page}`}
                          onClick={() => {
                            if (selectedCreationModule.length === 0) {
                              if (collapseStatus) {
                                asideMenuUtils.toggleOff();
                                return;
                              }
                              push(`/${settingsConfig.page}`);
                            } else {
                              changeRedirectionUrl(`/${settingsConfig.page}`);
                              changeShowConfirmationPopUpStatus(true);
                            }
                          }}>
                          <span className="svg-icon menu-icon">
                            {/* <span className="svg-icon menu-icon"> */}
                            <div className={`default-icon ${settingsConfig.icon}`} />
                            <div className={`active-icon ${settingsConfig.activeIcon}`} />
                            {/* </span> */}
                          </span>
                          <span className="menu-text">{settingsConfig.title}</span>
                        </div>
                      </li>
                    )
                    // {/*end::2 Level*/}
                  }
                </>
              )}
            </React.Fragment>
            {/* <li className={`menu-item ${getMenuItemActive(`help`, false)}`} aria-haspopup="true">
              <div
                className="menu-link"
                to={`/help`}
                onClick={() => {
                  if (selectedCreationModule.length === 0) {
                    if (collapseStatus) {
                      asideMenuUtils.toggleOff();
                      // return;
                    }
                    push(`/help`);
                  } else {
                    changeRedirectionUrl(`/help`);
                    changeShowConfirmationPopUpStatus(true);
                  }
                }}>
                <span className="svg-icon menu-icon">
                  <div className="default-icon bg-icon bg-icon16 bg-help-circle icon-grey" />
                  <div className="active-icon bg-icon bg-icon16 bg-help-circle-filled icon-grey" />
                </span>
                <span className="menu-text">Help</span>
              </div>
            </li> */}
            <hr />
            <div id="kt_aside_toggle"></div>
            {
              <li
                className={`menu-item collapse`}
                aria-haspopup="true"
                onClick={() => {
                  let _newCollapseStatus = false;
                  if (asideMenuUtils.getState() == "on") {
                    asideMenuUtils.toggleOff();
                    _newCollapseStatus = false;
                  } else {
                    asideMenuUtils.toggleOn();
                    _newCollapseStatus = true;
                  }
                  changeCollapseStatus(_newCollapseStatus);
                }}>
                <div className="menu-link">
                  <span className="svg-icon menu-icon">
                    {/* <span className="svg-icon menu-icon"> */}
                    <div className="bg-icon bg-icon16 bg-chevrons-left icon-grey" />
                    {/* </span> */}
                  </span>
                  <span className="menu-text">Collapse</span>
                </div>
              </li>
            }
          </ul>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = (props) => {
  const menuObj = props.appData?.globalConstants?.menu;

  return {
    appMenu: {
      ...menuObj,
      // template: 0,
      // contacts: {
      //   subMenu: {
      //     allContacts: 2,
      //     // sourcepools: 2,
      //     segmentation: 2,
      //     imports: 2,
      //   },
      // },
      // campaign: { subMenu: { sms: 2, email: 2, whatsapp: 2 } },
      // loyalty: { subMenu: { coupons: 2, landingPage: 2 } },
      // microGames: {
      //   subMenu: { survey: 2, quiz: 2, spinWheel: 2, scratchCard: 2 },
      // },
      // analytics: { subMenu: { audience: 2, acquisition: 2, behaviour: 2 } },
    },
  };
};

export default connect(mapStateToProps)(MenuList);
