import React from 'react';
import PageMenuItem from "../../../components/pageMenuItem";
import Tabs from "../../../components/Tabs";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { surveyService } from "../../../service/api/survey.service";

const SurveyWrapper = (props) => {
  const {location, match} = props;
  const [loading, setLoading] = React.useState(true);
  const [surveyData, setSurveyData] = React.useState();
  const {
    params: { id },
  } = match;
  let currentUrl = `/survey/${id}`
  const {
    push,
    location: { pathname },
  } = useHistory();
  const { path, url } = useRouteMatch();
  const selected = {
    [`${currentUrl}/summary`]: "summary",
    [`${currentUrl}/responses`]: "responses",
    [`${currentUrl}/users`]: "users",
    [`${currentUrl}/questionwise`]: "questionwise",
  }[pathname];

  const getSurveyDetails = async () => {
    setLoading(true);
    try {
      const data = await surveyService.getSurveyById(id);
      setSurveyData(data);

    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }

  React.useEffect(() => {
    getSurveyDetails();
  }, [])

  return(
    <div className="card-header">
      <div className="card-title">
        <h3 className="card-label overview-desc">{surveyData?.name || 'loading...'}</h3>
      </div>
      <div className="interMenu">
        <Tabs
          selected={selected}
          options={[
            { label: "Summary", value: "summary" },
            { label: "All Responses", value: "responses" },
            { label: "Users", value: "users" },
            { label: "Questionwise", value: "questionwise" }
          ]}
          onChange={(e) => push(`${currentUrl}/${e}`)}
        />
      </div>
      <br />
      <div className="">
        {props.children}
      </div>
    </div>
  );
}

export default SurveyWrapper;
