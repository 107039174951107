export const customStyles = (hasError) => ({
  placeholder: (provided: any) => ({
    ...provided,
    color: "#8E90B0",
    fontFamily: "HKGroteskRegular",
    fontWeight: "normal",
  }),
  control: (provided: any, state: any) => {
    const focusedState = {
      border: "1px solid #9192FF !important",
      color: "#212038 !important",
    };
    const selectedSate = {
      color: "#212038 !important",
      border: "1px solid #212038 !important",
      fontWeight: "normal",
      fontFamily: "HKGroteskRegular",
      "> div": {
        "> div:first-child": {
          "> div": {
            lineHeight: "16px",
            "> div": {
              backgroundColor: "#212038 !important",
            },
          },
        },
      },
    };
    const disabled = {
      background: "#E9EBF2 !important",
      border: "1px solid #E9EBF2 !important",
      color: "#8E90B0 !important",
    };
    const error = {
      color: "#212038 !important",
      border: "1px solid #DD5234 !important",
    };
    const _style = hasError
      ? error
      : state.isDisabled
      ? disabled
      : state.hasValue
      ? selectedSate
      : state.isFocused
      ? focusedState
      : {
          border: "1px solid #8E90B0 !important",
        };
    return {
      ...provided,
      boxSizing: "border-box",
      background: "white",
      borderRadius: "4px",
      border: "1px solid #8E90B0",
      boxShadow: state.isSelected ? "none" : "none",
      // fontWeight: 500,
      fontSize: "16px",
      height: "36px !importnant",
      lineHeight: "20px",
      minHeight: "36px",
      maxHeight: "36px",
      ..._style,
    };
  },
  indicatorSeparator: () => ({
    display: "none",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    // padding: '12px 16px'
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 2,
    padding: "8px",
    margin: "4px 0px 0px",
    "> div": {
      padding: "0px",
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isDisabled ? "#E9EBF2 !important" : state.isSelected ? "#F1F1FF !important" : "white !important",
    color: state.isSelected ? "#212038" : "#8E90B0",
    fontWeight: "normal",
    fontFamily: "HKGroteskRegular",
    borderRadius: "4px",
    padding: "4px 8px",
    fontSize: "14px",
    cursor: "pointer",
    wordBreak: "break-word",
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
    "&:not(:first-child)": {
      margin: "8px 0px 0px",
    },
    "&:active": {
      backgroundColor: "#F1F1FF !important",
    },
    "&:hover": {
      backgroundColor: state.isDisabled ? "" : "#F1F1FF !important",
    },
    "&:focus": {
      backgroundColor: "#F1F1FF !important",
    },
  }),
});

export const outerRelationStyle = {
  container: (provided: any) => ({
    ...provided,
    maxWidth: "110px",
    minWidth: "110px",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: "transparent",
    boxShadow: state.isSelected ? "none" : "none",
    border: "none",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#000000",
    textTransform: "uppercase",
  }),
  indicatorContainer: (provided: any) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 2,
  }),
};

export const relationStyle = {
  container: (provided: any) => ({
    ...provided,
    maxWidth: "110px",
    minWidth: "110px",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: "transparent",
    boxShadow: state.isSelected ? "none" : "none",
    border: "none",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#000000",
    textTransform: "uppercase",
  }),
  indicatorContainer: (provided: any) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
};

export const userMgmtStyle = {
  container: (provided: any) => ({
    ...provided,
    maxWidth: "110px",
    minWidth: "110px",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: "transparent",
    boxShadow: state.isSelected ? "none" : "none",
    border: "none",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#175B71",
    padding: 0,
  }),
  indicatorContainer: (provided: any) => ({
    ...provided,
    padding: 0,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "#175B71",
  }),
};

export const segSelectStyles = {
  container: (provided: any) => ({
    ...provided,
    flexGrow: 1,
    maxHeight: "40px",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    background: state.isDisabled ? "rgba(153,153,153,0.1)" : "#FFFFFF",
    border: "1px solid rgba(153, 153, 153, 0.2)",
    boxSizing: "border-box",
    borderRadius: "9px",
    boxShadow: state.isSelected ? "none" : "none",
    maxHeight: "40px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: "0 9px",
  }),
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
};
