import { QUIZ_STATUS } from "../pages/Loyalty/LoyaltyGames.Quiz/_constants/_utils";
import { SPIN_THE_WHEEL_STATUS } from "../pages/Loyalty/LoyaltyGames.SpinTheWheel/_constants/_utils";
import { SCRATCH_CARD_STATUS } from "../pages/Loyalty/LoyaltyGames.ScratchCard/_constants/_utils";
import { SURVEY_THE_WHEEL_STATUS } from "../pages/Survey/utils";


const moduleKeys = {
  CONTACTS: "CONTACTS",
  CAMPAIGN: "CAMPAIGN",
  SETTINGS: "SETTINGS",
  LOYALTY: "LOYALTY",
  MICRO_GAMES: "MICRO_GAMES",
  TEMPLATES: "TEMPLATES",
  ANALYTICS: "ANALYTICS",
  ASSETS_REF: "ASSETS_REF",
  ICONS_REF: "ICONS_REF"
};

const PageConfig = {
  CONTACTS: {
    breadcrumbTitle: "Contacts",
    pageHeading: "All Contacts",
    url: "/contacts",
    key:"contacts",
    innerPages: {
      ALL_CONTACTS: {
        parentComponent: moduleKeys.CONTACTS,
        breadcrumbTitle: "All Contacts",
        url: "/contacts/all-contacts",
        key: "allContacts",
        pageHeading: "All Contacts",
        innerPages: {
          FIRST_TIME:{
            parentComponent: moduleKeys.CONTACTS,
            breadcrumbTitle: "first time",
            key: "all-contacts",
            url: "/contacts/all-contacts/first-time",
            pageHeading: "Contacts",
          },
          IMPORT_SINGLE_CONTACT_FIRST_TIME: {
            parentComponent: moduleKeys.CONTACTS,
            breadcrumbTitle: "Imports",
            key: "all-contacts",
            url: "/contacts/all-contacts/first-time/single",
            pageHeading: "Imports",
          },
          IMPORT_MULTIPLE_CONTACTS:{
            parentComponent: moduleKeys.CONTACTS,
            breadcrumbTitle: "import multiple",
            key: "all-contacts",
            url: "/contacts/all-contacts/import/multiple",
            pageHeading: "Contacts",
          },
          CONTACTS_DB_SYNC:{
            parentComponent: moduleKeys.CONTACTS,
            breadcrumbTitle: "Database sync",
            key: "all-contacts",
            url: "/contacts/all-contacts/import/db-sync",
            pageHeading: "Contacts",
          },
          IMPORT_MULTIPLE_CONTACTS_1:{
            parentComponent: moduleKeys.CONTACTS,
            breadcrumbTitle: "import multiple",
            key: "all-contacts",
            url: "/contacts/all-contacts/import/upload-type",
            pageHeading: "Contacts",
          },
          IMPORT_SINGLE_CONTACT: {
            parentComponent: moduleKeys.CONTACTS,
            breadcrumbTitle: "Imports",
            key: "all-contacts",
            url: "/contacts/all-contacts/import/single",
            pageHeading: "Imports",
          },

          VIEW_CONTACT:{
            parentComponent: moduleKeys.CONTACTS,
            breadcrumbTitle: "Imports",
            key: "all-contacts",
            url: "/contacts/all-contacts/:id/view",
            pageHeading: "Imports",
            urlWithParams: (id: number | string) =>
            `/contacts/all-contacts/${id}/view`,
            innerPages:{
              EDIT:{
                parentComponent: moduleKeys.CONTACTS,
                breadcrumbTitle: "Imports",
                key: "all-contacts",
                url: "/contacts/all-contacts/:id/view/profile/edit",
                pageHeading: "Imports",
                urlWithParams: (id: number | string) =>
                `/contacts/all-contacts/${id}/view/profile/edit`,
              },
              PROFILE:{
                parentComponent: moduleKeys.CONTACTS,
                breadcrumbTitle: "Imports",
                key: "all-contacts",
                url: "/contacts/all-contacts/:id/view/profile",
                pageHeading: "Imports",
                urlWithParams: (id: number | string) =>
                `/contacts/all-contacts/${id}/view/profile`,
              },
              METRICS:{
                parentComponent: moduleKeys.CONTACTS,
                breadcrumbTitle: "Imports",
                key: "all-contacts",
                url: "/contacts/all-contacts/:id/view/metrics",
                pageHeading: "Imports",
                urlWithParams: (id: number | string) =>
                `/contacts/all-contacts/${id}/view/metrics`,
              },
              ACTIVITY:{
                parentComponent: moduleKeys.CONTACTS,
                breadcrumbTitle: "Imports",
                key: "all-contacts",
                url: "/contacts/all-contacts/:id/view/activity",
                pageHeading: "Imports",
                urlWithParams: (id: number | string) =>
                `/contacts/all-contacts/${id}/view/activity`,
              },
              ANALYTICS:{
                parentComponent: moduleKeys.CONTACTS,
                breadcrumbTitle: "Imports",
                key: "all-contacts",
                url: "/contacts/all-contacts/:id/view/analytics",
                pageHeading: "Imports",
                urlWithParams: (id: number | string) =>
                `/contacts/all-contacts/${id}/view/analytics`,
              },
               CAMPAIGN_DATA:{
                parentComponent: moduleKeys.CONTACTS,
                breadcrumbTitle: "Imports",
                key: "all-contacts",
                url: "/contacts/all-contacts/:id/view/campagin-data",
                pageHeading: "Imports",
                urlWithParams: (id: number | string) =>
                `/contacts/all-contacts/${id}/view/campagin-data`,
              }
            }
          }
        },
      },
      IMPORTS:{
        parentComponent: moduleKeys.CONTACTS,
        breadcrumbTitle: "Imports",
        url: "/contacts/imports",
        pageHeading: "imports",
        key:"imports"
      },
      SEGMENTATIONS: {
        parentComponent: moduleKeys.CONTACTS,
        breadcrumbTitle: "Segmentations",
        url: "/contacts/segmentations",
        pageHeading: "Segmentations",
        key:"segmentation",
        innerPages: {
          CREATE_SEGMENT: {
            parentComponent: moduleKeys.CONTACTS,
            breadcrumbTitle: "Create Segment",
            url: "/contacts/segmentations/create",
            pageHeading: "Create Segment",
          },
          SEGMENT_FORM: {
            parentComponent: moduleKeys.CONTACTS,
            breadcrumbTitle: "Edit Segment",
            url: "/contacts/segmentations/:id/form",
            pageHeading: "Edit Segment",
            urlWithParams: (id: number | string) =>
              `/contacts/segmentations/${id}/form`,
          },
          SEGMENT_VIEW: {
            parentComponent: moduleKeys.CONTACTS,
            breadcrumbTitle: "View Segment",
            url: "/contacts/segmentations/:id/view",
            pageHeading: "View Segment",
            urlWithParams: (id: number | string) =>
              `/contacts/segmentations/${id}/view`,
          },
          ALL_SEGMENTS: {
            parentComponent: moduleKeys.CONTACTS,
            breadcrumbTitle: "All Segment",
            url: "/contacts/segmentations/all-segments",
            pageHeading: "Create Segment",
          },
        },
      },
    },
  },
   TEMPLATES: {
    breadcrumbTitle: "Templates",
    pageHeading: "All Templates",
    url: "/templates",
    key:"template",
    innerPages: {
      EMAIL_TEMPLATE: {
      parentComponent: moduleKeys.TEMPLATES,
      breadcrumbTitle: "Create email template",
      url: "/templates/email",
      pageHeading: "Email Template",
      innerPages:{

        CREATE_TEMPLATE:{
        parentComponent: moduleKeys.TEMPLATES,
        breadcrumbTitle: "Create email template",
        url: "/templates/email/create",
        pageHeading: "Create email template",
        },

        TEMPLATE_FORM: {
          parentComponent: moduleKeys.TEMPLATES,
          breadcrumbTitle: "Edit Template",
          url: `/templates/email/:id/design`,
          urlWithParams: (id: number | string) =>
            `/templates/email/${id}/design`,
          pageHeading: "Edit Email Template",
        },

        TEMPLATE_FORM_UNLAYER_EDITOR: {
          parentComponent: moduleKeys.TEMPLATES,
          breadcrumbTitle: "Edit Template",
          url: `/templates/email/:id/design/unlayer-editor/:id`,
          urlWithParams: (id: number | string,templateId:string) =>
            `/templates/email/${id}/design/unlayer-editor/${templateId}`,
          pageHeading: "Edit Email Template",
        },

        TEMPLATE_FORM_TEXT_EDITOR: {
          parentComponent: moduleKeys.TEMPLATES,
          breadcrumbTitle: "Edit Template",
          url: `/templates/email/:id/design/text-editor`,
          urlWithParams: (id: number | string) =>
            `/templates/email/${id}/design/text-editor`,
          pageHeading: "Edit Email Template",
        },

        TEMPLATE_FORM_HTML_EDITOR: {
          parentComponent: moduleKeys.TEMPLATES,
          breadcrumbTitle: "Edit Template",
          url: `/templates/email/:id/design/html-editor`,
          urlWithParams: (id: number | string) =>
            `/templates/email/${id}/design/html-editor`,
          pageHeading: "Edit Email Template",
        },

        EDIT_TEMPLATE: {
          parentComponent: moduleKeys.TEMPLATES,
          breadcrumbTitle: "Edit Template",
          url: `/templates/email/:id/edit`,
          urlWithParams: (id: number | string) =>
            `/templates/email/${id}/edit`,
          pageHeading: "Edit Email Template",
        },
        PREVIEW_TEMPLATE: {
          parentComponent: moduleKeys.TEMPLATES,
          breadcrumbTitle: "Edit Template",
          url: `/templates/email/:id/preview`,
          urlWithParams: (id: number | string) =>
            `/templates/email/${id}/preview`,
          pageHeading: "Edit email Template",
        },
      }
    },

    SMS_TEMPLATE: {
      parentComponent: moduleKeys.TEMPLATES,
      breadcrumbTitle: "Create Sms template",
      url: "/templates/sms",
      pageHeading: "Sms Template",
      innerPages:{

        CREATE_TEMPLATE:{
        parentComponent: moduleKeys.TEMPLATES,
        breadcrumbTitle: "Create sms template",
        url: "/templates/sms/create",
        pageHeading: "Create sms template",
        },

        TEMPLATE_FORM: {
          parentComponent: moduleKeys.TEMPLATES,
          breadcrumbTitle: "Edit Template",
          url: `/templates/sms/:id/design`,
          urlWithParams: (id: number | string) =>
            `/templates/sms/${id}/design`,
          pageHeading: "Edit sms Template",
        },

        EDIT_TEMPLATE: {
          parentComponent: moduleKeys.TEMPLATES,
          breadcrumbTitle: "Edit Template",
          url: `/templates/sms/:id/edit`,
          urlWithParams: (id: number | string) =>
            `/templates/sms/${id}/edit`,
          pageHeading: "Edit sms Template",
        },

        PREVIEW_TEMPLATE: {
          parentComponent: moduleKeys.TEMPLATES,
          breadcrumbTitle: "Edit Template",
          url: `/templates/sms/:id/preview`,
          urlWithParams: (id: number | string) =>
            `/templates/sms/${id}/preview`,
          pageHeading: "Edit sms Template",
        },
      },
    },
    WHATSAPP_TEMPLATE: {
      parentComponent: moduleKeys.TEMPLATES,
      breadcrumbTitle: "Create WhatsApp template",
      url: "/templates/whatsapp",
      pageHeading: "WhatsApp Template",
      innerPages:{

        CREATE_TEMPLATE:{
        parentComponent: moduleKeys.TEMPLATES,
        breadcrumbTitle: "Create WhatsApp template",
        url: "/templates/whatsapp/create",
        pageHeading: "Create WhatsApp template",
        },

        TEMPLATE_FORM: {
          parentComponent: moduleKeys.TEMPLATES,
          breadcrumbTitle: "Edit Template",
          url: `/templates/whatsapp/:id/edit`,
          urlWithParams: (id: number | string) =>
            `/templates/whatsapp/${id}/edit`,
          pageHeading: "Edit Whatsapp Template",
        },

        EDIT_TEMPLATE: {
          parentComponent: moduleKeys.TEMPLATES,
          breadcrumbTitle: "Edit Template",
          url: `/templates/whatsapp/:id/edit`,
          urlWithParams: (id: number | string) =>
            `/templates/whatsapp/${id}/edit`,
          pageHeading: "Edit Whatsapp Template",
        },

        PREVIEW_TEMPLATE: {
          parentComponent: moduleKeys.TEMPLATES,
          breadcrumbTitle: "Edit Template",
          url: `/templates/whatsapp/:id/preview`,
          urlWithParams: (id: number | string) =>
            `/templates/whatsapp/${id}/preview`,
          pageHeading: "Edit Whatsapp Template",
        },
      },
    },
  }
},

  CAMPAIGN: {
    breadcrumbTitle: "Campaigns",
    pageHeading: "Campaigns",
    url: "/campaigns",
    key:"campaign",
    innerPages: {
  
      EMAIL_CAMPAIGN: {
        parentComponent: moduleKeys.CAMPAIGN,
        breadcrumbTitle: "Email Campaign",
        url: "/campaigns/email",
        pageHeading: "Email Campaign",
        key:"email",
        innerPages: {
          ALL_CAMPAIGNS: {
            parentComponent: moduleKeys.CAMPAIGN,
            breadcrumbTitle: "All",
            url: "/campaigns/email/all",
            pageHeading: "All",
            innerPages: {
              DRAFT: {
                parentComponent: moduleKeys.CAMPAIGN,
                breadcrumbTitle: "Draft",
                url: "/campaigns/email/Draft",
                pageHeading: "Draft",
              },
              SCHEDULE: {
                parentComponent: moduleKeys.CAMPAIGN,
                breadcrumbTitle: "Active",
                url: "/campaigns/email/Active",
                pageHeading: "Active",
              },
              COMPLETED: {
                parentComponent: moduleKeys.CAMPAIGN,
                breadcrumbTitle: "completed",
                url: "/campaigns/email/completed",
                pageHeading: "completed",
              },
            }
          },
          ACTIVE_CAMPAIGN: {
            parentComponent: moduleKeys.CAMPAIGN,
            breadcrumbTitle: "All",
            url: "/campaigns/email/active",
            pageHeading: "Active",
          },
          DRAFT_CAMPAIGN: {
            parentComponent: moduleKeys.CAMPAIGN,
            breadcrumbTitle: "All",
            url: "/campaigns/email/draft",
            pageHeading: "Draft",
          },
          SCHEDULE: {
            parentComponent: moduleKeys.CAMPAIGN,
            breadcrumbTitle: "All",
            url: "/campaigns/email/schedule",
            pageHeading: "Expiring",
          },
          COMPLETED: {
            parentComponent: moduleKeys.CAMPAIGN,
            breadcrumbTitle: "All",
            url: "/campaigns/email/completed",
            pageHeading: "completed",
          },
          EMAIL_CAMPAIGN_FORM: {
            parentComponent: moduleKeys.CAMPAIGN,
            breadcrumbTitle: "Edit Campaign Page",
            url: `/campaigns/email/:id/form`,
            urlWithParams: (id: number | string) =>
              `/campaigns/email/${id}/form`,
            pageHeading: "Edit Campaign Page",
          },
          CREATE_EMAIL_CAMPAIGN: {
            parentComponent: moduleKeys.CAMPAIGN,
            breadcrumbTitle: "Create Landing Page",
            url: "/campaigns/email/create",
            pageHeading: "Create Landing page",
          },
          EDIT_EMAIL_CAMPAIGN : {
            parentComponent: moduleKeys.CAMPAIGN,
            breadcrumbTitle: "Edit campaign Page",
            url: `/campaigns/email/:id/edit`,
            urlWithParams: (id: number | string) =>
              `/campaigns/email/${id}/edit`,
            pageHeading: "Edit campaign page",
          },
          VIEW_EMAIL_CAMPAIGN : {
            parentComponent: moduleKeys.CAMPAIGN,
            breadcrumbTitle: "Campaign Page",
            url: `/campaigns/email/:id/view`,
            urlWithParams: (id: number | string) =>
              `/campaigns/email/${id}/view`,
            pageHeading: "Campaign Page",
          },

        },
      },

      SMS_CAMPAIGN: {
        parentComponent: moduleKeys.CAMPAIGN,
        breadcrumbTitle: "SMS Campaign",
        url: "/campaigns/sms",
        pageHeading: "SMS Campaign",
        key:"sms",
        innerPages: {
          ALL_CAMPAIGNS: {
            parentComponent: moduleKeys.CAMPAIGN,
            breadcrumbTitle: "All",
            url: "/campaigns/sms/all",
            pageHeading: "All",
            innerPages: {
              DRAFT: {
                parentComponent: moduleKeys.CAMPAIGN,
                breadcrumbTitle: "Draft",
                url: "/campaigns/sms/Draft",
                pageHeading: "Draft",
              },
              SCHEDULE: {
                parentComponent: moduleKeys.CAMPAIGN,
                breadcrumbTitle: "Active",
                url: "/campaigns/sms/Active",
                pageHeading: "Active",
              },
              COMPLETED: {
                parentComponent: moduleKeys.CAMPAIGN,
                breadcrumbTitle: "completed",
                url: "/campaigns/sms/completed",
                pageHeading: "completed",
              },
            }
          },
          ACTIVE_CAMPAIGN: {
            parentComponent: moduleKeys.CAMPAIGN,
            breadcrumbTitle: "All",
            url: "/campaigns/sms/active",
            pageHeading: "Active",
          },
          DRAFT_CAMPAIGN: {
            parentComponent: moduleKeys.CAMPAIGN,
            breadcrumbTitle: "All",
            url: "/campaigns/sms/draft",
            pageHeading: "Draft",
          },
          SCHEDULE: {
            parentComponent: moduleKeys.CAMPAIGN,
            breadcrumbTitle: "All",
            url: "/campaigns/sms/schedule",
            pageHeading: "Expiring",
          },
          COMPLETED: {
            parentComponent: moduleKeys.CAMPAIGN,
            breadcrumbTitle: "All",
            url: "/campaigns/sms/completed",
            pageHeading: "completed",
          },
          SMS_CAMPAIGN_FORM: {
            parentComponent: moduleKeys.CAMPAIGN,
            breadcrumbTitle: "Edit Campaign Page",
            url: `/campaigns/sms/:id/form`,
            urlWithParams: (id: number | string) =>
              `/campaigns/sms/${id}/form`,
            pageHeading: "Edit Campaign Page",
          },
          CREATE_SMS_CAMPAIGN: {
            parentComponent: moduleKeys.CAMPAIGN,
            breadcrumbTitle: "Create SMS Page",
            url: "/campaigns/sms/create",
            pageHeading: "Create SMS page",
          },
          EDIT_SMS_CAMPAIGN : {
            parentComponent: moduleKeys.CAMPAIGN,
            breadcrumbTitle: "Edit campaigns Page",
            url: `/campaigns/sms/:id/edit`,
            urlWithParams: (id: number | string) =>
              `/campaigns/sms/${id}/edit`,
            pageHeading: "Edit campaigns page",
          },
          VIEW_SMS_CAMPAIGN : {
            parentComponent: moduleKeys.CAMPAIGN,
            breadcrumbTitle: "Campaign Page",
            url: `/campaigns/sms/:id/view`,
            urlWithParams: (id: number | string) =>
              `/campaigns/sms/${id}/view`,
            pageHeading: "Campaign Page",
          },

        },
      },


      WHATSAPP_CAMPAIGN: {
        parentComponent: moduleKeys.CAMPAIGN,
        breadcrumbTitle: "Whatsapp Campaign",
        url: "/campaigns/whatsapp",
        pageHeading: "Whatsapp Campaign",
        // key:"whatsapp",
        innerPages: {
          ALL_CAMPAIGNS: {
            parentComponent: moduleKeys.CAMPAIGN,
            breadcrumbTitle: "All",
            url: "/campaigns/whatsapp/all",
            pageHeading: "All",
            innerPages: {
              DRAFT: {
                parentComponent: moduleKeys.CAMPAIGN,
                breadcrumbTitle: "Draft",
                url: "/campaigns/whatsapp/Draft",
                pageHeading: "Draft",
              },
              SCHEDULE: {
                parentComponent: moduleKeys.CAMPAIGN,
                breadcrumbTitle: "Active",
                url: "/campaigns/whatsapp/Active",
                pageHeading: "Active",
              },
              COMPLETED: {
                parentComponent: moduleKeys.CAMPAIGN,
                breadcrumbTitle: "completed",
                url: "/campaigns/whatsapp/completed",
                pageHeading: "completed",
              },
            }
          },
          ACTIVE_CAMPAIGN: {
            parentComponent: moduleKeys.CAMPAIGN,
            breadcrumbTitle: "All",
            url: "/campaigns/whatsapp/active",
            pageHeading: "Active",
          },
          DRAFT_CAMPAIGN: {
            parentComponent: moduleKeys.CAMPAIGN,
            breadcrumbTitle: "All",
            url: "/campaigns/whatsapp/draft",
            pageHeading: "Draft",
          },
          SCHEDULE: {
            parentComponent: moduleKeys.CAMPAIGN,
            breadcrumbTitle: "All",
            url: "/campaigns/whatsapp/schedule",
            pageHeading: "Expiring",
          },
          COMPLETED: {
            parentComponent: moduleKeys.CAMPAIGN,
            breadcrumbTitle: "All",
            url: "/campaigns/whatsapp/completed",
            pageHeading: "completed",
          },
          WHATSAPP_CAMPAIGN_FORM: {
            parentComponent: moduleKeys.CAMPAIGN,
            breadcrumbTitle: "Edit Campaign Page",
            url: `/campaigns/whatsapp/:id/form`,
            urlWithParams: (id: number | string) =>
              `/campaigns/whatsapp/${id}/form`,
            pageHeading: "Edit Campaign Page",
          },
          CREATE_WHATSAPP_CAMPAIGN: {
            parentComponent: moduleKeys.CAMPAIGN,
            breadcrumbTitle: "Create WHATSAPP Page",
            url: "/campaigns/whatsapp/create",
            pageHeading: "Create WHATSAPP page",
          },
          EDIT_WHATSAPP_CAMPAIGN : {
            parentComponent: moduleKeys.CAMPAIGN,
            breadcrumbTitle: "Edit campaigns Page",
            url: `/campaigns/whatsapp/:id/edit`,
            urlWithParams: (id: number | string) =>
              `/campaigns/whatsapp/${id}/edit`,
            pageHeading: "Edit campaigns page",
          },
          VIEW_WHATSAPP_CAMPAIGN : {
            parentComponent: moduleKeys.CAMPAIGN,
            breadcrumbTitle: "Campaign Page",
            url: `/campaigns/whatsapp/:id/view`,
            urlWithParams: (id: number | string) =>
              `/campaigns/whatsapp/${id}/view`,
            pageHeading: "Campaign Page",
          },

        },
      },

      CREATE_CAMPAIGN: {
        parentComponent: moduleKeys.CAMPAIGN,
        breadcrumbTitle: "Create Campaign",
        url: "/campaigns/create-campaign",
        pageHeading: "Create Campaign",
      },
      SMS_REPORT: {
        parentComponent: moduleKeys.CAMPAIGN,
        breadcrumbTitle: "Sms report",
        url: "/campaigns/sms-report",
        pageHeading: "Sms report",
      },
      WHATS_APP_REPORT: {
        parentComponent: moduleKeys.CAMPAIGN,
        breadcrumbTitle: "Whats app report",
        url: "/campaigns/whats-app-report",
        pageHeading: "Whats app report",
      },
    },
  },
  SETTINGS: {
    parentComponent: moduleKeys.SETTINGS,
    breadcrumbTitle: "Settings",
    url: "/settings",
    pageHeading: "Settings",
    key:"settings",
    innerPages: {
      ACCOUNT_AND_BILLING: {
        parentComponent: moduleKeys.SETTINGS,
        breadcrumbTitle: "Account and billing",
        url: "/settings/account-and-billing",
        pageHeading: "Account and billing",
        innerPages: {
          OVERVIEW: {
            parentComponent: moduleKeys.SETTINGS,
            breadcrumbTitle: "Overview",
            url: "/settings/account-and-billing/overview",
            pageHeading: "Account and billing : Overview",
          },
          COMPANY_INFO: {
            parentComponent: moduleKeys.SETTINGS,
            breadcrumbTitle: "Company Info",
            url: "/settings/account-and-billing/company-info",
            pageHeading: "Account and billing : Company Info",
          },
          TRANSACTIONS: {
            parentComponent: moduleKeys.SETTINGS,
            breadcrumbTitle: "Transactions",
            url: "/settings/account-and-billing/transactions",
            pageHeading: "Account and billing : Transactions",
          },
          DOCUMENTS: {
            parentComponent: moduleKeys.SETTINGS,
            breadcrumbTitle: "Documents",
            url: "/settings/account-and-billing/documents",
            pageHeading: "Account and billing : Documents",
          },
          ACCOUNT: {
            parentComponent: moduleKeys.SETTINGS,
            breadcrumbTitle: "Account",
            url: "/settings/account-and-billing/account",
            pageHeading: "Account and billing : Account",
          },
        },
      },
      TEAM_AND_ROLE_MANAGEMENT: {
        parentComponent: moduleKeys.SETTINGS,
        breadcrumbTitle: "Team and Roles",
        url: "/settings/team-and-roles",
        pageHeading: "Team and roles managment",
        innerPages: {
          TEAM: {
            parentComponent: moduleKeys.SETTINGS,
            breadcrumbTitle: "Teams",
            url: "/settings/team-and-roles/team",
            pageHeading: "Account and billing : Team",
          },
          ROLES: {
            parentComponent: moduleKeys.SETTINGS,
            breadcrumbTitle: "Roles",
            url: "/settings/team-and-roles/roles",
            pageHeading: "Team and roles managment",
          },
        },
      },
      COMPANY_INFO:{
        parentComponent: moduleKeys.SETTINGS,
        breadcrumbTitle: "Channel settings",
        url: "/settings/company-information",
        pageHeading: "Settings",
        innerPages:{}
      },
      PROFILE_SETTING:{
        parentComponent: moduleKeys.SETTINGS,
        breadcrumbTitle: "Profile settings",
        url: "/settings/profile-setting",
        pageHeading: "Settings",
        innerPages:{}
      },

      DB_SYNC_SETTING:{
        parentComponent: moduleKeys.SETTINGS,
        breadcrumbTitle: "DB Sync settings",
        url: "/settings/dbsync-setting",
        pageHeading: "Settings",
        innerPages:{
          MY_SQL: {
            parentComponent: moduleKeys.SETTINGS,
            breadcrumbTitle: "Edit",
            url: "/settings/dbsync-setting/mysql",
            pageHeading: "Settings",
          },
          AMAZON_REDSHIFT: {
            parentComponent: moduleKeys.SETTINGS,
            breadcrumbTitle: "Edit",
            url: "/settings/dbsync-setting/amazonredshift",
            pageHeading: "Settings",
          },
          ORACLE_DB: {
            parentComponent: moduleKeys.SETTINGS,
            breadcrumbTitle: "Edit",
            url: "/settings/dbsync-setting/oracledb",
            pageHeading: "Settings",
          },
          QL_SERVER: {
            parentComponent: moduleKeys.SETTINGS,
            breadcrumbTitle: "Edit",
            url: "/settings/dbsync-setting/qlserver",
            pageHeading: "Settings",
          },
          POSTGRESQL: {
            parentComponent: moduleKeys.SETTINGS,
            breadcrumbTitle: "Edit",
            url: "/settings/dbsync-setting/postgresql",
            pageHeading: "Settings",
          },
        }
      },
      CHANNEL_SETTINGS: {
        parentComponent: moduleKeys.SETTINGS,
        breadcrumbTitle: "Channel settings",
        url: "/settings/channel-setting",
        pageHeading: "Settings",
        innerPages: {
          SMS: {
            parentComponent: moduleKeys.SETTINGS,
            breadcrumbTitle: "Sms",
            url: "/settings/channel-setting/sms",
            pageHeading: "Settings",
          },
          WHATSAPP: {
            parentComponent: moduleKeys.SETTINGS,
            breadcrumbTitle: "Whats App",
            url: "/settings/channel-setting/whatsapp",
            pageHeading: "Settings",
          },
          EMAIL: {
            parentComponent: moduleKeys.SETTINGS,
            breadcrumbTitle: "Email",
            url: "/settings/channel-setting/email",
            pageHeading: "Settings",
          },
        },
      },
      AUDIT_LOGS: {
        parentComponent: moduleKeys.SETTINGS,
        breadcrumbTitle: "Audit logs",
        url: "/settings/audit-logs",
        pageHeading: "Settings",
      },
      CONTACT_SCHEMAS: {
        parentComponent: moduleKeys.SETTINGS,
        breadcrumbTitle: "Contact schemas",
        url: "/settings/contact-schemas",
        pageHeading: "Settings",
      },
      SAVED_TEMPLATES: {
        parentComponent: moduleKeys.SETTINGS,
        breadcrumbTitle: "Saved templates",
        url: "/settings/saved-templates",
        pageHeading: "Settings",
        innerPages: {
          SMS: {
            parentComponent: moduleKeys.SETTINGS,
            breadcrumbTitle: "Sms",
            url: "/settings/saved-templates/sms",
            pageHeading: "Settings",
          },
          WHATSAPP: {
            parentComponent: moduleKeys.SETTINGS,
            breadcrumbTitle: "Whats App",
            url: "/settings/saved-templates/whats-app",
            pageHeading: "Settings",
          },
          EMAIL: {
            parentComponent: moduleKeys.SETTINGS,
            breadcrumbTitle: "Email",
            url: "/settings/saved-templates/email",
            pageHeading: "Settings",
          },
        },
      },
      USER_IDENTITY: {
        parentComponent: moduleKeys.SETTINGS,
        breadcrumbTitle: "User indentity",
        url: "/settings/user-identity",
        pageHeading: "Settings",
      },
      INTEGRATIONS: {
        parentComponent: moduleKeys.SETTINGS,
        breadcrumbTitle: "Integrations",
        url: "/settings/integrations",
        pageHeading: "Settings",
      },
      RESET:{
        parentComponent: moduleKeys.SETTINGS,
        breadcrumbTitle: "Account and billing",
        url: "/settings/set-password",
        pageHeading: "Account and billing",
     
      }
    },
  },
  LOYALTY: {
    parentComponent: moduleKeys.LOYALTY,
    breadcrumbTitle: "Loyalty",
    pageHeading: "Loyalty",
    url: "/loyalty",
    key:"loyalty",
    innerPages: {

      COUPONS: {
        parentComponent: moduleKeys.LOYALTY,
        breadcrumbTitle: "Coupons",
        url: "/loyalty/coupons",
        pageHeading: "Coupons",
        innerPages: {
          ALL_COUPONS: {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "All Coupons",
            url: "/loyalty/coupons/all",
            pageHeading: "All Coupons",
            paramKey: "all",
          },
          ACTIVE_COUPONS: {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "Expiring Coupons",
            url: "/loyalty/coupons/active",
            pageHeading: "Expiring Coupons",
            paramKey: "active",
          },
          DRAFT_COUPONS: {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "Expiring Coupons",
            url: "/loyalty/coupons/draft",
            pageHeading: "Expiring Coupons",
            paramKey: "draft",
          },
          EXPIRING_COUPONS: {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "Expiring Coupons",
            url: "/loyalty/coupons/expiring",
            pageHeading: "Expiring Coupons",
            paramKey: "expiringSoon",
          },
          EXPIRED_COUPONS: {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "Expired Coupons",
            url: "/loyalty/coupons/expired",
            pageHeading: "Expired Coupons",
            paramKey: "expired",
          },
          CREATE_COUPON: {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "Create Coupon",
            url: "/loyalty/coupons/create",
            pageHeading: "Create Coupon",
          },
          EDIT_COUPON: {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "Edit Coupon",
            url: `/loyalty/coupons/:id/edit`,
            urlWithParams: (id: number | string) =>
              `/loyalty/coupons/${id}/edit`,
            pageHeading: "Edit Coupon",
          },
          COUPON_FORM: {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "Edit Coupon",
            url: `/loyalty/coupons/:id/form`,
            urlWithParams: (id: number | string) =>
              `/loyalty/coupons/${id}/form`,
            pageHeading: "Edit Coupon",
          },
          EDIT_COUPON_CODE: {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "Add Coupon Code",
            url: `/loyalty/coupons/:id/add-coupon-code`,
            urlWithParams: (id: number | string) =>
              `/loyalty/coupons/${id}/add-coupon-code`,
            pageHeading: "Add Coupon Code",
          },
          VIEW_COUPON_CODE: {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "View Coupon Code",
            url: `/loyalty/coupons/:id/view`,
            urlWithParams: (id: number | string) =>
              `/loyalty/coupons/${id}/view`,
            pageHeading: "View Coupon Code",
          },
        },
      },
      SCRATCH_CARD: {
        parentComponent: moduleKeys.LOYALTY,
        breadcrumbTitle: "Coupons",
        url: "/loyalty/coupons",
        pageHeading: "Coupons",
        innerPages: {
          ALL_COUPONS: {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "All Coupons",
            url: "/loyalty/coupons/all",
            pageHeading: "All Coupons",
            paramKey: "all",
          },
          ACTIVE_COUPONS: {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "Expiring Coupons",
            url: "/loyalty/coupons/active",
            pageHeading: "Expiring Coupons",
            paramKey: "expiringSoon",
          },
          DRAFT_COUPONS: {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "Expiring Coupons",
            url: "/loyalty/coupons/draft",
            pageHeading: "Expiring Coupons",
            paramKey: "expiringSoon",
          },
          EXPIRING_COUPONS: {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "Expiring Coupons",
            url: "/loyalty/coupons/expiring",
            pageHeading: "Expiring Coupons",
            paramKey: "expiringSoon",
          },
          EXPIRED_COUPONS: {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "Expired Coupons",
            url: "/loyalty/coupons/expired",
            pageHeading: "Expired Coupons",
            paramKey: "expired",
          },
          CREATE_COUPON: {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "Create Coupon",
            url: "/loyalty/coupons/create",
            pageHeading: "Create Coupon",
          },
          EDIT_COUPON: {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "Edit Coupon",
            url: `/loyalty/coupons/:id/edit`,
            urlWithParams: (id: number | string) =>
              `/loyalty/coupons/${id}/edit`,
            pageHeading: "Edit Coupon",
          },
          COUPON_FORM: {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "Edit Coupon",
            url: `/loyalty/coupons/:id/form`,
            urlWithParams: (id: number | string) =>
              `/loyalty/coupons/${id}/form`,
            pageHeading: "Edit Coupon",
          },
          EDIT_COUPON_CODE: {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "Add Coupon Code",
            url: `/loyalty/coupons/:id/add-coupon-code`,
            urlWithParams: (id: number | string) =>
              `/loyalty/coupons/${id}/add-coupon-code`,
            pageHeading: "Add Coupon Code",
          },
          VIEW_COUPON_CODE: {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "View Coupon Code",
            url: `/loyalty/coupons/:id/view`,
            urlWithParams: (id: number | string) =>
              `/loyalty/coupons/${id}/view`,
            pageHeading: "View Coupon Code",
          },
        },
      },
      LOYALTY_GAMES: {
        parentComponent: moduleKeys.LOYALTY,
        breadcrumbTitle: "Games",
        url: "/loyalty/games/",
        pageHeading: "Loyalty Games",
        innerPages: {
          SURVEY: {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "Survey",
            url: "/loyalty/games/survey",
            pageHeading: "Survey",
            innerPages: {
              ALL_SURVEY: {
                parentComponent: moduleKeys.LOYALTY,
                breadcrumbTitle: "All",
                url: "/loyalty/games/survey/all",
                pageHeading: "All Surveys",
                paramKey:SURVEY_THE_WHEEL_STATUS.ALL
              },
              ACTIVE_SURVEY: {
                parentComponent: moduleKeys.LOYALTY,
                breadcrumbTitle: "Active",
                url: "/loyalty/games/survey/active",
                pageHeading: "Active Surveys",
                paramKey:SURVEY_THE_WHEEL_STATUS.ACTIVE
              },
              DRAFT_SURVEY: {
                parentComponent: moduleKeys.LOYALTY,
                breadcrumbTitle: "Draft",
                url: "/loyalty/games/survey/draft",
                pageHeading: "Draft Survey",
                paramKey:SURVEY_THE_WHEEL_STATUS.DRAFT
              },
              COMPLETED_SURVEY: {
                parentComponent: moduleKeys.LOYALTY,
                breadcrumbTitle: "Completed",
                url: "/loyalty/games/survey/completed",
                pageHeading: "Completed Survey",
                paramKey:SURVEY_THE_WHEEL_STATUS.COMPLETED
              }
            },
          },
          QUIZ: {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "Quiz",
            url: "/loyalty/games/quiz",
            pageHeading: "Quiz",
            innerPages: {
              ACTIVE_QUIZZES: {
                parentComponent: moduleKeys.LOYALTY,
                breadcrumbTitle: "Active",
                url: "/loyalty/games/quiz/active",
                pageHeading: "Active Quizzes",
                paramKey:QUIZ_STATUS.ACTIVE
              },
              DRAFT_QUIZZES: {
                parentComponent: moduleKeys.LOYALTY,
                breadcrumbTitle: "Draft",
                url: "/loyalty/games/quiz/draft",
                pageHeading: "Draft Quizzes",
                paramKey:QUIZ_STATUS.DRAFT
              },
              COMPLETED_QUIZZES: {
                parentComponent: moduleKeys.LOYALTY,
                breadcrumbTitle: "Completed",
                url: "/loyalty/games/quiz/completed",
                pageHeading: "Completed Quizzes",
                paramKey:QUIZ_STATUS.COMPLETED
              },
              CREATE_QUIZ: {
                parentComponent: moduleKeys.LOYALTY,
                breadcrumbTitle: "Create",
                url: "/loyalty/games/quiz/create",
                pageHeading: "Create Quiz",
              },
              EDIT_QUIZ: {
                parentComponent: moduleKeys.LOYALTY,
                breadcrumbTitle: "Edit Quiz",
                url: `/loyalty/games/quiz/:id/edit`,
                urlWithParams: (id: number | string) =>
                  `/loyalty/games/quiz/${id}/edit`,
                pageHeading: "Edit Quiz",
              },
              VIEW_QUIZ: {
                parentComponent: moduleKeys.LOYALTY,
                breadcrumbTitle: "View Quiz",
                url: `/loyalty/games/quiz/:id/view`,
                urlWithParams: (id: number | string) =>
                  `/loyalty/games/quiz/${id}/view`,
                pageHeading: "View Quiz",
              },
            },
          },
          SPIN_THE_WHEEL: {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "Spin the wheel",
            url: "/loyalty/games/spin-the-wheel",
            pageHeading: "Spin the Wheel",
            innerPages: {
              ALL_SPIN_THE_WHEEL: {
                parentComponent: moduleKeys.LOYALTY,
                breadcrumbTitle: "All",
                url: "/loyalty/games/spin-the-wheel/all",
                pageHeading: "All",
                paramKey:SPIN_THE_WHEEL_STATUS.ALL
              },
              ACTIVE_SPIN_THE_WHEEL: {
                parentComponent: moduleKeys.LOYALTY,
                breadcrumbTitle: "Active",
                url: "/loyalty/games/spin-the-wheel/active",
                pageHeading: "Active",
                paramKey:SPIN_THE_WHEEL_STATUS.ACTIVE
              },
              DRAFT_SPIN_THE_WHEEL: {
                parentComponent: moduleKeys.LOYALTY,
                breadcrumbTitle: "Draft",
                url: "/loyalty/games/spin-the-wheel/draft",
                pageHeading: "Draft",
                paramKey:SPIN_THE_WHEEL_STATUS.DRAFT
              },
              COMPLETED_SPIN_THE_WHEEL: {
                parentComponent: moduleKeys.LOYALTY,
                breadcrumbTitle: "Completed",
                url: "/loyalty/games/spin-the-wheel/completed",
                pageHeading: "Completed",
                paramKey:SPIN_THE_WHEEL_STATUS.COMPLETED
              },
              CREATE_SPIN_THE_WHEEL: {
                parentComponent: moduleKeys.LOYALTY,
                breadcrumbTitle: "Create",
                url: "/loyalty/games/spin-the-wheel/create",
                pageHeading: "Create spin the wheel",
              },
              EDIT_SPIN_THE_WHEEL: {
                parentComponent: moduleKeys.LOYALTY,
                breadcrumbTitle: "Edit spin the wheel",
                url: `/loyalty/games/spin-the-wheel/:id/edit`,
                urlWithParams: (id: number | string) =>
                  `/loyalty/games/spin-the-wheel/${id}/edit`,
                pageHeading: "Edit spin the wheel",
              },
              VIEW_SPIN_THE_WHEEL: {
                parentComponent: moduleKeys.LOYALTY,
                breadcrumbTitle: "View spin the wheel",
                url: `/loyalty/games/spin-the-wheel/:id/view`,
                urlWithParams: (id: number | string) =>
                  `/loyalty/games/spin-the-wheel/${id}/view`,
                pageHeading: "View spin the wheel",
              },
            },
          },
          SCRATCH_CARD: {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "Scratch Card",
            url: "/loyalty/games/scratch-card",
            pageHeading: "Scratch Card",
            innerPages: {
              ALL_SCRATCH_CARD: {
                parentComponent: moduleKeys.LOYALTY,
                breadcrumbTitle: "All",
                url: "/loyalty/games/scratch-card/all",
                pageHeading: "All",
                paramKey:SCRATCH_CARD_STATUS.ALL
              },
              ACTIVE_SCRATCH_CARD: {
                parentComponent: moduleKeys.LOYALTY,
                breadcrumbTitle: "Active",
                url: "/loyalty/games/scratch-card/active",
                pageHeading: "Active",
                paramKey:SCRATCH_CARD_STATUS.ACTIVE
              },
              DRAFT_SCRATCH_CARD: {
                parentComponent: moduleKeys.LOYALTY,
                breadcrumbTitle: "Draft",
                url: "/loyalty/games/scratch-card/draft",
                pageHeading: "Draft",
                paramKey:SCRATCH_CARD_STATUS.DRAFT
              },
              COMPLETED_SCRATCH_CARD: {
                parentComponent: moduleKeys.LOYALTY,
                breadcrumbTitle: "Completed",
                url: "/loyalty/games/scratch-card/completed",
                pageHeading: "Completed",
                paramKey:SCRATCH_CARD_STATUS.COMPLETED
              },
              CREATE_SCRATCH_CARD: {
                parentComponent: moduleKeys.LOYALTY,
                breadcrumbTitle: "Create",
                url: "/loyalty/games/scratch-card/create",
                pageHeading: "Create spin the wheel",
              },
              EDIT_SCRATCH_CARD: {
                parentComponent: moduleKeys.LOYALTY,
                breadcrumbTitle: "Edit spin the wheel",
                url: `/loyalty/games/scratch-card/:id/edit`,
                urlWithParams: (id: number | string) =>
                  `/loyalty/games/scratch-card/${id}/edit`,
                pageHeading: "Edit spin the wheel",
              },
              VIEW_SCRATCH_CARD: {
                parentComponent: moduleKeys.LOYALTY,
                breadcrumbTitle: "View spin the wheel",
                url: `/loyalty/games/scratch-card/:id/view`,
                urlWithParams: (id: number | string) =>
                  `/loyalty/games/scratch-card/${id}/view`,
                pageHeading: "View spin the wheel",
              },
            },
          },
        },
      },
      LANDING_PAGE: {
        parentComponent: moduleKeys.LOYALTY,
        breadcrumbTitle: "Landing page",
        url: "/loyalty/landing-page",
        pageHeading: "Landing page",
        innerPages: {
          ALL_LANDING_PAGE: {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "All",
            url: "/loyalty/landing-page/all",
            pageHeading: "All",
            innerPages: {
              DRAFT: {
                parentComponent: moduleKeys.LOYALTY,
                breadcrumbTitle: "Draft",
                url: "/loyalty/landing-page/Draft",
                pageHeading: "Draft",
              },
              SCHEDULE: {
                parentComponent: moduleKeys.LOYALTY,
                breadcrumbTitle: "Active",
                url: "/loyalty/landing-page/Active",
                pageHeading: "Active",
              },
              SENT: {
                parentComponent: moduleKeys.LOYALTY,
                breadcrumbTitle: "Expired",
                url: "/loyalty/landing-page/Expired",
                pageHeading: "Expired",
              },
            }
          },
          ACTIVE_LANDING_PAGE: {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "All",
            url: "/loyalty/landing-page/active",
            pageHeading: "Active",
          },
          DRAFT_LANDING_PAGE: {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "All",
            url: "/loyalty/landing-page/draft",
            pageHeading: "Draft",
          },
          EXPIRING_LANDING_PAGE: {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "All",
            url: "/loyalty/landing-page/expiring",
            pageHeading: "Expiring",
          },
          EXPIRED_LANDING_PAGE: {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "All",
            url: "/loyalty/landing-page/expired",
            pageHeading: "Expired",
          },
          LANDING_PAGE_FORM: {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "Edit Landing Page",
            url: `/loyalty/landing-page/:id/form`,
            urlWithParams: (id: number | string) =>
              `/loyalty/landing-page/${id}/form`,
            pageHeading: "Edit Landing Page",
          },
          CREATE_LANDING_PAGE : {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "Create Landing Page",
            url: "/loyalty/landing-page/create",
            pageHeading: "Create Landing page",
          },
          EDIT_LANDING_PAGE : {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "Edit landing Page",
            url: `/loyalty/landing-page/:id/edit`,
            urlWithParams: (id: number | string) =>
              `/loyalty/landing-page/${id}/edit`,
            pageHeading: "Edit landing page",
          },
          VIEW_LANDING_PAGE : {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "Landing Page",
            url: `/loyalty/landing-page/:id/view`,
            urlWithParams: (id: number | string) =>
              `/loyalty/landing-page/${id}/view`,
            pageHeading: "Landing Page",
          },
          ANALYTICS_LANDING_PAGE : {
            parentComponent: moduleKeys.LOYALTY,
            breadcrumbTitle: "Landing Page",
            url: `/loyalty/landing-page/:id/analytics`,
            urlWithParams: (id: number | string) =>
              `/loyalty/landing-page/${id}/analytics`,
            pageHeading: "Landing Page",
          },
          // EDIT_COUPON : {
          //   parentComponent: moduleKeys.LOYALTY,
          //   breadcrumbTitle: "Edit Coupon",
          //   url:`/loyalty/coupons/:id/edit`,
          //   urlWithParams: (id:number |string) => `/loyalty/coupons/${id}/edit`,
          //   pageHeading: "Edit Coupon",
          // }
        },
      },
    },
  },

  MICRO_GAMES: {
    parentComponent: moduleKeys.MICRO_GAMES,
    breadcrumbTitle: "Micro Games",
    pageHeading: "Micro Games",
    url: "/micro-games",
    key:"microGames",
    innerPages: {
      SCRATCH_CARD: {
        parentComponent: moduleKeys.MICRO_GAMES,
        breadcrumbTitle: "Scratch Card",
        url: "/micro-games/scratch-card",
        pageHeading: "Scratch Card",
        innerPages: {
          ALL_SCRATCH_CARD: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "All Scratch Card",
            url: "/micro-games/scratch-card/all",
            pageHeading: "All Scratch Cards",
            paramKey: "all",
          },
          ACTIVE_SCRATCH_CARD: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "Active Scratch Card",
            url: "/micro-games/scratch-card/active",
            pageHeading: "Active Scratch Cards",
            paramKey: "active",
          },
          DRAFT_SCRATCH_CARD: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "Draft Scratch Card",
            url: "/micro-games/scratch-card/draft",
            pageHeading: "Draft Scratch Card",
            paramKey: "draft",
          },
          COMPLETED_SCRATCH_CARD: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "Completed Scratch Card",
            url: "/micro-games/scratch-card/completed",
            pageHeading: "Completed Scratch Card",
            paramKey: "completed",
          },

          CREATE_SCRATCH_CARD: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "Create Scratch Card",
            url: "/micro-games/scratch-card/create",
            pageHeading: "Create Scratch Card",
          },
          EDIT_SCRATCH_CARD: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "Edit Scratch Card",
            url: `/micro-games/scratch-card/:id/edit`,
            urlWithParams: (id: number | string) =>
              `/micro-games/scratch-card/${id}/edit`,
            pageHeading: "Edit Scratch Card",
          },
          SCRATCH_CARD_FORM: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "Edit Scratch Card",
            url: `/micro-games/scratch-card/:id/form`,
            urlWithParams: (id: number | string) =>
              `/micro-games/scratch-card/${id}/form`,
            pageHeading: "Edit Scratch Card",
          },
          EDIT_SCRATCH_CARD_CODE: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "Add Scratch Card Code",
            url: `/micro-games/scratch-card/:id/add-coupon-code`,
            urlWithParams: (id: number | string) =>
              `/micro-games/scratch-card/${id}/add-coupon-code`,
            pageHeading: "Add Scratch Card Code",
          },
          VIEW_SCRATCH_CARD_CODE: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "View Scratch Card Code",
            url: `/micro-games/scratch-card/:id/view`,
            urlWithParams: (id: number | string) =>
              `/micro-games/scratch-card/${id}/view`,
            pageHeading: "View Scratch Card Code",
          },
        },
      },

      QUIZ: {
        parentComponent: moduleKeys.MICRO_GAMES,
        breadcrumbTitle: "Quiz",
        url: "/micro-games/quiz",
        pageHeading: "Quiz",
        innerPages: {
          ALL_QUIZ: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "All Quiz",
            url: "/micro-games/quiz/all",
            pageHeading: "All Quiz",
            paramKey: "all",
          },
          ACTIVE_QUIZ: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "Active Quiz",
            url: "/micro-games/quiz/active",
            pageHeading: "Active Quiz",
            paramKey: "active",
          },
          DRAFT_QUIZ: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "Draft Quiz",
            url: "/micro-games/quiz/draft",
            pageHeading: "Draft Quiz",
            paramKey: "draft",
          },
          COMPLETED_QUIZ: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "Completed Quiz",
            url: "/micro-games/quiz/completed",
            pageHeading: "Completed Quiz",
            paramKey: "completed",
          },

          CREATE_QUIZ: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "Create Quiz",
            url: "/micro-games/quiz/create",
            pageHeading: "Create Quiz",
            innerPages:{
              TRIVIA:{
                parentComponent: moduleKeys.MICRO_GAMES,
                breadcrumbTitle: "Quiz Trivia",
                url: "/micro-games/quiz/create/trivia",
                pageHeading: "Quiz Trivia",
              },
              SUM_BASED:{
                parentComponent: moduleKeys.MICRO_GAMES,
                breadcrumbTitle: "Quiz Sum Based",
                url: "/micro-games/quiz/create/sum-based",
                pageHeading: "Quiz Sum Based",
              },
              COUNT_BASED:{
                parentComponent: moduleKeys.MICRO_GAMES,
                breadcrumbTitle: "Quiz Count Based",
                url: "/micro-games/quiz/create/count-based",
                pageHeading: "Quiz Count Based",
              }
            }
          },

          EDIT_QUIZ: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "Edit Quiz",
            url: `/micro-games/quiz/:id/edit`,
            urlWithParams: (id: number | string) =>
              `/micro-games/quiz/${id}/edit`,
            pageHeading: "Edit Quiz",
          },
          QUIZ_FORM: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "Edit Quiz",
            url: `/micro-games/quiz/:type/:id/form`,
            urlWithParams: (type:string,id: number | string) =>
              `/micro-games/quiz/${type}/${id}/form`,
            pageHeading: "Edit Quiz",

          },

          VIEW_QUIZ: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "View Quiz",
            url: `/micro-games/quiz/:id/view`,
            urlWithParams: (id: number | string) =>
              `/micro-games/quiz/${id}/view`,
            pageHeading: "View Quiz",
          },
        },
      },



      SPIN_THE_WHEEL: {
        parentComponent: moduleKeys.MICRO_GAMES,
        breadcrumbTitle: "Spin the wheel",
        url: "/micro-games/spin-the-wheel",
        pageHeading: "Spin the wheel",
        innerPages: {
          ALL_SPIN_THE_WHEEL: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "All Spin the wheel",
            url: "/micro-games/spin-the-wheel/all",
            pageHeading: "All Spin the wheel",
            paramKey: "all",
          },
          ACTIVE_SPIN_THE_WHEEL: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "Expiring Spin the wheel",
            url: "/micro-games/spin-the-wheel/active",
            pageHeading: "Expiring Spin the wheel",
            paramKey: "active",
          },
          DRAFT_SPIN_THE_WHEEL: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "Expiring Spin the wheel",
            url: "/micro-games/spin-the-wheel/draft",
            pageHeading: "Expiring Spin the wheel",
            paramKey: "draft",
          },
          COMPLETED_SPIN_THE_WHEEL: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "Completed Spin the wheel",
            url: "/micro-games/spin-the-wheel/completed",
            pageHeading: "Completed Spin the wheel",
            paramKey: "completed",
          },
          EXPIRED_SPIN_THE_WHEEL: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "Expired Spin the wheel",
            url: "/micro-games/spin-the-wheel/expired",
            pageHeading: "Expired Spin the wheel",
            paramKey: "expired",
          },
          CREATE_SPIN_THE_WHEEL: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "Create Spin the wheel",
            url: "/micro-games/spin-the-wheel/create",
            pageHeading: "Create Spin the wheel",
          },
          EDIT_SPIN_THE_WHEEL: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "Edit Spin the wheel",
            url: `/micro-games/spin-the-wheel/:id/edit`,
            urlWithParams: (id: number | string) =>
              `/micro-games/spin-the-wheel/${id}/edit`,
            pageHeading: "Edit Spin the wheel",
          },
          SPIN_THE_WHEEL_FORM: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "Edit Spin the wheel",
            url: `/micro-games/spin-the-wheel/:id/form`,
            urlWithParams: (id: number | string) =>
              `/micro-games/spin-the-wheel/${id}/form`,
            pageHeading: "Edit Spin the wheel",
          },
          VIEW_SPIN_THE_WHEEL: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "View Spin the wheel Code",
            url: `/micro-games/spin-the-wheel/:id/view`,
            urlWithParams: (id: number | string) =>
              `/micro-games/spin-the-wheel/${id}/view`,
            pageHeading: "View Spin the wheel Code",
          },
        },
      },


      SURVEY: {
        parentComponent: moduleKeys.MICRO_GAMES,
        breadcrumbTitle: "Survey",
        url: "/micro-games/survey",
        pageHeading: "Survey",
        innerPages: {
          ALL_SURVEY: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "All Survey",
            url: "/micro-games/survey/all",
            pageHeading: "All Survey",
            paramKey: "all",
          },
          ACTIVE_SURVEY: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "Expiring Survey",
            url: "/micro-games/survey/active",
            pageHeading: "Expiring Survey",
            paramKey: "active",
          },
          DRAFT_SURVEY: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "Expiring Survey",
            url: "/micro-games/survey/draft",
            pageHeading: "Expiring Survey",
            paramKey: "draft",
          },
          COMPLETED_SURVEY: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "Completed Survey",
            url: "/micro-games/survey/completed",
            pageHeading: "Completed Survey",
            paramKey: "completed",
          },
          EXPIRED_SURVEY: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "Expired Survey",
            url: "/micro-games/survey/expired",
            pageHeading: "Expired Survey",
            paramKey: "expired",
          },
          CREATE_SURVEY: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "Create Survey",
            url: "/micro-games/survey/create",
            pageHeading: "Create Survey",
          },
          EDIT_SURVEY: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "Edit Survey",
            url: `/micro-games/survey/:id/edit`,
            urlWithParams: (id: number | string) =>
              `/micro-games/survey/${id}/edit`,
            pageHeading: "Edit Survey",
          },
          SURVEY_FORM: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "Edit Survey",
            url: `/micro-games/survey/:id/form`,
            urlWithParams: (id: number | string) =>
              `/micro-games/survey/${id}/form`,
            pageHeading: "Edit Survey",
          },
          EDIT_SURVEY_CODE: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "Add Survey Code",
            url: `/micro-games/survey/:id/add-coupon-code`,
            urlWithParams: (id: number | string) =>
              `/micro-games/survey/${id}/add-coupon-code`,
            pageHeading: "Add Survey Code",
          },
          VIEW_SURVEY_CODE: {
            parentComponent: moduleKeys.MICRO_GAMES,
            breadcrumbTitle: "View Survey Code",
            url: `/micro-games/survey/:id/view`,
            urlWithParams: (id: number | string) =>
              `/micro-games/survey/${id}/view`,
            pageHeading: "View Survey Code",
          },
        },
      },

    },
  },
  ASSETS_REF: {
    parentComponent: moduleKeys.ASSETS_REF,
    breadcrumbTitle: "Assets",
    pageHeading: "Assets",
    url: "/assets"
  },
  ICONS_REF: {
    parentComponent: moduleKeys.ASSETS_REF,
    breadcrumbTitle: "Icons",
    pageHeading: "Icons",
    url: "/icons"
  },
  ANALYTICS: {
    parentComponent: moduleKeys.ANALYTICS,
    breadcrumbTitle: "Analytics",
    pageHeading: "Analytics",
    url: "/analytics",
    key:"analytics",
    innerPages: {
      Audience:{
        parentComponent: moduleKeys.ANALYTICS,
            breadcrumbTitle: "Audience",
            key: "audience",
            url: "/analytics/audience",
            pageHeading: "Audience",
      },
      Acquisition:{
        parentComponent: moduleKeys.ANALYTICS,
            breadcrumbTitle: "Acquisition",
            key: "acquisition",
            url: "/analytics/acquisition",
            pageHeading: "Acquisition",
      },
      Behaviour:{
        parentComponent: moduleKeys.ANALYTICS,
            breadcrumbTitle: "Behaviour",
            key: "behaviour",
            url: "/analytics/behaviour",
            pageHeading: "Behaviour",
      }
    }
  }
};

export default PageConfig;
