import React from 'react';
import { toast } from 'react-toastify';

export default (props) => {
    const icon = {
      success: 'bg-check-circle',
      error: 'bg-x-octagon',
      warning: 'bg-alert-octagon'
    }[props.type];

    toast(<div className="toast-container">
      <div className={`bg-icon bg-icon32 ${icon||'bg-check-circle'}`}/>
      <span className="toast-text">{props.msg}</span>
    </div>, {
      type: ['success', 'error', 'warning'].indexOf[props.type]!=-1?props.type: 'success',
      position: "top-center"
    }
  )};
