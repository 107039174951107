import React, { useEffect } from "react";
import { Redirect, Switch, Route, useLocation, useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import DefaultPage from "./pages/DefaultPage";
import AuthPage from "./pages/Auth";
import EmailVerify from "./pages/Auth/pages/EmailVerify";
import CompanyInfoUpdate from "./pages/Auth/pages/CompanyInfoUpdate";
import ErrorsPage from "./pages/Errors/ErrorsPage";
import OrgChartComponent from "./components/OrgChart";

export function Routes() {
  const location = useLocation();
  const history = useHistory();

  const data = useSelector(({ auth }) => auth.user, shallowEqual);
  let isAuthorized = data && data?.authToken && data?.status != 2 && data?.status != 1;

  if (data?.status == 1) {
    return <EmailVerify />;
  }
  if (data?.status == 2) {
    return <CompanyInfoUpdate />;
  }

  if (
    (location.pathname.includes("/auth/set-password") || location.pathname.includes("/auth/reset-password")) &&
    location.search.includes("?email=")
  ) {
    if (isAuthorized) {
      localStorage.clear();
      window.location.reload(true);
      // history.push(window.location.href);
    }
  }

  return (
    <Switch>
      <Route path="/error" component={ErrorsPage} />
      {/* <Route path="/org-chart" component={OrgChartComponent} /> */}

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <AuthPage />
      ) : (
        <Layout>
          <DefaultPage />
        </Layout>
      )}
    </Switch>
  );
}
