import { SMSTemplateReqObj } from "../../pages/Campaigns/_constants/types";
import { campaignActionTypes } from "./campaign.actiontype";

export const campaignActions = {
	changeShowSaveTemplateModal: (data: any) => ({
		type: campaignActionTypes.CHANGE_SHOW_SAVE_TEMPLATE_MODAL, payload: data
	}),
	channelAddedSuccessFully: () => ({
		type: campaignActionTypes.CHANNEL_ADDED_SUCCESSFULLY
	}),
	changeSelectedTemplateMessage: (data: any) => ({
		type: campaignActionTypes.UPDATE_SELECTED_TEMPLATE_DATA, payload: data
	}),
	campaignOnSubmitHandler: () => ({
		type: campaignActionTypes.CAMPAIGN_ON_SUBMIT_HANDLER
	}),
	setDltApprovedStatus: (data: any) => ({
		type: campaignActionTypes.SET_IS_DLT_APPROVED_STATUS, payload: data
	}),
	setCampaginProviderFormData: (data: any) => ({
		type: campaignActionTypes.SET_CAMPAIGN_PROVIDER_FORM_DATA, payload: data
	}),
	setWhatsAppCampaginProviderFormData: (data: any) => ({
		type: campaignActionTypes.SET_WHATS_APP_CAMPAIGN_PROVIDER_FORM_DATA, payload: data
	}),
	setEmailCampaginProviderFormData: (data: any) => ({
		type: campaignActionTypes.SET_EMAIL_CAMPAIGN_PROVIDER_FORM_DATA, payload: data
	}),
	setSelectedSegmentDetail: (data: any) => ({
		type: campaignActionTypes.SET_SELECTED_SEGMENT_DETAIL, payload: data
	}),
	setCompanySmsChannel: (data: any) => ({
		type: campaignActionTypes.SET_COMPANY_SMS_CHANNEL, payload: data
	}),
	setDynamicMapping: (data: any) => ({type: campaignActionTypes.SET_DYNAMIC_MAPPING, payload: data}),
  resetTemplate: () => ({ type: campaignActionTypes.RESET_SAVE_TEMPLATE }),
  startTemplateSelect: (data: number, type: string) => ({
    type: campaignActionTypes.FETCH_SELECTED_TEMPLATE,
    payload: { id: data, type },
  }),
  saveTemplate: (data: SMSTemplateReqObj, successCallBack: () => {}) => ({
    type: campaignActionTypes.SAVE_TEMPLATE,
    payload: data,
		successCallBack
  }),
  saveWhatsAppTemplate: (data: SMSTemplateReqObj, successCallBack: () => {}) => ({
    type: campaignActionTypes.SAVE_WHATS_APP_TEMPLATE,
    payload: data,
		successCallBack
  }),
  saveEmailTemplate: (data: {template: string; templateName: string}, successCallBack: Function) => ({
    type: campaignActionTypes.SAVE_EMAIL_TEMPLATE,
    payload: data,
		successCallBack
  }),
  fetchSavedSMSTemplates: (data: {
    sort: string;
    pageNo: number;
    pageSize: number;
  }) => ({
    type:campaignActionTypes.FETCH_SAVED_SMS_TEMPLATES,
    payload:data
  }),
  fetchSMSLibraryTemplates: (data: {
    sort: string;
    pageNo: number;
    pageSize: number;
  }) => ({
    type: campaignActionTypes.FETCH_SMS_LIBRARY_TEMPLATES,
    payload:data
  }),
  fetchEmailLibraryTemplates: (data: {
    sort: string;
    pageNo: number;
    pageSize: number;
  }) => ({
    type: campaignActionTypes.FETCH_EMAIL_LIBRARY_TEMPLATES,
    payload:data
  }),
  fetchSavedWhatsAppTemplates: (data: {
    sort: string;
    pageNo: number;
    pageSize: number;
  }) => ({
    type:campaignActionTypes.FETCH_SAVED_WHATS_APP_TEMPLATES,
    payload:data
  }),
  fetchWhatsAppLibraryTemplates: (data: {
    sort: string;
    pageNo: number;
    pageSize: number;
  }) => ({
    type: campaignActionTypes.FETCH_WHATS_APP_LIBRARY_TEMPLATES,
    payload:data
  }),
	getSavedSmsTemplateSingle: (data: {id: number}) => ({
		type: campaignActionTypes.GET_SAVED_SMS_TEMPLATE_SINGLE,
		payload: data
	}),
	getSavedWhatsAppTemplateSingle: (data: {id: number}) => ({
		type: campaignActionTypes.GET_SAVED_WHATS_APP_TEMPLATE_SINGLE,
		payload: data
	}),
	saveTemplateSettingTemplate: (data: any) => ({
		type: campaignActionTypes.SAVE_TEMPLATE_SETTING_TEMPLATE,
		payload: data
	})
};
