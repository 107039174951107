import React from "react";
import { Spinner } from "react-bootstrap";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import SurveyWrapper from "../components/SurveyWrapper";
import { surveyService } from "../../../service/api/survey.service";
import { useParams, useHistory } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import "../_style.scss";
import Swal from "sweetalert2";

const BaggageService = ({ chartData }) => {
  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: chartData ? chartData.values : [],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "No. of responses",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">Count: </td>' +
        '<td style="padding:0"><b>{point.y:1f}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Linear scale",
        data: chartData ? chartData.responses : [],
      },
    ],
    colors: [
      "#1BC5BD",
      "#434348",
      "#90ed7d",
      "#f7a35c",
      "#8085e9",
      "#f15c80",
      "#e4d354",
      "#2b908f",
      "#f45b5b",
      "#91e8e1",
    ],
  };

  window.Highcharts = Highcharts;

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card card-custom card-stretch gutter-b">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  {chartData ? (
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={options}
                    />
                  ) : (
                    <div className="font-center">
                      {" "}
                      Loading Chart..{" "}
                      <Spinner animation="border" variant="primary" size="lg" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const MultipleChoiceAnswerDisplay = ({
  answers = [],
  others = [],
  totalResponses,
}) => {
  return (
    <>
      <div className="multiple-options">
        <div className="survey-responses">{totalResponses} responses</div>
        <br />
        {answers.map((item) => {
          const {
            value: option,
            responsePercent: value,
            responseCount: responses,
          } = item;
          return (
            <div className="option-item">
              <div className="options-value-stats">
                <div className="fill-bar" style={{ width: `${value}%` }}></div>
                <div className="option-item-container">
                  <div className="option-label">{option}</div>
                  <div className="option-value">{value} %</div>
                </div>
              </div>
              <div className="number-of-responses">{responses} responses</div>
            </div>
          );
        })}
      </div>
      {others && others.length !== 0 && (
        <>
          <div className="others-heading">Others</div>
          <div className="survey-responses">{others.length} responses</div>
          <div className="question-responses">
            {others.map((item) => {
              return (
                <div className="question-responses-item">
                  <div className="response-text">{item.other || "-"}</div>
                  <div className="response-timestamp">{item.date}</div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

const SliderAnswerDisplay = ({ chartData }) => {
  const data = !chartData
    ? null
    : chartData.reduce((acc, item) => {
        if (acc.values) {
          acc.values.push(item.value);
        } else {
          acc.values = [item.value];
        }
        if (acc.responses) {
          acc.responses.push(item.count);
        } else {
          acc.responses = [item.count];
        }
        return acc;
      }, {});
  return <BaggageService chartData={data} />;
};

const TextAnswers = ({ answers, totalResponses }) => {
  return (
    <div className="question-responses">
      <div className="survey-responses">{totalResponses} Responses</div>
      {answers.map((item) => {
        return (
          <div className="question-responses-item">
            <div className="response-text">{item.answer}</div>
            <div className="response-timestamp">{item.date}</div>
          </div>
        );
      })}
    </div>
  );
};

const StatsTileItem = ({ label, value }) => {
  return (
    <div className="p-5 stats-tile-item">
      <div className="stats-title">{label}</div>
      <div className="stats-value">{value}</div>
    </div>
  );
};

const QuestionCollapse = ({
  questionNo,
  question,
  surveyId,
  questionId,
  // answerTypes,
}) => {
  const answerTypes = {
    // SHORT_ANSWER: "Short Answer",
    // PARAGRAPH_ANSWER: "Paragraph Answer",
    // MULTIPLE_CHOICE: "Multiple Choice",
    // LINEAR_SCALE: "Linear Scale",


    SHORT: '1',
    LONG: '2',
    SLIDER: '3',
    CHECKBOX: '4',
    DROPDOWN: '5',
    RADIO: '6'
  };
  const [open, setOpen] = React.useState(false);
  const [answerData, setAnswerData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const getAnswers = async (surveyId, questionId) => {
    return surveyService.getQuestionAnswers(surveyId, questionId);
  };

  const handleClick = async () => {
    if (open) {
      setOpen(false);
      return;
    } else {
      setLoading(true);
      getAnswers(surveyId, questionId)
        .then((result) => {
          setAnswerData(result.data.data);
          setLoading(false);
          setOpen(true);
        })
        .catch((err) => {
          return Swal.fire(
            "",
            "Could not fetch answers, please try again!",
            "info"
          );
        });
    }
  };

  const renderAnswers = (
    answers = [],
    type,
    otherAnswers = [],
    totalResponses = 0
  ) => {
    switch (type) {
      case answerTypes.CHECKBOX:
        return (
          <div>
            <MultipleChoiceAnswerDisplay
              totalResponses={totalResponses}
              answers={answers}
              others={otherAnswers}
            />
          </div>
        );
      case answerTypes.DROPDOWN:
        return (
          <div>
            <MultipleChoiceAnswerDisplay
              totalResponses={totalResponses}
              answers={answers}
              others={otherAnswers}
            />
          </div>
        );
      case answerTypes.RADIO:
        return (
          <div>
            <MultipleChoiceAnswerDisplay
              totalResponses={totalResponses}
              answers={answers}
              others={otherAnswers}
            />
          </div>
        );
      case answerTypes.LONG:
        return (
          <div>
            <TextAnswers answers={answers} totalResponses={totalResponses} />
          </div>
        );
      case answerTypes.SHORT:
        return (
          <div>
            <TextAnswers answers={answers} totalResponses={totalResponses} />
          </div>
        );

      case answerTypes.SLIDER:
        return (
          <div>
            <SliderAnswerDisplay chartData={answers} />
          </div>
        );
      default:
        return <div>anser type does not exist</div>;
    }
  };

  return (
    <div className="question-section">
      <div
        className="survey-question cursor-pointer"
        aria-controls="example-collapse-text"
        aria-expanded={open}
        onClick={handleClick}
      >
        {questionNo}.&nbsp; {question}
      </div>

      <Collapse in={open}>
        {loading ? (
          <div className="w-100">
            <Spinner
              animation="border"
              variant="primary"
              className="mx-auto d-block"
            />
          </div>
        ) : (
          renderAnswers(
            answerData?.answers?.docs,
            answerData?.optionType,
            answerData?.otherAnswers?.docs,
            answerData?.answers?.totalResponse ||
              answerData?.answers?.totalEntries
          )
        )}
      </Collapse>
    </div>
  );
};

const Summary = (props) => {
  const { id } = useParams();
  const { push } = useHistory();
  const [surveySummary, setSurveySummary] = React.useState({
    // questions: {
    //   docs: [
    //     {id: 378, surveyId: 114, question: "yvjkbhk", description: "", optionType: 3, qOrder: 0},
    //     {id: 379, surveyId: 114, question: "vjvhvhj", description: "", optionType: 4, qOrder: 2},
    //     {id: 380, surveyId: 114, question: "57t6", description: "", optionType: 5, qOrder: 3},
    //     {id: 381, surveyId: 114, question: "uyedtdt", description: "", optionType: 6, qOrder: 4}
    //   ],
    //   totalEntries: 4,
    //   totalPage: 1
    // },
    // widgets: [
    //   {label: "Starts", value: 1},
    //   {label: "Responses", value: 1},
    //   {label: "Views", value: 6},
    //   {label: "Avg. Completion Rate", value: 50},
    //   {label: "Avg. Time Taken", value: "00:00:17"},
    // ]
  });
  const [loading, setLoading] = React.useState(true);

  const getSurveySummary = async (id) => {
    setLoading(true);

    try {
      let { data } = await surveyService.getSurveySummary(id);
      setSurveySummary({
        questions: {
          docs: [
            {id: 378, surveyId: 114, question: "yvjkbhk", description: "", optionType: 3, qOrder: 0},
            {id: 379, surveyId: 114, question: "vjvhvhj", description: "", optionType: 4, qOrder: 2},
            {id: 380, surveyId: 114, question: "57t6", description: "", optionType: 5, qOrder: 3},
            {id: 381, surveyId: 114, question: "uyedtdt", description: "", optionType: 6, qOrder: 4}
          ],
          totalEntries: 4,
          totalPage: 1
        },
        widgets: [
          {label: "Starts", value: 1},
          {label: "Responses", value: 1},
          {label: "Views", value: 6},
          {label: "Avg. Completion Rate", value: 50},
          {label: "Avg. Time Taken", value: "00:00:17"},
        ]
      });
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (id) {
      getSurveySummary(id);
    } else {
      push("/survey");
    }
  }, []);

  return (
    <div className="survey-summary">
      <SurveyWrapper {...props}>
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <>
            <div className="d-flex stats-tiles">
              {surveySummary?.widgets &&
                surveySummary.widgets.map((item) => {
                  return <StatsTileItem {...item} />;
                })}
            </div>
            <div className="summary-section">
              <div className="section-title display-4 font-weight-bold mt-4">
                Summary
              </div>
              {
                // following section should be rendered based on type of question
              }

              {surveySummary?.questions?.docs &&
                surveySummary?.questions?.docs.map((item, index) => {
                  return (
                    <QuestionCollapse
                      questionNo={index + 1}
                      question={item.question}
                      surveyId={id}
                      questionId={item.id}
                      answerTypes={props.answerTypes}
                    />
                  );
                })}
            </div>
          </>
        )}
      </SurveyWrapper>
    </div>
  );
};

export default Summary;
