import { AxiosResponse } from "axios";
import { HTTPClient } from "./http.service";

var qs = require("qs");

export const poolService = {
  async getAllPools() {
    try {
      const result: AxiosResponse = await HTTPClient.get(
        `/company/contacts/pools`
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getPools(params: any) {
    try {
      const result: AxiosResponse = await HTTPClient.get(
        `/company/contacts/pools/`,
        params
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async createPool(data: any) {
    try {
      let result: AxiosResponse = HTTPClient.customRequest({
        url: `/company/contacts/createPool`,
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data),
      });
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async createProperty(data: any) {
    try {
      let result: AxiosResponse = HTTPClient.customRequest({
        url: `/company/contacts/addCustomEntity`,
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data),
      });
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async uploadPool(data: any) {
    try {
      const result: AxiosResponse = await HTTPClient.post(
        `/company/contacts/uploadToPool`,
        data
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async uploadOptOutList(poolId: any, data: any) {
    try {
      const result: AxiosResponse = await HTTPClient.post(
        `/company/contacts/optFromPool/${poolId}`,
        data
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getCustomEntity(params: any) {
    try {
      const result: AxiosResponse = await HTTPClient.get(
        `/company/contacts/getCustomEntity`,
        params
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getPoolMappings(id: number | string) {
    try {
      const result: AxiosResponse = await HTTPClient.get(
        `/company/contacts/getPoolMappings/${id}`
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getTopPropertiesByPool(id: number | string | undefined, params: {property?:string, size?:string | number, pattern?:string}) {
    try {
      const result: AxiosResponse = await HTTPClient.get(
        `/company/contacts/getTopPropertyValue/${id}`,
        params
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getPoolStats(id: string | number) {
    try {
      const result = await HTTPClient.get(
        `/company/contacts/getPoolStats/${id}`
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async updatePool(id: any, data: any) {
    try {
      return await HTTPClient.customRequest({
        url: `/company/contacts/updatePool/${id}`,
        method: "put",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data),
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getPendingPools() {
    try {
      return await HTTPClient.get("/company/contacts/getPendingPools");
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async confirmOptOut(poolId:number){
    try{
      return await HTTPClient.put(`/company/contacts/optFromPool/${poolId}`, null)
    }
    catch(err){
      return Promise.reject(err)
    }
  },
  async removePool(poolId:number){
    try{
      return await HTTPClient.delete(`/company/contacts/removePoolFromQueue/${poolId}`, null)
    }
    catch(err){
      return Promise.reject(err)
    }
  }
};
