import {call, all} from 'redux-saga/effects';
import {authSaga} from './auth/auth.saga';
import {poolSaga} from './pool/pool.saga';
import {fileSaga} from './fileUpload/fileUpload.saga';
import {appStoreSaga} from './appStore/appStore.saga';
import { segmentSaga } from './segment/segment.saga';
import {campaignSaga} from './campaign/campaign.saga'
import {breadcrumbSaga} from './breadcrumbManager/breadcrumb.saga';

function* RootSaga() {
  yield all([
    call(authSaga),
    call(poolSaga),
    call(fileSaga),
    call(segmentSaga),
    call(appStoreSaga),
    call(campaignSaga),
    call(breadcrumbSaga)
  ]);
}

export default RootSaga;