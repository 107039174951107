import React from "react";
import { Spinner } from 'react-bootstrap';
import ReactHighmaps from 'react-highcharts/ReactHighmaps';
import EmptyBarChart from './assets/EmptyBarChart';
import './styles/styles.scss';
const days = {
    MONDAY: 6,
    TUESDAY: 5,
    WEDNESDAY: 4,
    THURSDAY: 3,
    FRIDAY: 2,
    SATURDAY: 1,
    SUNDAY: 0
}
interface Props {
    // chartData: any;
    data: any
}

export const FootfallHeatMap: React.FC<Props> = ({ data, title, isEmpty }: Props) => {
  const defaultData = new Array(24).fill(0).map((item, index) => ({time: index, totalClick: 0}))
    const cleanData = {
      MONDAY: defaultData,
      TUESDAY: defaultData,
      WEDNESDAY: defaultData,
      THURSDAY: defaultData,
      FRIDAY: defaultData,
      SATURDAY: defaultData,
      SUNDAY: defaultData
    }
    Object.keys(data).map(key => {
      if(!data[key.toUpperCase()]) {
        cleanData[key.toUpperCase()] = data[key];
      }
    });

    const keys = Object.keys(cleanData)
    const chartData = keys.reduce((acc, item) => {
        const values = cleanData[item].map(val => {
            return [days[item.toUpperCase()], val.time, val.totalClick]
        })
        return [...acc, ...values]
    }, [])

    const options = {
        chart: {
            type: 'heatmap',
            plotBorderWidth: 1,
            plotBorderColor: 'white'
        },
        title: {
            text: title,
            align: 'left',
            style: {
              fontFamily: 'Inter, Helvetica, "sans-serif"',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '16px',
            }
        },
        yAxis: {
            categories: ["07:00", "09:00", "11:00", "13:00", "15:00", "17:00", "19:00", "21:00", "23:00"],
            style: {
                fontSize: '12px',
                fontWeight: 500,
                fontFamily: 'Inter, Helvetica, "sans-serif"',
                lineHeight: 1.4,
                color: '#b5b5c3',
                textTransform: 'uppercase'
            }

        },
        xAxis: {
            categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].reverse(),
            title: null,
            reversed: true,
            visible: true,
            style: {
                fontSize: '12px',
                fontWeight: 500,
                fontFamily: 'Inter, Helvetica, "sans-serif"',
                lineHeight: 1.4,
                color: '#b5b5c3',
                textTransform: 'uppercase'
            }
        },
        colorAxis: {
            min: 0,
            minColor: isEmpty?'#E0E0E0':'#FFFFFF',
            maxColor: isEmpty?'#E0E0E0':'#0e3947'
        },
        legend: {
            align: 'right',
            layout: 'vertical',
            margin: 0,
            verticalAlign: 'top',
            y: 25,
            symbolHeight: 280
        },
        tooltip: {
            formatter: function () {
                // console.log(this.series.yAxis.categories,this.point.y)
                return '<b>' + this.series.xAxis.categories[this.point.x] + '</b>  <br><b>' +
                    this.point.value + '</b> Clicks after <br><b>' + this.point.y + 'Hrs</b>';
            }
        },
        series: [{
            name: '',
            borderWidth: 1,
            data: chartData?chartData.reverse():[],
            dataLabels: {
                enabled: !isEmpty,
            }
        }],
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                }
            }]
        },
        colors: ["white", "#434348", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"]
    }


    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card card-custom card-stretch gutter-b shadow-none">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    {
                                        chartData
                                            ?
                                            <ReactHighmaps config={options} />
                                            :
                                            <div className="font-center"> Loading Chart.. <Spinner animation="border" variant="primary" size="lg" /></div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
              isEmpty?
              <div className='graph-empty-state'>
                <EmptyBarChart />
              </div>:
              null
            }
        </>
    )

}

export default FootfallHeatMap;
