/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { authActionTypes } from "./../../../../../app/store/auth/auth.actiontype";

export function UserProfileDropdown() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [show, changeShowStatus] = React.useState(false);
  const onHide = () => changeShowStatus(false);
  const { user } = useSelector((state) => state.auth);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light: objectPath.get(uiService.config, "extras.user.dropdown.style") === "light",
    };
  }, [uiService]);

  const Logout = () => {
    console.log("logging out");
    dispatch({ type: authActionTypes.LOGIN_INIT, data: "" });
  };

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle as={DropdownTopbarItemToggler} id="dropdown-toggle-user-profile">
        <div className={"btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"}>
          {
            // user.profilePic?
            //   <img
            //     className = 'person-profile-pic'
            //     src = {user.profilePic}
            //     alt = 'person-profilePic' /> :
            <AccountCircleIcon className="person-profile-pic" style={{ width: "35px", height: "35px" }} />
          }
          <ArrowDropDownIcon />
          {
            // <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
            //   Hi,
            // </span>{" "}
            // <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
            //   {user?.firstName} {user?.lastName}
            // </span>
            // <span className="symbol symbol-35 symbol-light-primary">
            //   <span className="symbol-label font-size-h5 font-weight-bold">
            //     {user && user.firstName&& user?.firstName[0]}
            //   </span>
            // </span>
          }
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <>
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          {layoutProps.light && (
            <>
              <div className="d-flex align-items-center p-8 rounded-top">
                <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                  <img src={toAbsoluteUrl("/media/users/300_21.jpg")} alt="" />
                </div>
                <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                  {user?.firstName} {user?.lastName}
                </div>
              </div>
              <div className="separator separator-solid"></div>
            </>
          )}

          {!layoutProps.light && false && (
            <div
              className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
              style={{
                backgroundImage: `url(${toAbsoluteUrl("/media/misc/bg-1.png")})`,
              }}>
              <div className="symbol bg-white-o-15 mr-3">
                <span className="symbol-label text-success font-weight-bold font-size-h4">
                  {(user && user.firstName && user?.firstName[0]) || ""}
                </span>
                {/*<img alt="Pic" className="hidden" src={user.pic} />*/}
              </div>
              <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                {user?.firstName} {user?.lastName}
              </div>
            </div>
          )}
        </>

        {false && (
          <div className="navi navi-spacer-x-0 pt-5">
            <Link to="/user-profile" className="navi-item px-8 cursor-pointer">
              <div className="navi-link">
                <div className="navi-icon mr-2">
                  <i className="flaticon2-calendar-3 text-primary" />
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold cursor-pointer">My Profile</div>
                  <div className="text-muted">Account settings and more</div>
                </div>
              </div>
            </Link>
            <div className="navi-separator mt-3"></div>

            <div className="navi-footer  px-8 py-5">
              <a
                onClick={() => {
                  dispatch({ type: authActionTypes.LOGOUT_INIT, data: "" });
                }}
                className="btn btn-primary font-weight-bold">
                Sign Out
              </a>
            </div>
          </div>
        )}
        <div className="crm-custom-dropdown">
          <div className="profile-dropdown-header">
            {user?.firstName} {user?.lastName}
          </div>
          <div className="profile-dropdown-actions">
            <div className="profile-dropdown-action cursor-pointer" onClick={() => push("/settings/profile-setting")}>
              <i className="bg-icon bg-icon16 bg-user-circle icon-grey" />
              <span>My Profile</span>
            </div>
            <div className="profile-dropdown-action cursor-pointer" onClick={() => changeShowStatus(true)}>
              <i className="bg-icon bg-icon16 bg-sign-out icon-grey" />
              <span>Log Out</span>
            </div>
          </div>
        </div>
      </Dropdown.Menu>
      <Modal show={show} onHide={onHide} centered className="standard-modal">
        <Modal.Header>
          <Modal.Title>Log out</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body-text-light">Are you sure you want to log out? Any unsaved changes will be lost.</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              dispatch({ type: authActionTypes.LOGOUT_INIT, data: "" });
            }}>
            Confirm
          </Button>
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Dropdown>
  );
}
