import React from "react";
import { Form, Col, Row } from "react-bootstrap";
import { InputDataProps } from "./interface";

const InputCheckbox: React.FC<InputDataProps> = ({ id, name, size, label, required, error, handleCheckboxChange, disabled, value, options }) => {
  const handleChange = (name: string, checked: string, id: string) => {
    let data = { name, value: checked, id };
    handleCheckboxChange(data);
  };

  return (
    <Form.Group controlId={id}>
      <Form.Label>
        {label}
        {required && <span className="text-danger">*</span>}
      </Form.Label>

      <Col sm={12} className="tiersApplicable" style={{ padding: 0 }}>
        {options.map((option, index) => {
          const { isSelected, disabled } = option;
          let checked = isSelected;
          let indexIs = value.indexOf(option.value);
          if (typeof isSelected === "undefined") {
            if (indexIs > -1) {
              checked = true;
            } else {
              checked = false;
            }
          }

          return (
            <div className={`d-flex checkbox-edit mt-5 mb-0 ${size}`} key={`${name}-${option.label}-${index}`}>
              <span className={`input-checkbox ${checked ? "input-checked" : ""}`}>
                <i className="bg-icon bg-icon8 bg-tick-filled icon-white"></i>
              </span>
              <Form.Check
                className="position-relative check-box-label"
                key={index}
                name={name}
                type={"checkbox"}
                inline
                label={option.label}
                id={`inline-${id}-${index}-1`}
                // isInvalid={!!error}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, e.target.value, option.value)}
                disabled={disabled}
                value={option.value}
                checked={checked}
              />
            </div>
          );
        })}
        {error && <p className="error-msg">{error}</p>}
      </Col>
    </Form.Group>
  );
};

export default InputCheckbox;
