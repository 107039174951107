import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import InputText from "../../../components/Forms/Input/Input";
import MyButton from "../../../components/Forms/Button/Button";
import AlertBox from "../../../components/Forms/Alert/Alert";
import OTPVerification from "./OTPVerification";
import NewRestPasswrod from "./NewRestPasswrod";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { formDataProps, INITIAL_STATE_REGISTRATION, errorFormDataProps, validationFormData } from "../_constants/index";
import SignUpBanner from "../components/Banner";
import { authActionTypes } from "../../../store/auth/auth.actiontype";
import "../_styles/index.scss";
import { Link, useLocation } from "react-router-dom";
import { authService } from "../../../service";
import Swal from "sweetalert2";
import { getQueryParams } from "../_utils";
interface eventProps {
  name: string;
  value: string;
}
const AuthPage: React.FC = () => {
  const dispatch = useDispatch();
  const error: string | undefined = useSelector((state: any) => state.auth.error, shallowEqual);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [formData, setFormData] = useState<formDataProps>({
    ...INITIAL_STATE_REGISTRATION,
  });
  const [errors, setErrors] = useState<errorFormDataProps>({});
  const location: any = useLocation();
  const FieldValidation = ({ name, value }: eventProps) => {
    let error: undefined | string = validationFormData[name](value);
    if (error) {
      setErrors({ ...errors, [name]: error });
    } else {
      delete errors[name];
      setErrors(errors);
    }
  };
  const handleChange = ({ name, value }: eventProps) => {
    setFormData({ ...formData, [name]: value });
    if (errors[name]) {
      setErrors({ ...errors, [name]: "" });
    }
    if (error) {
      setError("");
    }
    // FieldValidation({ name, value });
  };
  const handleBlur = ({ name, value }: eventProps) => {
    // FieldValidation({ name, value });
  };
  const validateForm = (formDataValues: formDataProps): boolean => {
    let submitErrors: errorFormDataProps = {};
    for (const [key, value] of Object.entries(formDataValues)) {
      let error: undefined | string = validationFormData[key](value);
      if (error) {
        submitErrors[key] = error;
      }
    }
    setErrors(submitErrors);
    return Object.keys(submitErrors).length === 0;
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    formData.email = location?.state.signInEmail;
    let validationErrors: boolean = validateForm(formData);
    if (validationErrors) {
      try {
        dispatch({ type: authActionTypes.LOGIN_INIT, payload: formData });
      } catch (error) {}
    }
  };

  let queryParams = getQueryParams(location);

  useEffect(() => {
    if (queryParams && queryParams.enc) {
      let encodedToken = queryParams.enc;
      let token = atob(encodedToken);
      dispatch({ type: authActionTypes.LOGIN_INIT_AUTO, payload: token });
    }
  }, []);
};

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [otpSent, changeOtpSentStatus] = useState<boolean>(false);
  const [isRequesting, changeRequestingStatus] = useState<boolean>(false);
  const [otp, changeOTP] = useState<string>("");
  const [otpVerified, changeOtpVerifiedStatus] = useState<boolean>(false);
  const EmailRegex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
  const [formData, setFormData] = useState<formDataProps>({
    email: "",
  });
  const [errors, setErrors] = useState<errorFormDataProps>({});
  const FieldValidation = ({ name, value }: eventProps) => {
    let error: undefined | string = validationFormData[name](value);
    if (error) {
      setErrors({ ...errors, [name]: error });
    } else {
      delete errors[name];
      setErrors(errors);
    }
  };

  const validateForm = (formDataValues: formDataProps): boolean => {
    let submitErrors: errorFormDataProps = {};
    for (const [key, value] of Object.entries(formDataValues)) {
      let error: undefined | string = validationFormData[key](value);
      if (error) {
        submitErrors[key] = error;
      }
    }
    setErrors(submitErrors);
    return Object.keys(submitErrors).length === 0;
  };

  const handleChange = ({ name, value }: eventProps) => {
    setFormData({ ...formData, [name]: value });
    // FieldValidation({ name, value });
    if (errors[name]) {
      setErrors({ ...errors, [name]: "" });
    }
    setError("");
  };
  const handleBlur = ({ name, value }: eventProps) => {
    // FieldValidation({ name, value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    changeRequestingStatus(true);
    let validationErrors: boolean = validateForm(formData);
    if (validationErrors) {
      try {
        await authService.forgotPass_sendEmail(formData.email);
        changeOtpSentStatus(true);
      } catch (err) {
        setError(err.response?.data?.status?.message);
      }
    }
    changeRequestingStatus(false);
  };

  // if(otpVerified) {
  //   return (
  //     <NewRestPasswrod
  //       goBack={() => changeOtpVerifiedStatus(false)}
  //       email={formData.email}
  //       otp={otp}
  //     />
  //   );
  // }
  // if(otpSent) {
  //   return (<OTPVerification
  //     goBack={() => changeOtpSentStatus(false)}
  //     email={formData.email}
  //     otp={otp}
  //     changeOTP={changeOTP}
  //     changeOtpVerifiedStatus={() => {
  //       changeOtpVerifiedStatus(true);
  //       changeOtpSentStatus(false)
  //     }}
  //   />)
  // }

  return (
    <section className="registration-form">
      <div className="container-fluid">
        <Row className="registration-form-container">
          <Col md={7} className="reg-form-wrapper">
            <div className="reg-form-inner">
              <div className="form-logo">
                <img src="/media/onboarding/logo.png" />
                <span>UniSuite</span>
              </div>
            </div>
            <div className="reg-form-inner">
              <div className="form-steps-heading">
                <div className="form-heading">
                  <div className="form-title">Forgot your Password?</div>
                  <div className="form-subtext">
                    Don't worry. Just enter your email address associated with UniSuite and we’ll send you the instructions.
                  </div>
                </div>
              </div>
              <form id="kt_login_signin_form" className="form-box-container" onSubmit={handleSubmit}>
                <InputText
                  label="Email"
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Enter email address"
                  value={formData.email}
                  required={true}
                  // onChange={(e) => setEmail(e.target.value)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.email || error}
                  className="top-0"
                />

                {otpSent ? (
                  <p>Recovery mail has been sent to your email id. Kindly follow the instruction provided there to reset your password</p>
                ) : null}

                <div className="invalid-feedback">{error}</div>
                <MyButton
                  variant="primary"
                  type="submit"
                  name="Reset Password"
                  block={true}
                  disabled={errors.email || error || isRequesting}
                  className="btn-new"
                />
                <div className="wb-go-back-container">
                  <Link to="/auth" className="wb-go-back">
                    <NavigateBeforeIcon /> Go Back
                  </Link>
                </div>
              </form>
            </div>
          </Col>
          <Col md={5} className="reg-form-banner">
            <SignUpBanner signInViaPassword={true} />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default ForgotPassword;
