import React, { useState } from "react";
import InputText from "./Input";

const InputPassword = (props) => {
  const { autoValidate = false, placeholder, label, required = false, value, error, name, showCondition = false } = props;

  let REGEX = {
    number: /\d+/,
    specialChar: /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/,
    lowerCase: /[a-z]/,
    upperCase: /[A-Z]/,
    minLength: 7,
  };

  const [pwd, changePwd] = useState(value);
  const [viewPwd, changeViewPwd] = useState(false);

  const validatePwd = (value) => {
    if (!value) return false;

    let validPointObj = {
      number: false,
      specialChar: false,
      lowerCase: false,
      upperCase: false,
      minLength: false,
    };

    Object.keys(REGEX).forEach((it) => {
      if (it === "minLength") {
        {
          if (value.length > REGEX[it]) {
            validPointObj = { ...validPointObj, [it]: true };
          }
        }
      } else if (REGEX[it].test(value)) {
        validPointObj = { ...validPointObj, [it]: REGEX[it].test(value) };
      } else {
        validPointObj = { ...validPointObj, [it]: REGEX[it].test(value) };
      }
    });

    return validPointObj;
  };

  const isPwdValid = (obj) => {
    if (autoValidate) {
      return Object.keys(obj).every((it) => obj[it]);
    }
    return true;
  };

  return (
    <div>
      <div className="position-relative">
        <InputText
          required={required}
          label={label}
          type={viewPwd ? "text" : "password"}
          name={name}
          id={name}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            props.onChange(e);
          }}
          disabled={false}
          onBlur={() => {}}
          error={
            error
              ? error
              : !isPwdValid(validatePwd(value)) && showCondition
              ? "Password does not meet all conditions"
              : !isPwdValid(validatePwd(value))
          }
        />

        <div className="password-visible">
          <div
            className={`cursor-pointer bg-icon bg-icon bg-icon16 icon-grey ${viewPwd ? "bg-eye-off" : "bg-eye"}  ${
              !isPwdValid(validatePwd(value)) ? "icon-red" : ""
            }`}
            onClick={() => {
              changeViewPwd(!viewPwd);
            }}
          />
        </div>
      </div>

      {autoValidate && !isPwdValid(validatePwd(value)) ? (
        <>
          <div className="password-check-container">
            <div className="pcc-heading">Password must include:</div>

            <div className={`pcc-test-title`}>
              At least 8 characters long
              <div>{validatePwd(value)?.minLength ? <div className="bg-icon bg-check icon-grey bg-icon16" /> : null}</div>
            </div>

            <div className={`pcc-test-title`}>
              One uppercase character
              <div>{validatePwd(value)?.upperCase ? <div className="bg-icon bg-check icon-grey bg-icon16" /> : null}</div>
            </div>

            <div className={`pcc-test-title `}>
              One lowercase character
              <div>{validatePwd(value)?.lowerCase ? <div className="bg-icon bg-check icon-grey bg-icon16" /> : null}</div>
            </div>

            <div className={`pcc-test-title `}>
              One symbol
              <div>{validatePwd(value)?.specialChar ? <div className="bg-icon bg-check icon-grey bg-icon16" /> : null}</div>
            </div>

            <div className={`pcc-test-title `}>
              One number <div>{validatePwd(value)?.number ? <div className="bg-icon bg-check icon-grey bg-icon16" /> : null}</div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default InputPassword;
