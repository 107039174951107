import React from "react";
import { Row, Col, DropdownButton, Dropdown } from "react-bootstrap";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowDownwardOutlinedIcon from "@material-ui/icons/ArrowDownwardOutlined";
import ArrowUpwardOutlinedIcon from "@material-ui/icons/ArrowUpwardOutlined";
import RefreshIcon from '@material-ui/icons/Refresh';
import ConvertionRaitoGraph from "../../components/graphs/ConvertionRaitoGraph";
import WeeklyPerformance from "../../components/graphs/WeeklyPerformance";
import BarGraph from "../../components/graphs/BarGraph";
import TreeMap from "../../components/graphs/TreeMap";
import HeatMap from "../../components/graphs/HeatMap";
import FunnelHighChart from "../../components/graphs/FunnelHighChart";
import BrowserReport from "../../components/graphs/BrowserReport";
import ArrowRightOutlinedIcon from "@material-ui/icons/ArrowRightOutlined";
import OperatingSystemReport from "../../components/graphs/OperatingSystemReport";
import PieChart from "../../components/graphs/PieChart";
import DropDown from "../../components/DropDown.jsx";
import WorldReport from '../../components/graphs/WorldReport';
import EmptyBarChart from '../../components/graphs/assets/EmptyBarChart';
import countryCodeNameMapping from '../../constants/countryCodeNameMapping';
import {analyticsService} from '../../service/api/analytics.service';
import {SegmentConversionRatioDummyData} from '../../constants/graphEmptyState';
import {getDateAndTime} from "../../utils/date";
import "./_styles/SmsReport.scss";
import {roundOff} from '../../utils/utils';



const campaignColors = [
  '#1BC5BD',
  '#8085E9',
  '#41D4A8',
  '#FF8D08',
  '#3EAF86',
  '#418DD4',
  '#3551DD',
  '#3793A7',
  '#EC758B',
  '#FFC130'
];

interface IAnalytics {
    landingPageName: any,
    landingPageCreatedAt: any,
    landingPageUpdatedDate: any,
    status: any,
    isFunnel: any,
    time: any,
    country: any,
    os: any,
    browser: any,
    totalUsers: any,
    totalPageViews: any,
    totalUniquePageViews: any,
    totalGameStart: any,
    totalPrizeClaimed: any,
    bounceRate: any,
    bounceRateRatio: any,
    conversionRatio: any,
    totalUsersRatio: any,
    prizeClaimedRatio: any,
    totalUniquePageRatio: any,
    avgSession: any
  };
const selectionRange = {
  startDate: new Date(),
  endDate: new Date(),
  key: 'selection',
}

const IconIndicator = (props: { type: string | undefined }) => {
  switch (props.type) {
    case 'up': return <ArrowUpwardOutlinedIcon />;
    case 'down': return <ArrowDownwardOutlinedIcon />;
    default: return <div></div>
  }
}

export const DashboardPage: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [analyticsData, setAnalyticsData] = React.useState<IAnalytics>({});
  const [countriesMap, setCountryData] = React.useState(null)
  const [showDateRangeFilter, applyDateRange] = React.useState(false);
  // 1: All 2: Loyalty Games 3: Campaigns
  const [selectedBrowserData, changeSelectedBrowserData] = React.useState(2);
  const [selectedOSData, changeSelectedOSData] = React.useState(2);
  const [selectedCountryData, changeSelectedCountryData] = React.useState(2);
  const [selectedCampaignMessages, changeCampaignSelectedMenuItems] = React.useState([1]);

  const [currentActiveRange, setCurrrentActiveRange] = React.useState(1)
  const [rangeSelection, changeRange] = React.useState(selectionRange);
  const [expandedCountry, addExpandedCountry] = React.useState([]);

  const handleDateRange = (eventKey: any) => {
    setCurrrentActiveRange(eventKey)
    if(eventKey == 3) {
      applyDateRange(true);
    }
  }

  const onCountryClick = (item) => {
    const newData = expandedCountry.slice();
    const itemIndex = newData.indexOf(item.name);
    if(itemIndex === -1) {
      newData.push(item.name)
    } else {
      newData.splice(itemIndex, 1);
    }
    addExpandedCountry(newData);
  }

  const country = [
    // {"name": "US",
    //             "totalClick": 217,
    //             "percent": 97};
    {name: "IN", country: "India", totalClick: 5, percent: 64},
    {name: "SG", country: "Singapore", totalClick: 5, percent: 23},
    {name: "US", country: "United States of America", totalClick: 5, percent: 11},
    {name: "CA", country: "Canada", totalClick: 5, percent: 2},
    {name: "TH", country: "Thailand", totalClick: 5, percent: 1}
  ];

  const getData = async (params: any) => {
    setLoading(true);
    const queryResult = await analyticsService.getDashboardAnalytics(params);
    let data: any = {};
    data = queryResult;
    setAnalyticsData({...analyticsData, ...data});
    setCountryData(data.country);
    setLoading(false);
  }

  React.useEffect(() => {
    let params: any = {};
    if (currentActiveRange == 1) {
      params = {
        days: 7
      }
    } else if (currentActiveRange == 2) {
      params = {
        days: 30
      }
    }
    setTimeout(() => {
      getData(params);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActiveRange]);

  const onCampaignItemClicked = (item: any) => {
    const selectedArray = selectedCampaignMessages.slice();
    if(item.value === 1) {
      changeCampaignSelectedMenuItems([1]);
    } else {
      const indexAll = selectedArray.indexOf(1);
      if(indexAll !== -1) {
        selectedArray.splice(indexAll, 1);
      }
      const index = selectedCampaignMessages.indexOf(item.value);
      if(index === -1) {
        selectedArray.push(item.value);
      } else {
        selectedArray.splice(index, 1);
      }
      if(selectedArray.length === 0) {
        changeCampaignSelectedMenuItems([1]);
      } else {
        changeCampaignSelectedMenuItems(selectedArray);
      }
    }
  }

  const {
    campaign,
    campaignConversionRate,
    campaignFunnel,
    companyId,
    companyName,
    conversionRatio,
    createdAt,
    highestPerformingSegment,
    loyality,
    loyalityConversionRatio,
    loyalityRatio,
    quizCompleted,
    quizCompletedRatio,
    scratchcardUnlocked,
    scratchcardUnlockedRatio,
    spinwheelCompleted,
    spinwheelCompletedRatio,
    surveyCompleted,
    surveyCompletedRatio,
    time,
    totalCouponUnlocked,
    totalCouponUnlockedRatio,
    totalCustomerBase,
    totalGameStart,
    totalUserAcquired,
    totalUsers,
    updatedAt
  } = analyticsData;

  const getCampaignMessages = () => {
    if(typeof campaign === 'undefined') {
      return {
        sent: 0, delivered: 0, totalClick: 0, uniqueClick: 0
      }
    }
    let isAll = false;
    const {
      all, sms, whatsapp, email
    } = campaign;
    const data = {
      sent: 0, delivered: 0, totalClick: 0, uniqueClick: 0
    };
    selectedCampaignMessages.map((item: number) => {
      switch(item) {
        case 1:
          isAll = true;
        break;
        case 2:
          // email
          data.sent+=email?.sent;
          data.delivered+=email?.delivered;
          data.totalClick+=email?.totalClick;
          data.uniqueClick+=email?.uniqueClick;
        break;
        case 3:
          // SMS
          data.sent+=sms?.sent;
          data.delivered+=sms?.delivered;
          data.totalClick+=sms?.totalClick;
          data.uniqueClick+=sms?.uniqueClick;
        break;
        case 4:
          // Whats App
          data.sent+=whatsapp?.sent;
          data.delivered+=whatsapp?.delivered;
          data.totalClick+=whatsapp?.totalClick;
          data.uniqueClick+=whatsapp?.uniqueClick;
        break;
        case 5:
          // Web Notification
        break;
        case 6:
          // Push notification
        break;
        default:
        break;
      }
    });

    if(isAll) {
      return all;
    }
    return data;
  }

  const campaignMessages = getCampaignMessages();

  const applyDataFilter = () => {
      const startDate = rangeSelection.startDate;
      const endDate= rangeSelection.endDate;
      const params = {
        startDate: `${startDate.getUTCFullYear()}-${startDate.getUTCMonth()+1}-${startDate.getDate()}`,
        endDate: `${endDate.getUTCFullYear()}-${endDate.getUTCMonth()+1}-${endDate.getDate()}`
      }
      getData(params);
      applyDateRange(false);
  };


  React.useEffect(() => {
    setCountryData([]);
  }, []);

  // const allOSData = {};
  // loyality?.os?.map((item: any) => {
  //   allOSData[item.name] = item.totalClick;
  // });
  // campaign?.os?.map((item: any) => {
  //   if(allOSData[item.name]) {
  //     allOSData[item.name]+=item.totalClick;
  //   } else {
  //     allOSData[item.name]=item.totalClick;
  //   }
  // })
  // const allBrowserData = {}
  // loyality?.brower?.map((item: any) => {
  //   allBrowserData[item.name] = item.totalClick;
  // });
  // campaign?.brower?.map((item: any) => {
  //   if(allBrowserData[item.name]) {
  //     allBrowserData[item.name]+=item.totalClick;
  //   } else {
  //     allBrowserData[item.name]=item.totalClick;
  //   }
  // })

  const osData = {
    // 1: Object.keys(allOSData).map(key=> ({name: key, value: allOSData[key]})),
    2: loyality?.os?.map((item: any) => ({...item, name: item.name, value: roundOff(item.percent, 2)})) || [],
    3: campaign?.os?.map((item: any) => ({...item, name: item.name, value: roundOff(item.percent, 2)})) || [],
  };
  const browserData = {
    // 1: Object.keys(allBrowserData).map(key=> ({name: key, value: allBrowserData[key]})),
    2: loyality?.browser?.map((item: any) => ({...item, name: item.name, value: roundOff(item.percent, 2)})) || [],
    3: campaign?.browser?.map((item: any) => ({...item, name: item.name, value: roundOff(item.percent, 2)})) || []
  };

  const countryData = {
    // 1: Object.keys(allCountryData).map(key=> ({name: key, totalClick: allCountryData[key]})),
    // 2: country,
    2: loyality?.country?.map((item: any) => ({...item, name: item.name, totalClick: item.totalClick})) || [],
    3: campaign?.country?.map((item: any) => ({...item, name: item.name, totalClick: item.totalClick})) || []
  };

  if(loading) {
    return 'loading';
  }

  const isCountryWiseDataEmpty = !(countryData[selectedCountryData] && countryData[selectedCountryData].length);
  const countryDataForGraph = countryData[selectedCountryData] && countryData[selectedCountryData].length?
    countryData[selectedCountryData]: [
      {name: "India", totalClick: 120, percent: null},
      {name: "Singapore", totalClick: 60, percent: null},
      {name: "Unnited States of America", totalClick: 20, percent: null}
    ];
  return (
    <div className="sms-report-container container">
      <div className="container my-5 py-5">
        <div className="card border-0">
         <div className="card-body py-0 border-0 mt-3">
           <Row>
             <Col className='my-3'>
               <div className='campaign-basic-info d-flex justify-content-between'>
                 <div className='mt-2 info-header-2'>
                   <RefreshIcon />
                   <span className='ml-2'>Last Updated at {getDateAndTime(new Date())}{" "}</span>
                 </div>
                 <div className='info-header-1'>
                   <div className='export-action d-flex'><div className='bg-icons bg-blue-download mr-3' /> Export</div>
                 </div>
               </div>
             </Col>
           </Row>

           {false && <Row>
             <Col>
               <div className="dates-and-filter">
                 <div className="campaign-dates">
                   <div className="date-detail">
                     <div className="date-detail-label"></div>
                     <div className="date-detail-value"></div>
                   </div>
                 </div>
                 <div className="campaign-filters campaign-filters-week position-relative d-flex align-items-center">
                   <div className="text font-weight-bold mr-3">
                     Select Range
                   </div >
                   <DropdownButton id="dropdown-basic-button" title={currentActiveRange == 1 ? 'Last week' : currentActiveRange == 2 ? 'Last month' : currentActiveRange == 3 ? 'Custom Range' : ''} onSelect={handleDateRange}>
                     <Dropdown.Item eventKey='1'>Last week</Dropdown.Item>
                     <Dropdown.Item eventKey='2'>
                       Last month
                     </Dropdown.Item>
                     <Dropdown.Item eventKey='3'>
                       Custom Range
                     </Dropdown.Item>
                   </DropdownButton>
                 </div>
               </div>
             </Col>
           </Row>}
           <hr />
          <div className="overview">
            <h3 className="font-weight-bold py-2 pr-2">Overview</h3>
            <div className='d-flex mt-7'>
              <div className="stats-tile-container flex-grow-1 mr-7 w-50">
                <div className="d-flex campaign-stats">
                  <div className="stats-icon-wrapper">
                    <div class="bg-icons bg-blue-multi-user"></div>
                  </div>
                  <div className="ml-3">Total Customer Base</div>
                </div>
                <div className="stats-value mt-3">{totalCustomerBase}</div>
              </div>
              <div className="stats-tile-container flex-grow-1 w-50">
                <div className="d-flex campaign-stats">
                  <div className="stats-icon-wrapper">
                    <div class="bg-icons bg-blue-coupon"></div>
                  </div>
                  <div className="ml-3">Total Number of Coupons Unlocked</div>
                </div>
                <div className="stats-value mt-3">{totalCouponUnlocked}</div>
              </div>
            </div>

            <div className="stats-tile-container mt-7 position-relative">
              <BarGraph
                  categories={highestPerformingSegment?.segment}
                  xAxis={''}
                  yAxis={'Conversion Ratio %'}
                  data={
                    [{
                      name: '',
                      data: highestPerformingSegment?.value
                    }]
                  }
                  isEmpty={highestPerformingSegment?.value?.length === 0}
                  title="Highest Performing Segment"
                  emptyData={SegmentConversionRatioDummyData}
              />
            </div>

            {(
              <Row className="mt-7 position-relative">
                <Col className="my-3" md={12}>
                  <div className="stats-tile-container position-relative">
                    <div className='d-flex justify-content-between'>
                      <span>Country Wise Distribution of Users</span>
                      <div className='filter-dropdown'>
                        <DropDown items={[
                            // {label: 'All', value: 1},
                            {label: 'Loyalty Games', value: 2},
                            {label: 'Campaigns', value: 3}
                          ]}
                          title={
                            <span className='cursor-pointer'>
                              {
                                {
                                  // 1: 'All',
                                  2: 'Loyalty Games',
                                  3: 'Campaigns',
                                }[selectedCountryData]
                              }<KeyboardArrowDownIcon />
                              </span>
                            }
                          onMenuItemClicked={(item) => {
                            changeSelectedCountryData(item.value)
                          }}
                        />
                      </div>
                    </div>

                    <Row className="mt-5">
                      <Col className="my-3" md={6}>
                        {
                          countryDataForGraph && countryDataForGraph.length? <WorldReport
                            countries={countryDataForGraph}
                            expandedCountry={expandedCountry}
                            onCountryClick={onCountryClick}
                          />: <WorldReport
                            countries={countryDataForGraph}
                            expandedCountry={[]}
                            onCountryClick={() => {}}
                          />
                        }
                        {/* {<WorldReport data={countriesMap} />} */}
                      </Col>
                      <Col className="my-3" md={6}>
                        <div className="country-report-items">
                          {countryDataForGraph &&
                            countryDataForGraph.map((item: any, index: number) => {
                              const value = roundOff(item.percent, 2);
                              const nameOfContry = countryCodeNameMapping[item.code]?.name;
                              return (
                                <div className="country-report-item" onClick={() => onCountryClick({name: nameOfContry})}>
                                  <Row className="my-3">
                                    <Col className="my-3" md={4}>
                                      <div className="country-detail">
                                        <div className="flag-icon-wrapper">
                                          <img
                                            className="flag-icon"
                                            src={item.flag}
                                            alt="flagIcon"
                                          />
                                        </div>
                                        <span className="country-name cursor-pointer">
                                          {nameOfContry}
                                        </span>
                                      </div>
                                    </Col>
                                    <Col className="my-3" md={8}>
                                      <div className="country-stats-container">
                                        <div className="country-stats-graph">
                                          <div
                                            className="stats-actual-value"
                                            style={{
                                              width: `${value || 0
                                                }%`,
                                            }}
                                          ></div>
                                        </div>
                                        <div className="country-stats-number">
                                          {value ? value : isCountryWiseDataEmpty? '-': 0} %
                                        </div>
                                        <ArrowRightOutlinedIcon />
                                      </div>
                                    </Col>
                                  </Row>
                                  {
                                    expandedCountry.indexOf(nameOfContry) !== -1? <div className='px-3'>
                                      <div><label>Total clicks - </label><span>&nbsp;{item.totalClick}</span></div>
                                      {/*<div><label>Unique clicks - </label><span>&nbsp;{value}</span></div>*/}
                                      <div><label>Percent - </label><span>&nbsp;{roundOff(item.percent, 2)} %</span></div>
                                    </div>: null
                                  }
                                </div>
                              );
                            })}
                        </div>
                      </Col>
                    </Row>
                    {
                      isCountryWiseDataEmpty?
                      <div className='world-report-empty-state'>
                        <EmptyBarChart />
                      </div>: null
                    }
                  </div>
                </Col>
              </Row>
            )}

            <Row className="mt-5">
              <Col className="my-3" md={6}>
                <div className="stats-tile-container position-relative">
                  <div className='d-flex justify-content-between'>
                    <span>User’s Operating System Distribution</span>
                    <div className='filter-dropdown'>
                      <DropDown items={[
                          // {label: 'All', value: 1},
                          {label: 'Loyalty Games', value: 2},
                          {label: 'Campaigns', value: 3}
                        ]}
                        title={
                          <span className='cursor-pointer'>
                            {
                              {
                                // 1: 'All',
                                2: 'Loyalty Games',
                                3: 'Campaigns',
                              }[selectedOSData]
                            }<KeyboardArrowDownIcon />
                            </span>
                          }
                        onMenuItemClicked={(item) => {
                          changeSelectedOSData(item.value)
                        }}
                      />
                    </div>
                  </div>
                  <OperatingSystemReport
                    data={osData[selectedOSData]?.sort((a,b) => b.value-a.value)}
                    isEmpty={osData[selectedOSData].length === 0}
                    emptyData={
                      [
                        {name: "MacOS", value: null, percent: null},
                        {name: "Windows", value: null, percent: null},
                        {name: "Android", value: null, percent: null},
                        {name: "iOS", value: null, percent: null}
                      ]
                    }
                  />
                </div>
              </Col>
              <Col className="my-3" md={6}>
                <div className="stats-tile-container position-relative">
                  <div className='d-flex justify-content-between'>
                    <span>User’s Browser Distribution</span>
                    <div className='filter-dropdown'>
                      <DropDown items={[
                          // {label: 'All', value: 1},
                          {label: 'Loyalty Games', value: 2},
                          {label: 'Campaigns', value: 3}
                        ]}
                        title={
                          <span className='cursor-pointer'>
                            {
                              {
                                // 1: 'All',
                                2: 'Loyalty Games',
                                3: 'Campaigns',
                              }[selectedBrowserData]
                            }<KeyboardArrowDownIcon />
                            </span>
                          }
                        onMenuItemClicked={(item) => {
                          changeSelectedBrowserData(item.value)
                        }}
                      />
                    </div>
                  </div>
                  <BrowserReport
                    data={browserData[selectedBrowserData]?.sort((a,b) => b.value-a.value)}
                    isEmpty={browserData[selectedBrowserData].length === 0}
                    emptyData={
                      [
                        {name: "Firefox", value: null, percent: null},
                        {name: "Chrome", value: null, percent: null},
                        {name: "Safari", value: null, percent: null}
                      ]
                    }
                  />
                </div>
              </Col>
            </Row>
          </div>

          <div className="loyality-games">
            <h3 className="font-weight-bold py-2 pr-2">Loyalty Games</h3>
            <Row>
              <Col md={4} style={{height: '100%'}}>
                <div className="stats-tile-container position-relative">
                  <div className="d-flex campaign-stats">
                    <div className="stats-icon-wrapper">
                      <div className="bg-icons bg-blue-stats" />
                    </div>
                    <div className="ml-3">Loyalty Games Conversion ratio</div>
                  </div>
                  <div className="stats-value-graph">
                    <ConvertionRaitoGraph
                      data={[
                        ["Conversion", conversionRatio],
                        ["Bounce", 100-conversionRatio],
                      ]}
                      lable='Games'
                      conversionRate={conversionRatio}
                      isEmpty={loyalityConversionRatio?.percent === 0}
                      emptyData={
                        []
                      }
                    />
                  </div>
                  <div className="changes-in-stats d-flex justify-content-center">
                    <span className={`changes ${loyalityConversionRatio?.key === 'down'? 'negative': 'positive'}`}>
                      <IconIndicator type={loyalityConversionRatio?.key} />
                      {loyalityConversionRatio?.percent || '0'} %
                    </span>
                    <span>&nbsp; vs last 7 days</span>
                  </div>
                </div>
              </Col>
              <Col md={8}>
                <Row>
                  <Col md={12}>
                    <div className='d-flex'>
                      <div className="stats-tile-container flex-grow-1 w-50 mr-7 d-flex flex-column justify-content-center">
                        <div className="d-flex campaign-stats">
                          <div className="ml-0">Number of Surveys Completed</div>
                        </div>
                        <div className="stats-value mt-3">{surveyCompleted}</div>
                      </div>
                      <div className="stats-tile-container flex-grow-1 w-50 d-flex flex-column justify-content-center">
                        <div className="d-flex campaign-stats">
                          <div className="ml-0">Number of Quizes Completed</div>
                        </div>
                        <div className="stats-value mt-3">{quizCompleted}</div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className='d-flex mt-7'>
                      <div className="stats-tile-container flex-grow-1 w-50 mr-7 d-flex flex-column justify-content-center">
                        <div className="d-flex campaign-stats">
                          <div className="ml-0">Number of Spin the Wheels Completed</div>
                        </div>
                        <div className="stats-value mt-3">{spinwheelCompleted}</div>
                      </div>
                      <div className="stats-tile-container flex-grow-1 w-50 d-flex flex-column justify-content-center">
                        <div className="d-flex campaign-stats">
                          <div className="ml-0">Number of Scratch Cards Unlocked</div>
                        </div>
                        <div className="stats-value mt-3">{scratchcardUnlocked}</div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className='mt-7'>
              <Col md={5}>
                <div className="stats-tile-container position-relative">
                  <HeatMap
                    title={'Loyalty Games Usage Heat Map'}
                    data={time?time:{}}
                    isEmpty={!(time && Object.keys(time).length)}
                  />
                </div>
              </Col>
              <Col md={7}>
                <div className="stats-tile-container position-relative">
                  <FunnelHighChart
                    title={'Cumulative Loyalty Games Funnel'}
                    funnelData={[
                      ['Total', campaignFunnel?.totalUsers],
                      // ['Clicked', 100 ],
                      ['Game Started', campaignFunnel?.started],
                      ['Loyalty Game Completed', campaignFunnel?.compleated]
                    ]}
                    isEmpty={campaignFunnel?.totalUsers === 0}
                    emptyData={[
                      ['Total', 300],
                      // ['Clicked', 100 ],
                      ['Game Started', 200],
                      ['Loyalty Game', 50]
                    ]}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="stats-tile-container mt-7 position-relative">
                  <div className='d-flex justify-content-between'>
                    <span>Coupons Unlock Distribution in Loyalty Games</span>
                  </div>
                  <PieChart
                    data={[
                      { name: 'Spin the Wheel', value: roundOff(loyalityRatio?.spinwheel, 2) },
                      { name: 'Scratch Card', value: roundOff(loyalityRatio?.scratchcard, 2) },
                      { name: 'Quiz', value: roundOff(loyalityRatio?.quiz, 2) },
                      { name: 'Survey', value: roundOff(loyalityRatio?.survey, 2) },
                    ]}
                    isEmpty={ loyalityRatio && Object.values(loyalityRatio).every(v => v === 0)}
                    emptyData={[
                      { name: 'Spin the Wheel', value: null },
                      { name: 'Scratch Card', value: null },
                      { name: 'Quiz', value: null },
                      { name: 'Survey', value: null },
                    ]}
                    colors={['#8085E9', '#1BC5BD', '#F5EF55', '#FFC130']}
                  />
                </div>
              </Col>
            </Row>

            <Row className='mt-7'>
              <Col md={12}>
                <div className="stats-tile-container position-relative">
                  <WeeklyPerformance
                    yAxis='User Count'
                    title='Total Users Acquired via Loyalty Games in last 7 days'
                    categories={totalUserAcquired?.day || []}
                    data={totalUserAcquired?.value || []}
                    isEmpty={!(totalUserAcquired && totalUserAcquired.value.length !== 0)}
                    emptyData={{
                      data: [50, 75, 65, 55, 85, 77, 50],
                      categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
                    }}
                  />
                </div>
              </Col>
            </Row>

          </div>

          <div className="loyality-games mt-7">
            <h3 className="font-weight-bold py-2 pr-2">Campaigns</h3>
            <Row>
              <Col md={12}>
                <div className='stats-tile-container p-5'>
                  <div className='d-flex justify-content-between'>
                    <div className='graph-stats-title'>Campaign Messages</div>
                    <DropDown items={[
                        {label: 'All', value: 1, selected: selectedCampaignMessages.indexOf(1) !== -1},
                        {label: 'Email', value: 2, selected: selectedCampaignMessages.indexOf(2) !== -1},
                        {label: 'SMS', value: 3, selected: selectedCampaignMessages.indexOf(3) !== -1},
                        {label: 'Whatsapp', value: 4, selected: selectedCampaignMessages.indexOf(4) !== -1},
                        {label: 'Web Notification', value: 5, selected: selectedCampaignMessages.indexOf(5) !== -1},
                        {label: 'Push Notification', value: 6, selected: selectedCampaignMessages.indexOf(6) !== -1}
                      ]}
                      showCheckBox={true}
                      title={<span className='cursor-pointer'>Filter by <KeyboardArrowDownIcon /></span>}
                      onMenuItemClicked={(item : any) => {
                        onCampaignItemClicked(item);
                      }}
                    />
                  </div>
                  <div className="d-flex justify-content-around mx-5 mt-5">
                    <div className='flex-grow-1 mr-6 campaign-message-stat'>
                      <div className="d-flex campaign-stats align-items-start">
                        <div className="stats-icon-wrapper">
                          <div class="bg-icons bg-blue-tick"></div>
                        </div>
                        <div className="ml-3">
                          <div>Number of Messages Sent</div>
                          <div className="stats-value mt-3">{campaignMessages.sent}</div>
                        </div>
                      </div>
                    </div>
                    <div className='flex-grow-1 mr-6 campaign-message-stat'>
                      <div className="d-flex campaign-stats align-items-start">
                        <div className="stats-icon-wrapper">
                          <div class="bg-icons bg-blue-sun"></div>
                        </div>
                        <div className="ml-3">
                          <div>Number of Clicks</div>
                          <div className="stats-value mt-3">{campaignMessages.totalClick}</div>
                        </div>
                      </div>
                    </div>
                    <div className='flex-grow-1 mr-6 campaign-message-stat'>
                      <div className="d-flex campaign-stats align-items-start">
                        <div className="stats-icon-wrapper success">
                          <div class="bg-icons bg-green-double-tick"></div>
                        </div>
                        <div className="ml-3">
                          <div>Number of Messages Delivered</div>
                          <div className="stats-value mt-3">{campaignMessages.delivered}</div>
                        </div>
                      </div>
                    </div>
                    <div className='flex-grow-1 mr-6 campaign-message-stat no-border'>
                      <div className="d-flex campaign-stats align-items-start">
                        <div className="stats-icon-wrapper">
                          <div class="bg-icons bg-blue-person"></div>
                        </div>
                        <div className="ml-3">
                          <div>Number of Unique Clicks</div>
                          <div className="stats-value mt-3">{campaignMessages.uniqueClick}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className='mt-7'>
              <Col md={12}>
                <div className="stats-tile-container position-relative">
                  <TreeMap
                    data={campaignConversionRate?.map((item, index) => ({
                      name: item.name,
                      value: item.value,
                      color: campaignColors[index],
                      className: 'tier-tree-map-item',
                      isVisibleInLegend: true
                    }))}
                    title='Campaign Conversion Rate Treemap'
                    isEmpty={campaignConversionRate?.length === 0}
                    emptyData={[
                      {
                        name: 'Campaign 1',
                        value: Math.floor(Math.random() * 50),
                        color: 'darkgray',
                        className: 'tier-tree-map-item',
                        isVisibleInLegend: true
                      },
                      {
                        name: 'Campaign 2',
                        value: Math.floor(Math.random() * 50),
                        color: 'darkgray',
                        className: 'tier-tree-map-item',
                        isVisibleInLegend: true
                      },
                      {
                        name: 'Campaign 3',
                        value: Math.floor(Math.random() * 50),
                        color: 'darkgray',
                        className: 'tier-tree-map-item',
                        isVisibleInLegend: true
                      },
                      {
                        name: 'Campaign 4',
                        value: Math.floor(Math.random() * 50),
                        color: 'darkgray',
                        className: 'tier-tree-map-item',
                        isVisibleInLegend: true
                      },
                      {
                        name: 'Campaign 5',
                        value: Math.floor(Math.random() * 50),
                        color: 'darkgray',
                        className: 'tier-tree-map-item',
                        isVisibleInLegend: true
                      },
                      {
                        name: 'Campaign 6',
                        value: Math.floor(Math.random() * 50),
                        color: 'darkgray',
                        className: 'tier-tree-map-item',
                        isVisibleInLegend: true
                      },
                      {
                        name: 'Campaign 7',
                        value: Math.floor(Math.random() * 50),
                        color: 'darkgray',
                        className: 'tier-tree-map-item',
                        isVisibleInLegend: true
                      },
                      {
                        name: 'Campaign 8',
                        value: Math.floor(Math.random() * 50),
                        color: 'darkgray',
                        className: 'tier-tree-map-item',
                        isVisibleInLegend: true
                      },
                      {
                        name: 'Campaign 9',
                        value: Math.floor(Math.random() * 50),
                        color: 'darkgray',
                        className: 'tier-tree-map-item',
                        isVisibleInLegend: true
                      },
                      {
                        name: 'others',
                        value: Math.floor(Math.random() * 50),
                        color: 'darkgray',
                        className: 'tier-tree-map-item',
                        isVisibleInLegend: true
                      }
                    ]}
                  />
                </div>
              </Col>
            </Row>
          </div>

         </div>
        </div>
      </div>
    </div>
  );
};
