import React from "react";
import { useHistory } from "react-router-dom";
import StepTracker from "../../../components/StepTracker";
import { Button } from "react-bootstrap";
import { SurveyContext } from "../store/SurveyStateManager";
import SurveyInfo from "../components/SurveyInfo";
import { SURVEY_STEPS, SURVEY_STEPS_KEY } from "../utils";
import Questionnaire from "../components/Questionnaire";
import Preview from "../components/Preview";
const CreateSurvey = () => {
  const { location, ...history } = useHistory();
  const {
    stepper,
    setStepper,
    handleNextStep,
    publishSurvey,
    surveyDates,
    editMode,
    surveyInfoFormik,
    saveAsDraft
  } = React.useContext(SurveyContext);
  const [showSaveModal, setSaveModal] = React.useState(false);
  const [startDate, setStartDate] = React.useState(null);
  const [startTime, setStartTime] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [endTime, setEndTime] = React.useState(null);

  const renderStepper = () => {
    switch (stepper) {
      case SURVEY_STEPS_KEY.SURVEY_INFO:
        return (
          <div className="col-6">
            <SurveyInfo />
          </div>
        );
      case SURVEY_STEPS_KEY.QUESTIONNAIRE:
        return (
          <div className="col-6">
            <Questionnaire />
          </div>
        );
      case SURVEY_STEPS_KEY.TARGETING:
        return (
          <div className="col-12">
            <div>Target</div>
          </div>
        );

      case SURVEY_STEPS_KEY.PREVIEW:
        return (
          <div className="col-12">
            <Preview />
          </div>
        );
      default:
        return "";
    }
  };

  const handlePublish = () => {
    publishSurvey({ startDate, endDate, startTime, endTime });
    // handleNextStep(stepper)
  };

  React.useEffect(() => {
    if (surveyDates) {
      setStartDate(surveyDates.startDate);
      setStartTime(surveyDates.startTime);
      setEndDate(surveyDates.endDate);
      setEndTime(surveyDates.endTime);
    }
  }, [surveyDates]);

  const handleStepper = (stepper) => {
    if(stepper === 1 ) {
      history.push("/loyalty/games/survey/all");
    } else {
      setStepper((stepper) => stepper - 1);
    }

  }

  return (
    <div className="d-flex flex-column h-100">
      <StepTracker steps={[
        {
          label: "Survey Info",
          value: SURVEY_STEPS_KEY.SURVEY_INFO,
        },
        {
          label: "Questionnaire",
          value: SURVEY_STEPS_KEY.QUESTIONNAIRE,
        },
        {
          label: "Overview",
          value: SURVEY_STEPS_KEY.PREVIEW,
        },
      ]} active={stepper} />
      <div className="inner-pages-form-container flex-grow-1">
        <div className="row">{renderStepper()}</div>
        <div className="help-button">
          <div className="bg-icons bg-icon22 bg-question"></div>
        </div>
      </div>
      <div className="create-campaign-footer card-footer d-flex align-items-center">
        {/* {stepper !== SURVEY_STEPS_KEY.SURVEY_INFO && ( */}
          <Button
            className="ufyndBtn ufyndBtn_primary mr-1 ml-4 d-flex gray-button"
            // style={{ marginLeft: "auto" }}
            color="primary"
            onClick={() => {
              handleStepper(stepper);
            }}
          >
            <div className='bg-icons bg-icon16 bg-prev-blue'/>Back
          </Button>
        {/* )} */}

        <div className='d-block ml-auto mr-2'>
          {
            stepper===SURVEY_STEPS_KEY.PREVIEW &&
            <Button
              className="ufyndBtn ufyndBtn_primary save-draft-btn"
              style={{ marginLeft: "10px" }}
              color="primary"
              onClick={() => {
                saveAsDraft();
              }}
            >
              <i className='bg-icons bg-icon16 bg-save-blue'></i>
              Save as draft
            </Button>}
          <Button
            className="ufyndBtn ufyndBtn_primary"
            style={{ marginLeft: "10px" }}
            color="primary"
            onClick={() => {
              if(stepper===SURVEY_STEPS_KEY.PREVIEW) {
                handlePublish();
              } else {
                handleNextStep(stepper);
              }
            }}
          >
            {stepper === SURVEY_STEPS_KEY.PREVIEW ? (
              <span className='d-flex'>
                Publish <i className="bg-icons bg-icon16 bg-next-white"></i>
              </span>
            ) : (
              <div className='d-flex'>Continue<i className="bg-icons bg-icon16 bg-next-white"></i></div>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateSurvey;
