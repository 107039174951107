import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

export default function CharacterTrackerInput({
  label,
  type = "text",
  name,
  placeholder,
  value,
  onChange = () => {},
  onBlur,
  error,
  required = false,
  maxChar,
  disabled,
  textArea,
  allowResize = true
}) {
  const [count, setCount] = React.useState(0);

  const handleInputChange = (e, callback) => {
    // setCount(e.target.value.length);
    callback(e);
  };


React.useEffect(()=>{
  setCount(value.length)
},[value])


  return (
    <FormGroup className="position-relative with_char_tracker resize-none">
      <Label className="ufyndLabel">
        {label} {required && <span className="requiredField">*</span>}
      </Label>
      <div className="position-relative">
        {
          !textArea ?
          <Input
            disabled={disabled}
            type={type}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={(e) => handleInputChange(e, onChange)}
            onBlur={onBlur}
            maxlength={maxChar}
            className="input-box"
          /> :
          <textarea
            disabled={disabled}
            type={type}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={(e) => handleInputChange(e, onChange)}
            onBlur={onBlur}
            className={"form-control text-area " + (maxChar === 500 ? 'text-area-2' : '') }
            maxlength={maxChar}
          ></textarea>

        }

        <span class="character-count-wrapper d-flex align-items-center">
          <span className="current_count">{count}</span>/{maxChar}
          {textArea && allowResize && <div className="bg-icons bg-icon16 bg-text-area icon-left4"></div>}
        </span>
      </div>
      {error && <div className="customCssInputFieldError">{error}</div>}
    </FormGroup>
  );
}
