import { fileActionTypes } from "./fileUpload.actiontype";
import { IFileReducer } from "./types";

const initalState: IFileReducer = {
  fileEnteries: [],
  formData: undefined,
  fileMapping: [],
  fileImportType: null,
  optOutKeys:[]
};

const FileData = (state = initalState, action: { type: String; data: any }) => {
  switch (action.type) {
    case fileActionTypes.SET_FILE_ENTERIES:
      return {
        ...state,
        fileEnteries: action.data,
      };
    case fileActionTypes.SET_FILE:
      return {
        ...state,
        file: action.data,
      };
    case fileActionTypes.SET_FILE_MAPPING:
      return {
        ...state,
        fileMapping: action.data,
      };

    case fileActionTypes.SET_SELECTED_IMPORT_TYPE:
      return {
        ...state,
        fileImportType: action.data,
      };

    case fileActionTypes.SET_OPT_OUT_KEYS:
      return {
        ...state,
        optOutKeys:action.data
      };
    default:
      return state;
  }
};

export default FileData;
