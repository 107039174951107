/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import store, { persistor } from "./app/store/store";
import App from "./app/App";
import "./index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
import "./app/styles/customs.scss";
// import "./app/styles/default.scss";
import { MetronicLayoutProvider, MetronicSplashScreenProvider, MetronicSubheaderProvider } from "./_metronic/layout";
import { QueryClient, QueryClientProvider } from "react-query";
import { MetronicI18nProvider } from "./_metronic/i18n";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const { PUBLIC_URL } = process.env;
const queryClient = new QueryClient();
ReactDOM.render(
  <MetronicI18nProvider>
    <MetronicLayoutProvider>
      <MetronicSubheaderProvider>
        <MetronicSplashScreenProvider>
          <QueryClientProvider client={queryClient}>
            <App store={store} persistor={persistor} basename={PUBLIC_URL} />
          </QueryClientProvider>
        </MetronicSplashScreenProvider>
      </MetronicSubheaderProvider>
    </MetronicLayoutProvider>
  </MetronicI18nProvider>,
  document.getElementById("root")
);
