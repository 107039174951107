import { AxiosResponse } from "axios";
import { HTTPClient } from "./http.service";
var qs = require("qs");

export const teamService = {
  async addUser(data:any) {
      try {
          let result:AxiosResponse = HTTPClient.customRequest({
            url: `/company/companyManagement/addUser`,
            method: "post",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: qs.stringify(data),
          });
          return result;
        } catch (e) {
          return Promise.reject(e);
        }
  },
  async getUsers(params:any) {
        try {
          const result:AxiosResponse = await HTTPClient.get(`/company/companyManagement/users/`, params);
          return result;
        } catch (e) {
          return Promise.reject(e);
        }
    },
}