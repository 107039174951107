import { all, call, put, take, select } from "redux-saga/effects";
import { campaignActionTypes } from "./campaign.actiontype";
import { campaignService } from "../../service/api/campaign.service";
import { SMSTemplateReqObj } from "../../pages/Campaigns/_constants/types";
import { campaignFormSteps } from "../../constants/constants";
import { campaignActions } from "./campaign.action";
import campaignStepsActions from "./campaign.steps.actions";
import Swal from "sweetalert2";
import { format } from "date-fns";
import { RRule } from "rrule";
import {
  onSubmitValidation, onEmailSubmitValidation, onWhatsAppSubmitValidation
} from '../../pages/ChannelSetting/_utils/validation';

function formatDate(date: any) {
  return format(date, "yyyy-MM-dd");
}

function formatTime(date: any) {
  return format(date, "HH:mm");
}

// @ts-ignore
function* fetchTemplate(payload: { id: number }): Generator<any, any, any> {
  const data = yield campaignService.getSavedSmsTemplateSingle(payload);
  yield put({
    type: campaignActionTypes.SET_TEMPLATE,
    payload: data.data,
    templateType: "savedTemplates",
  });
  return;
}

function* saveTemplate(payload: SMSTemplateReqObj): Generator<any, any, any> {
  try {
    const { data } = yield campaignService.addSmsTemplate(payload);
    Swal.fire("Success", "SMS Template added successfully", "success");
    return data;
  } catch (err) {
    Swal.fire("Failed", err.response?.data?.status?.message, "error");
    return false;
  }
}

export function* selectTemplate() {
  while (true) {
    const { payload } = yield take(campaignActionTypes.FETCH_SELECTED_TEMPLATE);
    let selectedTemplatePayload;
    if (payload.type === "libraryTemplate") {
      const templateLibrary = yield select(
        (state) => state.Campaign.templateLibrary
      );
      selectedTemplatePayload = templateLibrary.filter(
        (item) => item.id === payload.id
      )[0];
    } else {
      const savedTemplates = yield select(
        (state) => state.Campaign.savedTemplates
      );
      selectedTemplatePayload = savedTemplates.filter(
        (item) => item.id === payload.id
      )[0];
    }
    yield put({
      type: campaignActionTypes.SET_TEMPLATE,
      payload: {
        ...selectedTemplatePayload,
        dltId:
          payload.type === "libraryTemplate"
            ? ""
            : selectedTemplatePayload.dltId,
      },
      templateType: payload.type,
    });
  }
}

export function* resetTemplateRes() {
  yield put({ type: campaignActionTypes.RESET_SAVE_TEMPLATE });
}

function* handleSaveTemplate(
  payload: SMSTemplateReqObj,
  successCallBack: () => {}
): Generator<any, any, any> {
  yield put({ type: campaignActionTypes.SAVING_TEMPPLATE });
  const res = yield call(saveTemplate, payload);
  if (res === false) {
    yield put({ type: campaignActionTypes.FAILURE_SAVE_TEMPLATE });
  } else {
    yield put({ type: campaignActionTypes.SUCCESS_SAVE_TEMPLATE });
    // yield put({type: campaignActionTypes.FETCH_SELECTED_TEMPLATE, payload:res.data});
    yield put({
      type: campaignActionTypes.SET_TEMPLATE,
      payload: res.data,
      templateType: "savedTemplates",
    });
    successCallBack();
    yield call(getSavedSmsTemplates);
  }
}

export function* initSaveTemplate(): Generator<any, any, any> {
  while (true) {
    const { payload, successCallBack } = yield take(
      campaignActionTypes.SAVE_TEMPLATE
    );
    yield call(handleSaveTemplate, payload, successCallBack);
  }
}

function* saveWhatsAppTemplate(
  payload: SMSTemplateReqObj
): Generator<any, any, any> {
  try {
    const { data } = yield campaignService.addWhatsAppTemplate(payload);
    Swal.fire("Success", "Whats App Template added successfully", "success");
    return data;
  } catch (err) {
    Swal.fire("Failed", err.response?.data?.status?.message, "error");
    return false;
  }
}

function* handleSaveWhatsAppTemplate(
  payload: SMSTemplateReqObj,
  successCallBack: () => {}
): Generator<any, any, any> {
  yield put({ type: campaignActionTypes.SAVING_TEMPPLATE });
  const res = yield call(saveWhatsAppTemplate, payload);
  if (res === false) {
    yield put({ type: campaignActionTypes.FAILURE_SAVE_TEMPLATE });
  } else {
    yield put({ type: campaignActionTypes.SUCCESS_SAVE_TEMPLATE });
    // yield put({type: campaignActionTypes.FETCH_SELECTED_TEMPLATE, payload:res.data});
    yield put({
      type: campaignActionTypes.SET_TEMPLATE,
      payload: res.data,
      templateType: "savedTemplates",
    });
    successCallBack();
    yield call(getSavedWhatsAppTemplates);
  }
}

export function* initSaveWhatsAppTemplate(): Generator<any, any, any> {
  while (true) {
    const { payload, successCallBack } = yield take(
      campaignActionTypes.SAVE_WHATS_APP_TEMPLATE
    );
    yield call(handleSaveWhatsAppTemplate, payload, successCallBack);
  }
}

function* saveEmailTemplate(
  payload: SMSTemplateReqObj
): Generator<any, any, any> {
  try {
    const { data } = yield campaignService.addEmailTemplate(payload);
    Swal.fire("Success", "Email template added successfully", "success");
    return data;
  } catch (err) {
    Swal.fire("Failed", err.response?.data?.status?.message, "error");
    return false;
  }
}

function* handleSaveEmailTemplate(
  payload: SMSTemplateReqObj,
  successCallBack: () => {}
): Generator<any, any, any> {
  yield put({ type: campaignActionTypes.SAVING_TEMPPLATE });
  const res = yield call(saveEmailTemplate, payload);
  if (res === false) {
    yield put({ type: campaignActionTypes.FAILURE_SAVE_TEMPLATE });
  } else {
    yield put({ type: campaignActionTypes.SUCCESS_SAVE_TEMPLATE });
    // yield put({type: campaignActionTypes.FETCH_SELECTED_TEMPLATE, payload:res.data});
    yield put({
      type: campaignActionTypes.SELECT_EMAIL_TEMPLATE,
      payload: res.data,
    });
    successCallBack();
    yield call(getSavedWhatsAppTemplates);
  }
}

export function* initSaveEmailTemplate(): Generator<any, any, any> {
  while (true) {
    const { payload, successCallBack } = yield take(
      campaignActionTypes.SAVE_EMAIL_TEMPLATE
    );
    yield call(handleSaveEmailTemplate, payload, successCallBack);
  }
}

function* getSavedSmsTemplates(
  data = { sort: "desc", pageNo: 1, pageSize: 100 }
): Generator<any, any, any> {
  const res = yield campaignService.getSavedSmsTemplate(data);
  yield put({
    type: campaignActionTypes.SET_SAVED_SMS_TEMPLATES,
    payload: res.data.templates,
  });
}

export function* fetchSavedSMSTemplates(): Generator<any, any, any> {
  while (true) {
    const { data } = yield take(campaignActionTypes.FETCH_SAVED_SMS_TEMPLATES);
    yield call(getSavedSmsTemplates, data);
  }
}

function* getSmsLibraryTemplates(
  data = { sort: "desc", pageNo: 1, pageSize: 100 }
): Generator<any, any, any> {
  const res = yield campaignService.getSmsLibrary(data);
  yield put({
    type: campaignActionTypes.SET_SMS_LIBRARY_TEMPLATES,
    payload: res.data.smsLibrary,
  });
}

function* getEmailLibraryTemplates(
  data = { sort: "desc", pageNo: 1, pageSize: 100 }
): Generator<any, any, any> {
  const res = yield campaignService.getLibraryEmailTemplates(data);
  yield put({
    type: campaignActionTypes.SET_SMS_LIBRARY_TEMPLATES,
    payload: res.data.templates,
  });
}

export function* fetchLibrarySMSTemplates(): Generator<any, any, any> {
  while (true) {
    const { data } = yield take(
      campaignActionTypes.FETCH_SMS_LIBRARY_TEMPLATES
    );
    yield call(getSmsLibraryTemplates, data);
  }
}

export function* fetchLibraryEmailTemplates(): Generator<any, any, any> {
  while (true) {
    const { data } = yield take(
      campaignActionTypes.FETCH_EMAIL_LIBRARY_TEMPLATES
    );
    yield call(getEmailLibraryTemplates, data);
  }
}

function* getSavedWhatsAppTemplates(
  data = { sort: "desc", pageNo: 1, pageSize: 100 }
): Generator<any, any, any> {
  const res = yield campaignService.getSavedWhatsAppTemplate(data);
  yield put({
    type: campaignActionTypes.SET_SAVED_SMS_TEMPLATES,
    payload: res.data.templates,
  });
}

function* getWhatsAppLibraryTemplates(
  data = { sort: "desc", pageNo: 1, pageSize: 100 }
): Generator<any, any, any> {
  const res = yield campaignService.getWhatsappLibrary(data);
  yield put({
    type: campaignActionTypes.SET_SMS_LIBRARY_TEMPLATES,
    payload: res.data.whatsappLibrary,
  });
}

export function* fetchSavedWhatsAppTemplates(): Generator<any, any, any> {
  while (true) {
    const { data } = yield take(
      campaignActionTypes.FETCH_SAVED_WHATS_APP_TEMPLATES
    );
    yield call(getSavedWhatsAppTemplates, data);
  }
}

export function* fetchLibraryWhatsAppTemplates(): Generator<any, any, any> {
  while (true) {
    const { data } = yield take(
      campaignActionTypes.FETCH_WHATS_APP_LIBRARY_TEMPLATES
    );
    yield call(getWhatsAppLibraryTemplates, data);
  }
}

export const getIsChannelSetupStatus = (state: any) => {
  const { campaignChannels, createEditCampaignForm } =
    state.CampaignStepsReducer;
  const channelId = createEditCampaignForm.channelInfo.channelId;
  const selectedCompanyChannels = campaignChannels.find(
    (item: any) => item.channelId === channelId
  );
  return (
    selectedCompanyChannels && selectedCompanyChannels.isChannelSetup === 1
  );
};

export function* goToNextStep(): Generator<any, any, any> {
  while (true) {
    yield take(campaignActionTypes.CAMPAIGN_GO_TO_NEXT_STEP);
    const { currentStep, isChannelSetup } = yield select(
      (state): { currentStep: any; isChannelSetup: any } => {
        const { currentStep } = state.CampaignStepsReducer;
        const isChannelSetup = getIsChannelSetupStatus(state);
        return {
          currentStep,
          isChannelSetup,
        };
      }
    );
    switch (currentStep) {
      case campaignFormSteps.TYPE:
        if (isChannelSetup) {
          yield put(
            campaignStepsActions.changeCreateCampaignStep(campaignFormSteps.WHO)
          );
        } else {
          yield put(
            campaignStepsActions.changeCreateCampaignStep(
              campaignFormSteps.SETUP
            )
          );
        }
        break;
      case campaignFormSteps.SETUP:
        yield put(
          campaignStepsActions.changeCreateCampaignStep(campaignFormSteps.WHO)
        );
        break;
      case campaignFormSteps.WHO:
        yield put(
          campaignStepsActions.changeCreateCampaignStep(campaignFormSteps.WHAT)
        );
        break;
      case campaignFormSteps.WHAT:
        yield put(
          campaignStepsActions.changeCreateCampaignStep(campaignFormSteps.WHEN)
        );
        break;
      case campaignFormSteps.WHEN:
        yield put(
          campaignStepsActions.changeCreateCampaignStep(
            campaignFormSteps.OVERVIEW
          )
        );
        break;
    }
  }
}

export function* goToPrevStep(): Generator<any, any, any> {
  while (true) {
    yield take(campaignActionTypes.CAMPAIGN_GO_TO_PREV_STEP);
    const { currentStep, channelId, currentSubStep } = yield select(
      (state): { currentStep: any; channelId: any; currentSubStep: any } => {
        const { currentStep, createEditCampaignForm, currentSubStep } =
          state.CampaignStepsReducer;
        return {
          currentStep,
          channelId: createEditCampaignForm.channelInfo.channelId,
          currentSubStep,
        };
      }
    );
    switch (currentStep) {
      case campaignFormSteps.TYPE:
        break;
      case campaignFormSteps.SETUP:
        yield put(
          campaignStepsActions.changeCreateCampaignStep(campaignFormSteps.TYPE)
        );
        break;
      case campaignFormSteps.WHO:
        yield put(
          campaignStepsActions.changeCreateCampaignStep(campaignFormSteps.TYPE)
        );
        break;
      case campaignFormSteps.WHAT:
        if (channelId === 2) {
          // if email channel selected
          switch (currentSubStep) {
            case campaignFormSteps.SELECT_TEMPLATE:
              yield put(
                campaignStepsActions.changeCreateCampaignStep(
                  campaignFormSteps.WHO
                )
              );
              break;
            case campaignFormSteps.ADD_TEMPLATE_DETAIL:
              yield put(
                campaignStepsActions.changeCampaignSubStep(
                  campaignFormSteps.SELECT_TEMPLATE
                )
              );
              break;
            case campaignFormSteps.ADD_EMAIL_SETTINGS:
              yield put(
                campaignStepsActions.changeCampaignSubStep(
                  campaignFormSteps.ADD_TEMPLATE_DETAIL
                )
              );
              break;
            default:
              yield put(
                campaignStepsActions.changeCreateCampaignStep(
                  campaignFormSteps.WHO
                )
              );
              break;
          }
        } else {
          yield put(
            campaignStepsActions.changeCreateCampaignStep(campaignFormSteps.WHO)
          );
        }
        break;
      case campaignFormSteps.WHEN:
        if (channelId === 2) {
          // if email channel selected
          yield put(
            campaignStepsActions.changeCampaignSubStep(
              campaignFormSteps.ADD_EMAIL_SETTINGS
            )
          );
        }
        yield put(
          campaignStepsActions.changeCreateCampaignStep(campaignFormSteps.WHAT)
        );
        break;
      case campaignFormSteps.OVERVIEW:
        yield put(
          campaignStepsActions.changeCreateCampaignStep(campaignFormSteps.WHEN)
        );
        break;
    }
  }
}

function validateScheduleData(scheduleInfo) {
  const errorObject: {[key: string]: string} = {};
  if (!scheduleInfo.scheduleType) {
    errorObject.scheduleType = "Please select a campaign schedule type";
  }
  if (scheduleInfo.scheduleType === "oneTime") {
    const campaignDate = scheduleInfo.oneTimeSchedule[0].campaignDate;
    const campaignTime = scheduleInfo.oneTimeSchedule[0].campaignTime;
    if(!campaignDate) {
      errorObject.campaignDate = 'Please select a date';
    }
    if(!campaignTime) {
      errorObject.campaignTime = 'Please select a time';
    }
    if(campaignDate && campaignTime) {
      const _date = new Date(campaignTime);
      _date.setUTCDate(campaignDate.getUTCDate());
      _date.setUTCMonth(campaignDate.getUTCMonth());
      _date.setUTCFullYear(campaignDate.getUTCFullYear());

      if(_date.getTime() - Date.now() < 0) {
        errorObject.campaignTime = 'Campaign cannot be created for past date time';
      }
    }
  } else if (scheduleInfo.scheduleType === "multipleDate") {
    const dates = scheduleInfo.multipleDaysSchedule.dates;
    const time = scheduleInfo.multipleDaysSchedule.time;
    if(!time) {
      errorObject.campaignTime = 'Please select a time';
    } else {
      let oldestDate: any;
      dates.forEach((element: any) => {
        if(!element) {
          errorObject.campaignDate = 'Please select a date';
        } else {
          if(oldestDate && oldestDate.getTime() - element.getTime() > 0) {
            oldestDate = element.getTime();
          } else if (typeof oldestDate === 'undefined') {
            oldestDate = element;
          }
        }
      });
      if(time && oldestDate) {
        const _time = new Date(time);
        _time.setUTCDate(oldestDate.getUTCDate());
        _time.setUTCMonth(oldestDate.getUTCMonth());
        _time.setUTCFullYear(oldestDate.getUTCFullYear());
        if(_time.getTime() - Date.now() < 0) {
          errorObject.campaignTime = 'Campaign cannot be created for past date time';
        }
      }
    }
  } else if (scheduleInfo.scheduleType === "recurring") {
    const campaignDate = scheduleInfo.recurring.campaignDate;
    const campaignTime = scheduleInfo.recurring.campaignTime;
    if(!campaignDate) {
      errorObject.campaignDate = 'Please select a date';
    }
    if(!campaignTime) {
      errorObject.campaignTime = 'Please select a time';
    }
    if(campaignDate && campaignTime) {
      const _date = new Date(campaignTime);
      _date.setUTCDate(campaignDate.getUTCDate());
      _date.setUTCMonth(campaignDate.getUTCMonth());
      _date.setUTCFullYear(campaignDate.getUTCFullYear());

      if(_date.getTime() - Date.now() < 0) {
        errorObject.campaignTime = 'Campaign cannot be created for past date time';
      }
    }
    switch (scheduleInfo.recurring.repeats) {
      case "Custom":
        if (
          !scheduleInfo.recurring.repeatRules.interval ||
          scheduleInfo.recurring.repeatRules.interval < 1 ||
          scheduleInfo.recurring.repeatRules.interval > 50
        ) {
          errorObject.interval = "Add a valid repeat number";
        }
        if (
          scheduleInfo.recurring.repeatRules.endType === "onADate" &&
          !scheduleInfo.recurring.repeatRules.until
        ) {
          errorObject.until = "Invalid Date";
        }
        if (
          scheduleInfo.recurring.repeatRules.endType === "occurrence" &&
          (scheduleInfo.recurring.repeatRules.count > 50 ||
            scheduleInfo.recurring.repeatRules.count < 1)
        ) {
          errorObject.count = "You can add 1 to 50 occurrence";
        }
        switch (scheduleInfo.recurring.repeatRules.repeatFreq) {
          case "week":
            if (
              scheduleInfo.recurring.repeatRules.byweekday.length === 0
            ) {
              errorObject.byweekday = "Please select week day";
            }
            break;
          case "month":
            if (!scheduleInfo.recurring.repeatRules.monthRepeatRules) {
              errorObject.monthRepeatRules = "Select a month repeat rule";
            }
            break;
          default:
            break;
        }
        break;
    }
  }
  return errorObject;
}

export function* smsCampaignSumbitHandler(): Generator<any, any, any> {
  while (true) {
    yield take(campaignActionTypes.SMS_CAMPAIGN_ON_SUBMIT_HANDLER);
    let errorObject: { [key: string]: string } = {};
    const { currentStep, state, channelData } = yield select((state) => {
      const { currentStep } = state.CampaignStepsReducer;
      const channelData = state.Campaign.campaignProviderFormData;
      return { state, currentStep, channelData };
    });

    const {
      channelInfo,
      segmentInfo,
      templateInfo,
      scheduleInfo,
      campaignNameInfo,
      isEdit,
      campaignId,
    } = state.CampaignStepsReducer.createEditCampaignForm;
    let shouldGoToNext = true;
    switch (currentStep) {
      case campaignFormSteps.TYPE:
        const { channelId } = channelInfo;
        if (!channelId) {
          errorObject.selectedChannelId = "Please select a channel";
        }
        break;
      case campaignFormSteps.SETUP:
        const errors  = onSubmitValidation({
          settingType: channelData.settingType,
          partnerId: channelData.partnerId,
          nickName: channelData.nickName,
          settings: channelData.settings,
          isDefault: channelData.isDefault? channelData.isDefault : false,
        });
        Object.keys(errors).map(key => {
          errorObject[key] = errors[key];
        });
        const requestData = {
          settingType: channelData.settingType,
          partnerId: channelData?.partnerId,
          nickName: channelData.nickName,
          isDefault: channelData.isDefault,
          settings:
            typeof channelData.settings === "string"
              ? channelData.settings
              : JSON.stringify(channelData.settings),
        };
        if (Object.keys(errorObject).length === 0) {
          try {
            const response = yield campaignService.addSmsChannel(requestData);
            yield put(campaignActions.channelAddedSuccessFully());
            if (response.status === 200) {
              Swal.fire("Success", "Sms service added successfully", "success");
              // errorObject.templateSaved = undefined;
            } else {
              Swal.fire("Failed", "Sms service added failed", "error");
              errorObject.templateSaved = 'Template Failed';
            }
          } catch (error) {
            Swal.fire("Failed", error.response?.data?.status?.message, "error");
            errorObject.templateSaved = 'Template Failed';
          }
        }
        break;
      case campaignFormSteps.WHO:
        if (!segmentInfo) {
          errorObject.selectedSegmentId = "Please select segment";
        }
        break;
      case campaignFormSteps.WHAT:
        // shouldGoToNext = false;
        const { selectTemplateType, selectedTemplate } = state.Campaign;
        const { isDLTApproved, dynamicMapping, companySmsChannel } =
          templateInfo;
        if (selectedTemplate.template.length <= 0) {
          errorObject.templateMessage = "Message should not be empty";
        }
        dynamicMapping.forEach((item: any, index: number) => {
          const {
            var: varName,
            isDynamic,
            staticValue,
            type,
          }: { var: string; isDynamic: Boolean } = item;
          if (isDynamic===0 && !staticValue) {
            errorObject.dynamicMapping = "Please complete the mapping details";
          }
        });
        if (!isDLTApproved) {
          errorObject.isDLTApproved = "Please accept DLT condition";
        }
        if (!companySmsChannel.value) {
          errorObject.companySmsChannel = "Please select a sender";
        }
        if (!selectedTemplate.dltId) {
          errorObject.dltId = "Please enter a valid DLT Id";
        }
        if (!selectTemplateType || selectTemplateType === "libraryTemplate") {
          if (Object.keys(errorObject).length === 0) {
            yield put(
              campaignActions.changeShowSaveTemplateModal({
                show: true,
                isSaveAndContinue: true,
              })
            );
            shouldGoToNext = false;
          }
        }
        break;
      case campaignFormSteps.WHEN:
        errorObject = {...errorObject, ...validateScheduleData(scheduleInfo)}
        break;
      case campaignFormSteps.OVERVIEW:
        shouldGoToNext = false;
        const _selectedTemplate = state.Campaign.selectedTemplate;

        var formData = createFormObject({
          ..._selectedTemplate,
          ...campaignNameInfo,
          ...segmentInfo,
          ...templateInfo,
        });

        let result = formatCampaignReqBody(formData, scheduleInfo, errorObject);

        errorObject = { ...errorObject, ...result.errorObject };
        formData = result.formData;

        try {
          if (isEdit) {
            const response = yield campaignService.updateCampaign(
              campaignId,
              formData
            );
          } else {
            const response = yield campaignService.addCampaign(formData);
          }
          yield put(campaignStepsActions.campaignCreatedSuccessfull());
        } catch (error) {
          Swal.fire("Failed", error?.response?.data?.status?.message, "error");
        }
        break;
    }

    yield put(campaignStepsActions.updateCampaignFormErrors(errorObject));
    if (Object.keys(errorObject).length === 0 && shouldGoToNext) {
      yield put(campaignStepsActions.goToNextStep());
    }
  }
}

export function* whatsAppCampaignSumbitHandler(): Generator<any, any, any> {
  while (true) {
    yield take(campaignActionTypes.WHATSAPP_CAMPAIGN_ON_SUBMIT_HANDLER);
    let errorObject: { [key: string]: string } = {};
    const { currentStep, state, channelData } = yield select((state) => {
      const { currentStep } = state.CampaignStepsReducer;
      const channelData = state.Campaign.whatsAppCampaignProviderFormData;
      return { state, currentStep, channelData };
    });

    const {
      channelInfo,
      segmentInfo,
      templateInfo,
      scheduleInfo,
      campaignNameInfo,
      isEdit,
      campaignId,
    } = state.CampaignStepsReducer.createEditCampaignForm;
    let shouldGoToNext = true;
    switch (currentStep) {
      case campaignFormSteps.TYPE:
        const { channelId } = channelInfo;
        if (!channelId) {
          errorObject.selectedChannelId = "Please select a channel";
        }
        break;
      case campaignFormSteps.SETUP:
        const errors = onWhatsAppSubmitValidation({
          settingType: channelData.settingType,
          partnerId: channelData.partnerId,
          nickName: channelData.nickName,
          settings: channelData.settings,
          isDefault: channelData?.isDefault || false,
        });
        Object.keys(errors).map(key => {
          errorObject[key] = errors[key];
        });
        const requestData = {
          settingType: channelData.settingType,
          partnerId: channelData.partnerId,
          nickName: channelData.nickName,
          isDefault: channelData.isDefault,
          settings:
            typeof channelData.settings === "string"
              ? channelData.settings
              : JSON.stringify(channelData.settings),
        };

        if (Object.keys(errorObject).length === 0) {
          try {
            const response = yield campaignService.addWhatsAppChannel( requestData );
            yield put(campaignActions.channelAddedSuccessFully());
            if (response.status === 200) {
              Swal.fire( "Success", "Whats app service added successfully", "success" );
            } else {
              Swal.fire("Failed", "Whats app service added failed", "error");
              errorObject.templateSaved = 'Template Failed';
              shouldGoToNext = false;
            }
          } catch (error) {
            Swal.fire("Failed", error.response?.data?.status?.message, "error");
            errorObject.templateSaved = 'Template Failed';
            shouldGoToNext = false;
          }
        }
        break;
      case campaignFormSteps.WHO:
        if (!segmentInfo) {
          errorObject.selectedSegmentId = "Please select segment";
        }
        break;
      case campaignFormSteps.WHAT:
        const { selectTemplateType, selectedTemplate } = state.Campaign;
        const { dynamicMapping, companySmsChannel } = templateInfo;
        if (selectedTemplate.template.length <= 0) {
          errorObject.templateMessage = "Message should not be empty";
        }
        dynamicMapping.map((item: any, index: number) => {
          const { var: varName, isDynamic, staticValue, type } = item;
          if (isDynamic===0 && !staticValue) {
            errorObject.dynamicMapping = "Please complete the mapping details";
          }
        });
        if (!companySmsChannel.value) {
          errorObject.companySmsChannel = "Please select a sender";
        }
        if (!selectedTemplate.whatsAppTemplateId) {
          errorObject.whatsAppTemplateId = "Please enter a template id";
        }
        if (!selectTemplateType || selectTemplateType === "libraryTemplate") {
          if (Object.keys(errorObject).length === 0) {
            yield put(
              campaignActions.changeShowSaveTemplateModal({
                show: true,
                isSaveAndContinue: true,
              })
            );
            shouldGoToNext = false;
          }
        }
        break;
      case campaignFormSteps.WHEN:
        errorObject = {...errorObject, ...validateScheduleData(scheduleInfo)}
        break;
      case campaignFormSteps.OVERVIEW:
        shouldGoToNext = false;
        const _selectedTemplate = state.Campaign.selectedTemplate;

        let formData = createFormObject({
          ..._selectedTemplate,
          ...campaignNameInfo,
          ...segmentInfo,
          ...templateInfo,
        });

        let result = formatCampaignReqBody(formData, scheduleInfo, errorObject);

        errorObject = { ...errorObject, ...result.errorObject };
        formData = result.formData;

        try {
          if (isEdit) {
            const response = yield campaignService.updateCampaign(
              campaignId,
              formData
            );
          } else {
            const response = yield campaignService.addCampaign(formData);
          }
          yield put(campaignStepsActions.campaignCreatedSuccessfull());
        } catch (e) {
          Swal.fire("Failed", e.response?.data?.status?.message, "error");
        }
        break;
    }

    yield put(campaignStepsActions.updateCampaignFormErrors(errorObject));
    if (Object.keys(errorObject).length === 0 && shouldGoToNext) {
      yield put(campaignStepsActions.goToNextStep());
    }
  }
}

export function* emailCampaignSumbitHandler(): Generator<any, any, any> {
  while (true) {
    yield take(campaignActionTypes.EMAIL_CAMPAIGN_ON_SUBMIT_HANDLER);
    let errorObject: { [key: string]: string } = {};
    const { currentStep, state, channelData, currentSubStep, emailAttachments } = yield select(
      (state) => {
        const { currentStep, currentSubStep } = state.CampaignStepsReducer;
        const channelData = state.Campaign.emailCampaignProviderFormData;
        const emailAttachments = state.Campaign.emailAttachments;
        return { state, currentStep, channelData, currentSubStep, emailAttachments };
      }
    );

    const {
      channelInfo,
      segmentInfo,
      templateInfo,
      scheduleInfo,
      campaignNameInfo,
      emailSettingsInfo,
      isEdit,
      campaignId,
    } = state.CampaignStepsReducer.createEditCampaignForm;
    let shouldGoToNext = true;
    switch (currentStep) {
      case campaignFormSteps.TYPE:
        const { channelId } = channelInfo;
        if (!channelId) {
          errorObject.selectedChannelId = "Please select a channel";
        }
        break;
      case campaignFormSteps.SETUP:

        const errors = onEmailSubmitValidation({
          settingType: channelData.settingType,
          partnerId: channelData.partnerId,
          nickName: channelData.nickName,
          settings: channelData.settings,
          isDefault: channelData?.isDefault || false,
        });
        Object.keys(errors).map(key => {
          errorObject[key] = errors[key];
        });
        const requestData = {
          settingType: String(channelData.settingType),
          partnerId: String(channelData.partnerId),
          nickName: String(channelData.nickName),
          isDefault: String(channelData.isDefault),
          settings:
            typeof channelData.settings === "string"
              ? channelData.settings
              : JSON.stringify(channelData.settings),
        };
        if (Object.keys(errorObject).length === 0) {
          try {
            const response = yield campaignService.addEmailChannel(requestData);
            yield put(campaignActions.channelAddedSuccessFully());
            if (response.status === 200) {
              Swal.fire("Success", "Email service added successfully", "success");
            } else {
              Swal.fire("Failed", "Email service added failed", "error");
              errorObject.templateSaved = 'Template Failed';
              shouldGoToNext = false;
            }
          } catch (error) {
            Swal.fire("Failed", error.response?.data?.status?.message, "error");
            errorObject.templateSaved = 'Template Failed';
            shouldGoToNext = false;
          }
        }
        break;
      case campaignFormSteps.WHO:
        if (!segmentInfo) {
          errorObject.selectedSegmentId = "Please select segment";
        } else {
          yield put(
            campaignStepsActions.changeCampaignSubStep(
              campaignFormSteps.SELECT_TEMPLATE
            )
          );
        }
        break;
      case campaignFormSteps.WHAT:
        const { selectTemplateType } = state.Campaign;
        const selectEmailTemplate = state.Campaign.selectEmailTemplate;
        const { dynamicMapping, companySmsChannel } = templateInfo;
        dynamicMapping.map((item: any, index: number) => {
          const { var: varName, isDynamic, staticValue, type, alternate } = item;
          if (isDynamic===0 && !staticValue) {
            errorObject.dynamicMapping = "Please complete the mapping details";
          }
        });
        shouldGoToNext = false;
        switch (currentSubStep) {
          case campaignFormSteps.SELECT_TEMPLATE:
            if (Object.keys(selectEmailTemplate).length === 0) {
              errorObject.selectTempalte = "Please select a template";
            } else {
              yield put(
                campaignStepsActions.changeCampaignSubStep(
                  campaignFormSteps.ADD_TEMPLATE_DETAIL
                )
              );
            }
            break;
          case campaignFormSteps.ADD_TEMPLATE_DETAIL:
            if (
              !selectEmailTemplate.template ||
              selectEmailTemplate.template.length <= 0
            ) {
              errorObject.templateMessage = "Message should not be empty";
            } else if (!templateInfo.companySmsChannel.value) {
              errorObject.companyEmailChannel = "Please Select a Sender";
            }
            if (emailAttachments) {
              emailAttachments.forEach(
                (element: any) => {
                  const parts = element.name.split(".");
                  const fileExtension = parts[parts.length - 1];
                  if (
                    ["jpeg", "jpg", "gif", "png", "pdf", "zip"].indexOf(
                      fileExtension.toLowerCase()
                    ) === -1
                  ) {
                    errorObject.attachment =
                      "Only jpeg, jpg, gif, png, pdf, zip allowed";
                  }
                }
              );
            }
            if (Object.keys(errorObject).length === 0) {
              yield put(
                campaignStepsActions.changeCampaignSubStep(
                  campaignFormSteps.ADD_EMAIL_SETTINGS
                )
              );
            }

            break;
          case campaignFormSteps.ADD_EMAIL_SETTINGS:
            shouldGoToNext = true;
            const {
              subject,
              fromName,
              fromEmail,
              replyToEmail,
              plainTextVersion,
              queryString,
            } = emailSettingsInfo;

            if (!subject) {
              errorObject.subject = "Subject should not be empty";
            }
            // if(!fromName) {
            //   errorObject.fromName = "Form name should not be empty";
            // }
            // if(!fromEmail) {
            //   errorObject.fromEmail = "From email should not be empty";
            // }
            // if(!replyToEmail) {
            //   errorObject.replyToEmail = "Reply to email should not be empty";
            // }
            if (!plainTextVersion) {
              errorObject.plainTextVersion =
                "Plain text version should not be empty";
            }
            // if(!queryString) {
            //   errorObject.queryString = "Query string should not be empty";
            // }
            if (
              !selectEmailTemplate.template ||
              selectEmailTemplate.template.length <= 0
            ) {
              errorObject.templateMessage = "Message should not be empty";
              yield put(
                campaignStepsActions.changeCampaignSubStep(
                  campaignFormSteps.ADD_TEMPLATE_DETAIL
                )
              );
            }
            if (selectEmailTemplate.type === "blank") {
              if (Object.keys(errorObject).length === 0) {
                yield put(
                  campaignActions.changeShowSaveTemplateModal({
                    show: true,
                    isSaveAndContinue: true,
                  })
                );
                shouldGoToNext = false;
              }
            }
            break;
          default:
            yield put(
              campaignStepsActions.changeCampaignSubStep(
                campaignFormSteps.SELECT_TEMPLATE
              )
            );
            break;
        }
        break;
      case campaignFormSteps.WHEN:
        errorObject = {...errorObject, ...validateScheduleData(scheduleInfo)}
        break;
      case campaignFormSteps.OVERVIEW:
        shouldGoToNext = false;
        const _selectedTemplate = state.Campaign.selectEmailTemplate;
        const _emailSettingsInfo =
          state.CampaignStepsReducer.createEditCampaignForm.emailSettingsInfo;

        let formData = createFormObject({
          ..._selectedTemplate,
          ...campaignNameInfo,
          ...segmentInfo,
          ...templateInfo,
        });

        formData.append("emailSubject", _emailSettingsInfo.subject);
        formData.append("plainTextEmail", _emailSettingsInfo.plainTextVersion);

        if (emailAttachments) {
          const deleteAttachment: any[] = [];
          emailAttachments.forEach((element: any) => {
            if (!element.isDelete) {
              if (element.lastModifiedDate) {
                formData.append("templateAttachments", element);
              }
            } else {
              deleteAttachment.push(element.name);
            }
          });
          if (deleteAttachment.length) {
            // @ts-ignore
            formData.append(
              "deleteAttachments",
              JSON.stringify(deleteAttachment)
            );
          }
        }

        let result = formatCampaignReqBody(formData, scheduleInfo, errorObject);
        formData = result.formData;
        errorObject = { ...errorObject, ...result.errorObject };

        try {
          if (isEdit) {
            const response = yield campaignService.updateCampaign(
              campaignId,
              formData
            );
          } else {
            const response = yield campaignService.addCampaign(formData);
          }
          yield put(campaignStepsActions.campaignCreatedSuccessfull());
        } catch (e) {
          Swal.fire("Failed", e.response?.data?.status?.message, "error");
        }
        break;
    }
    yield put(campaignStepsActions.updateCampaignFormErrors(errorObject));
    if (Object.keys(errorObject).length === 0 && shouldGoToNext) {
      yield put(campaignStepsActions.goToNextStep());
    }
  }
}

export function* saveCampaignAsDraft(): Generator<any, any, any> {
  while (true) {
    yield take(campaignActionTypes.SAVE_CAMPAIGN_AS_DRAFT);
    const errorObject: { [key: string]: string } = {};
    const { state } = yield select((state) => {
      return { state };
    });
    const {
      segmentInfo,
      templateInfo,
      scheduleInfo,
      campaignNameInfo,
      isEdit,
      campaignId,
      channelInfo,
      emailSettingsInfo: { subject, plainTextVersion },
    } = state.CampaignStepsReducer.createEditCampaignForm;
    const _selectedTemplate = state.Campaign.selectedTemplate;
    const emailAttachments = state.Campaign.emailAttachments;
    const channelId = channelInfo.channelId;
    const formData = new FormData();
    formData.append("campaignName", campaignNameInfo.name);
    formData.append("channelId", templateInfo.companySmsChannel.value);
    formData.append("segmentId", segmentInfo.value);
    if (channelId === 1) {
      formData.append("templateId", state.Campaign.selectedTemplate.id);
    } else if (channelId === 2) {
      const _selectedTemplate = state.Campaign.selectEmailTemplate;
      formData.append("templateId", _selectedTemplate.id);
      formData.append("emailSubject", subject);
      formData.append("plainTextEmail", plainTextVersion);
      if (emailAttachments) {
        const deleteAttachment: any[] = [];
        emailAttachments.forEach((element: any) => {
          if (!element.isDelete) {
            if (element.lastModifiedDate) {
              formData.append("templateAttachments", element);
            }
          } else {
            deleteAttachment.push(element.name);
          }
        });
        if (deleteAttachment.length) {
          // @ts-ignore
          formData.append(
            "deleteAttachments",
            JSON.stringify(deleteAttachment)
          );
        }
      }
    } else if (channelId === 4) {
      formData.append("templateId", state.Campaign.selectedTemplate.id);
    } else {
      formData.append("templateId", _selectedTemplate.id);
    }
    formData.append("isActive", "1");
    formData.append("dynamicMapping", JSON.stringify(templateInfo?.dynamicMapping?.map((item: any) => {
      delete item.key;
      return item;
    })));

    const { scheduleType, oneTimeSchedule, multipleDaysSchedule, recurring } =
      scheduleInfo;

    if (scheduleType === "oneTime" && oneTimeSchedule) {
      formData.append("campaignType", "1");
      formData.append(
        "campaignDays",
        JSON.stringify([
          Object.assign({}, oneTimeSchedule[0], {
            campaignTime: formatTime(oneTimeSchedule[0].campaignTime),
            campaignDate: formatDate(oneTimeSchedule[0].campaignDate),
          }),
        ])
      );
    } else if (scheduleType === "multipleDate" && multipleDaysSchedule) {
      formData.append("campaignType", "2");
      const { dates, time, campaignTimezone } = multipleDaysSchedule;
      const campaignDays = dates.map((date: any) => ({
        campaignDate: formatDate(date),
        campaignTime: formatTime(time),
        campaignTimezone: campaignTimezone,
      }));
      formData.append("campaignDays", JSON.stringify(campaignDays));
    } else if (scheduleType === "recurring") {
      const {
        campaignDate,
        campaignTime,
        campaignTimezone,
        repeats,
        repeatRules: {
          repeatFreq,
          byweekday,
          bymonth,
          count,
          interval,
          until,
          endType,
          monthRepeatRules,
        },
      } = recurring;

      if (scheduleType === "recurring") {
        switch (repeats) {
          case "Custom":
            if (!interval || interval < 1 || interval > 50) {
              errorObject.interval = "Add a valid repeat number";
            }
            if (endType === "onADate" && !until) {
              errorObject.until = "Invalid Date";
            }
            if (endType === "occurrence" && (count > 50 || count < 1)) {
              errorObject.count = "You can add 1 to 50 occurrence";
            }
            switch (repeatFreq) {
              case "week":
                if (byweekday.length === 0) {
                  errorObject.byweekday = "Please select week day";
                }
                break;
              case "month":
                if (!monthRepeatRules) {
                  errorObject.monthRepeatRules = "Select a month repeat rule";
                }
                break;
              default:
                break;
            }
            break;
        }
      }

      formData.append("campaignType", "3");
      let recurringType = repeats;
      const ststart = new Date(campaignDate);
      ststart.setHours(campaignTime.getHours());
      ststart.setMinutes(campaignTime.getMinutes());
      ststart.setSeconds(campaignTime.getSeconds());
      let recurringData: { [key: string]: any } = {
        dtstart: ststart,
      };

      switch (repeats) {
        case "MonthlyOnLastWendnesday":
          recurringData = {
            ...recurringData,
            freq: RRule.MONTHLY,
            byweekday: [RRule.WE.nth(-1)],
            count: 50,
          };
          break;
        case "WeeklyOnWednesday":
          recurringData = {
            ...recurringData,
            freq: RRule.WEEKLY,
            byweekday: [RRule.WE],
            count: 50,
          };
          break;
        case "AnnuallyOnMarch31st":
          recurringData = {
            ...recurringData,
            freq: RRule.YEARLY,
            bymonth: [3],
            bymonthday: [31],
            count: 50,
          };
          break;
        case "EveryWeekdayM2F":
          recurringData = {
            ...recurringData,
            freq: RRule.WEEKLY,
            byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
            count: 50,
          };
          break;
        case "Custom":
          recurringData = {
            ...recurringData,
            interval,
            // @ts-ignore
            freq: {
              day: RRule.DAILY,
              week: RRule.WEEKLY,
              month: RRule.MONTHLY,
              year: RRule.YEARLY,
            }[repeatFreq],
          };
          switch (endType) {
            case "never":
              recurringData.count = 50;
              break;
            case "onADate":
              recurringData.until = until;
              break;
            case "occurrence":
              recurringData.count = count > 50 ? 50 : count;
              break;

            default:
              break;
          }
          switch (repeatFreq) {
            case "day":
              break;
            case "week":
              const daysMapping: { [key: string]: any } = {
                Mon: RRule.MO,
                Tue: RRule.TU,
                Wed: RRule.WE,
                Thu: RRule.TH,
                Fri: RRule.FR,
                Sat: RRule.SA,
                Sun: RRule.SU,
              };
              recurringData.byweekday = byweekday.map((item: any) => {
                return daysMapping[item];
              });
              break;
            case "month":
              recurringData = {
                ...recurringData,
                ...RRule.fromText(monthRepeatRules).origOptions,
              };
              recurringType += `&month:${monthRepeatRules}`;
              break;
            case "year":
              break;

            default:
              break;
          }
          break;
        case "Daily":
        default:
          recurringData = {
            ...recurringData,
            freq: RRule.DAILY,
            count: 50,
          };
          break;
      }
      formData.append("recurringType", recurringType);
      formData.append("recurringRuleQuery", JSON.stringify(recurringData));
      formData.append("campaignDays", JSON.stringify([]));
      formData.append("campaignTimeZone", "utc");
    }

    yield put(campaignStepsActions.updateCampaignFormErrors(errorObject));
    if (Object.keys(errorObject).length === 0) {
      try {
        if (isEdit) {
          yield campaignService.updateCampaign(campaignId, formData);
        } else {
          yield campaignService.addCampaign(formData);
        }
        yield put(campaignStepsActions.campaignCreatedSuccessfull());
      } catch (e) {
        Swal.fire("Failed", e.response?.data?.status?.message, "error");
      }
    }
  }
}

export function* campaignSubmitHandler(): Generator<any, any, any> {
  while (true) {
    yield take(campaignActionTypes.CAMPAIGN_ON_SUBMIT_HANDLER);
    const { channelId } = yield select((state) => ({
      channelId:
        state.CampaignStepsReducer.createEditCampaignForm.channelInfo.channelId,
    }));
    switch (channelId) {
      case 1:
        yield put({
          type: campaignActionTypes.SMS_CAMPAIGN_ON_SUBMIT_HANDLER
        });
        break;
      case 2:
        yield put({
          type: campaignActionTypes.EMAIL_CAMPAIGN_ON_SUBMIT_HANDLER,
        });
        break;
      case 4:
        yield put({
          type: campaignActionTypes.WHATSAPP_CAMPAIGN_ON_SUBMIT_HANDLER,
        });
        break;
      default:
        Swal.fire("", "Channel is unavailable", "error");
    }
  }
}

export function* fetchCampaignChannels(): Generator<any, any, any> {
  while (true) {
    yield take(campaignActionTypes.FETCH_CAMPAIGN_CHANNELS);
    const data = yield campaignService.getCompanyChannels();
    yield put(campaignStepsActions.saveCampaignChannels(data));
  }
}

export function* getTempalteSettingTemplate(): Generator<any, any, any> {
  while (true) {
    const requestData = yield take(
      campaignActionTypes.GET_SAVED_SMS_TEMPLATE_SINGLE
    );
    const data = yield campaignService.getSavedSmsTemplateSingle(
      requestData.payload
    );
    yield put({
      type: campaignActionTypes.SAVE_TEMPLATE_SETTING_TEMPLATE,
      payload: data.data,
    });
  }
}

export function* getTempalteSettingWhatsAppTemplate(): Generator<
  any,
  any,
  any
> {
  while (true) {
    const requestData = yield take(
      campaignActionTypes.GET_SAVED_WHATS_APP_TEMPLATE_SINGLE
    );
    const data = yield campaignService.getSavedWhatsAppTemplateSingle(
      requestData.payload
    );
    yield put({
      type: campaignActionTypes.SAVE_TEMPLATE_SETTING_TEMPLATE,
      payload: data.data,
    });
  }
}

const createFormObject = (data: any) => {
  const formData = new FormData();
  formData.append("campaignName", data.name);
  formData.append("channelId", data.companySmsChannel.value);
  formData.append("segmentId", data.value);
  formData.append("templateId", data.id);
  formData.append("isActive", "2");
  formData.append("dynamicMapping", JSON.stringify(data.dynamicMapping.map((item: any) => {
    delete item.key;
    return item;
  })));

  return formData;
};

const formatCampaignReqBody = (
  formData: any,
  scheduleInfo: any,
  errorObject: any
) => {
  const { scheduleType, oneTimeSchedule, multipleDaysSchedule, recurring } =
    scheduleInfo;
  if (scheduleType === "oneTime") {
    formData.append("campaignType", "1");
    formData.append(
      "campaignDays",
      JSON.stringify([
        Object.assign({}, scheduleInfo.oneTimeSchedule[0], {
          campaignTime: formatTime(oneTimeSchedule[0].campaignTime),
          campaignDate: formatDate(oneTimeSchedule[0].campaignDate),
        }),
      ])
    );
  } else if (scheduleType === "multipleDate") {
    formData.append("campaignType", "2");
    const { dates, time, campaignTimezone } = multipleDaysSchedule;
    const campaignDays = dates.map((date: any) => ({
      campaignDate: formatDate(date),
      campaignTime: formatTime(time),
      campaignTimezone: campaignTimezone,
    }));
    formData.append("campaignDays", JSON.stringify(campaignDays));
  } else if (scheduleType === "recurring") {
    const {
      campaignDate,
      campaignTime,
      campaignTimezone,
      repeats,
      repeatRules: {
        repeatFreq,
        byweekday,
        bymonth,
        count,
        interval,
        until,
        endType,
        monthRepeatRules,
      },
    } = recurring;
    formData.append("campaignType", "3");
    let recurringType = repeats;
    const ststart = new Date(campaignDate);
    ststart.setHours(campaignTime.getHours());
    ststart.setMinutes(campaignTime.getMinutes());
    ststart.setSeconds(campaignTime.getSeconds());
    let recurringData: { [key: string]: any } = {
      dtstart: ststart,
    };

    switch (repeats) {
      case "WeeklyOnWednesday":
        recurringData = {
          ...recurringData,
          freq: RRule.WEEKLY,
          byweekday: [RRule.WE],
          count: 50,
        };
        break;
      case "AnnuallyOnMarch31st":
        recurringData = {
          ...recurringData,
          freq: RRule.YEARLY,
          bymonth: [3],
          bymonthday: [31],
          count: 50,
        };
        break;
      case "EveryWeekdayM2F":
        recurringData = {
          ...recurringData,
          freq: RRule.WEEKLY,
          byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
          count: 50,
        };
        break;
      case "Custom":
        if (!interval || interval <= 1) {
          errorObject.interval = "Add a valid interval";
        }
        recurringData = {
          ...recurringData,
          interval,
          // @ts-ignore
          freq: {
            day: RRule.DAILY,
            week: RRule.WEEKLY,
            month: RRule.MONTHLY,
            year: RRule.YEARLY,
          }[repeatFreq],
        };
        switch (endType) {
          case "never":
            recurringData.count = 50;
            break;
          case "onADate":
            recurringData.until = until;
            break;
          case "occurrence":
            recurringData.count = count > 50 ? 50 : count;
            break;

          default:
            break;
        }
        switch (repeatFreq) {
          case "day":
            break;
          case "week":
            if (byweekday.length === 0) {
              errorObject.byweekday = "Please select week day";
            }
            const daysMapping: { [key: string]: any } = {
              Mon: RRule.MO,
              Tue: RRule.TU,
              Wed: RRule.WE,
              Thu: RRule.TH,
              Fri: RRule.FR,
              Sat: RRule.SA,
              Sun: RRule.SU,
            };
            recurringData.byweekday = byweekday.map((item: any) => {
              return daysMapping[item];
            });
            break;
          case "month":
            if (!monthRepeatRules) {
              errorObject.monthRepeatRules = "Select a month repeat rule";
            }
            recurringData = {
              ...recurringData,
              ...RRule.fromText(monthRepeatRules).origOptions,
            };
            recurringType += `&month:${monthRepeatRules}`;
            break;
          case "year":
            break;

          default:
            break;
        }
        break;
      case "Daily":
      default:
        recurringData = {
          ...recurringData,
          freq: RRule.DAILY,
          count: 50,
        };
        break;
    }
    formData.append("recurringType", recurringType);
    formData.append("recurringRuleQuery", JSON.stringify(recurringData));
    formData.append("campaignDays", JSON.stringify([]));
    formData.append("campaignTimeZone", "utc");
  }

  return { errorObject, formData };
};

export function* campaignSaga() {
  yield all([
    call(selectTemplate),
    call(initSaveTemplate),
    call(initSaveWhatsAppTemplate),
    call(initSaveEmailTemplate),
    call(fetchSavedSMSTemplates),
    call(fetchLibrarySMSTemplates),
    call(fetchLibraryEmailTemplates),
    call(fetchSavedWhatsAppTemplates),
    call(fetchLibraryWhatsAppTemplates),
    call(campaignSubmitHandler),
    call(goToNextStep),
    call(goToPrevStep),
    call(fetchCampaignChannels),
    call(smsCampaignSumbitHandler),
    call(emailCampaignSumbitHandler),
    call(whatsAppCampaignSumbitHandler),
    call(getTempalteSettingTemplate),
    call(getTempalteSettingWhatsAppTemplate),
    call(saveCampaignAsDraft),
  ]);
}
