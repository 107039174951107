import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from "./pages/Login";
import NewRestPasswrod from "./pages/NewRestPasswrod";
import LoginViaPassword from "./pages/LoginViaPassword";
import LoginViaOTP from "./pages/LoginViaOTP";
import ForgotPassword from "./pages/ForgotPassword";
import OTPVerification from "./pages/OTPVerification";
import SetPassword from "./pages/SetPassword";

const AuthPages = () => {
  return (
    <Switch>
      {/* <Redirect exact from="/contacts" to="/contacts/all-contacts" /> */}
      <Route exact path="/auth/login" component={Login} />
      <Route exact path="/auth/reset-password" component={NewRestPasswrod} />
      <Route exact path="/auth/login-password" component={LoginViaPassword} />
      <Route exact path="/auth/login-otp" component={LoginViaOTP} />
      <Route exact path="/auth/forgot-password" component={ForgotPassword} />
      <Route exact path="/auth/otp-verification" component={OTPVerification} />
      <Route exact path="/auth/set-password" component={SetPassword} />

      <Route exact path="/" component={Login} />
      <Redirect exact from="/*" to="/" />
    </Switch>
  );
};

export default AuthPages;

// const verifyLink = async (email: string, code: string | number) => {
//   authService
//     .verifyLink({
//       email,
//       verificationCode: code,
//     })
//     .then(() => {
//       setLoading(false);
//     })
//     .catch((err) => {
//       setLoading(false);
//       setExpired(true);
//     });
// };
