import { HTTPClient, HTTPClientV3 } from "./http.service";
export const authService = {
  async login(loginObj: any) {
    const constVariable = {
      device: "Dashboard",
      deviceId: String(new Date()),
      build: "1.0.0",
    };
    try {
      const {
        data: { data },
      } = await HTTPClientV3.post("/auth/companyLogin", {
        ...loginObj,
        ...constVariable,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async forgotPass_sendEmail(email: string) {
    try {
      const response = await HTTPClientV3.post("/auth/forgotCompanyPassword", { email });
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async ResetPassword(payload: any) {
    try {
      const response = await HTTPClient.post("/company/auth/resetCompanyPassword", {
        ...payload,
      });
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async registerUser(payload: any) {
    try {
      const response = await HTTPClient.post("/auth/verifyUser", {
        ...payload,
      });
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async updateUser(payload: any) {
    try {
      let result = HTTPClient.customRequest({
        url: `/profile/updateUser`,
        method: "put",
        headers: { "Content-Type": "multipart/form-data" },
        data: payload,
      });
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getUser() {
    try {
      let result = await HTTPClient.get(`/company/companyManagement/userProfile`);
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async updateProfile(params: { newFirstName: string; newLastName: string; newMobile: string }) {
    try {
      const response = await HTTPClient.put("/company/userProfile/updateProfile", params);
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async changePassword(params: { oldPassword: string; newPassword: string; confirmPassword: string }) {
    try {
      const response = await HTTPClient.put("company/userProfile/changeCompanyPassword", params);
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async verifyLink(params: { email: string; verificationCode?: string | number }) {
    try {
      const response = await HTTPClient.get("/company/auth/verifyCode/", params);
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async setPassword(params) {
    try {
      const response = await HTTPClientV3.put(`/auth/setPassword/?email=${params.email}&key=${params.passCode}`, {
        password: params.password,
        confirmPassword: params.confirmPassword,
      });
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async verifyResetCode(params: { email: string; verificationCode?: string | number }) {
    try {
      const response = await HTTPClient.get("/company/auth/verifyResetCode/", params);
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async sendVerificationLink(params: { email: string; status: string | number }) {
    try {
      const response = await HTTPClientV3.post(`/auth/sendVerificationLink`, params);
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async verifyEmail(params: { email: string }) {
    try {
      const response = await HTTPClient.post(`/company/auth/verifyEmail`, params);
      return response;
    } catch (err) {
      throw err;
    }
  },
  async resetCompanyPassword(params: { email: string }) {
    try {
      const response = await HTTPClient.post(`/company/auth/resetCompanyPassword`, params);
      return response;
    } catch (err) {
      throw err;
    }
  },
  async verifyCode(params) {
    try {
      const result = await HTTPClientV3.get("/auth/verifyCode/", params);
      return result;
    } catch (error) {
      throw error;
    }
  },

  async validateCode(params) {
    try {
      const result = await HTTPClientV3.get("/auth/validateCode/", params);
      return result;
    } catch (error) {
      throw error;
    }
  },
  async updateCompanyDetails(data, token) {
    try {
      HTTPClientV3.saveHeader({ key: "Authorization", value: `Bearer ${token}` });
      return await HTTPClientV3.post("/companyManagement/addCompany", data);
    } catch (error) {
      throw error;
    }
  },
};
