import { ISpinWheelInfo } from "./types";
import * as Yup from "yup";
const REQUIRED_MESSAGE = "This is a required field";

export enum SPIN_THE_WHEEL_STATUS {
  ALL = "all",
  DRAFT = "1",
  ACTIVE = "2",
  COMPLETED = "3",
}

export enum SPIN_THE_WHEEL_TYPE {
  TRIVIA = 1,
  DYNAMIC = 2,
}

export enum SPIN_THE_WHEEL_FORM_STEP_KEYS {
  DETAILS = "1",
  PRIZES = "2",
  OVERVIEW = "3"
}

export const SpinTheWheelFormSteps = [
  { label: "Spin the wheel details", value: SPIN_THE_WHEEL_FORM_STEP_KEYS.DETAILS },
  { label: "Spins and prizes", value: SPIN_THE_WHEEL_FORM_STEP_KEYS.PRIZES },
  { label: "Overview", value: SPIN_THE_WHEEL_FORM_STEP_KEYS.OVERVIEW },
];

export const SpinTheWhellInfoInitialValues: ISpinWheelInfo = {
  name: "",
  description: "",
  termsAndConditions: ""
};

export const SpinTheWheelDetailValidationSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED_MESSAGE).min(4, 'Name must have at least 4 charater.').max(48, 'Name must have at most 48 characters.'),
  // description: Yup.string().required(REQUIRED_MESSAGE).min(40, 'Description must have at least 40 characters.').max(150, 'Description must have at most 150 characters.'),
  termsAndConditions: Yup.string().required(REQUIRED_MESSAGE),
});

export const SLOTS_OPTIONS = [
  { label: "12 slots", value: 12 },
  { label: "11 slots", value: 11 },
  { label: "10 slots", value: 10 },
  { label: "9 slots", value: 9 },
  { label: "8 slots", value: 8 },
  { label: "7 slots", value: 7 },
  { label: "6 slots", value: 6 },
  { label: "5 slots", value: 5 },
  { label: "4 slots", value: 4 },
  { label: "3 slots", value: 3 },
];

export const SpinTheWheelPrizesInitialValues = {
  // totalSpins: null,
  benefits: [],
  redemptionPerUser: null,
  spinPerDay: null,
  noOfSlots: null,
  defaultMessage: '',
};

export const SpinTheWheelPrizesValidationSchema = Yup.object().shape({
  // totalSpins: Yup.number().typeError(REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
  benefits: Yup.array()
    .of(Yup.object().shape({
        benefitType: Yup.number().typeError(REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
        // count: Yup.number().typeError(REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
        value: Yup.mixed().typeError(REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
        count: Yup.mixed().typeError(REQUIRED_MESSAGE).required(REQUIRED_MESSAGE)
      })),
  // redemptionPerUser: Yup.number().typeError(REQUIRED_MESSAGE).required(REQUIRED_MESSAGE).min(1, 'Must be greater than or equal to 1'),
  // spinPerDay: Yup.number().typeError(REQUIRED_MESSAGE).required(REQUIRED_MESSAGE).min(1, 'Must be greater than or equal to 1'),
  noOfSlots: Yup.number().typeError(REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
  defaultMessage: Yup.string().typeError(REQUIRED_MESSAGE).required(REQUIRED_MESSAGE)
});
