import { HTTPClient } from "./http.service";
var qs = require("qs");

export const analyticsService = {
  async getDashboardAnalytics(params: object) {
    try {
      const {
        data: { data: companyChannels },
      } = await HTTPClient.get("/company/companyManagement/analytics/dashboard", params);
      return companyChannels;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getAudienceAnalytics(params: object) {
    try {
      const {
        data: { data: companyChannels },
      } = await HTTPClient.get("/company/companyManagement/analytics/audience", params);
      return companyChannels;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getAcquisitionAnalytics() {
    try {
      const {
        data: { data: companyChannels },
      } = await HTTPClient.get("/company/companyManagement/analytics/acquisition");
      return companyChannels;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getBehaviouralAnalytics() {
    try {
      const {
        data: { data: companyChannels },
      } = await HTTPClient.get("/company/companyManagement/analytics/behavioural");
      return companyChannels;
    } catch (e) {
      return Promise.reject(e);
    }
  },
}
