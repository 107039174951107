import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Link } from 'react-router-dom';
import {Form, Col, Row } from 'react-bootstrap';

const ActionDropdown = ({onMenuItemClicked, items, title, showCheckBox}) => {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {title? <div onClick={handleClick}>{title}</div>: <MoreVertIcon onClick={handleClick}/>}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items.map(item => {
          return (
            <MenuItem
              key={item.value}
              className="MuiButtonIconContainer" onClick={() => {
                onMenuItemClicked(item);
                handleClose();
              }}
            >
              <div className="MuiButtonIcon d-flex justify-content-between w-100">
                <span className='mr-5'>{item.label}</span>
                {showCheckBox && <Form.Check
                  type={'checkbox'}
                  // disabled={disabled}
                  value={item.value}
                  checked={item.selected}
                />}
              </div>
            </MenuItem>
          )
        })}

      </Menu>
    </div>
  )
}

export default ActionDropdown;
