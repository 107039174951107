import React from 'react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";


export default (props) => {
  const {variant, placement, description, label, trigger} = props;
  const renderTooltip = (_props) => (
    <Tooltip
      id="button-tooltip" {..._props}
      className={variant? variant: 'primary'}
    >
      {description}
    </Tooltip>
  );
  return (
    <OverlayTrigger
      trigger={trigger || "hover"}
      placement={placement?placement: 'top'}
      delay={{ show: 250, hide: 1000 }}
      overlay={renderTooltip}
    >
      {label}
    </OverlayTrigger>
  )
}
