import { HTTPClient } from "./http.service";
var qs = require("qs");


const innerObj = {
  attribute: "",
  operation: "",
  type: "",
  value: "",
  to: "",
  from: "",
  nextRelation: null,
  between: false,
  filterByDisabled: true,
  deleteFilterBtn: false
}

const outerObj = {
  listToCompute: [innerObj],
  nextRelation: null,
  deleteLogicBtn: false,
  disableLogicBtn: true
}


export const segmentService = {
  async segmentQueryBuilder(id: number | string | undefined, data: any) {
      try {
        let dataToSend = [];
        for(let index = 0; index < data.query.length; index++) {
          const obj = data.query[index];
          // data.query.map((obj: any) => {
            const item = {
              ...obj,
              listToCompute: obj.listToCompute.map((lObj: any) => {
                return {
                  ...lObj,
                  attribute: lObj.attribute.value,
                  operation: lObj.operation.label,
                  value: typeof lObj.value.value !== 'undefined' ? (lObj.value.value).toString() : '',
                  nextRelation: (lObj.nextRelation && typeof lObj.nextRelation.value !== 'undefined') ? lObj.nextRelation.value : null,
                  to: lObj.to.value ? (lObj.to.value).toString(): '',
                  from: lObj.from.value ? (lObj.from.value).toString(): ''
                };
              }),
              nextRelation: (obj.nextRelation && obj.nextRelation.value) ? obj.nextRelation.value : null
            };
            dataToSend.push(item);
            if(!item.nextRelation) {
              break;
            }
          // });
        }
        console.log('dataToSend', dataToSend);
        const arrayWithoutEmptyInnerList = dataToSend.map((obj:any) => {
          return {
            ...obj,
            listToCompute: obj.listToCompute.filter((lObj: any) => {
              return (JSON.stringify(lObj) !== JSON.stringify(innerObj))
            })
          }
        })

        const arrayWithoutEmptyOuterList = arrayWithoutEmptyInnerList.filter((obj: any) => (
          (JSON.stringify(obj) !== JSON.stringify(outerObj))
        ))

        let finalData = arrayWithoutEmptyOuterList.map((obj: any) => {
          obj.listToCompute.map((lObj: any) => {
            delete lObj.between;
            delete lObj.filterByDisabled;
            delete lObj.deleteFilterBtn;
            return lObj;
          })
          delete obj.deleteLogicBtn;
          delete obj.disableLogicBtn;
          return obj;
        })

        const requestData: any = {
          query: JSON.stringify(finalData),
        };
        if(data.segmentId) {
          requestData.segmentId = data.segmentId;
        }

        let result = HTTPClient.customRequest({
          url: `company/segment/segmentQueryBuilder/${id}`,
          method: 'post',
          headers: {'Content-Type': 'application/json'},
          data: requestData
      });
        return result;
      }catch(e) {
        return Promise.reject(e);
      }
  },
  async updateSegment(id: string | number, data: object) {
      try {
          let result = HTTPClient.customRequest({
            url: `/company/segment/${id}`,
            method: "put",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: qs.stringify(data),
          });
          return result;
        } catch (e) {
          return Promise.reject(e);
        }
  },
  async getSegments(params: object) {
      try {
          const result = await HTTPClient.get(`/company/segment/`, params);
          return result;
      } catch(e) {
          return Promise.reject(e);
      }
  },
  async getSegmentDetail(id: number) {
    try {
        const result = await HTTPClient.get(`/company/segment/${id}`);
        return result;
    } catch(e) {
        return Promise.reject(e);
    }
  },
  async getSegmentUserList(id: string | number, params: object) {
      try {
          const result = await HTTPClient.get(`/company/contacts/getUserList/segment/${id}`, params);
          return result;
      } catch(e) {
          return Promise.reject(e);
      }
  },
  async duplicateSegment(id: string | number, segmentName: string) {
      try {
        let result = HTTPClient.customRequest({
          url: `company/segment/duplicate/${id}`,
          method: 'post',
          headers: {'Content-Type': 'application/json'},
          data: {segmentName}
      });
        return result;
      }catch(e) {
        return Promise.reject(e);
      }
  }
}
