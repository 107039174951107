import { takeLatest, all, call, put, take } from "redux-saga/effects";
import { authActionTypes } from "./auth.actiontype";
import { authService } from "../../service/api/auth.service";
import * as authActions from "./auth.action";
import { HTTPClient, HTTPClientV2, HTTPClientV3 } from "../../service/api/http.service";
import { appService } from "../../service";
import { appMenuSuccesss } from "../appStore/appStore.action";

function* handleRegisterUser() {}

export function* registerSagar() {
  yield takeLatest(authActionTypes.REGISTER_INIT, handleRegisterUser);
}

function* handleLogin(payload) {
  try {
    const data = yield authService.login(payload);
    const { status } = data;

    if (status == 2) {
      // console.log(2);
    } else if (status == 1) {
      //   yield put(authActions.loginFail(null));
      // ask for email verify first and then
    } else {
      HTTPClient.saveHeader({ key: "Authorization", value: `Bearer ${data.authToken}` });
      HTTPClient.saveHeader({ key: "companyId", value: `company_${data.companyId}` });
      HTTPClientV2.saveHeader({ key: "Authorization", value: `Bearer ${data.authToken}` });
      HTTPClientV2.saveHeader({ key: "companyId", value: `company_${data.companyId}` });
      HTTPClientV3.saveHeader({ key: "Authorization", value: `Bearer ${data.authToken}` });
      HTTPClientV3.saveHeader({ key: "companyId", value: `company_${data.companyId}` });
      yield put(authActions.loginFail(null));

      const res = yield appService.getMenuObj();

      yield put(appMenuSuccesss(res));

      if (payload.otp) {
        yield put(authActions.otpVerifyed());
        const delay = (ms) => new Promise((res) => setTimeout(res, ms));
        yield delay(2000);
      }
    }

    yield put(authActions.loginSuccess({ ...data, ...payload }));

    // yield call(appMenuStoreSaga);

    // setTimeout(() => {
    //   // window.location.reload();
    //   window.location.replace("/");
    // }, 100);

    return true;
  } catch (error) {
    let err = error.response.data.status.message;
    yield put(authActions.loginFail(err));
    return false;
  }
}

export function* handleAutoLogin(token) {
  try {
    // const data = yield authService.login(payload);
    HTTPClient.saveHeader({ key: "Authorization", value: `Bearer ${token}` });
    HTTPClientV2.saveHeader({ key: "Authorization", value: `Bearer ${token}` });
    HTTPClientV3.saveHeader({ key: "Authorization", value: `Bearer ${token}` });
    const data = yield authService.getUser();
    const user = data.data.data;
    HTTPClient.saveHeader({ key: "companyId", value: `company_${user.companyId}` });
    HTTPClientV2.saveHeader({ key: "companyId", value: `company_${user.companyId}` });
    HTTPClientV3.saveHeader({ key: "companyId", value: `company_${user.companyId}` });
    yield put(authActions.loginFail(null));
    yield put(authActions.loginSuccess({ ...user, authToken: token }));
    return true;
  } catch (error) {
    let err = error.response.data.status.message;
    yield put(authActions.loginFail(err));
    return false;
  }
}

export function* loginFlow() {
  while (true) {
    const { payload } = yield take(authActionTypes.LOGIN_INIT);
    yield call(handleLogin, payload);
  }
}

export function* autoLoginFlow() {
  while (true) {
    const { payload } = yield take(authActionTypes.LOGIN_INIT_AUTO);
    yield call(handleAutoLogin, payload);
  }
}

export function* handleLogout() {
  yield put(authActions.clearUserData());
}

export function* logoutFlow() {
  yield takeLatest(authActionTypes.LOGOUT_INIT, handleLogout);
}

export function* authLogger() {
  while (true) {
    yield take("*");
    // console.log(`Auth Saga: ${JSON.stringify(action, null, 2)}`);
  }
}

export function* authSaga() {
  yield all([call(autoLoginFlow), call(registerSagar), call(loginFlow), call(authLogger), call(logoutFlow)]);
}
