import React from "react";
import Select from "react-select";
import { FormGroup, Input, Label } from "reactstrap";
import { FormControlLabel } from "@material-ui/core"
import { Switch } from "@material-ui/core";
import { SurveyContext } from "../store/SurveyStateManager";
import AnswerComponent from "./AnswerComponent";
import CharacterTrackerInput from "./CharacterTrackerInput";

export default function QuestionBox({ question, order }) {
  const [showDescription, setShowDescription] = React.useState(false);
  const {
    updateQuestionData,
    deleteQuestionBox,
    copyQuestionBox,
    questionnaireFormik,
    ANSWER_TYPES,
    ANSWER_TYPE_OPTIONS,
    isDisabled,
    allQuestionsTouched,
    setQuestionsTouched
  } = React.useContext(SurveyContext);

  React.useEffect(() => {
    return () => setQuestionsTouched(false);
  }, [])

  const handleAnswerTypeChange = (e) => {

    questionnaireFormik.setFieldValue(`questions[${order}].answerType`, e);
    if (
      e.value === ANSWER_TYPES.CHECKBOX ||
      e.value === ANSWER_TYPES.DROPDOWN ||
      e.value === ANSWER_TYPES.RADIO
    ) {
      questionnaireFormik.setFieldValue(`questions[${order}].answer`, [
        { value: "", type: "static", id: new Date().valueOf(), oorder:1 },
      ]);
    } else if (e.value === ANSWER_TYPES.SLIDER) {
      questionnaireFormik.setFieldValue(`questions[${order}].answer`, {
        maxValue: { label: "", value: "" },
        minValue: { label: "", value: "" },
      });
    } else {
      questionnaireFormik.setFieldValue(`questions[${order}].answer`, "");
    }
  };

  const toggleRequired = (value) => {
    questionnaireFormik.setFieldValue(`questions[${order}].required`, value);
  };

  return (
    <div className="row background-white">
      <div className="question-dragable-icon" />
      <div className="question-box col-12">
        <FormGroup className="mt-5">
          <Label className="ufyndLabel d-flex">Question {order + 1}<p className='text-danger'>&nbsp;*</p></Label>
          <Input
            onBlur={() => questionnaireFormik.setFieldTouched(`questions[${order}].questionTitle`, true)}
            onChange={(e) =>
              questionnaireFormik.setFieldValue(
                `questions[${order}].questionTitle`,
                e.target.value
              )
            }
            value={questionnaireFormik.values?.questions[order]?.questionTitle}
            placeholder="Enter your question here"
            disabled={isDisabled('questionTitle')}
          />
          {
            ((questionnaireFormik?.touched?.questions &&
            questionnaireFormik?.touched?.questions[order]?.questionTitle)  || allQuestionsTouched) &&
            questionnaireFormik?.errors?.questions &&
            questionnaireFormik?.errors?.questions[order]?.questionTitle && (
              <div className="customCssInputFieldError">
                {questionnaireFormik.errors.questions[order].questionTitle}
              </div>
            )}

          {!showDescription && (
            <span
              className="primary mt-2 d-block text-primary cursor-pointer"
              onClick={() => setShowDescription(true)}
            >
              <i className="fa fa-plus text-primary"></i>&nbsp; Add description
            </span>
          )}
        </FormGroup>

        {showDescription && (
          <FormGroup className="mt-5">
            {/* <Label className="ufyndLabel">Description</Label> */}
            <CharacterTrackerInput
              allowResize={false}
              {...questionnaireFormik.getFieldProps(
                `questions[${order}].questionDescription`
              )}
              value={
                questionnaireFormik.values?.questions[order]
                  ?.questionDescription
              }
              disabled={isDisabled('questionDescription')}
              placeholder="Add a description for your question"
              label="Description"
              maxChar={120}
              textArea={true}
            />
          </FormGroup>
        )}
        <FormGroup className="mt-5 ">
          <Label className="ufyndLabel d-flex">Answer Type <p className='text-danger'>&nbsp;*</p></Label>

          <Select
            className=""
            options={ANSWER_TYPE_OPTIONS}
            onChange={handleAnswerTypeChange}
            value={questionnaireFormik?.values?.questions[order].answerType}
            isDisabled={isDisabled('answerType')}
            onBlur={() => questionnaireFormik.setFieldTouched(`questions[${order}].questionTitle`, true)}
            placeholder="Select answer type"
          />

          {
            ((questionnaireFormik?.touched?.questions && questionnaireFormik?.touched?.questions)  || allQuestionsTouched )&&
            questionnaireFormik?.errors?.questions &&
            questionnaireFormik?.errors?.questions[order]?.answerType && (
              <div className="customCssInputFieldError">
                Selecting an Answer Type is required
              </div>
            )}
        </FormGroup>

        <AnswerComponent
          order={order}
          errors={questionnaireFormik.errors}
          setFieldTouched={questionnaireFormik.setFieldTouched}
          touched={questionnaireFormik.touched}
          type={questionnaireFormik?.values?.questions[order].answerType?.value}
          data={questionnaireFormik?.values?.questions[order].answer || null}
          allQuestionsTouched={allQuestionsTouched}
          setQuestionsTouched={setQuestionsTouched}
          updateData={(items) =>
            questionnaireFormik.setFieldValue(
              `questions[${order}].answer`,
              items
            )
          }
          addOption={() =>
            questionnaireFormik.setFieldValue(`questions[${order}].answer`, [
              ...question.answer,
              {
                value: "",
                type: "static",
                id: new Date().valueOf(),
                oOrder: question.answer.length + 1,
              },
            ])
          }
          addOtherOption={() => {
            questionnaireFormik.setFieldValue(
              `questions[${order}].isOther`,
              true
            );
            questionnaireFormik.setFieldValue(`questions[${order}].answer`, [
              ...question.answer,
              { value: "", type: "other", id: new Date().valueOf() },
            ]);
          }}
          removeAnswer={(id) =>
            questionnaireFormik.setFieldValue(
              `questions[${order}].answer`,
              question.answer.filter((item) => {
                return item?.id !== id;
              })
            )
          }
          removeOtherAnswer={() => {
            questionnaireFormik.setFieldValue(
              `questions[${order}].isOther`,
              false
            );

            questionnaireFormik.setFieldValue(
              `questions[${order}].answer`,
              question?.answer &&
                Array.isArray(question?.answer) &&
                question?.answer.length !== 0
                ? question?.answer?.filter((item, index) => {
                    return item?.type !== "other";
                  })
                : null
            );
          }}
          updateAnswer={(value, id) => {
            questionnaireFormik.setFieldValue(
              `questions[${order}].answer`,
              question.answer.map((item, index) => {
                return item?.id === id ? { ...item, value } : item;
              })
            );
          }}
          updateOtherAnswer={(value) => {
            questionnaireFormik.setFieldValue(
              `questions[${order}].answer`,
              question.answer.map((item, index) => {
                return item?.type === "other" ? { ...item, value } : item;
              })
            );
          }}
          updateLinearScaleData={(data, key) => {
            questionnaireFormik.setFieldValue(`questions[${order}].answer`, {
              ...question.answer,
              [key]: { ...question.answer[key], ...data },
            });
          }}
          isOther={question.isOther}
          disabledInputs={isDisabled("answer")}
        />
      </div>

      <div className="col-12 d-flex justify-content-between align-items-center mt-5">
        <FormControlLabel
          control={
            <Switch
              checked={question.required}
              onChange={(e) => toggleRequired(e.target.checked)}
              name="required"
              color="primary"
              disabled={isDisabled('isRequired')}
            />
          }
          label="Required"
        />
        <div className="button-group d-flex align-items-center question-box-actions">
          <div className='bg-icons bg-icon20 bg-copy cursor-pointer copy-btn' onClick={() => copyQuestionBox(order, question)}></div>
          {order !== 0 ?
          <div className="bg-icons bg-icon20 bg-trash icon-left20 cursor-pointer delete-btn" onClick={() => deleteQuestionBox(order)}></div>: null}
        </div>
      </div>
    </div>
  );
}
