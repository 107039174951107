import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import CreateSurvey from "./pages/CreateSurvey.jsx";
import { SurveyContextProvider } from "./store/SurveyStateManager.js";
import SurveyListing from "./pages/List.jsx";
import Summary from "./pages/Summary";
import Responses from "./pages/Responses";
import UserResponse from "./pages/UserResponse";
import Users from "./pages/Users";
import QuestionWise from "./pages/QuestionWise";
import "./_style.scss";
import EditSurvey from "./pages/EditSurvey.jsx";
import ViewSurvey from "./pages/ViewSurvey.jsx";
import { useSelector } from "react-redux";
import { generateOptions } from "./utils.js";

const Survey = () => {
  const CONSTANTS = useSelector(
    (store) => store.appData.globalConstants.survey
  );
  const { ANSWER_TYPES } = React.useMemo(() => generateOptions({}), []);
  return (
    <div>
      <Switch>
        {<Redirect exact from={`/loyalty/games/survey`} to={`/loyalty/games/survey/all`} />}

        <Route
          path={`/loyalty/games/survey/create-survey`}
          render={(props) => (
            <SurveyContextProvider editMode={false}>
              <CreateSurvey {...props} />
            </SurveyContextProvider>
          )}
        />

        <Route
          path={`/loyalty/games/survey/edit/:id`}
          render={(props) => (
            <SurveyContextProvider editMode={true}>
              <EditSurvey {...props} />
            </SurveyContextProvider>
          )}
        />
        <Route
          path={`/loyalty/games/survey/view/:id`}
          render={(props) => (
            <SurveyContextProvider>
              <ViewSurvey {...props} />
            </SurveyContextProvider>
          )}
        />

        <Route
          path={`/loyalty/games/survey/:id/summary`}
          render={(props) => <Summary answerTypes={ANSWER_TYPES} {...props} />}
        />
        <Route path={`/loyalty/games/survey/:id/responses`} component={Responses} />
        <Route
          path={`/loyalty/games/survey/:id/users/:userId`}
          render={(props) => (
            <UserResponse {...props} answerTypes={ANSWER_TYPES} />
          )}
        />
        <Route path={`/loyalty/games/survey/:id/users`} component={Users} />
        <Route
          path={`/loyalty/games/survey/:id/questionwise`}
          render={(props) => (
            <QuestionWise {...props} answerTypes={ANSWER_TYPES} />
          )}
        />
        <Route path={`/loyalty/games/survey/:type`} render={(props) => <SurveyContextProvider editMode={false}><SurveyListing {...props}/></SurveyContextProvider>} />
      </Switch>
    </div>
  );
};

export default Survey;
