import {appStoreActiontype} from './appStore.actiontype';

export const appConst_init = () => ({
  type: appStoreActiontype.APPCONSTANT_INIT
});

export const appConstSuccess = (appConst: any) => ({
  type: appStoreActiontype.APPCONSTANT_SUCCESS,
  payload: appConst
});


export const appMenuSuccesss = (appConst: any) => ({
  type: appStoreActiontype.APP_MENU_SUCCESS,
  payload: appConst
});

export const appConstOnLogout = () => ({
  type: appStoreActiontype.APPCONSTANT_ONLOGOUT
});

export const appConstOnError = (errorObj: any) => ({
  type: appStoreActiontype.APPSTORE_ERROR,
  payload: errorObj
});

export const appConstClearError = () => ({
  type: appStoreActiontype.APPSTORE_CLEAR_ERROR
})

export const setCreationPage = (moduleName: string) => ({
  type: appStoreActiontype.SET_CREATION_PAGE,
  payload: {moduleName}
});

export const clearCreationPage = () => setCreationPage('');
