import React, { useState, useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import "./styles.scss";

export function InputSearch(props) {
  const [searchTerm, setSearchTerm] = useState("");

  const { onSearch, delay, placeholder } = props;

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onSearch(searchTerm);
    }, delay || 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, delay]);

  const handleChange = (value) => {
    setSearchTerm(value);
  };

  return (
    <div className="preview-search">
      <InputGroup className="ufyndTextBox_search">
        <div className="searchIcon">
          <i className="bg-icon bg-icon16 bg-search icon-grey"></i>
        </div>
        <Form.Control
          id="search"
          name="search"
          className={`input-field ${searchTerm?.length === 0 ? "" : "typed border-radius-4"}`}
          onChange={(e) => {
            handleChange(e.target.value);
          }}
          type="text"
          value={searchTerm}
          placeholder={placeholder || "Search Placeholder"}
        />
      </InputGroup>
    </div>
  );
}
