export const prepareObj = (rawData) => {
  if (!rawData) return [];

  let table = rawData?.tableStructure;
  let root = rawData?.root;

  let arr = [];

  arr.push({ name: table?.[root]?.tableName, nodeId: table?.[root]?.tableName, stage: 1 });

  table?.[root]?.child?.forEach((item) => {
    let found = arr.find((it) => it.name === root);
    arr.push({ name: item?.tableName, parentNodeId: root, nodeId: item?.tableName, stage: found?.stage + 1 });
  });

  Object.keys(table?.[root])?.forEach((it) => {
    if (it === "tableName" || it === "mapping" || it === "child") return;

    table?.[root]?.[it]?.child.forEach((item) => {
      let found = arr.find((itt) => itt.name === it);

      arr.push({ name: item?.tableName, parentNodeId: it, nodeId: item?.tableName, stage: found?.stage + 1 });
    });
  });

  return arr;
};
