import { campaignActionTypes } from "./campaign.actiontype";
import {campaignFormSteps} from '../../constants/constants';
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";


interface ICampaignChannelInfo {
	channelId: null | number,
	companyChannelId: {value: string; label: string}
};

interface ICampaignNameInfo {
	modal: {show: boolean, isCreate: boolean, isEdit: boolean},
	name: string
};

interface ITemplateInfo {
	isDLTApproved: boolean;
	selectedTemplate: {
		name: string,
		template: string,
		dltId: string
	};
	dynamicMapping: Array<any>;
	companySmsChannel: any;
}

interface ICreateEditCampaignForm {
	channelInfo: ICampaignChannelInfo,
	campaignNameInfo: ICampaignNameInfo,
	segmentInfo: null | {label: string; value: string;},
	templateInfo: ITemplateInfo,
	scheduleInfo: IScheduleInfo,
	emailSettingsInfo: IEmailSettingsInfo
};

interface ICampaignStepsReducer {
	currentStep: string;
	currentSubStep: string;
	campaignChannels: null | Array<any>;
	createEditCampaignForm: ICreateEditCampaignForm;
	campaignFormErrors: {[key: string]: string};
	scheduleInfo: IScheduleInfo;
	emailSettingsInfo: IEmailSettingsInfo;
	campaignCreated: boolean;
};

export interface dateTimeScheduleType {
  campaignDate: MaterialUiPickersDate;
  campaignTime: MaterialUiPickersDate;
  campaignTimezone: string;
  repeats?: string;
	repeatRules?: any
}

interface IScheduleInfo {
	scheduleType: null | string;
	oneTimeSchedule: Array<dateTimeScheduleType>;
	multipleDaysSchedule: {
		dates: MaterialUiPickersDate[];
		time: MaterialUiPickersDate;
		campaignTimezone: string;
	};
	recurring: dateTimeScheduleType
}

interface IEmailSettingsInfo {
	subject: string;
	fromName: string;
	fromEmail: string;
	replyToEmail: string;
	plainTextVersion: string;
	queryString: string;
}


// initialStates

const campaignChannelInfoInitialState: ICampaignChannelInfo = {
	channelId: null,
	companyChannelId: {value: '', label: ''}
};

const campaignNameInfoInitialState: ICampaignNameInfo = {
	modal: {show: false, isCreate: false, isEdit: false},
	name: ''
};

const templateInfoInitialState: ITemplateInfo = {
	isDLTApproved: false,
	selectedTemplate: {
		name: "",
		template: "",
		dltId: ""
	},
	dynamicMapping: [],
	companySmsChannel: {}
};

const scheduleInfoInitalState: IScheduleInfo = {
	scheduleType: "",
	oneTimeSchedule: [{
		campaignDate: new Date(),
		campaignTime: new Date(),
		campaignTimezone: "ist"
	}],
	multipleDaysSchedule: {
		dates: [new Date()],
		time: new Date(),
		campaignTimezone: "ist"
	},
	recurring: {
		campaignDate: new Date(),
		campaignTime: new Date(),
		campaignTimezone: "ist",
		repeats: 'Daily',
		repeatRules: {
			repeatFreq: 'day',
			byweekday: [
				'Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
			bymonth: [],
			count: 1,
			interval: 1,
			until: new Date(),
			endType: 'never',
			monthRepeatRules: ''
		}
	}
}

const emailSettingsInfoInitalState: IEmailSettingsInfo = {
	subject: '',
	fromName: '',
	fromEmail: '',
	replyToEmail: '',
	plainTextVersion: '',
	queryString: '',
}

const createEditCampaignFormInitialState: ICreateEditCampaignForm = {
	channelInfo: campaignChannelInfoInitialState,
	campaignNameInfo: campaignNameInfoInitialState,
	segmentInfo: null,
	templateInfo: templateInfoInitialState,
	scheduleInfo: scheduleInfoInitalState,
	emailSettingsInfo: emailSettingsInfoInitalState
};

const initialState: ICampaignStepsReducer = {
	currentStep: campaignFormSteps.TYPE,
	currentSubStep: '',
	campaignChannels: null,
	createEditCampaignForm: createEditCampaignFormInitialState,
	campaignFormErrors: {},
	campaignCreated: false
};

// reducers

const campaignNameInfo = (
	state = campaignNameInfoInitialState,
	action: {type:string, payload: any}
) => {
	switch (action.type) {
		case campaignActionTypes.CHANGE_CAMPAIGN_NAME_MODAL:
			return Object.assign({}, state, {
				modal: action.payload
			});
		case campaignActionTypes.SET_CAMPAIGN_NAME:
			return Object.assign({}, state, {
				name: action.payload
			});
		default:
			return state;
	}
}

const channelInfo = (
	state = campaignChannelInfoInitialState,
	action: {type: string; payload: any}
) => {
	switch (action.type) {
		case campaignActionTypes.CHANGE_CAMPAIGN_CHANNEL_ID:
			return Object.assign({}, state, {
				channelId: action.payload
			});
		case campaignActionTypes.CHANGE_COMPANY_CHANNEL_ID:
			return Object.assign({}, state, {
				companyChannelId: action.payload
			});
		default:
			return state
	}
}

const templateInfo = (
	state = templateInfoInitialState,
	action: {type: string, payload: any}
) => {
	switch(action.type) {
		case campaignActionTypes.SET_IS_DLT_APPROVED_STATUS:
			return Object.assign({}, state, {isDLTApproved: action.payload});
		case campaignActionTypes.UPDATE_SELECTED_TEMPLATE_DATA:
			return Object.assign({}, state, {selectedTemplate: action.payload});
		case campaignActionTypes.SET_DYNAMIC_MAPPING:
			return Object.assign({}, state, {dynamicMapping: action.payload});
		case campaignActionTypes.SET_COMPANY_SMS_CHANNEL:
			return Object.assign({}, state, {companySmsChannel: action.payload});
		default:
			return state;
	}
}

const createEditCampaignForm = (
	state = createEditCampaignFormInitialState,
	action: {type: string; payload: any}
) => {
	switch (action.type) {
		case campaignActionTypes.SET_CREATE_EDIT_CAMPAIGN_FORM:
			return Object.assign({}, action.payload);
		case campaignActionTypes.CHANGE_CAMPAIGN_CHANNEL_ID:
		case campaignActionTypes.CHANGE_COMPANY_CHANNEL_ID:
			return Object.assign({}, state, {
				channelInfo: channelInfo(state.channelInfo, action)
			});
		case campaignActionTypes.CHANGE_CAMPAIGN_NAME_MODAL:
		case campaignActionTypes.SET_CAMPAIGN_NAME:
			return Object.assign({}, state, {
				campaignNameInfo: campaignNameInfo(state.campaignNameInfo, action)
			});
		case campaignActionTypes.CHANGE_CAMPAIGN_SEGMENT:
			return Object.assign({}, state, {
				segmentInfo: action.payload
			});
		case campaignActionTypes.SET_IS_DLT_APPROVED_STATUS:
		case campaignActionTypes.UPDATE_SELECTED_TEMPLATE_DATA:
		case campaignActionTypes.SET_DYNAMIC_MAPPING:
		case campaignActionTypes.SET_COMPANY_SMS_CHANNEL:
			return Object.assign({}, state, {
				templateInfo: templateInfo(state.templateInfo, action),
				channelSettingsInfo: action.payload
			});
		case campaignActionTypes.CHANGE_SCHEDULE_DATA:
			return Object.assign({}, state, {
				scheduleInfo: action.payload
			});
		case campaignActionTypes.CHANGE_EMAIL_SETTINGS:
			return Object.assign({}, state, {
				emailSettingsInfo: action.payload
			});
		default:
			return state;
	}
}

const CampaignStepsReducer = (
	state = initialState,
	action: { type: string; payload: any}
) => {
	switch (action.type) {
		case campaignActionTypes.CAMPAIGN_CHANGE_CREATE_CAMPAIGN_STEP:
			return Object.assign({}, state, {
				currentStep: action.payload.step
			});
		case campaignActionTypes.CAMPAIGN_CHANGE_CAMPAIGN_SUB_STEP:
			return Object.assign({}, state, {
				currentSubStep: action.payload.step
			});
		case campaignActionTypes.SAVE_CAMPAIGN_CHANNELS:
			return Object.assign({}, state, {
				campaignChannels: action.payload
			});
		case campaignActionTypes.CHANNEL_ADDED_SUCCESSFULLY:
			const selectedChannelId = state.createEditCampaignForm.channelInfo.channelId;
			const channels = state?.campaignChannels.slice() || [];
			const newChannels = channels.map(item => {
				if(item.channelId === selectedChannelId) {
					return ({
						...item,
						isChannelSetup: 1
					})
				} else {
					return ({...item})
				}
			});
			return Object.assign({}, state, {
				campaignChannels: newChannels
			});
		case campaignActionTypes.UPDATE_CAMPAIGN_FORM_ERRORS:
			return Object.assign({}, state, {
				campaignFormErrors: action.payload
			})
		case campaignActionTypes.SET_CREATE_EDIT_CAMPAIGN_FORM:
		case campaignActionTypes.CHANGE_CAMPAIGN_CHANNEL_ID:
		case campaignActionTypes.CHANGE_COMPANY_CHANNEL_ID:
		case campaignActionTypes.CHANGE_CAMPAIGN_NAME_MODAL:
		case campaignActionTypes.SET_CAMPAIGN_NAME:
		case campaignActionTypes.CHANGE_CAMPAIGN_SEGMENT:
		case campaignActionTypes.SET_IS_DLT_APPROVED_STATUS:
		case campaignActionTypes.UPDATE_SELECTED_TEMPLATE_DATA:
		case campaignActionTypes.SET_DYNAMIC_MAPPING:
		case campaignActionTypes.SET_COMPANY_SMS_CHANNEL:
		case campaignActionTypes.CHANGE_SCHEDULE_DATA:
		case campaignActionTypes.CHANGE_EMAIL_SETTINGS:
		case campaignActionTypes.SET_COMPANY_SMS_CHANNEL:
			return Object.assign({}, state, {
				createEditCampaignForm: createEditCampaignForm(
					state.createEditCampaignForm, action)
			});
		case campaignActionTypes.RESET_CAPAIGN_FORM_DATA:
			return Object.assign({}, state, {
				createEditCampaignForm: createEditCampaignFormInitialState,
				currentStep: campaignFormSteps.TYPE,
				campaignCreated: false
			});
		case campaignActionTypes.CAMPAIGN_CREATED_SUCCESSFULL:
			return Object.assign({}, state, {
				...initialState,
				campaignCreated: true
			});
		default:
			return state;
	}
};

export default CampaignStepsReducer;
