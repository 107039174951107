import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { ButtonInterfaceProps } from "./ButtonInterface";

const CustomButton: React.FC<ButtonInterfaceProps> = ({
  variant,
  name,
  type,
  size,
  disabled,
  loading,
  block,
  onClick,
	className,
  ...restProps
}: ButtonInterfaceProps): JSX.Element => {
  return (
    <Button
      className = {`d-flex align-items-center justify-content-center ${className}`}
      onClick = {() => { if(onClick) { onClick(); } }}
      type={type} variant={variant} disabled={disabled} size={size} block={block} {...restProps}>
      {name} {loading && <Spinner animation="border" />}
    </Button>
  );
};

export default CustomButton;
