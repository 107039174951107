import { AxiosResponse } from "axios";
import {
  IGetCouponParams,
} from "../../../pages/Loyalty/Coupons/_constants/types";
import {  HTTPClientV3 } from "../http.service";


export const surveyService = {
  async getSurveys(params: IGetCouponParams) {
    try {
      const result: AxiosResponse = await HTTPClientV3.get(
        `/survey/`,
        params
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async addSurvey(data: any) {
    try {
      const result: AxiosResponse = await HTTPClientV3.post(
        `/survey/`,
        data
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async updateSurvey(id:any, data: any) {
    try {
      const result: AxiosResponse = await HTTPClientV3.put(
        `/survey/${id}`,
        data
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getSurveyWithId(surveyId:any, params: any) {
    try {
      const result: AxiosResponse = await HTTPClientV3.get(
        `/survey/${surveyId}`,
        params
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async updateSurveyStatus(surveyId: any, status: any) {
    try {
      const result: AxiosResponse = await HTTPClientV3.put(
        `/survey/status/${surveyId}`,
        {status}
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getSurveyOverview(surveyId:any) {
    try {
      const result: AxiosResponse = await HTTPClientV3.get(
        `/survey/overview/${surveyId}`
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
