export const smsProviderValidation: {[key: string]: Function} = {
  nickName: (value) =>  {
    if(!value) {
      return 'Please enter a nickname';
    }
  },
  requestType: (value) => {
    if(!value) {
      return 'Please select a request type';
    }
  },
  httpEndpoint: (value) => {
    if(!value) {
      return 'Please enter a http endpoint';
    }
  },
  authenticationType: (value) => {
    if(!value) {
      return 'Please Select a authentication type';
    }
  },
  basicAuthenticationUserName: (value) => {
    if(value.length < 8) {
      return 'Username should have atleast 8 character long'
    }
  },
  basicAuthenticationPassword: (value) => {
    if(value.length < 8) {
      return 'Password should have atleast 8 character long'
    }
  },
  headersKey: (value) => {
    if(!value) {
      return 'Please enter a key';
    }
  },
  headersValue: (value) => {
    if(!value) {
      return 'Please enter a value';
    }
  }
};


export const onSubmitValidation = (channelData) => {
  const settings = typeof channelData.settings === 'string'
    ? JSON.parse(channelData.settings)
    : channelData.settings? channelData.settings: {};
  const errorObject: {[key: string]: string} = {};
  if(!channelData.nickName) {
    errorObject.nickName = 'Please enter a nickname'
  }
  if(channelData.settingType === 1) {
    // otherGeneric type
    if(!settings.requestType) {
      errorObject.requestType = 'Please select a vaild RequestType'
    }
    if(!settings.endPoint) {
      errorObject.httpEndpoint =	"Please select a vaild EndPoint"
    }
    if(settings.headers) {
      settings.headers.map((item: any, index: number) => {
        const {key, value} = item;
        if(key.length === 0) {
          errorObject[`headersKey-${index}`] = "Headers key should not be empty"
        }
        if(value.length === 0) {
          errorObject[`headersValue-${index}`] = "Headers value should not be empty"
        }
      });
    }

    if(settings?.username?.length < 8) {
      errorObject.basicAuthenticationUserName = 'Username should have atleast 8 character long';
    }
    if(settings?.password?.length < 8) {
      errorObject.basicAuthenticationPassword = 'Password should have atleast 8 character long';
    }
  } else {
    Object.keys(settings).map(key => {
      if(!settings[key]) {
        errorObject[key]=`${key} is required.`;
      }
    });
  }
  return errorObject;
};

export const onEmailSubmitValidation = (channelData) => {
  const errorObject: {[key: string]: string} = {};
  if(!channelData.nickName) {
    errorObject.nickName = 'Please enter a nickname'
  }
  if(!channelData.settingType || channelData.settingType === 1) {
    errorObject.settingType = 'Please select a partner option';
    return errorObject;
  }
  const settings = typeof channelData.settings === 'string'
    ? JSON.parse(channelData.settings)
    : channelData.settings? channelData.settings: {};
  Object.keys(settings).map(key => {
    if(!settings[key]) {
      errorObject[key]=`${key} is required.`;
    }
  });
  return errorObject;
}
export const onWhatsAppSubmitValidation = (channelData) => {
  const errorObject: {[key: string]: string} = {};
  if(!channelData.nickName) {
    errorObject.nickName = 'Please enter a nickname'
  }
  if(!channelData.settingType || channelData.settingType === 1) {
    errorObject.settingType = 'Please select a partner option';
    return errorObject;
  }
  const settings = typeof channelData.settings === 'string'
    ? JSON.parse(channelData.settings)
    : channelData.settings? channelData.settings: {};
  Object.keys(settings).map(key => {
    if(!settings[key]) {
      errorObject[key]=`${key} is required.`;
    }
  });
  return errorObject;
}
