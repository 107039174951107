import { AxiosResponse } from "axios";
import { HTTPClient } from "./http.service";
var qs = require("qs");

export const contactsService = {
  async getContacts({ ...params }: any) {
    try {
      const result: AxiosResponse = await HTTPClient.get(
        `/company/contacts/`,
        params
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async createContact(data: any) {
    try {
			return await HTTPClient.customRequest({
				url: `/company/contacts/createContact`,
				method: "post",
				headers: { "Content-Type": "application/x-www-form-urlencoded" },
				data: qs.stringify(data)
			})
		} catch(e) {
			return Promise.reject(e)
		}
  },
  async getSingleContact(poolId: number| string ,id: number | string) {
    try {
      const result: AxiosResponse = await HTTPClient.get(`/company/contacts/getPoolUserProfile`, {
        poolId,
        customerId:id
      });
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async saveNote(data: any) {
    try {
      return await HTTPClient.post('/company/userNotes', data)
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async updateNote(id:any, data: any) {
    try {
      return await HTTPClient.put(`/company/userNotes/${id}`, data);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async deleteNote(id:any) {
    try {
      return await HTTPClient.delete(`/company/userNotes/${id}`);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getNotes(params: any) {
    try {
      return await HTTPClient.get('/company/userNotes', params)
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getComments(params: any) {
    try {
      return await HTTPClient.get('/company/userNotes/comments', params)
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async addComment(id: any, data: any) {
    try {
      return await HTTPClient.post(`/company/userNotes/comments/`, {...data, noteId: id})
    } catch (e) {
      return Promise.reject(e);
    }
  }
};
