import React, { useState, useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { convertDateToYYYYMMDD } from '../../../utils/date';

export interface Props {
	minDate: any;
	currentDate: any;
	onDateChange: any;
	maxDate: any;
}

const DateSearch: React.FC<Props> = (props) => {
  const [selectedDate, handleDateChange] = useState(props.currentDate);

  const handleChange = (date: any) =>  {
    handleDateChange(date);
    let formattedDate = convertDateToYYYYMMDD(new Date(date))
    props.onDateChange(formattedDate)
  }

  useEffect(()=>{
    handleDateChange(props.currentDate)
  },[props.currentDate])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
            className="dateSearchField searchDateInput"
            inputVariant="outlined"
            openTo="date"
            format="dd/MM/yyyy"
            minDate={props.minDate && new Date(convertDateToYYYYMMDD(new Date(props.minDate)))}
            maxDate={props.maxDate? new Date(convertDateToYYYYMMDD(new Date(props.minDate))):  new Date()}
            placeholder="DD/MM/YYYY"
            views={["year", "month", "date"]}
            value={selectedDate}
            onChange={handleChange}
        />
    </MuiPickersUtilsProvider>
  );
}

export default DateSearch;
