import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import InputText from "../../../components/Forms/Input/Input";
import MyButton from "../../../components/Forms/Button/Button";
import AlertBox from "../../../components/Forms/Alert/Alert";
import {
  formDataProps,
  INITIAL_STATE_REGISTRATION,
  errorFormDataProps,
  validationFormData,
} from "../_constants/index";
import SignUpBanner from "../components/Banner";
import { authActionTypes } from "../../../store/auth/auth.actiontype";
import "../_styles/index.scss";
import { Link, useLocation } from "react-router-dom";
import { authService } from "../../../service";
import Swal from "sweetalert2";
import { getQueryParams } from "../_utils";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import OtpInput from "react-otp-input";

interface eventProps {
  name: string;
  value: string;
}
const RESEND_COUNTER = 30;
const AuthPage: React.FC = (props) => {
  const dispatch = useDispatch();
  const error: string | undefined = useSelector(
    (state: any) => state.auth.error,
    shallowEqual
  );
  const [isOpen, setOpen] = useState<boolean>(false);
  const [otpError, changeOtpError] = useState<string>('');
  const [otpVerified, changeOTPVerified] = useState<boolean>(false);
  const [isRequesting, changeRequestingStatus] = useState<boolean>(false);
  const [resendTimerCount, changeResendCount] = useState<number>(RESEND_COUNTER);
  const [formData, setFormData] = useState<formDataProps>({
    ...INITIAL_STATE_REGISTRATION,
  });
  const [errors, setErrors] = useState<errorFormDataProps>({});
  const location = useLocation();
  const FieldValidation = ({ name, value }: eventProps) => {
    let error: undefined | string = validationFormData[name](value);
    if (error) {
      setErrors({ ...errors, [name]: error });
    } else {
      delete errors[name];
      setErrors(errors);
    }
  };
  const handleChange = ({ name, value }: eventProps) => {
    setFormData({ ...formData, [name]: value });
    FieldValidation({ name, value });
  };
  const handleBlur = ({ name, value }: eventProps) => {
    FieldValidation({ name, value });
  };
  const validateForm = (formDataValues: formDataProps): boolean => {
    let submitErrors: errorFormDataProps = {};
    for (const [key, value] of Object.entries(formDataValues)) {
      let error: undefined | string = validationFormData[key](value);
      if (error) {
        submitErrors[key] = error;
      }
    }
    setErrors(submitErrors);
    return Object.keys(submitErrors).length === 0;
  };
  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    if (props.otp.length===6) {
      changeRequestingStatus(true);
      try {
        await authService.verifyResetCode({
          email: props.email,
          resetPassword: props.otp
        });
        changeOTPVerified(true);
        setTimeout(() => {
          props.changeOtpVerifiedStatus();
        }, 3000)
      } catch (error) {
        changeOtpError('Invalid OTP');
        // const message = error?.data?.status?.message;
      }
      changeRequestingStatus(false);
    }
  };

  let queryParams = getQueryParams(location);

  const resendOtp = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    await authService.forgotPass_sendEmail(props.email);
    changeResendCount(RESEND_COUNTER);
    startResendTimer();
  }

  const startResendTimer = () => {
    const intervalInstance = setInterval(() => {
      changeResendCount((nextNumber) => {
        if(nextNumber === 0) {
          clearInterval(intervalInstance);
          return 0;
        }
        return nextNumber-1
      });
    }, 1000)
  }

  React.useEffect(() => {
    startResendTimer();
  }, []);

  useEffect(() => {
    if (queryParams && queryParams.enc) {
      let encodedToken = queryParams.enc;
      let token = atob(encodedToken);
      dispatch({ type: authActionTypes.LOGIN_INIT_AUTO, payload: token });
    }
  }, []);

  return (
    <section className="registration-form">
      <div className="container-fluid">
        <Row className="registration-form-container">
          <Col md={7} className="reg-form-wrapper">
            <div className="reg-form-inner">
              <div className="form-logo">
                <img src="/media/onboarding/logo.png" />
                <span>UniSuite</span>
              </div>
            </div>
            <div className="reg-form-inner">
              <div className="form-steps-heading">
                <div className="form-heading">
                  <div className="form-title">OTP Verification</div>
                  <div className="form-subtext">Please enter the 6-digit OTP recieved in your email.</div>
                </div>
              </div>
              <form
                id="kt_login_signin_form"
                className="form-box-container"
                onSubmit={handleSubmit}
                >
                <div className="form-group">
                  <label className="form-label">Enter 6-digit OTP <span className="required-input-field">*</span></label>
                  <div className="otp-container">
                    <OtpInput
                      value={props.otp}
                      className="otp-input-list"
                      numInputs={6}
                      placeholder=""
                      inputStyle={`otp-input ${otpError?'invalid-otp': ''} ${otpVerified? 'verify-otp': ''}`}
                      focusStyle="otp-input-focused"
                      isInputNum={true}
                      onChange={(value) => {
                        changeOtpError('');
                        changeOTPVerified(false);
                        props.changeOTP(value);
                      }}
                    />
                    {otpVerified && <img className="otp-verified" src="/media/onboarding/otp-verified.svg"/>}
                  </div>
                  {otpError && <div className="text-danger">{otpError}</div>}
                  <div className="resend-otp-container">
                    {(resendTimerCount>0 && !otpVerified) && <span className="resend-wait">Resend in {resendTimerCount}s</span>}
                    {(resendTimerCount<=0 && !otpVerified) &&<span className="resend-active" onClick={resendOtp}>Resend OTP</span>}
                  </div>
                </div>
                <MyButton
                  variant="primary"
                  type="submit"
                  name="Next"
                  block={true}
                  disabled={props.otp.length!==6 || otpVerified || otpError || isRequesting}
                  className="btn-new"
                />
                <div to="/" className="btn-back" onClick={props.goBack}><NavigateBeforeIcon /> Back</div>
              </form>
            </div>
          </Col>
          <Col md={5} className="reg-form-banner">
            <SignUpBanner signInOTPVerification={true} />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default AuthPage;
