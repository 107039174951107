export const convertDateToYYYYMMDD: (data: any) => any = (data) => {
  if (!data) {
    return;
  }
  let d, date, month, year;
  d = new Date(data);
  date = d.getDate();
  month = d.getMonth();
  year = d.getFullYear();

  return [year, month < 9 ? '0'+ (month + 1) : month + 1, date < 10 ? '0'+ date : date].join('-')
}

export const convertDateToMMDDYYYY = (data: any) => {
  if (!data) {
    return;
  }
  let d, date, month, year;
  d = new Date(data);
  date = d.getDate();
  month = d.getMonth();
  year = d.getFullYear();

  return [month < 9 ? '0'+ (month + 1) : month + 1, date < 10 ? '0'+ date : date, year].join('-')
}
export const convertDateToDDMMYYYY = (data: any) => {
  if (!data) {
    return;
  }
  let d, date, month, year;
  d = new Date(data);
  date = d.getDate();
  month = d.getMonth();
  year = d.getFullYear();

  return [date < 10 ? '0'+ date : date, month < 9 ? '0'+ (month + 1) : month + 1,year].join('-')
}

export const convertDateToHHMM = (data: any) => {
  if (!data) {
    return;
  }
  let d, hour, minutes;
  d = new Date(data);
  hour = d.getMinutes();
  minutes = d.getHours();

  return [minutes, hour].join(':')
}

export const convertISO = (str: string) => {
  let darr = str.split("-");
  return new Date(parseInt(darr[2]),parseInt(darr[1])-1,parseInt(darr[0]));
}

export const formatAMPM = (time: string) => {
  const [HR, MIN] = time.split(":");
  return `${+HR > 12 ? (+HR - 12) : HR}:${MIN} ${+HR >= 12 ? 'PM': 'AM'}`;
}
export const formatHHMM = (time: string) => {
  const [HH, MM] = time.split(":");
  return [HH,MM].join(':');
}

export const getDateAndTime = (dateTimestamp: any) => {
  let dt = new Date(dateTimestamp);

  const year = dt.getUTCFullYear();
  let month: string | number = dt.getUTCMonth() + 1;
  let day: string | number = dt.getUTCDate();

  if(day < 10) {
    day = "0"+day;
  }
  if(month < 10) {
    month = "0"+month;
  }

  const date = day + '-' + month + '-' + year;

  let hours =  dt.getHours();
  let AmOrPm = hours >= 12 ? 'pm' : 'am';

  hours = (hours % 12) || 12;

  let minutes: string | number = dt.getMinutes();
  let seconds: string | number = dt.getSeconds();

  if(minutes < 10) {
    minutes = "0"+minutes;
  }
  if(seconds < 10) {
    seconds = "0"+seconds;
  }

  let finalTime = hours + ":" + minutes + " " + AmOrPm;

  return date + " " + finalTime;
}


export function time24to12HourConvert (time: string) {
  // Check correct time format and split into components
  let newTime = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (newTime.length > 1) { // If newTime format correct
    newTime = newTime.slice (1);  // Remove full string match value
    newTime[5] = +newTime[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
    newTime[0] = String(+newTime[0] % 12 || 12); // Adjust hours
  }
  return newTime.join (''); // return adjusted time or original string
}
