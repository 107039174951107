import { RouterProps, StaticRouterProps } from "react-router-dom";

/**
 *
 * @param location - Location prop from React Router
 * @returns Object containing key value pairs of query param keys and their values
 */
export const getQueryParams = (location: any) => {
  const queriesArray = location.search
    .slice(1, location.search.length)
    .split("&");
  let queryObject: { [key: string]: string } = {};
  queriesArray.forEach((item: string) => {
    let query = item.split("=");
    queryObject[query[0]] = query[1];
  });
  return queryObject;
};
