import React from "react";
import CharacterTrackerInput from "./CharacterTrackerInput";
import { FormControlLabel } from "@material-ui/core";
import { FormGroup, Label, Input } from "reactstrap";
import Select from "react-select";
import { Switch } from "@material-ui/core";
import { SurveyContext } from "../store/SurveyStateManager";
import CouponsAsyncSelect from "./CouponsAsyncSelect";
import RewardsAsyncSelect from "./RewardsAsyncSelect";
import { BUCKET_TYPE_COUPON, BUCKET_TYPE_REWARDS } from "../utils";
import Editor from "../../../components/Forms/Editor";
import RichTextEditor from "react-rte";

export default function SurveyInfo() {
  const {
    surveyInfoFormik,
    PRIZE_SELECT_OPTIONS,
    PRIZE_TYPES,
    DefaultTypeOptions,
    isDisabled,
    editMode,
  } = React.useContext(SurveyContext);
  const renderPrizeSelection = () => {
    if (surveyInfoFormik?.values?.isPrize) {
      return (
        <div className="col-12">
          <FormGroup>
            <Label className="ufyndLabel">
              Select Prize <span className="requiredField">*</span>
            </Label>
            <Select
              onBlur={() => surveyInfoFormik.setFieldTouched("prize", true)}
              options={[{ label: "Coupon", value: 1 }]}
              className={"w-50"}
              // defaultValue={PRIZE_SELECT_OPTIONS[0]}
              name="prize"
              value={surveyInfoFormik?.values?.prize}
              onChange={(e) => surveyInfoFormik.setFieldValue("prize", e)}
              isDisabled={isDisabled("defaultType")}
            />
            {surveyInfoFormik?.touched?.prize &&
              surveyInfoFormik?.errors?.prize && (
                <div className="customCssInputFieldError">
                  Choosing a prize type is required
                </div>
              )}
          </FormGroup>
        </div>
      );
    } else {
      return (
        <div className="col-12">
          <FormGroup>
            <CharacterTrackerInput
              type="text"
              label="Add a success message"
              name="successMessage"
              id="successMessage"
              placeholder="success message"
              required
              value={surveyInfoFormik.values.successMessage || ''}
              maxChar={156}
              onChange={(event: any) => {
                surveyInfoFormik.setFieldValue('successMessage', event.target.value);
              }}
              onBlur={() => surveyInfoFormik.setFieldTouched("successMessage", true)}
              error={surveyInfoFormik?.touched?.successMessage &&
                surveyInfoFormik?.errors?.successMessage}
            />
          </FormGroup>
        </div>
      );
    }
  };
  const renderPrizeFollowupOptions = () => {
    if (!PRIZE_TYPES || !surveyInfoFormik.values.isPrize) {
      return "";
    }
    switch (surveyInfoFormik?.values?.prize?.value) {
      case PRIZE_TYPES.COUPON:
        //COUPON SELECT
        return (
          <div className="col-6">
            <FormGroup>
              <Label className="ufyndLabel">
                Select coupon <span className="requiredField">*</span>
              </Label>
              <CouponsAsyncSelect
                onBlur={() => {
                  surveyInfoFormik.setFieldTouched("benefitId", true);
                }}
                updateCouponValue={(e) => {
                  // alert("triggered");
                  surveyInfoFormik.setFieldValue("benefitId", e);
                }}
                value={surveyInfoFormik.values.benefitId}
                category={BUCKET_TYPE_COUPON}
                disabled={isDisabled("benefitId")}
              />
            </FormGroup>
            {surveyInfoFormik?.touched?.benefitId &&
              surveyInfoFormik?.errors?.benefitId && (
                <div className="customCssInputFieldError">
                  Please select coupon
                </div>
              )}
          </div>
        );
    }
  };

  const togglePrize = (value) => {
    surveyInfoFormik.setFieldValue(`isPrize`, value);
  };

  return (
    <div>
      <h3 className="font-weight-bold py-3">Survey details</h3>
      <CharacterTrackerInput
        label="Survey Title"
        type="text"
        placeholder="Add survey name"
        required
        maxChar={140}
        onBlur={() => surveyInfoFormik.setFieldTouched("surveyTitle", true)}
        error={
          surveyInfoFormik?.touched?.surveyTitle &&
          surveyInfoFormik?.errors?.surveyTitle
        }
        {...surveyInfoFormik.getFieldProps("surveyTitle")}
        disabled={isDisabled("surveyTitle")}
      />

      {/* <CharacterTrackerInput
        label="Survey Description"

        placeholder="Add a description for your survey"
        error={
          surveyInfoFormik?.touched?.surveyDescription &&
          surveyInfoFormik?.errors?.surveyDescription
        }
        required
        maxChar={500}
        disabled={isDisabled("surveyDescription")}
        onBlur={() =>
          surveyInfoFormik.setFieldTouched("surveyDescription", true)
        }
        textArea={true}

        {...surveyInfoFormik.getFieldProps("surveyDescription")}
      /> */}


      <Editor
        onChange={(e) => surveyInfoFormik.setFieldValue("surveyDescription", e)}
        content={surveyInfoFormik.values.surveyDescription}
        label="Survey Description"
        name="surveyDescription"
        required={true}
        config={{pastePlain: true}}
        error = {
          surveyInfoFormik?.touched?.surveyDescription ?
            surveyInfoFormik.errors.surveyDescription ? surveyInfoFormik.errors.surveyDescription:
            RichTextEditor.createValueFromString(surveyInfoFormik.values.surveyDescription, 'html')._editorState.getCurrentContent().getPlainText().length >500?
              'Should not more than 500':
            RichTextEditor.createValueFromString(surveyInfoFormik.values.surveyDescription, 'html')._editorState.getCurrentContent().getPlainText().length === 0?
              'Required': '': ''
        }
        onBlur={() => surveyInfoFormik.setFieldTouched("surveyDescription", true)}
      />


      <div className="row">
        <div className="col-6">
          <FormGroup>
            <Label className="ufyndLabel">
              Prize
            </Label>
            <br />
            <FormControlLabel
              control={
                <Switch
                  checked={surveyInfoFormik.values.isPrize}
                  onChange={(e) => togglePrize(+e.target.checked)}
                  name="required"
                  color="primary"
                  disabled={isDisabled("isRequired")}
                />
              }
            />
            {surveyInfoFormik?.errors?.isPrize && (
              <div className="customCssInputFieldError">
                Choosing a prize type is required
              </div>
            )}
          </FormGroup>
        </div>
      </div>
      <div className="row">{renderPrizeSelection()}</div>
      <div className="row">{renderPrizeFollowupOptions()}</div>
    </div>
  );
}
