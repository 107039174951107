import React, { useLayoutEffect, useRef, useEffect } from "react";
import { OrgChart } from "d3-org-chart";
import * as d3 from "d3";
import MappingOverlay from "../../pages/ContactsV2/components/dbSync/MappingOverlay";
import Tooltip from "../Tooltip";

export const OrgChartComponent = (props, ref) => {
  const { searchText, stageValue, setStageValue, formData, changeFormData, indexOfItem } = props
  console.log(props);
  const stageNodeClass = {
    1: "first",
    2: "second",
    3: "third",
    4: "fourth",
  };
  const d3Container = useRef(null);
  let chart = null;
  let _childTableForMapping = '';

  const [tablesLayout, changeTablesLayout] = React.useState([]);

  const getClassName = (data) => {
    if (searchText.length > 0) {
      return data?.name.includes(searchText) ? " highlight" : " fade";
    } else if (stageValue) {
      return data?.stage == stageValue ? "" : " fade";
    } else if (tablesLayout?.length > 0 && !tablesLayout.includes(data?.name)) {
      return " fade";
    }
    return "";
  };

  const getNodeHeight = (data) => {
    return getClassName(data) === " highlight" ? 55 : 50;
  }

  const getStroke = (data) => {
    if (searchText.length > 0) {
      return "#C6C7D7";
    } else if (stageValue) {
      if (data.data.stage == stageValue) {
        return "#9192FF";
      }
      return "#C6C7D7";
    } else if (tablesLayout?.length > 0) {
      if (tablesLayout.includes(data.data.name)) {
        return "#9192FF";
      } else {
        return "#C6C7D7";
      }
    }
    return "#9192FF";
  };

  const checkIsParent = (table) => {
    return props.rootOfTable === table ? " disable" : "";
  };

  const getButtonJoint = (n, s) => {
    if (searchText.length > 0) {
      return `<div class="button-joint light ${n.data.name}"></div>`;
    } else if (stageValue) {
      if (n.data.stage == stageValue - 1) {
        return `<div class="button-joint ${n.data.name}"></div>`;
      }
      return `<div class="button-joint light ${n.data.name}"></div>`
    } else if (tablesLayout?.length > 0 && !tablesLayout.includes(n.data?.name)) {
      return `<div class="button-joint light ${n.data.name}"></div>`;
    }
    return `<div class="button-joint ${n.data.name}"></div>`;
  };

  const getAllChildTables = (childTables, tableName, tableConfig) => {
    //const childTables = [];
    const currentTableDetail = tableConfig.tableStructure[tableName];
    if (currentTableDetail?.child?.length > 0) {
      childTables.push(currentTableDetail.child[0]);
      getAllChildTables(childTables, currentTableDetail.child[0], tableConfig);
    }
    if (currentTableDetail?.child?.length > 1) {
      childTables.push(currentTableDetail.child[1]);
      getAllChildTables(childTables, currentTableDetail.child[1], tableConfig);
    }
    return childTables;
  };

  const getTablesLinkedToCurrentTable = (currentTable, tableName, showLayoutClicked) => {
    let tableLayout = [tableName];
    if (!showLayoutClicked) {
      const childTable = currentTable?.child ? currentTable.child : [];
      tableLayout = [...tableLayout, ...childTable]
      return tableLayout;
    } else {
      let parentTable = currentTable?.parent
      while (parentTable) {
        tableLayout.push(parentTable);
        parentTable = formData.tableConfig[indexOfItem].tableStructure[parentTable]?.parent;
      }
      //const childTables = getAllChildTables([],tableName, formData.tableConfig[indexOfItem]);
      //tableLayout = [...tableLayout, ...childTables];
      changeTablesLayout(tableLayout);
      setStageValue(() => 0);
    }
  };

  //delete current mapping from parent table
  const deleteMappingInParentTable = (tableName, tableConfig) => {
    let currentTableDetail = tableConfig.tableStructure[tableName];
    let parentTableDetail = tableConfig.tableStructure[currentTableDetail?.parent];
    let formattedFileMapping = parentTableDetail.fileMapping.map((item) => {
      if (item?.tableName == tableName) {
        return ({
          columnName: item.columnName,
          crmProperty: item.crmProperty,
          data: item.data,
          index: item.index,
          isForeignKey: item.isForeignKey,
          label: item.label,
        })
      }
      return item;
    })
    return [formattedFileMapping, currentTableDetail.parent];
  }

  // deleting the current table and also removing the child from it's parent table
  const getTableConfig = (tableLayout, tableName) => {
    let _tableConfig = formData.tableConfig[indexOfItem];
    let [deleteMappingInParent, parentTable] = deleteMappingInParentTable(tableName, _tableConfig);
    tableLayout.reverse().forEach((key) => {
      let currentTable = _tableConfig.tableStructure[key];
      let parentTableName = currentTable?.parent;
      let parentTableDetail = _tableConfig.tableStructure[parentTableName];

      if (key == tableName && parentTable == parentTableName) {
        _tableConfig.tableStructure[parentTableName].fileMapping = deleteMappingInParent;
      }
      let childTables = parentTableDetail.child.filter(child => child !== key);
      _tableConfig.tableStructure[parentTableName].child = childTables;
      _childTableForMapping = childTables?.length > 0 ? childTables[childTables.length - 1] : parentTableName;
      delete _tableConfig.tableStructure[key];
    })
    let updatedData = { ...formData };
    updatedData.tableConfig[indexOfItem] = _tableConfig;
    return updatedData.tableConfig;
  }
  //   props.setClick(addNode);

  // We need to manipulate DOM
  useLayoutEffect(() => {
    if (props.data && d3Container.current) {
      if (!chart) {
        chart = new OrgChart();
      }
      chart
        .container(d3Container.current)
        .data(props?.data?.length ? props?.data : [])
        .nodeWidth((d) => 300)
        .nodeHeight((d) => getNodeHeight(d.data))
        .childrenMargin((d) => 100)
        .buttonContent(({ node, state }) => {
          return getButtonJoint(node, state);//`<div class="button-joint"></div>`;
        })
        .nodeContent(function (d, i, arr, state) {
          const { data } = d;
          const color = "#FFFFFF";
          return `
          <div class="tree-node-container body ${stageNodeClass[data?.stage] + getClassName(data)}"> 
          <div class="tnc-left ${searchText && getClassName(data) === " highlight" ? " highlight" : ""}">
            <div class="tnc-level ${searchText && getClassName(data) === " highlight" ? " highlight" : ""}">${data?.stage}</div>
          </div>
          <div class="tnc-middle">${data?.name}</div>
          <div class="tnc-right ${data?.name}"><div class="bg-icon bg-icon20 icon-black bg-more-horizontal bg-cursor"</div>
        </div>
  `;
        })
        .linkUpdate(function (d, i, arr) {
          d3.select(this).attr("stroke", getStroke(d));
          d3.select(this).attr("stroke-width", "1");
        })
        .compact(false)
        .render()
        .expandAll();
    }
    addClickEvents();
  }, [props.data, d3Container.current, searchText, stageValue, tablesLayout]);

  const addClickEvents = () => {
    const icons = document.querySelectorAll(".tnc-right");
    //const container = document.querySelectorAll(".org-tree-chart-container");

    icons.forEach((icon, index) => {
      icon.addEventListener('click', (event) => {

        const iconContentPrev = document.querySelector('.icon-content');
        const buttonJointPrev = document.querySelector(`.button-joint.none`);

        if (iconContentPrev && !event.target.closest('.icon-content')) {
          iconContentPrev.remove();
          buttonJointPrev.classList.remove('none');
        }

        const buttonJoint = document.querySelector(`.button-joint.${icon.classList[1]}`);
        if (buttonJoint) {
          buttonJoint.classList.add('none');
        }
        const iconContent = document.createElement('div');
        iconContent.classList.add('icon-content');
        iconContent.innerHTML = `
        <div class="table-dropdown-menu-item table-layout">
         <div class="menu-icon-wrapper view">
           <div class="bg-icon bg-icon16 bg-eye"></div>
         </div>
         <span class="menu-lable">View Table</span></div>
        </div>
        <div class="table-dropdown-menu-item table-layout">
         <div class="menu-icon-wrapper edit">
          <div class="bg-icon bg-icon16 bg-git-branch"></div>
         </div>
         <span class="menu-lable">Show Mapping</span>
        </div>
        <div class="table-dropdown-menu-item table-layout ${checkIsParent(icon.classList[1])}" style="border-top: 0.5px solid #D1D1FF">
         <div class="menu-icon-wrapper delete">
          <div class="bg-icon bg-icon16 bg-trash-2"></div>
         </div>
         <span class="menu-lable">Delete Database</span>
        </div>
    `;

        icon.appendChild(iconContent);

        const menuItems = document.querySelectorAll('.table-dropdown-menu-item.table-layout');
        menuItems.forEach((item, index) => {
          if (index === 0) {
            item.onclick = function () {
              changeFormData({
                ...formData,
                mappingOverlay: 1,
                childTableForMapping: icon.classList[1],
                showTableStructure: false,
                showLayout: false,
              });
            }
          } else if (index === 1) {
            item.onclick = function () {
              const currentTable = formData.tableConfig[indexOfItem].tableStructure[icon.classList[1]];
              getTablesLinkedToCurrentTable(currentTable, icon.classList[1], true);

            }
          } else {
            item.onclick = function () {
              const currentTable = formData.tableConfig[indexOfItem].tableStructure[icon.classList[1]];
              const tableLayout = getTablesLinkedToCurrentTable(currentTable, icon.classList[1], false);
              changeFormData({
                ...formData,
                showLayout: true,
                tableConfig: getTableConfig(tableLayout, icon.classList[1]),
                childTableForMapping: _childTableForMapping,
              })
            }
          }
        });

        event.stopPropagation();
      });
    });

    document.addEventListener('click', (event) => {

      const iconContent = document.querySelector('.icon-content');
      const buttonJoint = document.querySelector('.button-joint.none');

      if (iconContent && !event.target.closest('.icon-content')) {
        iconContent.remove();
        buttonJoint.classList.remove('none');
      }
    });
  };
  return (
    <div className="org-tree-chart-container" onClick={() => { setStageValue(0); changeTablesLayout(() => []); }}>
      <div ref={d3Container} />
      <div className="flow-chart-actions">
        <div className="action" onClick={() => chart.zoomIn()}>
          <Tooltip
            label={<div className="bg-icon bg-zoom-in" style={{ backgroundColor: "#5A57E2 !important" }}></div>}
            variant="primary"
            description={<span>Zoom in</span>}
            placement="bottom"
          />
        </div>
        <br />
        <div className="action" onClick={() => chart.zoomOut()}>
          <Tooltip
            label={<div className="bg-icon bg-zoom-out" style={{ backgroundColor: "#5A57E2 !important" }}></div>}
            variant="primary"
            description={<span>Zoom out</span>}
            placement="top"
          />
        </div>
      </div>
    </div>
  );
};
