import { AxiosResponse } from "axios";

import { HTTPClientV2, HTTPClientV3 } from "../http.service";

var qs = require("qs");

export const contactService = {
  async setPrimaryIdentifier(params: any) {
    try {
      const result: AxiosResponse = await HTTPClientV3.post(
        `/contacts/setupIdentifier/`, params
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getDefaultProperties(params: any) {
    try {
      const result: AxiosResponse = await HTTPClientV3.get(
        `/contacts/getDefaultProperties/`
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getPrimaryIdentifier(params: any) {
    try {
      const result: AxiosResponse = await HTTPClientV3.get(
        `/contacts/getPrimaryIdentifier/`
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getContactsList(params: any) {
    try {
      const result: AxiosResponse = await HTTPClientV3.get(
        `/contacts/list`,
        params
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getContactsForLanding(params: any, id: any) {
    try {
      const result: AxiosResponse = await HTTPClientV2.get(
        `/company/landingPage/analytics/landingPageDetail/${id}`,
        params
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getDefaultProperties(params: any) {
    try {
      const result: AxiosResponse = await HTTPClientV3.get(
        `/contacts/getDefaultProperties`,
        params
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async addCustomEntity(data: any) {
    try {
      const result: AxiosResponse = await HTTPClientV3.post(
        `/contacts/addCustomEntity`,
        data
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async addContactPool(data: any) {
    try {
      const result: AxiosResponse = await HTTPClientV3.post(
        `/contacts/uploadToPool`,
        data
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async dbSyncTableMapping(data: any) {
    try {
      const result: AxiosResponse = await HTTPClientV3.post(
        `/contacts/dbSync/tableMapping`,
        data
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async addNewEvent(data: any) {
    try {
      const result: AxiosResponse = await HTTPClientV3.post(
        `/contacts/events/addEvent`,
        data
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getEventList(params: any) {
    try {
      const result: AxiosResponse = await HTTPClientV3.get(
        `/contacts/events/eventList`,
        params
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getDBConnections(params: any) {
    try {
      const result: AxiosResponse = await HTTPClientV3.get(
        `/contacts/dbSync/settingsList`,
        params
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getTableList(id: any) {
    try {
      const result: AxiosResponse = await HTTPClientV3.get(
        `/contacts/dbSync/getTableList/${id}`
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async checkConnection(data: any) {
    try {
      const result: AxiosResponse = await HTTPClientV3.post(
        `/contacts/dbSync/checkConnection`,
        data
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getTableStructure(data: any) {
    try {
      const result: AxiosResponse = await HTTPClientV3.get(
        `/contacts/dbSync/getTableStructure/${data.dbConnectionId}/?tableName=${data.tableName}`
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getEventList(data: any) {
    try {
      const result: AxiosResponse = await HTTPClientV3.get(
        `/contacts/events/eventList`,
        data
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getEventById(id: any) {
    try {
      const result: AxiosResponse = await HTTPClientV3.get(
        `/contacts/events/${id}`
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async addSingleContact(data: any) {
    try {
      const result: AxiosResponse = await HTTPClientV3.post(
        `/contacts/addSingleData/`,
        data
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async filterSegment(data: any) {
    try {
      const result: AxiosResponse = await HTTPClientV3.post(
        `/contacts/segment/filter`,
        data
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async addSegment(data: any) {
    try {
      const result: AxiosResponse = await HTTPClientV3.post(
        `/contacts/segment`,
        data
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getSegmentUsers(id: any, data: any) {
    try {
      const result: AxiosResponse = await HTTPClientV3.get(
        `/contacts/segment/${id}`,
        data
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getImportList(params) {
    try {
      const result: AxiosResponse = await HTTPClientV3.get(
        `/contacts/imports/`,
        params
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getSingleImport(id) {
    try {
      const result: AxiosResponse = await HTTPClientV3.get(
        `/contacts/imports/${id}`,
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async retryImport(id) {
    try {
      const result: AxiosResponse = await HTTPClientV3.post(
        `/contacts/imports/retryImport/${id}`,
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getImportsList(params) {
    try {
      const result: AxiosResponse = await HTTPClientV3.get(
        `/contacts/dbSync/settingsList`, params
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getImportsSettingsList(params) {
    try {
      const result: AxiosResponse = await HTTPClientV3.get(
        `/contacts/imports`, params
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getImportDetail(id) {
    try {
      const result: AxiosResponse = await HTTPClientV3.get(
        `/contacts/imports/${id}`
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async updateLiveStatus(id, data) {
    try {
      const result: AxiosResponse = await HTTPClientV3.put(
        `/contacts/dbSync/settingsStatus/${id}`, data
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async deleteImport(id) {
    try {
      const result: AxiosResponse = await HTTPClientV3.delete(
        `/contacts/dbSync/settings/${id}`
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async updateImportSettings(id, data) {
    try {
      const result: AxiosResponse = await HTTPClientV3.put(
        `/contacts/dbSync/settings/${id}`, data
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },


  async postOptOut(payload:any){
    try {
      const result: AxiosResponse = await HTTPClientV3.post(
        `/contacts/optFromPool`,payload
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },


  async deleteUser(userId:any){
    try {
      const result: AxiosResponse = await HTTPClientV3.get(
        `/contacts/singular/deleteUser/${userId}`,
      );
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  }


}
