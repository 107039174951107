import axios from 'axios';
import store from '../../store/store';
// import {appConstOnError} from '../../store/appStore/appStore.action';
// import {logoutStart,loginStart} from '../../store/auth/auth.action';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {logoutStart} from '../../store/auth/auth.action'
// import '../../style/sweetAlert.scss';

interface errorType {
  response: {
    data: {
      status: {
        code: number;
        message: string
      };
    }
  }
}

const axiosInstance  = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

const axiosInstanceV2  = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_V2
});

const axiosInstanceV3  = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_V3
});

const instanceCreator = ($axios:any) => ({
  getHeader() {
    return $axios.defaults.headers.common;
  },
  saveHeader({key, value}: {key: string; value: string}) {
    $axios.defaults.headers.common[key] = value;
  },
  deleteHeader(key: string) {
    delete $axios.defaults.headers.common[key];
  },
  get(url: string, params?: any) {
    return $axios.get(url, { params });
  } ,
  post(resource: any, data: any) {
    return $axios.post(resource, data);
  },
  put(resource: any, data: any) {
    return $axios.put(resource, data);
  },
  delete(resource: any) {
    return $axios.delete(resource);
  },
  deleteWithBody(resource: any, data: any) {
    return $axios.delete(resource, {data: data});
  },
  customRequest(config: any) {
    return $axios(config)
  },
  successHandler(response: any) {
    return response;
  },
  errorHandler(error: errorType) {
    const {response} = error;
    const {data:{status}} = response;
    if (status.code === 401 && status.message === 'Token Expired'){
      // window.location.href = '/auth/logout';
      store.dispatch(logoutStart());
    }
    console.error(status.message,'NETWORK API ERROR')
    // store.dispatch(appConstOnError({type: 'error', message: status.message}));
    // // if (status.code >= 400 && status.code < 500) {
    // //   store.dispatch(appConstOnError({type: 'error', message: status.message}));
    // // } else {
    // //   store.dispatch(appConstOnError({type: 'error', message: 'Something went wrong'}));
    // // }
    return Promise.reject(error);
  },
  interceptorRef: null,
  mountResponseInterceptor() {
    this.interceptorRef = $axios.interceptors.response.use(
      this.successHandler,
      this.errorHandler
    );
  },
  ejectResponseInterceptor() {
    $axios.interceptors.response.eject(this.interceptorRef);
  }
});

export const HTTPClient = instanceCreator(axiosInstance);
HTTPClient.mountResponseInterceptor();

export const HTTPClientV2 = instanceCreator(axiosInstanceV2);
HTTPClientV2.mountResponseInterceptor();

export const HTTPClientV3 = instanceCreator(axiosInstanceV3);
HTTPClientV2.mountResponseInterceptor();
