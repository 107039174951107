import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { SurveyContext } from "../store/SurveyStateManager";
import QuestionBox from "./QuestionBox";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "react-bootstrap";

export default function Questionnaire() {
  const {
    addQuestionBox,
    questionnaireFormik ,
  } = React.useContext(SurveyContext);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.forEach((item, index)=>{
      item.qOrder = index
    })
    return result;
  };

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      questionnaireFormik?.values?.questions,
      result.source.index,
      result.destination.index
    );
    questionnaireFormik.setFieldValue("questions", items);
  }
  return (
    <>
      <h3 className="font-weight-bold py-3">Questionnaire</h3>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
              <div
                className="questionnaire-form"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {questionnaireFormik?.values?.questions?.map((item, index) => (
                  <Draggable key={`${index}`} draggableId={`question-${index}`} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <QuestionBox question={item} order={index} />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}

        </Droppable>
      </DragDropContext>
      <Button
      className="mx-2 text-white gray-button"
        style={{ marginLeft: "auto" }}
        onClick={addQuestionBox}
      >
        <AddIcon /> Add Question
      </Button>
    </>
  );
}
