import * as Yup from "yup";
export const SURVEY_STEPS_KEY = {
  SURVEY_INFO: 1,
  QUESTIONNAIRE: 2,
  // TARGETING: 3,
  PREVIEW: 3,
};
export const SURVEY_STEPS = {
  [SURVEY_STEPS_KEY.SURVEY_INFO]: "Survey Info",
  [SURVEY_STEPS_KEY.QUESTIONNAIRE]: "Questionnaire",
  [SURVEY_STEPS_KEY.TARGETING]: "Targeting",
  [SURVEY_STEPS_KEY.PREVIEW]: "Preview",
};

export const PRIZE_TYPES = {
  POINTS: 3,
  GEMS: 4,
  COUPON: 1,
  REWARD: 2,
};

export const SURVEY_THE_WHEEL_STATUS = {
  ALL: "all",
  ACTIVE: "1",
  DRAFT: "0",
  COMPLETED: "2",
}

export const PRIZE_SELECT_OPTIONS = [
  { label: "Points", value: PRIZE_TYPES.POINTS },
  { label: "Gems", value: PRIZE_TYPES.GEMS },
  { label: "Coupon", value: PRIZE_TYPES.COUPON },
  { label: "Reward", value: PRIZE_TYPES.REWARD },
];

export const ANSWER_TYPES = {
  // SHORT_ANSWER: "Short Answer",
  // PARAGRAPH_ANSWER: "Paragraph Answer",
  // MULTIPLE_CHOICE: "Multiple Choice",
  // LINEAR_SCALE: "Linear Scale",


  SHORT: '1',
  LONG: '2',
  SLIDER: '3',
  CHECKBOX: '4',
  DROPDOWN: '5',
  RADIO: '6'
};


export const ANSWER_TYPE_OPTIONS = [
  { label: ANSWER_TYPES.SHORT, value: ANSWER_TYPES.SHORT },
  { label: ANSWER_TYPES.LONG, value: ANSWER_TYPES.LONG },
  { label: ANSWER_TYPES.SLIDER, value: ANSWER_TYPES.SLIDER },
  { label: ANSWER_TYPES.CHECKBOX, value: ANSWER_TYPES.CHECKBOX },
  { label: ANSWER_TYPES.DROPDOWN, value: ANSWER_TYPES.DROPDOWN },
  { label: ANSWER_TYPES.RADIO, value: ANSWER_TYPES.RADIO },
  // {
  //   label: ANSWER_TYPES.PARAGRAPH_ANSWER,
  //   value: ANSWER_TYPES.PARAGRAPH_ANSWER,
  // },
  // { label: ANSWER_TYPES.MULTIPLE_CHOICE, value: ANSWER_TYPES.MULTIPLE_CHOICE },
  // { label: ANSWER_TYPES.LINEAR_SCALE, value: ANSWER_TYPES.LINEAR_SCALE },
];

export const ReactSelectValidation = Yup.object().shape({
  label: Yup.string().required(),
  value: Yup.string().required(),
});

export const ReactSelectValidationOptional = Yup.object().shape({
  label: Yup.string(),
  value: Yup.string(),
});

export const STATUS_TYPES = {
  DRAFT: 0,
  SCHEDULED: 1,
  ACTIVE: 2,
  END: 3,
  COMPLETED: 4,
};

export const STATUS_URLS = {
  draft: STATUS_TYPES.DRAFT,
  scheduled: STATUS_TYPES.SCHEDULED,
  active: STATUS_TYPES.ACTIVE,
  end: STATUS_TYPES.END,
  completed: STATUS_TYPES.COMPLETED,
};

function capitalizeFirstLetter() {
  return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
}

String.prototype.capitalize = capitalizeFirstLetter;

/**Generates usable variables for Selects and Key matching by formatting the response from constatnts api for survey */
export const generateOptions = ({
  QUESTION_OPTIONS,
  SURVEY_STATUS,
  BENEFIT_TYPES,
  DEFAULT_TYPE_BENEFITS,
}) => {
  let obj = {};
  if (QUESTION_OPTIONS) {
    let ANSWER_TYPES = QUESTION_OPTIONS;
    let ANSWER_TYPE_OPTIONS = Object.entries(QUESTION_OPTIONS).map((item) => {
      return {
        label: item[0].capitalize(),
        value: item[1],
      };
    });

    obj = { ...obj, ANSWER_TYPES, ANSWER_TYPE_OPTIONS };
  }
  if (SURVEY_STATUS) {

    let STATUS_URLS = {};
    let STATUS_TYPES = {};
    Object.keys(SURVEY_STATUS).forEach((item) => {
      STATUS_URLS = {
        ...STATUS_URLS,
        [item.toLowerCase()]: SURVEY_STATUS[item].status,
      };

      STATUS_TYPES = { ...STATUS_TYPES, [item]: SURVEY_STATUS[item].status };
    });

    obj = { ...obj, STATUS_TYPES, STATUS_URLS };
  }

  if (BENEFIT_TYPES) {
    let PRIZE_SELECT_OPTIONS = Object.entries(BENEFIT_TYPES).map((item) => {
      return { label: item[0].capitalize(), value: item[1] };
    });

    let PRIZE_TYPES = BENEFIT_TYPES;
    obj = { ...obj, PRIZE_TYPES, PRIZE_SELECT_OPTIONS };
  }

  if (DEFAULT_TYPE_BENEFITS) {
    let DefaultTypeOptions = Object.entries(DEFAULT_TYPE_BENEFITS).map(
      (item) => {
        return { label: item[0].capitalize(), value: item[1] };
      }
    );

    obj = { ...obj, DefaultTypeOptions };
  }

  return obj;
};




export const BUCKET_TYPE_COUPON = 1;
export const BUCKET_TYPE_REWARDS = 2;


export const COMPLETED_SURVEY_USERS = {value:1, label:'Users Completed Survey '};
export const INCOMPLETE_SURVEY_USERS = {value:0, label: 'Users Incomplete Survey'};
