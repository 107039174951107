import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import InputSelect from "../../../components/Forms/Select/InputSelect2";

export default function InputModal(props) {
  const { onSave, showModal, onHide } = props;
  const [selectedTable, changeSelectedTable] = useState(null);
  const [err, changeError] = useState(null);

  let TABLES_LIST = props.tableList.map(item => ({ label: item, value: item }));

  const isTableSelected = () => {
    if (!selectedTable) {
      changeError('This field is required');
      return false;
    }
    return true;
  }

  return (
    <Modal show={showModal} onHide={onHide} centered className="standard-modal">
      <Modal.Header>
        <Modal.Title>Select a table that contains this foreign key</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputSelect
          className="top-0"
          label="Select Table"
          options={TABLES_LIST}
          onChange={(e) => {
            changeSelectedTable(e);
            changeError(null);
          }}
          error={err}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={err ? true : false}
          onClick={() => {
            if (isTableSelected()) {
              onSave(selectedTable);
            }
          }}
        >
          Add
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            changeError(null);
            onHide();
          }}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
