import React from "react";
import { useHistory } from "react-router";

const EmptyState = ({ imgUrl, redirectTo, titleText, createButton, footerText }) => {
  const { push } = useHistory();
  const click = () => {
    typeof redirectTo ===  "string" ? push(redirectTo) : redirectTo()
  }
  return (
    <div className="d-flex flex-column justify-content-center align-items-center rounded w-100 mt-5 p-3 empty-state-wrapper custom-dashed-border">
      {titleText && <span className="title">{titleText}</span>}
      <img className="empty-image" src={imgUrl} alt="placholder" />
      <div onClick={() => {
        click()
      }} className="link-container">
        {
          createButton
          &&
          <span>
            <img src="/media/add-icon.svg" alt="add-icon" />
          </span>
        }
        <span className="footer">{footerText}</span>
      </div>
    </div>
  );
};

export default EmptyState;
