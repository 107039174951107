import React, { useState, useEffect, useCallback } from "react";
import DataTableComponent, { initParams } from "../../../components/Table";
// import DebounceSearchInput from "../../../component/debounceSearch";
import Tabs from "../../../components/Tabs";
import ActionCompContainer from "../../../components/Table/_actions";
import { SubHeader } from "../../../../_metronic/layout/components/subheader/SubHeader";
import DataTable from "react-data-table-component";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import {SurveyContext } from '../store/SurveyStateManager';
import { surveyService } from "../../../service/api/survey.service";
import AddIcon from "@material-ui/icons/Add";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Swal from "sweetalert2";
// import ActionCompContainer from "../components/ActionCompContainer";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import withReactContent from "sweetalert2-react-content";
import {
  AdjustOutlined,
  DescriptionOutlined,
  RemoveFromQueueTwoTone,
} from "@material-ui/icons";
import * as moment from "moment";
import PagesList from "../../../utils/PagesList"
import { useDispatch } from 'react-redux';
import { headerActions } from "../../../store/header/header.action";

const SurveyListing = () => {
  const pages = PagesList.LOYALTY.innerPages.LOYALTY_GAMES.innerPages.SURVEY;
  const { type } = useParams();
  const dispatch = useDispatch();
  const {
    STATUS_TYPES, STATUS_URLS, clearData
  } = React.useContext(SurveyContext);
  const [params, setParams] = useState({...initParams, status: 'all'});

  const {
    push,
    location: { pathname },
  } = useHistory();
  const { path, url } = useRouteMatch();

  const [surveys, setSurveys] = useState([]);
  const [totalEntries, setTotalEntries] = useState(0);
  const [totalPages, setPages] = useState(0);
  const { location, ...history } = useHistory();
  const [loading, setLoading] = useState(false);

  const momentize = (date) => {
    return date && date !== "Invalid date"
      ? moment(date, "YYYY-MM-DD hh:mm a").format("hh:mmA, DD/MM/YYYY")
      : "-";
  };

  var columns = [
    {
      name: "SURVEY NAME",
      left: true,
      // cell: (row) => <span>{row.name}</span>,
      cell: ({ id, name, status }) => (
        <div className="table-actions-dropdown d-flex justify-content-between">
          <span>{name || ""}</span>
          { <ActionCompContainer
            options={{
              Active: [
                {
                  label: (
                    <div className="d-flex align-items-center cursor-pointer table-action-color">
                      &nbsp;<div className="bg-icons bg-view" />&nbsp;{" "}
                      <span>View survey</span>
                    </div>
                  ),
                  onClick: () => push(`/loyalty/games/survey/view/${id}`),
                },
                // {
                //   label: (
                //     <div className="d-flex align-items-center cursor-pointer table-action-color">
                //       &nbsp;{" "}
                //       <DescriptionOutlined className="icon-with-bg bg-light mr-2 p-1" />
                //       <span>View analytics</span>
                //     </div>
                //   ),
                //   onClick: () => push(`/survey/${id}/summary`),
                // },
                {
                  label: (
                    <div className="d-flex align-items-center cursor-pointer table-action-color">
                      &nbsp;
                      <AdjustOutlined className="text-danger bg-light mr-2 p-1 icon-with-bg" />{" "}
                      &nbsp;
                      <span className="text-danger">End survey</span>
                    </div>
                  ),
                  onClick: (id) => handleEndSurvey(id),
                }
              ],
              Draft: [
                {
                  label: (
                    <div className="d-flex align-items-center cursor-pointer table-action-color">
                      &nbsp;<div className="bg-icons bg-view" />&nbsp;{" "}
                      <span>View survey</span>
                    </div>
                  ),
                  onClick: () => push(`/loyalty/games/survey/view/${id}`),
                },
                {
                  label: (
                    <div className="d-flex align-items-center cursor-pointer table-action-color">
                      &nbsp;<div className="bg-icons bg-edit" />&nbsp;{" "}
                      <span>Edit survey</span>
                    </div>
                  ),
                  onClick: () => push(`/loyalty/games/survey/edit/${id}`),
                },

                {
                  label: (
                    <div className="d-flex align-items-center cursor-pointer table-action-color">
                      &nbsp;<div className="bg-icons bg-trash" />&nbsp;{" "}
                      <span className="MuiButtonIcon">Delete Survey</span>
                    </div>
                  ),
                  onClick: (id) => handleDeleteSurvey(id),
                },
              ],
              End: [
                {
                  label: (
                    <div className="d-flex align-items-center cursor-pointer table-action-color">
                      &nbsp;<div className="bg-icons bg-view" />&nbsp;{" "}
                      <span>View survey</span>
                    </div>
                  ),
                  onClick: () => push(`/loyalty/games/survey/view/${id}`),
                },
                // {
                //   label: (
                //     <div className="d-flex align-items-center cursor-pointer table-action-color">
                //       &nbsp;{" "}
                //       <DescriptionOutlined className="icon-with-bg bg-light mr-2 p-1" />
                //       <span>View analytics</span>
                //     </div>
                //   ),
                //   onClick: () => push(`/loyalty/games/survey/${id}/summary`),
                // }
              ]
            }[status]}
            trigger={
              <Button variant="light">
                Actions <ArrowDropDownIcon />
              </Button>
            }
            id={String(id)}
          />
        }
        </div>
      ),
    },
    {
      name: "CREATED ON",
      center: true,
      cell: (row) => <span>{momentize(row.createdAt)}</span>,
    },
    {
      name: "TOTAL QUESTIONS",
      center: true,
      cell: (row) => <span>{row.totalQuestions}</span>,
    },
    {
      name: "TOTAL RESPONSES",
      center: true,
      cell: (row) => <span>{row.allResponses}</span>,
    },
    {
      name: "STATUS",
      center: true,
      selector: 'status',
      cell: ({status}) => {
        return <div className = {`survey-status ${status}`}>{status ==='End'? 'Completed': status}</div>
      },
    },
  ];

  const handleDeleteSurvey = async (id) => {
    withReactContent(Swal)
      .fire({
        title: `Are you sure you want to delete this survey?`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        denyButtonText: "No",
      })
      .then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          surveyService
            .deleteSurvey(id)
            .then((res) => {
              if (res.status === 200) {
                list();
                return withReactContent(Swal).fire(
                  `Deleted survey successfully`,
                  "",
                  "success"
                );
              } else {
                return withReactContent(Swal).fire(res.error, "", "info");
              }
            })
            .catch((e) => {
              return withReactContent(Swal).fire(
                e.response.message,
                "",
                "info"
              );
            });
        } else if (result.isDenied) {
          return;
        }
      });
  };

  const list = async (obj = null) => {
    if(!loading) {
      setLoading(true);
      try {
        let data = await surveyService.getSurveyByStatus(obj || params);
        if (data.data.docs) {
          setSurveys(data.data.docs);
          setTotalEntries(data.data.totalEntries);
          setPages(data.data.totalPage);
          dispatch(headerActions.updateHeaderData(data.data.totalEntries))
        } else {
          setSurveys([]);
          setTotalEntries(0);
          setPages(0);
        }
      } catch (err) {
        Swal.fire("Failed", err.response?.data?.status?.message, "error");
        setSurveys([]);
      } finally {
        setLoading(false);
      }
    }

  };

  const handleStatusTypeParam = () => {
    setParams((params) => ({ ...params, status: typeof STATUS_URLS[type] !== 'undefined'?STATUS_URLS[type]: 'all' }));
  };

  const handleEndSurvey = (id) => {
    withReactContent(Swal)
      .fire({
        title: `Are you sure you want to end this survey?`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        denyButtonText: "No",
      })
      .then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          surveyService
            .endSurvey(id)
            .then((res) => {
              if (res.status === 200) {
                list();
                return withReactContent(Swal).fire(
                  `Survey ended successfully`,
                  "",
                  "success"
                );
              } else {
                return withReactContent(Swal).fire(res.error, "", "info");
              }
            })
            .catch((e) => {
              Swal.fire("Failed", e.response?.data?.status?.message, "error");
            });
        } else if (result.isDenied) {
          return;
        }
      });
  };


  useEffect(() => {
    setTimeout(() => {
      list(params);
    })
  }, [params]);

  useEffect(() => {
    handleStatusTypeParam();
  }, [type]);

  useEffect(() => {
    clearData();
  }, [])

  const tabs = React.useMemo(() => {
    return {
      all: pages.innerPages.ALL_SURVEY.url,
      active: pages.innerPages.ACTIVE_SURVEY.url,
      drafts: pages.innerPages.DRAFT_SURVEY.url,
      completed: pages.innerPages.COMPLETED_SURVEY.url,
    };
  }, []);

  const tabOptions = React.useMemo(
    () => [
      { label: "All", value: tabs.all },
      { label: "Active", value: tabs.active },
      { label: "Drafts", value: tabs.drafts },
      { label: "Completed", value: tabs.completed },
    ],
    [tabs]
  );


  return (
    <div className='container my-5 py-5'>
      <div className="ufyndPageWrapper">
        <div className='card border-0'>
          <div className='card-header border-0'>
            <h2>
              <span className="float-right ">
                <Button
                  className="ufyndBtn ufyndBtn_primary"
                  style={{ marginLeft: "auto" }}
                  color="primary"
                  onClick={() => {
                    history.push("/loyalty/games/survey/create-survey");
                  }}
                >
                  <AddIcon/> Create Survey
                </Button>
              </span>
              <SubHeader />
            </h2>
          </div>
          {/* <Tabs
            selected={selected}
            options={[
              { label: "All", value: pages.innerPages.ALL_SURVEY.url },
              { label: "Active", value: pages.innerPages.ACTIVE_SURVEY.url },
              // { label: "Scheduled", value: "scheduled" },
              { label: "Drafts", value: pages.innerPages.DRAFT_SURVEY.url },
              { label: "Completed", value: pages.innerPages.COMPLETED_SURVEY.url }
            ]}
            onChange={(e) => {
              console.log(e)
              push(`${baseUrl}/${e}`)
            }}
          /> */}
           <Tabs
            selected={url}
            options={tabOptions}
            onChange={(e) => {
              push(e)
            }}
          />
          <div className="card-body">
            <DataTableComponent
              isLoading={loading}
              label={"Surveys"}
              columns={columns}
              data={{data: surveys, totalEntries: totalEntries}}
              onChangeParams={setParams}
              params={params}

              imgUrl="/media/Presenting.png"
              redirectTo="/loyalty/games/survey/create-survey"
              titleText="There are no surveys to display"
              createButton={true}
              footerText="Create a new survey"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyListing;
