import React from "react";
import BrandLogo from "../../../_metronic/layout/components/brand/BrandLogo";
import ResetLinkImg from "./assets/expired-reset-link.svg";
import VerificationLinkImg from "./assets/expired-verification-link.svg";

import "./styles.scss";

const ExpiredLink = (props) => {
  const { type } = props;

  let component = type === "reset" ? <ResetLinkBody /> : type === "verification" ? <VerificationLinkBody /> : null;

  return (
    <div className="expired-link-container">
      <div className="elc-header">
        <BrandLogo />
        <span> UniSuite</span>
      </div>
      <div className="elc-main">
        <div className="elc-main-body">{component}</div>
      </div>
    </div>
  );
};

const ResetLinkBody = () => {
  return (
    <>
      <h3>Oops! Password Reset link expired. </h3>
      <img src={ResetLinkImg} alt="" />
      <p>
        Go to <a href="./">unisuite.com</a> to generate a new password reset link.
      </p>
    </>
  );
};

const VerificationLinkBody = () => {
  return (
    <>
      <h3>Oops! Verification link expired. </h3>
      <img src={VerificationLinkImg} alt="" />
      <p>
        Go to <a href="./">unisuite.com</a> to generate a new password reset link.
      </p>
    </>
  );
};

export default ExpiredLink;
