import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import addFunnel from "highcharts/modules/funnel";
import EmptyBarChart from './assets/EmptyBarChart';
import './styles/styles.scss';
import {highchartsColors} from '../../constants/constants';


interface Props {
  data: Object[],
  conversionRate: number
}

Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: 'Inter, Helvetica, "sans-serif"',
    },
  },
});

export const SmsConvertionRatio: React.FC<Props> = (props) => {
  const [chartLoaded, setChartLoaded] = useState(false);
  const {data, conversionRate, lable} = props;

  const options = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
				height: 150
    },
    title: {
        text: `${conversionRate?conversionRate: 0} %`,
        align: 'center',
        verticalAlign: 'middle',
        y: 25
    },
    tooltip: {
				enabled: true,
        // pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
        pie: {
            dataLabels: {
                enabled: false,
                distance: -50,
                style: {
                    fontWeight: 'bold',
                    color: 'white'
                }
            },
            startAngle: 0,
            endAngle: 360,
            center: ['50%', '50%'],
            size: '120%'
        }
    },
    series: [{
        type: 'pie',
        name: lable? lable: 'Sms',
        innerSize: '70%',
        data: data
    }],
		colors: ["#1BC5BD","#EEEEEE"]
	};

  return (
    <>
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      constructorType={"chart"}
    />
    {
      props.isEmpty?
      <div className='graph-empty-state'>
        <EmptyBarChart />
      </div>:
      null
    }
    </>
  );
};

export default SmsConvertionRatio;
