import keyMirror from "key-mirror";

export const campaignFormSteps = keyMirror({
  TYPE: null,
  SETUP: null,
  WHO: null,
  WHAT: null,
  WHEN: null,
  OVERVIEW: null,

  // no need for label
  ADD_TEMPLATE_DETAIL: null,
  ADD_EMAIL_SETTINGS: null,
  SELECT_TEMPLATE: null,
});

export const campaignFormStepsLabelMapping = {
  [campaignFormSteps.TYPE]: "Type",
  [campaignFormSteps.SETUP]: "Setup",
  [campaignFormSteps.WHO]: "Who",
  [campaignFormSteps.WHAT]: "What",
  [campaignFormSteps.WHEN]: "When",
  [campaignFormSteps.OVERVIEW]: "Overview",

  // no need for label
  [campaignFormSteps.ADD_TEMPLATE_DETAIL]: "ADD_TEMPLATE_DETAIL",
  [campaignFormSteps.ADD_EMAIL_SETTINGS]: "ADD_EMAIL_SETTINGS",
  [campaignFormSteps.SELECT_TEMPLATE]: "SELECT_TEMPLATE",
};

export const tableInitParams = {
  sort: "desc",
  filters: "active",
  pageNo: 1,
  pageSize: 10,
};

export const highchartsColors = [
  "#1BC5BD",
  "#3551DD",
  "#8085E9",
  "#A8E980",
  "#FF8D08",
  "#FFC130",
  "#3793A7",
  "#F5EF55",
  "#9C41D4",
  "#3EAF86",
  "#418DD4",
  "#41D4A8",
  "#3699FF",
  "#EC758B",
  "#D857B4",
];
