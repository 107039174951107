import * as headerActionTypes from '../header/header.actiontype'

const initialState: { count: number | string | null } = {
  count: null,
};




const HeaderData  = (state = initialState, action:{type:String, data:any}) => {
    switch(action.type){
      case headerActionTypes.UPDATE_HEADER_DATA:
        return {
          ...state,
          count: action.data
        }
   
      default:
        return state;
    }
  }
  
  export default HeaderData;
  
