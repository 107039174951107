import keyMirror from "key-mirror";

export const campaignActionTypes = keyMirror({
	FETCH_SELECTED_TEMPLATE: null,
	SET_TEMPLATE: null,
	SAVE_TEMPLATE: null,
	SAVING_TEMPPLATE: null,
	SUCCESS_SAVE_TEMPLATE: null,
	FAILURE_SAVE_TEMPLATE: null,
	RESET_SAVE_TEMPLATE: null,
	FETCH_SAVED_SMS_TEMPLATES: null,
	SET_SAVED_SMS_TEMPLATES: null,
	FETCH_LIBRARY_SMS_TEMPLATES: null,
	SET_LIBRARY_SMS_TEMPLATES: null,
	SET_CAMPAIGN_NAME: null,
	CLEAR_CAMPAIGN_DATA: null,
	SET_SMS_LIBRARY_TEMPLATES: null,
	FETCH_SMS_LIBRARY_TEMPLATES: null,
	CHANGE_CAMPAIGN_NAME_MODAL: null,
	SET_DYNAMIC_MAPPING: null,
	SET_COMPANY_SMS_CHANNEL: null,
	SET_CAMPAIGN_PROVIDER_FORM_DATA: null,
	SET_SELECTED_SEGMENT_DETAIL: null,
	SET_IS_DLT_APPROVED_STATUS: null,
	UPDATE_SELECTED_TEMPLATE_DATA: null,
	CHANGE_SHOW_SAVE_TEMPLATE_MODAL: null,
	CAMPAIGN_ON_SUBMIT_HANDLER: null,
	CAMPAIGN_CHANGE_CREATE_CAMPAIGN_STEP: null,
	CAMPAIGN_GO_TO_NEXT_STEP: null,
	CAMPAIGN_GO_TO_PREV_STEP: null,
	FETCH_CAMPAIGN_CHANNELS: null,
	SAVE_CAMPAIGN_CHANNELS: null,

	SET_CREATE_EDIT_CAMPAIGN_FORM: null,
	CHANGE_CAMPAIGN_CHANNEL_ID: null,
	CHANGE_COMPANY_CHANNEL_ID: null,
	CHANGE_CAMPAIGN_SEGMENT: null,
	UPDATE_CAMPAIGN_FORM_ERRORS: null,
	CHANGE_SCHEDULE_DATA: null,
	RESET_CAPAIGN_FORM_DATA: null,
	CHANGE_SMS_PROVIDER_DATA: null,
	CAMPAIGN_CREATED_SUCCESSFULL: null,
	SMS_CAMPAIGN_ON_SUBMIT_HANDLER: null,
	WHATSAPP_CAMPAIGN_ON_SUBMIT_HANDLER: null,
	GET_SAVED_SMS_TEMPLATE_SINGLE: null,
	SAVE_TEMPLATE_SETTING_TEMPLATE: null,
	SET_WHATS_APP_CAMPAIGN_PROVIDER_FORM_DATA: null,
	FETCH_SAVED_WHATS_APP_TEMPLATES: null,
	FETCH_WHATS_APP_LIBRARY_TEMPLATES: null,
	SAVE_WHATS_APP_TEMPLATE: null,
	GET_SAVED_WHATS_APP_TEMPLATE_SINGLE: null,
	SAVE_CAMPAIGN_AS_DRAFT: null,
	EMAIL_CAMPAIGN_ON_SUBMIT_HANDLER: null,
	SET_EMAIL_CAMPAIGN_PROVIDER_FORM_DATA: null,
	SELECT_EMAIL_TEMPLATE: null,
	SAVE_EMAIL_TEMPLATE: null,
	CAMPAIGN_CHANGE_CAMPAIGN_SUB_STEP: null,
	CAMPAIGN_GO_TO_NEXT_SUB_STEP: null,
	CAMPAIGN_GO_TO_PREV_SUB_STEP: null,
	CHANGE_EMAIL_SETTINGS: null,
	CHANGE_EMAIL_PREVIEW_MODAL_STATUS: null,
	FETCH_EMAIL_LIBRARY_TEMPLATES: null,
	UPDATE_EMAIL_ATTACHMENT: null,
	CHANNEL_ADDED_SUCCESSFULLY: null
});
