import React from "react";
import AsyncSelect from "react-select/async";
import { loyaltyService } from "../../../service/api/loyalty.service";
import { Form } from "react-bootstrap";

export default function CouponsAsyncSelect({
  updateCouponValue = () => {},
  category,
  value = null,
  disabled = false,
  onBlur = () => {},
  label = "",
  required = false,
  className = "",
  error = "",
  extraParams = {},
  isUsed = 0,
}) {
  const [params, setParams] = React.useState({
    status: "active",
    sort: "desc",
    categorization: category,
    pageSize: 25,
    pageNo: 1,
    isUsed,
    ...extraParams
  });

  const [couponsList, setCouponsList] = React.useState([]);

  const getCouponList = async () => {
    try {
      let data = await loyaltyService.getCoupons(params);
      setCouponsList(data.data.data.couponList);
    } catch (err) {}
  };

  const promiseOptions = async (inputValue) => {
    try {
      let result = await loyaltyService.getCoupons({
        ...params,
        pattern: inputValue,
      });
      return result.data.data.couponList;
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    getCouponList();
  }, []);

  let obj = { couponName: value?.label, id: value?.value };

  return (
    <Form.Group className={className}>
      {label && (
        <Form.Label>
          {label}
          {required && <span className="required-input-field">*</span>}
        </Form.Label>
      )}
      <AsyncSelect
        defaultOptions={couponsList}
        getOptionLabel={(option) => option.couponName}
        getOptionValue={(option) => option.id}
        cacheOptions
        placeholder="Search Coupon"
        onChange={(e) =>
          updateCouponValue({ label: e.couponName, value: e.id })
        }
        loadOptions={promiseOptions}
        value={obj}
        isDisabled={disabled}
        onBlur={onBlur}
        required
      />
      {error && <p className="invalid-feedback d-block">{error}</p>}
    </Form.Group>
  );
}
