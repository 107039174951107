import React from 'react';
import DateSearch from './DatePicker';
import {Form, InputGroup} from 'react-bootstrap';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import '../styles/DateTime.module.scss';

export interface Props {
	currentState: {
		dateStart: any;
		dateEnd: any;
	};
	handleFromDate: (date: any) => void,
	handleToDate: (date: any) => void,
	resetCalendar: Function

}

const Range: React.FC<Props> = ({currentState, handleFromDate, handleToDate, resetCalendar}) => {

  return (
    <div className="dateSearch">
      <Form.Label>From :</Form.Label>
      <DateSearch
        // minDate={new Date('2001-01-01')}
        minDate={`01-01-2000`}
        currentDate={currentState.dateStart}
        onDateChange={handleFromDate}
      />
      <Form.Label>To :</Form.Label>
      <DateSearch
        // minDate={currentState.dateStart !== null ? currentState.dateStart : new Date('2001-01-01') }
        minDate={`01-01-2000`}
        currentDate={currentState.dateEnd}
        onDateChange={handleToDate}
      />
      <InputGroup.Append className="ufyndSearchAppend" onClick={() => resetCalendar()}>
				<InputGroup.Text className="ufyndSearchIcon"><HighlightOffIcon /></InputGroup.Text>
			</InputGroup.Append>
    </div>
  )
}

export default Range;
