import React from "react";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import EmptyBarChart from './assets/EmptyBarChart';
import './styles/styles.scss';

Highcharts.setOptions({
    chart: {
      style: {
        fontFamily: 'Inter, Helvetica, "sans-serif"',
      },
    },
  });

const LinearChart = ({
  categories, data, title, xAxis, yAxis, detailStats,
  detailStats2, isEmpty, emptyData, className, colors
}) => {
    const options = {
        chart: {
            type: 'column',
            height: 300
        },
        title: {
            text: title,
            align: 'left',
            style: {
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontSize: '12px',
              fontWeight: '600',
              color: '#212121'
            }
        },
        xAxis: {
            title: {
                text: xAxis,
                style: {
                    fontSize: '12px',
                    fontWeight: 500,
                    fontFamily: 'Inter, Helvetica, "sans-serif"',
                    lineHeight: 1.4,
                    color: '#b5b5c3',
                    textTransform: 'uppercase'
                }
            },
            labels: {
                style: {
                    fontSize: '12px',
                    fontWeight: 500,
                    fontFamily: 'Inter, Helvetica, "sans-serif"',
                    lineHeight: 1.4,
                    color: '#181c32',
                    whiteSpace: "nowrap"
                },
                rotation: -20,
                skew3d: true,
                x: 15,
            },
            categories: isEmpty? emptyData.categories: categories,
            // crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: yAxis,
                style: {
                    fontSize: '12px',
                    fontWeight: 500,
                    fontFamily: 'Inter, Helvetica, "sans-serif"',
                    lineHeight: 1.4,
                    color: '#b5b5c3',
                    textTransform: 'uppercase'
                }
            },
            labels: {
                y:5,
                formatter: function () {
                    return this.axis.defaultLabelFormatter.call(this)+'%';
                },
                style: {
                    fontSize: '12px',
                    fontWeight: 500,
                    fontFamily: 'Inter, Helvetica, "sans-serif"',
                    lineHeight: 1.4,
                    color: '#181c32',

                }
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        legend: {
           enabled: false
       },
        series: isEmpty? emptyData.data: data,
        colors: isEmpty? ['#E0E0E0']:
          colors? colors: ["#ec9b3d","#1bc5bd",  "#6086e7", "#922dc2", "#d622c4", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"]
    }

    window.Highcharts = Highcharts;

    return(
        <div className={className}>
            <div className="row">
                <div className='col-xl-12'>
                    <div className={"card card-custom card-stretch" + ((detailStats || detailStats2) ? ' card-detail-stats' : '')}>
                        <div className={'chart' + ((detailStats || detailStats2) ? ' card-left' : '')}>
                            <div className="">
                            <div className="row">
                                <div className="col-12 highchart-content">
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={options}
                                    />
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            {
              isEmpty?
              <div className='graph-empty-state'>
                <EmptyBarChart />
              </div>:
              null
            }
        </div>
    )

}

export default LinearChart;
