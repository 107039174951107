import React, { useState, useEffect } from "react";
// import { TableWrapper, TableHeader } from "../../../component/tableWrapper";
import DataTable from "react-data-table-component";
import ActionDropdown from "../components/ActionDropdown";
import { useHistory, useParams } from "react-router-dom";
import SurveyTabs from "../components/SurveyTabs";
import { Button } from "react-bootstrap";
import SurveyWrapper from "../components/SurveyWrapper";
import { surveyService } from "../../../service/api/survey.service";
import { Spinner } from "react-bootstrap";

const Responses = (props) => {
  const [questions, setQuestions] = React.useState([
    // {id: 1, qId: null, name: "Submitted At"},
    // {id: 2, qId: 378, name: "yvjkbhk"},
    // {id: 3, qId: 379, name: "vjvhvhj"},
    // {id: 4, qId: 380, name: "57t6"},
    // {id: 5, qId: 381, name: "uyedtdt"}
  ]);
  const [responses, setResponses] = React.useState([
    // {id: 1, answer: "2021-10-12 08:07 pm", other: null, ansId: null},
    // {id: 2, answer: "1", other: null, ansId: 749},
    // {id: 3, answer: "161", other: null, ansId: 750},
    // {id: 4, answer: "yjbnnkl ", other: null, ansId: 751},
    // {id: 5, answer: "31351212", other: null, ansId: 752}
  ]);
  const [loading, setLoading] = React.useState(true);
  const columns = React.useMemo(() => {
    if (questions && questions.length !== 0) {
      return questions.map((item, index) => {
        return {
          name: item.name,
          left: true,
          cell: (data) => (
            <span>
              {data[index]
                ? data[index].answer
                  ? data[index].answer + " " + (data[index].other || '')
                  : data[index].other
                  ? data[index].other
                  : "-"
                : "-"}
            </span>
          ),
        };
      });
    } else {
      return [];
    }
  }, [questions]);

  const [totalEntries, setTotalEntries] = useState(0);
  const { location, ...history } = useHistory();
  const { id } = useParams();

  const responsesList = () => {
    return surveyService.getSurveyResponses(id);
  };

  const list = () => {
    setLoading(true);
    responsesList()
      .then((res) => {
        if (res.status === 200) {
          let temp = res.data.data.docs.responseData;
          let questions = res.data.data.questions;
          setQuestions(questions);
          setResponses(temp);
          setTotalEntries(res.data.data.totalEntries);
        }
        if (res.errors) {
          // setSurveys([]);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePerRowsChange = () => {};

  const handlePageChange = () => {};

  const paginationRowsPerPageOptions = () => {};

  const handleSorting = () => {};

  const handleSearch = () => {};

  useEffect(() => {
    list();
  }, []);
  return (
    <SurveyWrapper {...props}>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          <div className='card border-0'>
          <div className='card-header border-0'>
            <h2>
              {`${responses?.length || 0} responses`}
            </h2>
          </div>
          <div className="card-body">
            <DataTable
              className="responses-table"
              columns={columns}
              data={responses}
              paginationTotalRows={totalEntries}
              loading={false}
              // onChangeRowsPerPage={handlePerRowsChange}
              // onChangePage={handlePageChange}
              // onSort={handleSorting}
              // paginationRowsPerPageOptions={paginationRowsPerPageOptions}
              paginationServer
              noHeader
            />
          </div>
        </div>
        </>
      )}
    </SurveyWrapper>
  );
};

export default Responses;
