import React from "react";
import { Row, Form, Col } from "react-bootstrap";
import { OnChangeEventProps, InputDataProps } from "./SelectInterface";

const InputSelect: React.FC<InputDataProps> = ({
  id,
  label,
  name,
  value,
  handleInputSelectChange,
  error,
  disabled,
  options,
  required,
  onBlur = () => {},
  className,
  placeholder = "Select a value",
}) => {
  const handleSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value }: OnChangeEventProps = e.target;
    let data = { name, value };
    handleInputSelectChange(data);
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLSelectElement>): void => {
    const { name, value }: OnChangeEventProps = e.target;
    onBlur({ name, value });
  };

  return (
    <Form.Group className={className}>
      {label && (
        <Form.Label>
          {label}
          {required && <span className="required-input-field">*</span>}
        </Form.Label>
      )}
      <Form.Control
        as="select"
        name={name}
        value={!value ? "" : value}
        onChange={handleSelectChange}
        isInvalid={!!error}
        disabled={disabled ? true : false}
        onBlur={handleOnBlur}
      >
        <option
          value=""
          disabled={true}
          selectable="false"
          style={{ display: "none" }}
        >
          {placeholder}
        </option>
        {options &&
          options.map((option, index) => {
            return (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            );
          })}
      </Form.Control>
      {error && <p className="invalid-feedback d-block">{error}</p>}
    </Form.Group>
  );
};

export default InputSelect;
