import {poolActionTypes} from './pool.actiontype.js';
import {authActionTypes} from '../auth/auth.actiontype.js';
import { IPoolReducer } from './types';

const initialState:IPoolReducer = {
  selectedPool: null ,
  entities: [],
  poolMapping: []
}

const Pool  = (state = initialState, action:{type:String, data:any}) => {
  switch(action.type){
    case poolActionTypes.SET_SELECTED_POOL:
      return {
        ...state,
        selectedPool: action.data
      }
    case poolActionTypes.SET_ENTITIES:
      return {
        ...state,
        entities: action.data
      }
      case poolActionTypes.SET_POOL_MAPPING:
        return {
          ...state,
          poolMapping: action.data
        }
     case authActionTypes.CLEAR_USER_DATA:
        return Object.assign({}, initialState);
    default:
      return state;
  }
}

export default Pool;
